import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import StoreBadge from "./StoreBadge";

function DeepLink() {
	const { type, id }: any = useParams();
	const navigateTriggered = useRef<boolean>(false);

	useEffect(() => {
		navigateTriggered.current = true;
	}, []);

	const [showBadge, setShowBadge] = useState(false);

	useEffect(() => {
		try {
			if (navigateTriggered.current) {
				const path = type ? type : "Welcome";
				const pathId = id ? id : "";

				setTimeout(function () {
					setShowBadge(true);
				}, 25);

				window.location.href = `wilya://${path}/${pathId}`;
			}
		} catch (e) {
			console.log(e);
		}
	}, [id, type, setShowBadge]);

	return (
		<>
			{showBadge && (
				<div
					style={{
						padding: 24,
						justifyContent: "center",
						alignItems: "center",
						flex: 1,
					}}
				>
					<p>Please download Wilya app here:</p>
					<StoreBadge
						name="Wilya"
						googlePlayUrl="https://play.google.com/store/apps/details?id=com.gigandtake.workerapp"
						appStoreUrl="https://apps.apple.com/us/app/gig-and-take/id1612768820"
					/>
				</div>
			)}
		</>
	);
}

export default DeepLink;