import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import useGatAdminConfigData from "../../../../controllers/use-gat-admin-config-data";
import {
	resetUpdateWorkerTypeHours,
	updateWorkerTypeHours,
} from "../../../../store/gat-admin-slice";
import { WorkerTypeHours } from "../../../../store/gat-admin-slice-types";

const MAX_HOURS = 168;
export const useWeeklyHourLimitController = (
	workerTypeHour: WorkerTypeHours
) => {
	const dispatch = useDispatch();
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const isSaving = useRef(false);
	const [workerTypeLimit, setWorkerTypeLimit] = useState(workerTypeHour);

	const { updateWorkerTypeHoursStatus } = useGatAdminConfigData();

	const handleHoursChange = (value: number) => {
		const sanitizedValue = isNaN(value)
			? 0
			: Math.max(0, Math.min(value, MAX_HOURS));
		setWorkerTypeLimit((prev) => ({ ...prev, maxHours: sanitizedValue }));
	};

	const handleSave = () => {
		if (workerTypeLimit.maxHours === workerTypeHour.maxHours) return;
		isSaving.current = true;
		setIsFocused(false);
		dispatch(updateWorkerTypeHours([workerTypeLimit]));
	};

	const handleCancel = () => {
		setWorkerTypeLimit((prev) => ({
			...prev,
			maxHours: workerTypeHour.maxHours,
		}));
		isSaving.current = true;
		setIsFocused(false);
	};

	const handleBlur = () => {
		if (isSaving.current) {
			isSaving.current = false;
			return;
		}

		if (workerTypeLimit.maxHours !== workerTypeHour.maxHours) {
			handleSave();
		} else {
			setIsFocused(false);
		}
	};

	const resetUpdateWorkerType = () => {
		dispatch(resetUpdateWorkerTypeHours());
	};

	useEffect(() => {
		if (workerTypeHour) {
			setWorkerTypeLimit(workerTypeHour);
		}
	}, [workerTypeHour]);

	return [
		{
			workerTypeLimit,
			isFocused,
			updateWorkerTypeHoursStatus,
		},
		{
			setIsFocused,
			handleBlur,
			handleHoursChange,
			handleSave,
			handleCancel,
			resetUpdateWorkerType,
		},
	];
};
