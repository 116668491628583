import {
	Box,
	Grid2,
	Skeleton,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import useGatAdminConfigData from "../../../../controllers/use-gat-admin-config-data";
import ActionButtons from "./action-buttons";

interface IMinShiftsGapProps {
	newShiftSettings: any;
	setNewShiftSettings: any;
	//	loading: boolean;
	oldData: any;
	newData: any;
	resetChanges: () => void;
	handleSave: () => Promise<void>;
}

const MinShiftsGap = ({
	newShiftSettings,
	setNewShiftSettings,

	oldData,
	newData,
	resetChanges,
	handleSave,
}: IMinShiftsGapProps) => {
	const [loading, setLoading] = useState(false);
	const { getLocationSettingsStatus } = useGatAdminConfigData();
	const inputRef = useRef<HTMLInputElement>(null);

	const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value === "") {
			setNewShiftSettings((prevSettings: any) => ({
				...prevSettings,
				minShiftsGapHours: 0,
			}));
			setTimeout(() => {
				if (inputRef.current) {
					const inputElement = inputRef.current.querySelector("input");
					if (inputElement) {
						inputElement.select();
					}
				}
			}, 0);
		} else {
			const parsedValue = parseInt(value, 10);
			if (!isNaN(parsedValue) && parsedValue > 0 && parsedValue <= 24) {
				setNewShiftSettings((prevSettings: any) => ({
					...prevSettings,
					minShiftsGapHours: parsedValue,
				}));
			} else if (parsedValue <= 0) {
				setNewShiftSettings((prevSettings: any) => ({
					...prevSettings,
					minShiftsGapHours: 0,
				}));
			}
		}
	};

	const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		event.target.select(); // Always select the entire content on focus
	};

	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		if (e.target.value === "") {
			setNewShiftSettings((prevSettings: any) => ({
				...prevSettings,
				minShiftsGapHours: 0,
			}));
			setTimeout(() => {
				if (inputRef.current) {
					const inputElement = inputRef.current.querySelector("input");
					if (inputElement) {
						inputElement.select();
					}
				}
			}, 0);
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (
			!/[0-9]/.test(e.key) &&
			e.key !== "Backspace" &&
			e.key !== "Delete" &&
			e.key !== "ArrowLeft" &&
			e.key !== "ArrowRight" &&
			e.key !== "ArrowUp" &&
			e.key !== "ArrowDown"
		) {
			e.preventDefault();
		}
	};

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.value = newShiftSettings.minShiftsGapHours.toString();
		}
	}, [newShiftSettings.minShiftsGapHours]);

	useEffect(() => {
		if (getLocationSettingsStatus === "fulfilled") {
			setLoading(false);
		}
	}, [getLocationSettingsStatus]);

	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				padding: "16px",
				marginTop: "32px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Ensure Workers have a minimum gap between Shifts
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Setup a required gap to ensure Workers get sufficient rest between
						Shifts
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{loading || getLocationSettingsStatus === "pending" ? (
						<Skeleton width={50} height={40} />
					) : (
						<Switch
							checked={newShiftSettings.minShiftsGapEnabled ?? false}
							onChange={(e) =>
								setNewShiftSettings((prevSettings: any) => ({
									...prevSettings,
									minShiftsGapEnabled: e.target.checked,
								}))
							}
						/>
					)}
				</Box>
			</Box>
			{newShiftSettings.minShiftsGapEnabled && (
				<Box
					sx={{
						marginTop: "32px",
					}}
				>
					<Grid2 container>
						<Grid2
							width={"100%"}
							sx={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "16px",
										color: "rgba(0, 0, 0, 0.87)",
									}}
								>
									Duration of gap between two Shifts
								</Typography>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									marginLeft: "8px",
								}}
							>
								{loading || getLocationSettingsStatus === "pending" ? (
									<Skeleton width={60} height={55} />
								) : (
									<TextField
										inputRef={inputRef}
										id='duration-between-shifts'
										variant='filled'
										type='number'
										onFocus={handleFocus}
										onBlur={handleBlur}
										onKeyDown={handleKeyDown}
										sx={{
											"& .MuiInputBase-input": {
												background: "#FFF",
												padding: "8px",
											},
											width: "60px",
										}}
										onChange={handleDurationChange}
										value={newShiftSettings.minShiftsGapHours}
									/>
								)}
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "16px",
										color: "rgba(0, 0, 0, 0.87)",
										marginLeft: "8px",
									}}
								>
									Hours
								</Typography>
							</Box>
						</Grid2>
					</Grid2>
				</Box>
			)}
			<ActionButtons
				oldData={oldData}
				newData={newData}
				resetChanges={resetChanges}
				handleSave={handleSave}
				loading={loading}
				getLocationSettingsStatus={getLocationSettingsStatus}
				setLoading={setLoading}
			/>
		</Box>
	);
};

export default MinShiftsGap;
