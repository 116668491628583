// TODO - Type 'any' needs to be fixed.
/* eslint-disable no-mixed-spaces-and-tabs */
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { GridOverlay, useGridApiRef } from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useState } from "react";
import React from "react";
import { useSelector } from "react-redux";

import DataGrid from "../../../../../design/wilya-datagrid";
import useViewport from "../../././../../../utils/use-viewport";

interface RowData {
	shiftTime: string;
	shiftDateTime: string;
	workersRequired: number;
	hoursScheduled: string;
	id: number;
	jobRole: any; // Adjust the type accordingly
	date: string;
	time: { start: string; end: string };
	createdByName: string;
	totalPublishedTime: number;
	shiftDurationInMinutes: number;
	restrictFlexHours: boolean;
	skills?: string[];
}

type ReviewPublishingShiftsTableProps = {
	rows: RowData[];
	columns: any[];
	handleShiftSelection?: (params: any) => void;
	checkAllowedToPublishStatus: string;
	checkAllowedToPublishData: any;
	selectedShifts?: any[];
	timeLeftByJobs: any;
	workerCountByShift: any;
	workerCountByShiftStatus: string;
};

interface RowWithTimeRemaining extends RowData {
	timeRemaining?: number;
	allowedToPublish?: boolean;
	timeExceeded: boolean;
}

function NoShiftsAvailableForPublish() {
	return (
		<GridOverlay>
			<Box display='flex' flexDirection='column' alignItems='center'>
				<CalendarTodayIcon fontSize='large' />
				<Typography>No Draft Shifts for this week.</Typography>
				<Typography>Add some Draft Shifts and then try publishing.</Typography>
			</Box>
		</GridOverlay>
	);
}

const ReviewPublishingShiftsTable = ({
	rows,
	columns,
	handleShiftSelection,
	checkAllowedToPublishStatus,
	checkAllowedToPublishData,
	selectedShifts,
	timeLeftByJobs,
	workerCountByShift,
	workerCountByShiftStatus,
}: ReviewPublishingShiftsTableProps) => {
	const { height } = useViewport();
	const apiRef = useGridApiRef();
	const [loading, setLoading] = useState(true);

	const restrictFlexShifts = useSelector(
		(state: any) => state.user.userData.restrictFlexHours
	);

	const rowsWithTimeRemaining: RowWithTimeRemaining[] = rows.map((row: any) => {
		if (selectedShifts?.includes(row?.id)) {
			return {
				...row,
				timeRemaining:
					!!row && checkAllowedToPublishData[row.id]?.timeRemaining,
				allowedToPublish:
					!!row && checkAllowedToPublishData[row.id]?.allowedToPublish,
				timeExceeded: false,
			};
		}
		return {
			...row,
			timeRemaining: checkAllowedToPublishData[row.id]?.timeRemaining,
			allowedToPublish: checkAllowedToPublishData[row.id]?.allowedToPublish,
			timeExceeded:
				row &&
				timeLeftByJobs[row.jobRole?.id] * 60 <
					row.shiftDurationInMinutes * row.workersRequired,
		};
	});

	const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<
		number[]
	>([]);
	const [expandedRowIds, setExpandedRowIds] = useState<number[]>([]);

	const getDetailPanelContent = useCallback(({ row }: any) => {
		return !row.allowedToPublish && row.timeExceeded ? (
			<Alert severity='error'>{`This will exceed the maximum monthly limit on flex hours for ${row.jobRole.name} in  (${row.timeRemaining} hrs)`}</Alert>
		) : null;
	}, []);

	const getDetailPanelWorkerCount = useCallback(() => {
		return (
			<Alert severity='error'>
				{
					"Unable to publish the shift as there are no eligible workers. Please check."
				}
			</Alert>
		);
	}, []);

	const getDetailPanelHeight = useCallback(() => 50, []);

	useEffect(() => {
		if (!restrictFlexShifts) return;
		const expandedIds: number[] = rows
			.filter((row) => !checkAllowedToPublishData[row.id]?.allowedToPublish)
			.map((row) => row.id);
		setDetailPanelExpandedRowIds(expandedIds);
	}, [rows, checkAllowedToPublishData, selectedShifts, restrictFlexShifts]);

	useEffect(() => {
		if (workerCountByShift) {
			// get ids with count 0
			const ids = Object.keys(workerCountByShift).filter(
				(id) => workerCountByShift[id] === 0
			);
			const expandedIds = ids.map((id) => parseInt(id));
			setExpandedRowIds(expandedIds);
		}
	}, [workerCountByShift]);

	useEffect(() => {
		if (restrictFlexShifts) {
			if (
				checkAllowedToPublishStatus === "fulfilled" &&
				rows.length > 0 &&
				JSON.stringify(checkAllowedToPublishData) !== "{}"
			) {
				setLoading(false);
			} else if (rows.length === 0) {
				setLoading(false);
			}
		} else {
			setLoading(false);
		}
	}, [
		restrictFlexShifts,
		checkAllowedToPublishStatus,
		checkAllowedToPublishData,
		selectedShifts,
		rows,
	]);

	useEffect(() => {
		if ((selectedShifts?.length ?? 0) > 0 && workerCountByShift) {
			// Check if any selected shifts have 0 workers
			const shiftsToDeselect: number[] =
				selectedShifts?.filter(
					(shiftId) => workerCountByShift[shiftId] === 0
				) ?? [];

			if (shiftsToDeselect.length > 0) {
				const newSelection = selectedShifts?.filter(
					(shiftId) => !shiftsToDeselect.includes(shiftId)
				);
				if (handleShiftSelection) {
					handleShiftSelection(newSelection);
				}
			}
		}
	}, [workerCountByShift, selectedShifts, handleShiftSelection]);

	const finalRows = !restrictFlexShifts
		? rows
		: Object.keys(checkAllowedToPublishData).length === 0
		? []
		: rowsWithTimeRemaining;

	return (
		<Box width={1050} height={height - 383} mr='auto' ml='auto'>
			{finalRows.length === 1 ? (
				<DataGrid
					apiRef={apiRef}
					rows={finalRows}
					columns={columns}
					checkboxSelection
					isRowSelectable={(params: any) =>
						params.row.restrictFlexHours && expandedRowIds.length === 0
							? !params.row.timeExceeded
							: expandedRowIds.includes(params.row.id)
							? false
							: true
					}
					hideFooter={true}
					selectionModel={selectedShifts}
					onSelectionModelChange={handleShiftSelection}
					disableRowSelectionOnClick
					disableColumnSelector
					disableDensitySelector
					disableColumnPinning
					disableColumnReorder
					disableColumnResize
					loading={loading || workerCountByShiftStatus === "pending"}
					components={{
						NoRowsOverlay: NoShiftsAvailableForPublish,
					}}
					// getRowHeight={() => "auto"}
					detailPanelExpandedRowIds={[
						...detailPanelExpandedRowIds,
						...expandedRowIds,
					]}
					getDetailPanelContent={
						restrictFlexShifts && expandedRowIds.length === 0
							? getDetailPanelContent
							: getDetailPanelWorkerCount
					}
					getDetailPanelHeight={getDetailPanelHeight}
					sx={{
						border: "none !important",
						"& .MuiDataGrid-columnSeparator": {
							display: "none",
						},
						"& .MuiDataGrid-row": {
							border: "none !important",
						},
						"& .MuiDataGrid-columnHeaderCheckbox": {
							visibility: "hidden",
						},
					}}
				/>
			) : (
				<DataGrid
					apiRef={apiRef}
					rows={finalRows}
					columns={columns}
					checkboxSelection
					isRowSelectable={(params: any) =>
						params.row.restrictFlexHours && expandedRowIds.length === 0
							? !params.row.timeExceeded
							: expandedRowIds.includes(params.row.id)
							? false
							: true
					}
					hideFooter={true}
					onSelectionModelChange={handleShiftSelection}
					disableRowSelectionOnClick
					disableColumnSelector
					disableDensitySelector
					disableColumnPinning
					disableColumnReorder
					disableColumnResize
					loading={loading || workerCountByShiftStatus === "pending"}
					components={{
						NoRowsOverlay: NoShiftsAvailableForPublish,
					}}
					// getRowHeight={() => "auto"}
					detailPanelExpandedRowIds={[
						...detailPanelExpandedRowIds,
						...expandedRowIds,
					]}
					getDetailPanelContent={
						restrictFlexShifts && expandedRowIds.length === 0
							? getDetailPanelContent
							: getDetailPanelWorkerCount
					}
					getDetailPanelHeight={getDetailPanelHeight}
					sx={{
						border: "none !important",
						"& .MuiDataGrid-columnSeparator": {
							display: "none",
						},
						"& .MuiDataGrid-row": {
							border: "none !important",
						},
						"& .MuiDataGrid-columnHeaderCheckbox": {
							visibility: "hidden",
						},
					}}
				/>
			)}
		</Box>
	);
};

export default ReviewPublishingShiftsTable;
