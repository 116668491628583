// TODO - Type 'any' needs to be fixed.
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { PickersDay } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import addDays from "date-fns/addDays";
import endOfWeek from "date-fns/endOfWeek";
import isSameDay from "date-fns/isSameDay";
import isSunday from "date-fns/isSunday";
import isWithinInterval from "date-fns/isWithinInterval";
import enGB from "date-fns/locale/en-GB";
import startOfWeek from "date-fns/startOfWeek";
import subDays from "date-fns/subDays";
import { useState } from "react";
import React from "react";
import { useSelector } from "react-redux";

function getDayNumber(dayName: string): 0 | 1 | 2 | 3 | 4 | 5 | 6 {
	const days: Record<string, 0 | 1 | 2 | 3 | 4 | 5 | 6> = {
		sunday: 0,
		monday: 1,
		tuesday: 2,
		wednesday: 3,
		thursday: 4,
		friday: 5,
		saturday: 6,
	};
	return days[dayName?.toLowerCase()] ?? 1;
}

interface CustomPickersDayProps
	extends React.ComponentProps<typeof PickersDay> {
	dayIsBetween?: boolean;
	isFirstDay?: boolean;
	isLastDay?: boolean;
}

const CustomPickersDay = styled(PickersDay, {
	shouldForwardProp: (prop) =>
		prop !== "dayIsBetween" &&
		prop !== "isFirstDay" &&
		prop !== "isLastDay" &&
		prop !== "selected",
})<CustomPickersDayProps>(
	({ theme, dayIsBetween, isFirstDay, isLastDay, selected }) => ({
		...(dayIsBetween && {
			borderRadius: 0,
			backgroundColor: "rgba(47, 77, 139, 0.08)",
			color: theme.palette.common.black,
		}),
		...(isFirstDay && {
			borderTopLeftRadius: "50%",
			borderBottomLeftRadius: "50%",
		}),
		...(isLastDay && {
			borderTopRightRadius: "50%",
			borderBottomRightRadius: "50%",
		}),
		...(selected && {
			backgroundColor: "rgba(47, 77, 139, 0.08)",
		}),
	})
);

export default function WeekDatePicker({
	anyDateOfSelectedWeek,
	changeDateOfSelectedWeek,
}: any) {
	const [value, setValue] = useState(new Date(anyDateOfSelectedWeek));
	const weekStartDay = useSelector(
		(state: any) => state.user?.userData?.weekStartDay
	);

	const renderWeekPickerDay = (
		day: unknown,
		selectedDates: unknown[],
		pickersDayProps: React.ComponentProps<typeof PickersDay>
	) => {
		const date = day as Date; // Type assertion since we know it's a Date
		if (!value) {
			const { key, ...otherProps } = pickersDayProps;
			return <PickersDay key={key} {...otherProps} />;
		}

		let start = addDays(
			startOfWeek(value, { weekStartsOn: getDayNumber(weekStartDay) }),
			0
		);
		let end = addDays(
			endOfWeek(value, { weekStartsOn: getDayNumber(weekStartDay) }),
			0
		);

		if (isSunday(value)) {
			const newValue = subDays(value, 7);
			start = addDays(startOfWeek(newValue), 1);
			end = addDays(endOfWeek(newValue), 1);
		}

		const dayIsBetween = isWithinInterval(date, { start, end });
		const isFirstDay = isSameDay(date, start);
		const isLastDay = isSameDay(date, end);

		const { key, ...otherProps } = pickersDayProps;

		return (
			<CustomPickersDay
				key={key}
				{...otherProps}
				disableMargin
				dayIsBetween={dayIsBetween}
				isFirstDay={isFirstDay}
				isLastDay={isLastDay}
				showDaysOutsideCurrentMonth={true}
			/>
		);
	};

	const handleChange = (newValue: any) => {
		setValue(newValue);
		changeDateOfSelectedWeek(new Date(newValue));
	};

	return (
		<Box
			sx={{
				".MuiCalendarPicker-root .MuiTypography-root.MuiTypography-caption": {
					margin: 0,
				},
			}}
		>
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
				<StaticDatePicker
					displayStaticWrapperAs='desktop'
					label='Week picker'
					value={value}
					onChange={(newValue) => handleChange(newValue)}
					renderDay={renderWeekPickerDay}
					renderInput={(params) => <TextField {...params} />}
					inputFormat="'Week of' MMM d"
					// disableFuture={disableFuture}
				/>
			</LocalizationProvider>
		</Box>
	);
}
