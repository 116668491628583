import EditIcon from "@mui/icons-material/Edit";
import { Box, Typography } from "@mui/material";
import format from "date-fns/format";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { changeEditWorkerStep } from "../../../../export/workers";
import JobColor from "../../../design/ui/job-color";
import { deCamelCase, formatDate } from "../../../utils/helpers";

interface PrimaryJob {
	name: string;
	id: number;
	hexColor: string;
}
interface WorkerProfile {
	firstName: string;
	lastName: string;
	joiningDate: string;
	companyEmployeeId: string;
	location?: { name: string };
	mobilePhoneNumber?: string;
	managerName?: string;
	workCenter?: { name: string };
	shiftPattern?: { name: string };
	jobTitle?: string;
	workerTypeId: string;
	primaryJob: PrimaryJob;
	workerSubTypeName?: string;
}

interface WorkerType {
	id: string;
	text?: string;
	name?: string;
}

interface EditWorkerDetailsProps {
	workerProfile: WorkerProfile;
	workerTypes: WorkerType[];
}

const EditWorkerDetails: React.FC<EditWorkerDetailsProps> = ({
	workerProfile,
	workerTypes,
}) => {
	const dispatch = useDispatch();
	const { firstName, lastName, joiningDate, companyEmployeeId } = workerProfile;

	const isWorkerSubTypeEnabled = useSelector(
		(state: any) => state.user.userData.workerSubTypeEnabled
	);

	const locationName = workerProfile.location?.name || "-";

	const phoneNumber = workerProfile.mobilePhoneNumber || "-";

	const supervisorName = workerProfile.managerName || "-";

	const workcenterName = workerProfile.workCenter?.name || "-";

	const shiftPattern = workerProfile.shiftPattern?.name || "-";

	const jobTitle = workerProfile.jobTitle || "-";

	const primaryJobName = workerProfile.primaryJob.name;

	const primaryJobColor = workerProfile.primaryJob.hexColor;

	const workerSubType = workerProfile.workerSubTypeName || "-";

	const getWorkerType = useCallback(() => {
		const returnValue = {
			name: "-",
			text: "-",
		};
		const id = workerProfile.workerTypeId;
		if (workerTypes) {
			workerTypes.forEach((workerType) => {
				if (parseInt(workerType.id) === parseInt(id)) {
					if (workerType.text) {
						returnValue.text = workerType.text;
						returnValue.name = workerType.name || "";
					} else if (workerType.name) {
						returnValue.text = deCamelCase(workerType.name);
						returnValue.name = workerType.name;
					}
				}
			});
		}
		return returnValue;
	}, [workerProfile.workerTypeId, workerTypes]);

	return (
		<Box
			sx={{
				borderRadius: "10px",
				border: "3px solid #f4f4f4",
				//pointerEvents: 'none',
			}}
			mb={5}
		>
			<Box p={2}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
				>
					<Typography fontWeight='bold'>Worker details*</Typography>

					<EditIcon
						onClick={() => dispatch(changeEditWorkerStep({ step: 1 }))}
						sx={{ cursor: "pointer" }}
					/>
				</Box>
				<Typography variant='caption'>
					Add in workers in personal information.
				</Typography>
			</Box>
			<Box pl={2} mb={1} display={"flex"}>
				<Box width={250}>
					<Typography variant='subtitle2'>Name</Typography>
				</Box>
				<Box>
					<Typography>{firstName + " " + lastName}</Typography>
				</Box>
			</Box>
			<Box pl={2} mb={1} display={"flex"}>
				<Box width={250}>
					<Typography variant='subtitle2'>Phone Number</Typography>
				</Box>
				<Box>
					<Typography>{phoneNumber}</Typography>
				</Box>
			</Box>
			<Box pl={2} mb={1} display={"flex"}>
				<Box width={250}>
					<Typography variant='subtitle2'>Joining Date</Typography>
				</Box>
				<Box>
					{/* joining date value format : "yyyy-mm-dd" hence avoiding format function from date-fns */}
					<Typography>
						{formatDate({
							dateStr: joiningDate,
							formatType: "NUMERIC_DATE",
							ISOString: false,
						})}
					</Typography>
				</Box>
			</Box>
			{locationName !== "-" && (
				<Box pl={2} mb={1} display={"flex"}>
					<Box width={250}>
						<Typography variant='subtitle2'>Location</Typography>
					</Box>
					<Box>
						<Typography>{locationName}</Typography>
					</Box>
				</Box>
			)}
			<Box pl={2} mb={1} display={"flex"}>
				<Box width={250}>
					<Typography variant='subtitle2'>Workcenter</Typography>
				</Box>
				<Box>
					<Typography>{workcenterName}</Typography>
				</Box>
			</Box>
			<Box pl={2} mb={1} display={"flex"}>
				<Box width={250}>
					<Typography variant='subtitle2'>Worker ID</Typography>
				</Box>
				<Box>
					<Typography>
						{companyEmployeeId.length > 0 ? companyEmployeeId : "-"}
					</Typography>
				</Box>
			</Box>
			<Box pl={2} mb={1} display={"flex"}>
				<Box width={250}>
					<Typography variant='subtitle2'>Job Title</Typography>
				</Box>
				<Box>
					<Typography>{jobTitle}</Typography>
				</Box>
			</Box>
			<Box pl={2} mb={1} display={"flex"}>
				<Box width={250}>
					<Typography variant='subtitle2'>Primary Job</Typography>
				</Box>
				{primaryJobColor && primaryJobName ? (
					<Box display='flex' alignItems='center' gap={0.5}>
						<JobColor color={`#${primaryJobColor}`} />
						<Typography>{primaryJobName}</Typography>
					</Box>
				) : (
					<Typography>-</Typography>
				)}
			</Box>
			<Box pl={2} mb={1} display={"flex"}>
				<Box width={250}>
					<Typography variant='subtitle2'>Worker Type</Typography>
				</Box>
				<Box>
					<Typography>{getWorkerType().text}</Typography>
				</Box>
			</Box>
			{workerSubType && isWorkerSubTypeEnabled && (
				<Box pl={2} mb={1} display={"flex"}>
					<Box width={250}>
						<Typography variant='subtitle2'>Worker Sub Type</Typography>
					</Box>
					<Box>
						<Typography>{workerSubType}</Typography>
					</Box>
				</Box>
			)}
			<Box pl={2} mb={1} display={"flex"}>
				<Box width={250}>
					<Typography variant='subtitle2'>Supervisor</Typography>
				</Box>
				<Box>
					<Typography>{supervisorName}</Typography>
				</Box>
			</Box>
			<Box pl={2} mb={1} display={"flex"}>
				<Box width={250}>
					<Typography variant='subtitle2'>Shift Pattern</Typography>
				</Box>
				<Box>
					<Typography>{shiftPattern}</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default EditWorkerDetails;
