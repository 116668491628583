// TODO - Type 'any' needs to be fixed.
import Dialog from "@mui/material/Dialog";
import isPast from "date-fns/isPast";
import React from "react";
import { useSelector } from "react-redux";

import DialogWrapper from "../../../../../design/dialog-wrapper";
import CNSDialogForm from "./new-shift-dialog-form";
import PastShiftDialog from "./past-shift";

type CNSDialogProps = {
	handleClose: (() => void) | undefined;
	open: boolean;
	draftShiftDate: Date;
	isShiftSwap?: boolean;
	requestComponent?: any;
	isShiftCoverage?: boolean;
};

const CNSDialog: React.FC<CNSDialogProps> = ({
	handleClose,
	open,
	draftShiftDate,
	isShiftSwap,
	requestComponent,
	isShiftCoverage,
}) => {
	return (
		<Dialog
			onClose={(event, reason) => {
				if (reason !== "backdropClick" && handleClose) {
					handleClose();
				}
			}}
			aria-labelledby='add-new-shift'
			open={open}
			PaperProps={{ sx: { maxWidth: "700px" } }}
			fullWidth
		>
			{isPast(draftShiftDate) ? (
				<PastShiftDialog handleClose={handleClose} open={open} />
			) : (
				<DialogWrapper
					dialogTitle={isShiftSwap ? "Approve worker request" : "Create shift"}
					dialogContent={
						<CNSDialogForm
							draftShiftDate={draftShiftDate}
							handleClose={handleClose}
							isShiftSwap={isShiftSwap}
							requestComponent={requestComponent}
							isShiftCoverage={isShiftCoverage}
						/>
					}
				/>
			)}
		</Dialog>
	);
};

export default CNSDialog;
