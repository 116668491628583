import SendIcon from "@mui/icons-material/Send";
import { Box, Button, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { useDispatch } from "react-redux";

import LoadingButton from "../../../../../../ts/design/wilya-loading-button";
import useViewport from "../../../../../utils/useViewport";
import { BORDER_COLOR } from "../../../constants/colors";
import { SCHEDULED_SHIFTS } from "../../../constants/pages";
import useScheduleController from "../../../controllers/schedule-controller";
import { WeekHeaderRow } from "../../week-header-row";
import { WeeklyScheduleDateRangePicker } from "../../weekly-schedule-date-range-picker";
import { RequestNewShiftsReviewDialog } from "./request-new-shifts-review-dialog";
import { Row } from "./request-new-shifts-table-row";

const RequestNewShiftsTable = ({ changePage }) => {
	const [
		{
			anyDateOfSelectedWeek,
			allJobRoles,
			allowFlexibleWorkersForShiftsStatus,
			allowFlexibleWorkersForShiftsErrorMessage,
			weeklyDraftShifts,
		},
		{ selectWeek, getShiftByJobId, resetAllowFlexibleWorkersForShifts },
	] = useScheduleController();
	const { height } = useViewport();
	const dispatch = useDispatch();
	const boxRef = useRef();
	const [scheduleTableHeight, setScheduleTableHeight] = useState();

	const [localJobs, setLocalJobs] = useState([]);
	const [searchValue, setSearchValue] = useState("");

	const [reviewDraftShiftsOpen, setReviewDraftShiftsOpen] = useState(false);

	const changeLocalJobs = (jobs) => {
		setLocalJobs(jobs);
	};

	useEffect(() => {
		if (allJobRoles !== undefined) {
			setLocalJobs(allJobRoles);
		}
	}, [allJobRoles]);

	useEffect(() => {
		// This function calculate X and Y
		const getPosition = () => {
			if (boxRef) {
				try {
					const y = boxRef.current.offsetTop;
					setScheduleTableHeight(height - y - 92);
				} catch (error) {
					// Do nothing
				}
			}
		};
		getPosition();
	}, [height]);

	return (
		<Box>
			<Box
				sx={{
					height: "68px",
					borderBottom: 1,
					borderBottomWidth: 1,
					borderBottomColor: BORDER_COLOR,
					padding: "16px 24px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					zIndex: 9999,
				}}
			>
				<WeeklyScheduleDateRangePicker />
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<LoadingButton
						fullWidth
						onClick={() => {
							setReviewDraftShiftsOpen(true);
							//createFlexDraftShift(weeklyDraftShifts);
						}}
						startIcon={<SendIcon />}
						sx={{
							fontFamily: "Roboto",
							fontSize: "12px",
							padding: "4px 16px",
							color: "#FFFFFF",
							borderColor: "#2F4D8B",
							marginLeft: "16px",
						}}
						loading={allowFlexibleWorkersForShiftsStatus === "pending"}
						variant='contained'
					>
						Publish and Notify
					</LoadingButton>
				</Box>
			</Box>
			<Box
				sx={{
					overflowY: "auto",
					scrollbarGutter: "stable",
				}}
			>
				<Box
					sx={{
						position: "sticky",
						top: 0,
						boxShadow: "0px 11px 8px -10px rgba(204,204,204,0.5)",
						zIndex: 999,
					}}
				>
					<WeekHeaderRow
						allJobRoles={allJobRoles}
						changeLocalJobs={changeLocalJobs}
						datesArrayOfSelectedWeek={selectWeek(anyDateOfSelectedWeek)}
						searchvalue={searchValue}
						setSearchValue={(text) => setSearchValue(text)}
					/>
				</Box>
				<Box
					ref={boxRef}
					sx={{
						height: scheduleTableHeight,
					}}
				>
					<>
						{localJobs.map((job, index) => (
							<Box
								key={`job-week-row-${index}-${job.id}`}
								borderTop={index !== 0 ? 1 : 0}
								borderColor={BORDER_COLOR}
							>
								<Row
									jobName={job.name}
									scheduledShifts={getShiftByJobId(job.id, weeklyDraftShifts)}
									datesArrayOfSelectedWeek={selectWeek(anyDateOfSelectedWeek)}
									searchValue={searchValue}
								/>
							</Box>
						))}
					</>
				</Box>
			</Box>
			<Box
				sx={{
					height: "72px",
					boxShadow: "0px -11px 8px -10px rgba(204,204,204,0.5)",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					<Box
						sx={{
							flex: 1,
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
							padding: "17px 24px",
							borderTop: 1,
							borderTopWidth: 1,
							borderTopColor: BORDER_COLOR,
							borderRadius: "0px 0px 15px 15px",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Button
								onClick={() => changePage(SCHEDULED_SHIFTS)}
								sx={{
									fontFamily: "Roboto",
									fontSize: "12px",
									padding: "4px 0px",
									color: "#2F4D8B",
								}}
							>
								Cancel
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
			{allowFlexibleWorkersForShiftsStatus === "fulfilled" && (
				<Snackbar
					open={allowFlexibleWorkersForShiftsStatus === "fulfilled"}
					autoHideDuration={1000}
					onClose={() => {
						try {
							dispatch(resetAllowFlexibleWorkersForShifts());
							changePage(SCHEDULED_SHIFTS);
						} catch (e) {
							// Do nothing
						}
					}}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='success'>
						Flex Workers Notified
					</MuiAlert>
				</Snackbar>
			)}
			{allowFlexibleWorkersForShiftsStatus === "error" && (
				<Snackbar
					open={allowFlexibleWorkersForShiftsStatus === "error"}
					autoHideDuration={1000}
					onClose={() => {
						try {
							dispatch(resetAllowFlexibleWorkersForShifts());
						} catch (e) {
							// Do nothing
						}
					}}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='error'>
						{allowFlexibleWorkersForShiftsErrorMessage}
					</MuiAlert>
				</Snackbar>
			)}
			{reviewDraftShiftsOpen && (
				<RequestNewShiftsReviewDialog
					open={reviewDraftShiftsOpen}
					handleClose={() => setReviewDraftShiftsOpen(false)}
				/>
			)}
		</Box>
	);
};

export default RequestNewShiftsTable;
