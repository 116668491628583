import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DoDisturbOutlinedIcon from "@mui/icons-material/DoDisturbOutlined";
import PauseCircleOutlinedIcon from "@mui/icons-material/PauseCircleOutlined";
import { Box, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { format, isPast } from "date-fns";
import React from "react";

import JobRoleChip from "../../../design/ui/job-role-chip";
import { formatDate } from "../../../utils/helpers";
import Highlight from "../../Highlight";
import workerNoShow from "../assets/worker_no_show.png";

interface IStatusObjType {
	[key: string]: {
		title: string;
		color: string;
		icon: JSX.Element;
	};
}

const statusObj: IStatusObjType = {
	p: {
		title: "Present",
		color: "#017374",
		icon: <CheckCircleOutlinedIcon color='success' />,
	},
	a: {
		title: "No Show",
		color: "#b00020",
		icon: (
			<img
				src={workerNoShow}
				alt='worker_no_show'
				style={{ color: "#1B5E20" }}
			/>
		),
	},
	t: {
		title: "Tardy",
		color: "#01a299",
		icon: <AccessTimeOutlinedIcon color='warning' />,
	},
	e: {
		title: "Excused Absence",
		color: "#7d5260",
		icon: <PauseCircleOutlinedIcon color='info' />,
	},
	x: {
		title: "Excused Tardy",
		color: "#7d5260",
		icon: <PauseCircleOutlinedIcon color='info' />,
	},
	cancelled: {
		title: "Cancelled",
		color: "#b00020",
		icon: <DoDisturbOutlinedIcon color='error' />,
	},
};

const useDayActivityColumn = (search: string) => {
	const columns = [
		{
			field: "workerName",
			headerName: "Worker Name",
			flex: 1,
			minWidth: 165,
			hideable: true,
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 500,
						fontSize: "14px",
						lineHeight: "24px",
						letterSpacing: "0.17px",
						color: "#000000DE",
					}}
				>
					Worker Name
				</Typography>
			),
			renderCell: (params: GridRenderCellParams) => {
				const workerName = params.row.workerName;
				return (
					<Tooltip title={workerName}>
						<span
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
							}}
						>
							<Highlight
								searchText={search}
								value={workerName !== "" ? workerName : "-"}
							/>
						</span>
					</Tooltip>
				);
			},
			valueGetter: (
				_: string,
				row: {
					workerName: string;
					id: string;
				}
			) => {
				return row.workerName + "-" + row.id;
			},
		},
		{
			field: "shiftStartDate",
			headerName: "Shift Date",
			flex: 1,
			minWidth: 165,
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 500,
						fontSize: "14px",
						lineHeight: "24px",
						letterSpacing: "0.17px",
						color: "#000000DE",
					}}
				>
					Shift Date
				</Typography>
			),
			renderCell: (params: GridRenderCellParams) => {
				if (params.row.isShiftAvailable) {
					const shiftStartDate = params.row.shiftStartDateTime;
					const startDate = format(new Date(shiftStartDate), "MM/dd/yyyy");
					return (
						<Tooltip title={startDate}>
							<span
								style={{
									textOverflow: "ellipsis",
									overflow: "hidden",
								}}
							>
								<Highlight
									searchText={search}
									value={startDate !== "" ? startDate : "-"}
								/>
							</span>
						</Tooltip>
					);
				}
				return "-";
			},
			valueGetter: (
				_: string,
				row: {
					isShiftAvailable: boolean;
					shiftStartDateTime: string;
					shiftId: string;
					id: string;
				}
			) => {
				if (row.isShiftAvailable) {
					const shiftStartDate = row.shiftStartDateTime;
					const startDate = formatDate({
						dateStr: shiftStartDate,
						formatType: "NUMERIC_DATE",
					});
					return startDate + "-" + row.shiftId + "-" + row.id;
				}
				return row.id;
			},
		},
		{
			field: "shiftHours",
			headerName: "Shift Hours",
			flex: 1,
			minWidth: 165,
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 500,
						fontSize: "14px",
						lineHeight: "24px",
						letterSpacing: "0.17px",
						color: "#000000DE",
					}}
				>
					Shift Time
				</Typography>
			),
			renderCell: (params: GridRenderCellParams) => {
				if (params.row.isShiftAvailable) {
					const shiftHour = formatDate({
						dateStr: params.row.shiftStartDateTime,
						formatType: "TIME_RANGE",
						endDateStr: params.row.shiftEndDateTime,
					});

					return (
						<Tooltip title={shiftHour}>
							<span
								style={{
									textOverflow: "ellipsis",
									overflow: "hidden",
								}}
							>
								<Highlight
									searchText={search}
									value={shiftHour !== "" ? shiftHour : "-"}
								/>
							</span>
						</Tooltip>
					);
				}
				return "-";
			},
			valueGetter: (
				_: string,
				row: {
					isShiftAvailable: boolean;
					shiftStartDateTime: string;
					shiftEndDateTime: string;
					shiftId: string;
					id: string;
				}
			) => {
				if (row.isShiftAvailable) {
					const endDate = formatDate({
						dateStr: row.shiftStartDateTime,
						formatType: "TIME_RANGE",
						endDateStr: row.shiftEndDateTime,
					});
					return endDate + "-" + row.shiftId + "-" + row.id;
				}
				return row.id;
			},
		},
		{
			field: "shiftLocation",
			headerName: "Shift Location",
			flex: 1,
			minWidth: 120,
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 500,
						fontSize: "14px",
						lineHeight: "24px",
						letterSpacing: "0.17px",
						color: "#000000DE",
					}}
				>
					Shift Location
				</Typography>
			),
			renderCell: (params: GridRenderCellParams) => {
				const shiftLocation = params.row.shiftLocationName;
				return (
					<Tooltip title={shiftLocation}>
						<span
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
							}}
						>
							<Highlight
								searchText={search}
								value={shiftLocation !== "" ? shiftLocation : "-"}
							/>
						</span>
					</Tooltip>
				);
			},
			valueGetter: (
				_: string,
				row: {
					isShiftAvailable: boolean;
					shiftLocationName: string;
					shiftId: string;
					id: string;
				}
			) => {
				if (row.isShiftAvailable) {
					return row.shiftLocationName + "-" + row.shiftId + "-" + row.id;
				}
				return row.id;
			},
		},
		{
			field: "workcenter",
			headerName: "Workcenter",
			flex: 1,
			minWidth: 165,
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 500,
						fontSize: "14px",
						lineHeight: "24px",
						letterSpacing: "0.17px",
						color: "#000000DE",
					}}
				>
					Workcenter
				</Typography>
			),
			renderCell: (params: GridRenderCellParams) => {
				const workcenter = params.row.shiftWorkcenterName;
				return (
					<Tooltip title={workcenter}>
						<span
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
							}}
						>
							<Highlight
								searchText={search}
								value={workcenter ? workcenter : "-"}
							/>
						</span>
					</Tooltip>
				);
			},
			valueGetter: (
				_: string,
				row: {
					isShiftAvailable: boolean;
					shiftWorkcenterName: string;
					shiftId: string;
					id: string;
				}
			) => {
				if (row.isShiftAvailable) {
					return row.shiftWorkcenterName + "-" + row.shiftId + "-" + row.id;
				}
				return row.id;
			},
		},
		{
			field: "publishedByName",
			headerName: "Published By",
			flex: 1,
			minWidth: 165,
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 500,
						fontSize: "14px",
						lineHeight: "24px",
						letterSpacing: "0.17px",
						color: "#000000DE",
					}}
				>
					Published By
				</Typography>
			),
			renderCell: (params: GridRenderCellParams) => {
				const publishedByName = params.row.publishedByName;
				return (
					<Tooltip title={publishedByName}>
						<span
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
							}}
						>
							<Highlight
								searchText={search}
								value={publishedByName !== "" ? publishedByName : "-"}
							/>
						</span>
					</Tooltip>
				);
			},
			valueGetter: (
				_: string,
				row: {
					isShiftAvailable: boolean;
					publishedByName: string;
					shiftId: string;
					id: string;
				}
			) => {
				if (row.isShiftAvailable) {
					return row.publishedByName + "-" + row.shiftId + "-" + row.id;
				}
				return row.id;
			},
		},
		{
			field: "jobRoleSkills",
			headerName: "Job Role/Skill",
			flex: 1,
			minWidth: 185,
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 500,
						fontSize: "14px",
						lineHeight: "24px",
						letterSpacing: "0.17px",
						color: "#000000DE",
					}}
				>
					Job Role/Skill
				</Typography>
			),
			renderCell: (params: GridRenderCellParams) => {
				const jobName = params.row.jobName;
				const skills = params.row.skills
					? params.row.skills
							.map((skill: { skillName: string }) => skill.skillName)
							.join(", ")
					: "";
				if (jobName) {
					return (
						<Box sx={{ marginTop: "10px" }}>
							<JobRoleChip name={jobName} color={params.row.jobColor} />
						</Box>
					);
				}
				return (
					<Tooltip title={skills}>
						<span
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
							}}
						>
							<Highlight
								searchText={search}
								value={skills !== "" ? skills : "-"}
							/>
						</span>
					</Tooltip>
				);
			},
			valueGetter: (
				_: string,
				row: {
					isShiftAvailable: boolean;
					jobName: string;
					skills: { skillName: string }[];
					id: string;
				}
			) => {
				const jobName = row.jobName;
				const skills = row.skills
					? row.skills
							.map((skill: { skillName: string }) => skill.skillName)
							.join(", ")
					: "";
				if (row.isShiftAvailable) {
					if (jobName) {
						return jobName + " " + row.id;
					}
					return skills + " " + row.id;
				}
				return row.id;
			},
		},
		{
			field: "status",
			headerName: "Status",
			flex: 1,
			minWidth: 165,
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 500,
						fontSize: "14px",
						lineHeight: "24px",
						letterSpacing: "0.17px",
						color: "#000000DE",
					}}
				>
					Status
				</Typography>
			),
			renderCell: (params: GridRenderCellParams) => {
				const shiftAttendanceStatus = params.row.attendanceStatus.toLowerCase();
				return (
					<>
						{isPast(new Date(params.row.shiftEndDateTime)) ? (
							<Box display='flex' alignItems='center' ml={1.25} mt={2}>
								{statusObj[shiftAttendanceStatus]?.icon}
								<Typography
									align='center'
									variant='subtitle2'
									ml={1}
									flexWrap='wrap'
								>
									<Highlight
										value={statusObj[shiftAttendanceStatus]?.title}
										searchText={search}
									/>
								</Typography>
							</Box>
						) : (
							<>--</>
						)}
					</>
				);
			},
			valueGetter: (
				_: string,
				row: {
					isShiftAvailable: boolean;
					attendanceStatus: string;
					shiftId: string;
					id: string;
				}
			) => {
				if (row.isShiftAvailable) {
					return row.attendanceStatus + "-" + row.shiftId + "-" + row.id;
				}
				return row.id;
			},
		},
		{
			field: "supervisorComments",
			headerName: "Supervisor Comments",
			flex: 1,
			minWidth: 200,
			renderHeader: () => (
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 500,
						fontSize: "14px",
						lineHeight: "24px",
						letterSpacing: "0.17px",
						color: "#000000DE",
					}}
				>
					Supervisor Comments
				</Typography>
			),
			renderCell: (params: GridRenderCellParams) => {
				const supervisorComments = params.row.supervisorComments;
				return (
					<Tooltip title={supervisorComments}>
						<span
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
							}}
						>
							<Highlight
								searchText={search}
								value={supervisorComments ? supervisorComments : "-"}
							/>
						</span>
					</Tooltip>
				);
			},
			valueGetter: (
				_: string,
				row: {
					isShiftAvailable: boolean;
					supervisorComments: string;
					shiftId: string;
					id: string;
				}
			) => {
				if (row.isShiftAvailable) {
					return row.supervisorComments + "-" + row.shiftId + "-" + row.id;
				}
				return row.id;
			},
		},
	];

	return columns;
};

export default useDayActivityColumn;
