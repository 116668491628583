/* eslint-disable simple-import-sort/imports */
import CloseIcon from "@mui/icons-material/Close";
import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import useTableHeight from "../../../../../utils/use-table-height";
import useWorkerDetailsInsightController from "../../../controllers/use-insight-worker-details-controller";
import WorkerInsightDetailsTabs from "./worker-insight-details-tabs";
import { WorkerInsightModel } from "../../../slices/insight-report-types";

function WorkerInsightDetails({ workerId, allLocations, sidePanelHeight = 24 }: { workerId: string, allLocations: string[], sidePanelHeight?: number }) {

	const { height, boxRef } = useTableHeight();

	const [
		{
			allSkillLevels,
			workerInsightReportData,
			workerInsightReportDetailsData,
			fetchWorkerInsightDetailsStatus,
			jobSkillLevelMapping
		},
		{
			dispatch,
			closeWorkerInsightDetails
		}

	]: any = useWorkerDetailsInsightController({ workerId });

	const handleOnPanelCloseClick = () => {
		if (dispatch !== undefined && closeWorkerInsightDetails !== undefined) {
			dispatch(closeWorkerInsightDetails());
		}
	};

	const selectedWorker = workerInsightReportData?.find((worker: WorkerInsightModel) => worker.workerId === workerId)

	const calculateWorkerSeniority = (joiningDate: any) => {

		const joinDate = new Date(joiningDate);
		const currentDate = new Date();

		const difference = currentDate.getTime() - joinDate.getTime();

		// Convert the difference from milliseconds to years
		const years = difference / (1000 * 60 * 60 * 24 * 365);

		// Return the seniority in years (rounded to 2 decimal places)
		return years.toFixed(1);
	}

	return (
		<Box
			sx={{ backgroundColor: "white" }}
			height={height + sidePanelHeight}
			overflow='auto'
			ref={boxRef} >

			<Box
				display='flex' width={100}
				sx={{
					cursor: "pointer",
					"&:hover": {
						backgroundColor: "#f8f8f8",
						borderRadius: "5px",
					},
				}} onClick={handleOnPanelCloseClick} mt={4} ml={1} p={2} >

				<CloseIcon />

				<Typography variant='button'>Close</Typography>

			</Box>

			<Box
				display='flex'
				alignItems='center'
				justifyContent='flex-start'
				ml={2}>

				<Box ml={1}>
					<Box display='flex' flexWrap='wrap' alignItems='center' mb={1}>
						<Typography
							variant='h6'
							mr={1}
						>{`${selectedWorker?.firstName} ${selectedWorker?.lastName}`}</Typography>
						<Chip
							sx={{
								"& .MuiChip-label": {
									fontWeight: "bold",
								},
							}}
							label={`${calculateWorkerSeniority(selectedWorker?.joiningDate)} years`}
							size='small'
						/>
					</Box>
					{jobSkillLevelMapping && selectedWorker?.primaryJobName &&
						<Box sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
							flexDirection: "row",
							paddingBottom: "8px",
						}}>
							<Typography sx={{
								fontFamily: "Roboto",
								fontSize: "16px",
								lineHeight: "20px",
							}}>{selectedWorker?.primaryJobName}</Typography>
							<Box sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								backgroundColor: "#F2F5F7",
								padding: "4px 8px",
								borderRadius: "4px",
								marginLeft: "8px"
							}}>
								<Typography sx={{
									fontFamily: "Roboto",
									fontSize: "12px",
									lineHeight: "16px",
									color: "#1C2B36"
								}}>Primary Job</Typography>
							</Box>
						</Box>
					}
				</Box>
			</Box>

			<WorkerInsightDetailsTabs
				allSkillLevels={allSkillLevels}
				workerId={workerId}
				workerInsightDetailsData={workerInsightReportDetailsData}
				fetchWorkerInsightDetailsStatus={fetchWorkerInsightDetailsStatus}
				allLocations={allLocations}
				selectedWorker={selectedWorker}
			/>

		</Box>
	)
}

export default WorkerInsightDetails;