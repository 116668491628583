// TODO - Type 'any' needs to be fixed.
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
	BroadcastMessageDialog,
	CopyShiftsDialog,
	ShiftDetailsDialog,
} from "../../../../export/scheduler";
// import BMessageDialog from "./dialog/bmessage-dialog";
import { MarkAttendanceDialog } from "../../../../export/scheduler";
import {
	CNSDialog,
	EditShiftDialog,
	ReviewPublishingShiftsDialog,
} from "../../../../export/shift-scheduler";
import MobileShiftDetails from "../../../../ts/features/scheduler/shift-details/mobile-shift-details";
import ShiftAttendanceDialog from "../../../../ts/features/scheduler/ShiftAttendance/shift-attendance-dialog";
import useViewport from "../../../../ts/utils/use-viewport";
import MobileReviewShifts from "../../../features/scheduler/reviewPublishingShifts/mobile-review-shifts";
import {
	resetAssignWorkers,
	resetAssignWorkersResponse,
	resetGetOTWorkers,
	resetGetOTWorkersResponse,
	resetSetOTWorkers,
	resetSetOTWorkersResponse,
	resetShiftDetails,
	setOTWorkers,
} from "../store/scheduler-slice";
import PastShiftDialog from "./dialog/create-new-shift-dialog/past-shift";
import DeleteDraftDialog from "./dialog/delete-draft-dialog";
import PublishDraftDialog from "./dialog/publish-draft-dialog";
import ShiftCancelDialog from "./dialog/shift-cancel-dialog";

const SchedulerActions = ({
	state,
	skillState,
	editDraftShiftStatus,
	changeWeekAsyncStatus,
	deleteDraftShiftAsyncStatus,
	isEditShiftDialogOpen,
	isBMessageDialogOpen,
	closeBMessageDialog,
	isCreateNewShiftDialogOpen,
	closeCreateNewShiftDialog,
	draftShiftDate,
	isCreateWeekShiftDialogOpen,
	closeCreateWeekShiftDialog,
	weekPasteDate,
	isShiftDetailsDialogOpen,
	shiftIdForShiftDetailsDialog,
	isShiftAttendanceDialogOpen,
	isReviewPublishingShiftsDialogOpen,
	createDraftShiftAsyncStatus,
	broadcastMessageStatus,
	publishDraftShiftsAsyncStatus,
	renotifyWorkersStatus,
	renotifyWorkersErrorMessage,
	closePastShiftDialog,
	isPastShiftDialogOpen,
	isMarkAttendanceDialogOpen,
	closeMarkAttendanceDialog,
	isShiftCancelDialogOpen,
	isDeleteDraftShiftDialogOpen,
	isPublishDraftShiftDialogOpen,
	/**
	 * Actions
	 */
	setIsShiftDetailsDialogOpen,
	setIsShiftAttendanceDialogOpen,
	closeReviewPublishShiftDialog,
	editDraftShiftDialogClose,
	resetDeleteDraftShiftAsync,
	resetCreateDraftShiftAsyncState,
	resetBroadcastMessageState,
	resetPublishDraftShiftsState,
	resetRenotifyWorkers,
	setController,
	resetEditDraftShift,
	dispatchLocal,
	dispatchLocalSkill,
	closeShiftCancelDialog,
	closeDeleteDraftShiftDialog,
	closePublishDraftShiftDialog,
}: any) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const classes: any = {};
	const { width } = useViewport();

	const handleRenotifyWorkersClose = () => {
		dispatch(resetRenotifyWorkers());
		navigate("/login");
	};

	const deleteDraftShiftAsyncStatusPending = (
		<Backdrop
			className={classes.backdrop}
			open={deleteDraftShiftAsyncStatus === "pending"}
			// onClick={handleClose}
		>
			<Box display='flex' flexDirection='column' alignItems='center'>
				<CircularProgress color='inherit' />
				Deleting Shift
			</Box>
		</Backdrop>
	);
	const deleteDraftShiftAsyncStatusFulfilled = (
		<Snackbar
			open={deleteDraftShiftAsyncStatus === "fulfilled"}
			autoHideDuration={3000}
			onClose={() => dispatch(resetDeleteDraftShiftAsync())}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<MuiAlert elevation={6} variant='filled' severity='success'>
				Shift Deleted Successfully
			</MuiAlert>
		</Snackbar>
	);
	const createDraftShiftAsyncStatusFulfilled = (
		<Snackbar
			open={createDraftShiftAsyncStatus === "fulfilled"}
			autoHideDuration={3000}
			onClose={() => dispatch(resetCreateDraftShiftAsyncState())}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<MuiAlert elevation={6} variant='filled' severity='success'>
				New Draft Shift Created
			</MuiAlert>
		</Snackbar>
	);
	const broadcastMessageStatusFulfilled = (
		<Snackbar
			open={broadcastMessageStatus === "fulfilled"}
			autoHideDuration={3000}
			onClose={() => dispatch(resetBroadcastMessageState())}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<MuiAlert elevation={6} variant='filled' severity='success'>
				Message Sent Successfully
			</MuiAlert>
		</Snackbar>
	);
	const publishDraftShiftsAsyncStatusFulfilled = (
		<Snackbar
			open={publishDraftShiftsAsyncStatus === "fulfilled"}
			autoHideDuration={3000}
			onClose={() => {
				setController((prevState: any) => ++prevState);
				dispatch(resetPublishDraftShiftsState());
			}}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<MuiAlert elevation={6} variant='filled' severity='success'>
				Shifts Published Successfully
			</MuiAlert>
		</Snackbar>
	);
	const renotifyWorkersStatusFulfilled = (
		<Snackbar
			open={renotifyWorkersStatus === "fulfilled"}
			autoHideDuration={3000}
			onClose={() => dispatch(resetRenotifyWorkers())}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<MuiAlert elevation={6} variant='filled' severity='success'>
				Eligible Workers Notified Successfully
			</MuiAlert>
		</Snackbar>
	);
	const renotifyWorkersStatusError = (
		<Snackbar
			open={renotifyWorkersStatus === "error"}
			autoHideDuration={3000}
			onClose={handleRenotifyWorkersClose}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<MuiAlert
				elevation={6}
				variant='filled'
				severity='error'
				onClose={handleRenotifyWorkersClose}
			>
				<Box display='flex' flexDirection='column' alignItems='center'>
					<Box mb={1}>
						{renotifyWorkersErrorMessage ??
							"An Error occurred. Please try again."}
					</Box>
					{/*<Button*/}
					{/*	variant="contained"*/}
					{/*	color="primary"*/}
					{/*	onClick={() => history.push("/login")}*/}
					{/*>*/}
					{/*	Try Login Again*/}
					{/*</Button>*/}
				</Box>
			</MuiAlert>
		</Snackbar>
	);

	const changeWeekAsyncStatusPending = (
		<Backdrop
			className={classes.backdrop}
			open={changeWeekAsyncStatus === "pending"}
			// onClick={handleClose}
		>
			<Box display='flex' flexDirection='column' alignItems='center'>
				<CircularProgress color='inherit' />
				Fetching Shifts
			</Box>
		</Backdrop>
	);

	const editDraftShiftStatusFulfilled = (
		<Snackbar
			open={editDraftShiftStatus === "fulfilled"}
			autoHideDuration={3000}
			onClose={() => dispatch(resetEditDraftShift())}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<MuiAlert elevation={6} variant='filled' severity='success'>
				Shift Edited Successfully
			</MuiAlert>
		</Snackbar>
	);
	const bMessageDialog = (
		<BroadcastMessageDialog
			open={isBMessageDialogOpen}
			handleClose={closeBMessageDialog}
		/>
	);
	const createNewShiftDialog = (
		<CNSDialog
			open={isCreateNewShiftDialogOpen}
			handleClose={closeCreateNewShiftDialog}
			draftShiftDate={draftShiftDate ?? new Date()}
		/>
	);
	const createWeekShiftDialog = (
		<CopyShiftsDialog
			open={isCreateWeekShiftDialogOpen}
			handleClose={closeCreateWeekShiftDialog}
			weekPasteDate={weekPasteDate}
			// draftShiftDate={draftShiftDate}
		/>
	);
	const shiftDetailsDialogMobile = (
		<MobileShiftDetails
			open={isShiftDetailsDialogOpen}
			handleClose={() => {
				setIsShiftDetailsDialogOpen(false);
			}}
			shiftId={shiftIdForShiftDetailsDialog}
		/>
	);
	const shiftDetailsDialog = (
		<ShiftDetailsDialog
			open={isShiftDetailsDialogOpen}
			handleClose={() => {
				dispatch(resetAssignWorkers());
				dispatch(resetGetOTWorkers());
				dispatch(resetSetOTWorkers());
				dispatch(resetShiftDetails());
				dispatch(resetAssignWorkersResponse());
				dispatch(resetGetOTWorkersResponse());
				dispatch(resetSetOTWorkersResponse());
				setIsShiftDetailsDialogOpen(false);
			}}
			shiftId={shiftIdForShiftDetailsDialog}
			isWorkerRequest={false}
		/>
	);
	const shiftAttendanceDialog = (
		<ShiftAttendanceDialog
			open={isShiftAttendanceDialogOpen}
			onClose={() => setIsShiftAttendanceDialogOpen(false)}
			shiftId={shiftIdForShiftDetailsDialog}
		/>
	);
	const reviewPublicShiftDialogMobile = (
		<MobileReviewShifts
			open={isReviewPublishingShiftsDialogOpen}
			handleClose={() => dispatch(closeReviewPublishShiftDialog())}
		/>
	);
	const reviewPublicShiftDialogWeb = (
		<ReviewPublishingShiftsDialog
			open={isReviewPublishingShiftsDialogOpen}
			handleClose={() => dispatch(closeReviewPublishShiftDialog())}
		/>
	);

	const reviewPublicShiftDialog =
		width > 600 ? reviewPublicShiftDialogWeb : reviewPublicShiftDialogMobile;

	const editShiftDialog = (
		<EditShiftDialog
			open={isEditShiftDialogOpen}
			handleClose={() => dispatch(editDraftShiftDialogClose())}
			dispatchLocal={dispatchLocal}
			dispatchLocalSkill={dispatchLocalSkill}
			state={state}
			skillState={skillState}
		/>
	);

	const pastShiftDialog = (
		<PastShiftDialog
			open={isPastShiftDialogOpen}
			handleClose={closePastShiftDialog}
		/>
	);

	const markAttendanceDialog = (
		<MarkAttendanceDialog
			open={isMarkAttendanceDialogOpen}
			handleClose={closeMarkAttendanceDialog}
			shiftId={shiftIdForShiftDetailsDialog}
		/>
	);

	const shiftCancelDialog = (
		<ShiftCancelDialog
			open={isShiftCancelDialogOpen}
			handleClose={closeShiftCancelDialog}
			shiftId={shiftIdForShiftDetailsDialog}
		/>
	);

	const deleteDraftShiftDialog = (
		<DeleteDraftDialog
			open={isDeleteDraftShiftDialogOpen}
			onClose={closeDeleteDraftShiftDialog}
			shiftId={shiftIdForShiftDetailsDialog}
		/>
	);

	const publishDraftShiftDialog = (
		<PublishDraftDialog
			open={isPublishDraftShiftDialogOpen}
			onClose={closePublishDraftShiftDialog}
			shiftId={shiftIdForShiftDetailsDialog}
		/>
	);

	return {
		publishDraftShiftDialog,
		shiftCancelDialog,
		editShiftDialog,
		createNewShiftDialog,
		reviewPublicShiftDialog,
		bMessageDialog,
		shiftDetailsDialog,
		shiftDetailsDialogMobile,
		createWeekShiftDialog,
		deleteDraftShiftAsyncStatusPending,
		deleteDraftShiftAsyncStatusFulfilled,
		createDraftShiftAsyncStatusFulfilled,
		broadcastMessageStatusFulfilled,
		publishDraftShiftsAsyncStatusFulfilled,
		renotifyWorkersStatusFulfilled,
		renotifyWorkersStatusError,
		changeWeekAsyncStatusPending,
		editDraftShiftStatusFulfilled,
		shiftAttendanceDialog,
		pastShiftDialog,
		markAttendanceDialog,
		deleteDraftShiftDialog,
	};
};

export default SchedulerActions;
