import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { JOB, SKILLS } from "../../../assets/constants";
import {minTimeSlotHeight} from "./constants";
import { Shift } from "./shift";

export const ShiftContainer = ({ data, isLayer = false, infoAtBottom = false, isSelected = false, timeSlotHeight = minTimeSlotHeight }: any): JSX.Element => {
  const [style, setStyle] = useState<{
    transform?: string;
    transformOrigin?: string;
    outline?: string;
  }>(
    isLayer
      ? {
          transform: "scale(1)",
        }
      : {
          transform: "translateX(0px)",
          transformOrigin: "right center",
        }
  );
  const shiftByType = parseInt(data.jobId) === 0 ? SKILLS : JOB;

  const [borderStyle, setBorderStyle] = useState<{
    border?: string;
  }>({});
  const color = useSelector((state: any) => {
    if (shiftByType === JOB) {
      const jobRole =
        state.supervisor?.filterData?.allJobRoles.length > 0 &&
        state.supervisor?.filterData?.allJobRoles?.filter(
          (jobRole: { id: string }) => jobRole.id === data.jobId
        );
      return `#${jobRole[0]?.hexColor}`;
    } else {
      return "#F0F0F0";
    }
  });
  useEffect(() => {
    if (data.isPublished && data.numberOfWorkersNeeded > data.slotsFilled) {
      setBorderStyle({
        border: "2px solid #F2B8B5",
      });
    } else if (!data.isPublished) {
      setBorderStyle({
        border: "1px dashed #616161",
      });
    } else {
      setBorderStyle({
        border: "0px",
      });
    }
  }, [data]);

  useEffect(() => {
      if (isSelected) {
          setStyle(
              isLayer
                  ? {
                      transform: "scale(1.05)",
                      outline: "1px solid rgba(0,0,0,0.2)",
                  }
                  : {
                      transform: "translateX(-12px)",
                      transformOrigin: "right center",
                      outline: "1px solid rgba(0,0,0,0.2)",
                  }
          );
      } else {
            setStyle(
                isLayer
                    ? {
                        transform: "scale(1)",
                        outline: "none",
                    }
                    : {
                        transform: "translateX(0px)",
                        transformOrigin: "right center",
                        outline: "none"
                    }
            );
      }
  }, [isSelected, isLayer]);
  return (
    <Box
      onMouseEnter={() => {
        setStyle(
            isLayer
                ? {
                  transform: "scale(1.05)",
                }
                : {
                  transform: "translateX(-12px)",
                  transformOrigin: "right center",
                }
        );
      }}
      onMouseLeave={() => {
        if (!isSelected) {
          setStyle(
            isLayer
              ? {
                  transform: "scale(1)",
                }
              : {
                  transform: "translateX(0px)",
                  transformOrigin: "right center",
                }
          );
        }
      }}
      style={{
        ...style,
        ...borderStyle,
        backgroundColor: `${!data.isPublished ? "#fff" : color ?? "#F3A7A0"}`,
        background:
          shiftByType === JOB
            ? !data.isPublished
              ? `${
                  color ?? "#F3A7A0"
                } conic-gradient(from 90deg at 1px 1px,#0000 90deg,rgba(0,0,0,0.05) 0) 0 0/5px 5px`
              : color ?? "#F3A7A0"
            : !data.isPublished
            ? "#fff"
            : color ?? "#F3A7A0",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "flex-start",
        paddingTop: "2px",
        paddingLeft: "6px",
        flex: 1,
        transition: "transform 0.2s ease-in-out",
        height: "100%",
      }}
    >
      <Shift data={data} isLayer={isLayer} infoAtBottom={infoAtBottom} timeSlotHeight={timeSlotHeight}/>
    </Box>
  );
};
