import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "../../../../js/utils/api-client"
import { setSupervisorRateLimitError } from "../../../features/user/user-slice";
import { WorkerInsightReportSliceStateTypes } from "./insight-report-types";


const initialState: WorkerInsightReportSliceStateTypes = {

    fetchWorkerInsightReport: {
        status: "idle",
        errorMessage: ""
    },
    workerInsightReportData: [],

    fetchskilllevels: {
        status: "idle",
        errorMessage: ""
    },
    skillsLevelLegendsData: [],

    fetchWorkerInsightDetails: {
        status: "idle",
        errorMessage: ""
    },
    workerInsightDetailsData: {
        workerId: "",
        firstName: "",
        lastName: "",
        seniorityYears: 0,
        interestedSkills: [],
        workerSkillsByLevel: [],
        workerSkillsByPriority: [],
        workerBackupList: [],
    },
    workerInsightPanelDetails: {
        isOpen: false,
        workerId: "",
        locations: []
    },

    departmentInsightPanelDetails: {
        isOpen: false,
        id: "",
        name: "",
    },
}

//Get all worker insight
export const getWorkerInsightReport = createAsyncThunk(
    "workerInsightReport/getWorkerInsightReport",
    async (payload, thunkAPI) => {
        try {

            const workerInsightResponse = await axios.get(
                `${process.env.REACT_APP_API_END_POINT}/Insight/GetWorkerInsightForUserV2/workerinsightV2`
            );

            return thunkAPI.fulfillWithValue(workerInsightResponse.data)
        } catch (error: any) {
            const errorResponse = error?.response.data;
            errorResponse.status = error?.response?.status;
            try {
                if (errorResponse.status === 429) {
                    thunkAPI.dispatch(setSupervisorRateLimitError());
                }
                return thunkAPI.rejectWithValue(error?.message);
            } catch (e) {
                return thunkAPI.rejectWithValue("An error occurred");
            }
        }
    }
)

//Get all skill levels
export const fetchskilllevels = createAsyncThunk(
    "workerInsightReport/fetchSkillsLevelLegend",
    async (payload, thunkAPI) => {
        try {

            const skillsLevelsResponse = await axios.get(
                `${process.env.REACT_APP_API_END_POINT}/Insight/GetCompanySkillLevels/skilllevels`
            );

            return thunkAPI.fulfillWithValue(skillsLevelsResponse.data)
        } catch (error: any) {
            const errorResponse = error?.response.data;
            errorResponse.status = error?.response?.status;
            try {
                if (errorResponse.status === 429) {
                    thunkAPI.dispatch(setSupervisorRateLimitError());
                }
                return thunkAPI.rejectWithValue(error?.message);
            } catch (e) {
                return thunkAPI.rejectWithValue("An error occurred");
            }
        }
    }
)

//Get worker insight details by workerId
export const fetchWorkerInsightDetails = createAsyncThunk(
    "workerInsightReport/fetchWorkerInsight",
    async (payload:string, { fulfillWithValue, rejectWithValue, dispatch }) => {
        try {
            const workerDetailsResponse = await axios.get(
                `${process.env.REACT_APP_API_END_POINT}/Insight/GetWorkerInsightDetailsV2/workerinsightV2/${payload}`
            );
            return fulfillWithValue({ ...workerDetailsResponse.data, workerId: payload });
        } catch (error: any) {
            const errorResponse = error?.response.data;
            errorResponse.status = error?.response?.status;
            try {
                if (errorResponse.status === 429) {
                    dispatch(setSupervisorRateLimitError());
                    return rejectWithValue(errorResponse);
                }
                return rejectWithValue(error?.message);
            } catch (e) {
                return rejectWithValue("An error occurred");
            }
        }
    }
);

export const WorkerInsightReportSlice = createSlice({
    name: "workerInsightReport",
    initialState,
    reducers: {
        openWorkerInsightDetails: (state, action) => {
            state.workerInsightPanelDetails.isOpen = true;
            state.workerInsightPanelDetails.workerId = action.payload.workerId;
            state.workerInsightPanelDetails.locations = action.payload.locations
        },
        closeWorkerInsightDetails: (state) => {
            state.workerInsightPanelDetails.isOpen = false;
            state.workerInsightPanelDetails.workerId = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWorkerInsightReport.fulfilled, (state, action) => {
                const workerInsightData = action.payload && action.payload.length > 0 ? action.payload : []
                state.fetchWorkerInsightReport.status = "fulfilled";
                state.workerInsightReportData = workerInsightData;
            })
            .addCase(getWorkerInsightReport.rejected, (state, action:any) => {
                if (action.payload?.status === 429) {
                    state.fetchWorkerInsightReport.status = "error";
                }
                else{
                    state.fetchWorkerInsightReport.status = "error";
                    state.fetchWorkerInsightReport.errorMessage = action.payload ?? "An error occurred";
                }
            })
            .addCase(getWorkerInsightReport.pending, (state) => {
                state.fetchWorkerInsightReport.status = "pending";
            })
            .addCase(fetchskilllevels.fulfilled, (state, action) => {
                state.fetchskilllevels.status = "fulfilled";
                state.skillsLevelLegendsData = action.payload;
            })
            .addCase(fetchskilllevels.rejected, (state, action:any) => {
                if (action.payload?.status === 429) {
                    state.fetchskilllevels.status = "error";
                }
                else{
                    state.fetchskilllevels.status = "error";
                    state.fetchskilllevels.errorMessage = action.payload ?? "An error occurred";
                }
            })
            .addCase(fetchskilllevels.pending, (state) => {
                state.fetchskilllevels.status = "pending";
            })
            .addCase(fetchWorkerInsightDetails.fulfilled, (state, action) => {
                state.fetchWorkerInsightDetails.status = "fulfilled";
                state.workerInsightDetailsData = action.payload;
            })
            .addCase(fetchWorkerInsightDetails.rejected, (state, action:any) => {
                if (action.payload?.status === 429) {
                    state.fetchWorkerInsightDetails.status = "error";
                }
                else{
                    state.fetchWorkerInsightDetails.status = "error";
                    state.fetchWorkerInsightDetails.errorMessage = action.payload ?? "An error occurred";
                }
            })
            .addCase(fetchWorkerInsightDetails.pending, (state) => {
                state.fetchWorkerInsightDetails.status = "pending";
                state.fetchWorkerInsightDetails.errorMessage = "";
                state.workerInsightDetailsData = {
                    workerId: "",
                    firstName: "",
                    lastName: "",
                    seniorityYears: 0,
                    interestedSkills: [],
                    workerSkillsByLevel: [],
                    workerSkillsByPriority: [],
                    workerBackupList: [],
                };
            });
    },
})

export const {
    openWorkerInsightDetails,
    closeWorkerInsightDetails
} = WorkerInsightReportSlice.actions;

export default WorkerInsightReportSlice.reducer;