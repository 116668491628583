/* eslint-disable sort-imports */
/* eslint-disable no-mixed-spaces-and-tabs */
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DoDisturbOutlinedIcon from "@mui/icons-material/DoDisturbOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import { Box, Stack } from "@mui/material";
import React from "react";

import {
	ALL,
	NO_CALL_NO_SHOWS,
	WORKER_CANCELLATIONS,
	WORKERS_WITH_ATLEAST_1_SHIFT,
	WORKERS_WITH_NO_SHIFTS,
} from "../../../../../assets/constants";
import workerNoShow from "../../../assets/worker_no_show.png";
import { useWorkerActivityData } from "../../../controllers/use-worker-activity-data";
// import { setSelectedKPI } from "../../../store/worker-activity-slice";
import ActivityKpiCard from "../activity-kpi-card";

interface KpiSummariesProps {
	selectedKPI: string;
	setSelectedKPI: (kpi: string) => void;
	KPICount: any;
}

const KpiSummaries: React.FC<KpiSummariesProps> = ({
	selectedKPI,
	setSelectedKPI,
	KPICount,
}) => {
	const { monthlyActivityReportStatus } = useWorkerActivityData();

	const handleClick = (kpi: string) => {
		setSelectedKPI(kpi === selectedKPI ? ALL : kpi);
	};

	const commonProps = {
		loading: monthlyActivityReportStatus,
		handleClick,
	};

	return (
		<Stack
			direction={{ xs: "column", sm: "row" }}
			sx={{ flexWrap: "wrap" }}
			mb={1}
			mt={2}
		>
			<ActivityKpiCard
				{...commonProps}
				icon={<GroupsIcon />}
				iconBackgroundColor='#E8F5E9'
				iconColor='#26A69A'
				kpiDataCount={KPICount[WORKERS_WITH_ATLEAST_1_SHIFT].count}
				title='Workers with at least 1 Shift'
				subTitle={`${KPICount[WORKERS_WITH_ATLEAST_1_SHIFT].percentage}% of your Workers`}
				isToggled={selectedKPI === "WORKERS WITH AT LEAST 1 SHIFT"}
				kpiData={WORKERS_WITH_ATLEAST_1_SHIFT}
			/>
			<ActivityKpiCard
				icon={<GroupsIcon />}
				iconBackgroundColor={"#F9DEDC"}
				iconColor={"#E44B48"}
				kpiDataCount={KPICount[WORKERS_WITH_NO_SHIFTS].count}
				title={"Workers with no Shifts"}
				subTitle={`${KPICount[WORKERS_WITH_NO_SHIFTS].percentage}% of your Workers`}
				isToggled={selectedKPI === "WORKERS WITH NO SHIFTS"}
				kpiData={WORKERS_WITH_NO_SHIFTS}
				{...commonProps}
			/>
			<ActivityKpiCard
				icon={<DoDisturbOutlinedIcon />}
				iconBackgroundColor={"#F9DEDC"}
				iconColor={"#E44B48"}
				kpiDataCount={KPICount[WORKER_CANCELLATIONS].count}
				title={"Worker Cancellations"}
				subTitle={`${KPICount[WORKER_CANCELLATIONS].percentage}% of Shifts`}
				isToggled={selectedKPI === "WORKER CANCELLATIONS"}
				kpiData={WORKER_CANCELLATIONS}
				{...commonProps}
			/>
			<ActivityKpiCard
				icon={
					<img
						src={workerNoShow}
						alt='worker_no_show'
						style={{ color: "#1B5E20" }}
					/>
				}
				iconBackgroundColor={"#F9DEDC"}
				iconColor={"#E44B48"}
				kpiDataCount={KPICount[NO_CALL_NO_SHOWS].count}
				title={"No Call No Shows"}
				subTitle={`${KPICount[NO_CALL_NO_SHOWS].percentage}% of Shifts`}
				isToggled={selectedKPI === "NO CALL NO SHOWS"}
				kpiData={NO_CALL_NO_SHOWS}
				{...commonProps}
			/>
		</Stack>
	);
};

export default React.memo(KpiSummaries);
