import { Add } from "@mui/icons-material";
import { Box, Button, Snackbar, Switch, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { number } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getWorkerTypes } from "../../../../../workers/store/manage-workers-slice";
import useGatAdminConfigData from "../../../../controllers/use-gat-admin-config-data";
import {
	getWorkerSubType,
	getWorkerTypeHours,
	resetAddWorkerSubType,
	resetEditWorkerSubType,
} from "../../../../store/gat-admin-slice";
import WorkerSubTypeDialog from "./ui/worker-subtype-dialog";
import { WorkerSubTypeTable } from "./ui/worker-subtype-table";
type WorkerSubTypeProps = {
	newAddOnSettings: any;
	setNewAddOnSettings: any;
};

const WorkerSubType: React.FC<WorkerSubTypeProps> = ({
	newAddOnSettings,
	setNewAddOnSettings,
}) => {
	const dispatch = useDispatch();
	const {
		getCompanySettingsStatus,
		addWorkerSubTypeStatus,
		editWorkerSubTypeStatus,
		workerTypes,
		companyId,
	} = useGatAdminConfigData();
	const [addWorkerSubTypeDialogOpen, setAddWorkerSubTypeDialogOpen] =
		useState(false);
	const [editWorkerSubTypeDialogOpen, setEditWorkerSubTypeDialogOpen] =
		useState<null | number>(null);

	const openDialog = (type: "add" | "edit", id?: number) => {
		if (type === "add") {
			setAddWorkerSubTypeDialogOpen(true);
		} else {
			setEditWorkerSubTypeDialogOpen(id ?? null);
		}
	};

	const closeDialog = () => {
		setAddWorkerSubTypeDialogOpen(false);
		setEditWorkerSubTypeDialogOpen(null);
	};

	useEffect(() => {
		if (
			addWorkerSubTypeStatus === "fulfilled" ||
			editWorkerSubTypeStatus === "fulfilled"
		) {
			closeDialog();
			dispatch(getWorkerTypeHours(companyId));
		}
	}, [addWorkerSubTypeStatus, editWorkerSubTypeStatus, companyId, dispatch]);

	useEffect(() => {
		if (workerTypes.length === 0) {
			dispatch(getWorkerTypes());
		}
	}, [workerTypes, dispatch]);

	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				marginTop: "32px",
				marginBottom: "16px",
				padding: "16px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						Setup Worker Sub Types
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 400,
							fontSize: "12px",
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						Setup Worker Sub Types for each Worker Type as per Company policy
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Switch
						disabled={getCompanySettingsStatus === "pending"}
						onChange={(e) => {
							setNewAddOnSettings({
								...newAddOnSettings,
								workerSubTypeEnabled: e.target.checked,
							});
						}}
						checked={newAddOnSettings.workerSubTypeEnabled ?? false}
					/>
				</Box>
			</Box>
			{newAddOnSettings.workerSubTypeEnabled && (
				<Box>
					<Box display='flex' justifyContent='flex-end' mt={2}>
						<Button
							variant='outlined'
							startIcon={<Add />}
							onClick={() => openDialog("add")}
						>
							Worker Sub Type
						</Button>
					</Box>
					<WorkerSubTypeTable
						openEditDialog={openDialog}
						closeEditDialog={closeDialog}
					/>
				</Box>
			)}
			<WorkerSubTypeDialog
				open={addWorkerSubTypeDialogOpen}
				onClose={closeDialog}
				type='add'
			/>
			<WorkerSubTypeDialog
				open={editWorkerSubTypeDialogOpen ?? false}
				onClose={closeDialog}
				type='edit'
				id={editWorkerSubTypeDialogOpen ?? undefined}
			/>

			<Snackbar
				open={addWorkerSubTypeStatus === "fulfilled"}
				autoHideDuration={3000}
				onClose={() => {
					dispatch(resetAddWorkerSubType());
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					{"Worker Sub Type Created Successfully!"}
				</MuiAlert>
			</Snackbar>

			<Snackbar
				open={editWorkerSubTypeStatus === "fulfilled"}
				autoHideDuration={3000}
				onClose={() => {
					dispatch(resetEditWorkerSubType());
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					{"Worker Sub Type Updated Successfully!"}
				</MuiAlert>
			</Snackbar>
		</Box>
	);
};

export default WorkerSubType;
