import { format } from "date-fns";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { fetchMaxWorkersByLocationJob } from "../../../../../../../js/features/ManageJobs/ManageJobsSlice";
import useSchedulerData from "../../../../controllers/use-scheduler-data";
import { publishDraftShiftsAsync } from "../../../../store/scheduler-slice";

const usePublishDraftDialogController = (
	shiftId: string,
	onClose: () => void
) => {
	const dispatch = useDispatch();
	const {
		shiftsData,
		allSkillLevels,
		filterAllWorkcenters,
		publishDraftShiftsAsyncStatus,
	} = useSchedulerData();

	const shift = useMemo(
		() => shiftsData.find((shift: { id: string }) => shift.id === shiftId),
		[shiftsData, shiftId]
	);

	const workCenter = useMemo(
		() =>
			filterAllWorkcenters.find(
				(workCenter: { id: number }) => workCenter.id === shift.workCenterId
			),
		[filterAllWorkcenters, shift]
	);
	const formatedShiftDate = useCallback(() => {
		if (!shift) return "";
		const formattedStartDate = format(
			new Date(shift.startDate),
			"EEE, dd MMM h:mm a"
		);
		const formattedEndTime = format(new Date(shift.endDate), "h:mm a");
		return `${formattedStartDate} - ${formattedEndTime}`;
	}, [shift]);

	const allSkillsObj = useMemo(
		() =>
			shift?.skills
				?.map((skill: { level: string }) => {
					const matchingObject = allSkillLevels.find(
						(level: { level: string }) => level.level === skill.level
					);
					if (matchingObject) {
						const { repCode, foreColorCode, backColorCode, value } =
							matchingObject;
						return { ...skill, repCode, foreColorCode, backColorCode, value };
					}
					return null;
				})
				.filter(Boolean),
		[shift, allSkillLevels]
	);

	useEffect(() => {
		if (publishDraftShiftsAsyncStatus === "fulfilled" && onClose) {
			onClose();
		}
	}, [publishDraftShiftsAsyncStatus, onClose]);

	const publishDraftShifts = () => {
		dispatch(publishDraftShiftsAsync([Number(shiftId)]));
	};

	console.log("shift", shift, workCenter);
	useEffect(() => {
		console.log("shift", shift, workCenter);
		if (!shift || !workCenter) return;

		const data = {
			locationId: workCenter.locationId,
			jobId: shift.jobId === 0 ? null : shift.jobId,
			startDateTime: shift.startDate,
			endDateTime: shift.endDate,
			shiftSkills: shift.skills,
			shiftId: shift.id,
		};
		dispatch(fetchMaxWorkersByLocationJob(data));
	}, [dispatch, shift, workCenter]);

	return [
		{ shift, allSkillsObj, workCenter, publishDraftShiftsAsyncStatus },
		{ formatedShiftDate, publishDraftShifts },
	];
};

export default usePublishDraftDialogController;
