/* eslint-disable no-mixed-spaces-and-tabs */
import { useConfirm } from "material-ui-confirm";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { useColumnDivider } from "../../../../export/cross-skilling";
import { updateWorkerSkillLevel } from "../../../../export/workers";
import { formatDate, hasPermission } from "../../../../ts/utils/helpers";
import useColumnSelector from "../../../design/column-selector/controllers/use-column-selector";
import {
	addSkillInterested,
	fetchManageWorkersData,
	fetchNotAssignedSkillLevel,
	fetchSkillMatrixDefaultFilters, // TODO: For integrating dedicated skill matrix worker data
	fetchSkillMatrixFilters,
	resetSkillLevelStatus,
	resetSkillMatrixDefaultFilters,
	resetSkillMatrixFilter,
} from "../../../features/ManageWorkers/ManageWorkersSlice";
import { getLowestLevelObject } from "../../../utils/helpers";
import useViewport from "../../../utils/useViewport";
import useCrossSkillColumn from "./use-cross-skill-column";
import useCrossSkillData from "./use-cross-skill-data";
import useCrossSkillFilterController from "./use-cross-skill-filter-controller";

const useCrossSkillController = () => {
	const {
		allWorkers,
		allJobRoles,
		fetchSkillLevelsStatus,
		allJobs,
		fetchJobsByUserIdStatus,
		workerTypes,
		fetchManageWorkersDataStatus,
		allSkills,
		allSkillLevels,
		allLocations,
		allWorkCenters,
		allShiftPatterns,
		updateWorkerSkillLevelStatus,
		fullTimeSchedule,
		allOrganizations,
		allOrganizationTypes,
		allManagers,
		skillPriorities,
		fetchAllOrganizationsStatus,
		fetchAllLocationsStatus,
		fetchAllManagersStatus,
		skillMatrixFiltersStatus,
		skillMatrixDefaultFiltersStatus,
		skillMatrixAppliedFilters,
		skillInsightsEnabled,
		isSkillPriorityEnabled,
		companyId,
		userRolesWithPermission,
		notAssignedSkillLevel,
		skillMatrixFiltersWorkerSubTypes,
		isWorkerSubTypeEnabled,
	} = useCrossSkillData();

	const dispatch = useDispatch();
	const { width } = useViewport();
	const containerRef = useRef();

	const [allData, setAllData] = useState([]);
	const [data, setData] = useState([]);
	const [dataProcessed, setDataProcessed] = useState(false);
	const [filters, setFilters] = useState([]);
	const [filterStatus, setFilterStatus] = useState(false);
	const [selectedWorkCenter, setSelectedWorkCenter] = useState([]);
	const [gotOrgs, setGotOrgs] = useState(false);
	const [totalWorkers, setTotalWorkers] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [selectedPage, setSelectedPage] = useState(0);

	const [searchText, setSearchText] = useState("");
	const [fixedColumns, setFixedColumns] = useState([
		"companyEmployeeId",
		"jobTitle",
		"name",
		"workerType",
		"workerSubTypeText",
		"primaryJob",
		"location",
		"joiningDate",
		"managerName",
		"workCenter",
		"shiftPatternName",
		"skillScore",
	]);
	const defaultCheckedColumns = ["skillScore", "name"];
	const [finalPickerColumn, setFinalPickerColumn] = useState();

	const confirm = useConfirm();
	const changeFilters = useCallback((newFilters) => {
		setFilters(newFilters);
	}, []);
	const [filterControllerProps, filterControllerEvents] =
		useCrossSkillFilterController({
			filters,
			changeFilters,
			allJobRoles,
			allSkills,
			allOrganizations,
			allWorkCenters,
			allShiftPatterns,
			allLocations,
			allManagers,
			workerTypes,
			skillPriorities,
			skillMatrixAppliedFilters,
			skillMatrixFiltersWorkerSubTypes,
		});

	const { filteredJobs } = filterControllerProps;
	const handleSkillLevels = (params, skill, level, setState) => {
		const obj = {
			skillId: skill.id,
			levelId: level.levelId,
			workerId: params.id,
		};

		const workerSkill = params.row.skills.find(
			(x) => parseInt(x.skillId) === parseInt(skill.id)
		);
		if (workerSkill) {
			if (workerSkill.levelId !== level.levelId) {
				confirm({
					title: "Are you sure?",
					description: `You are updating skill level from ${workerSkill.levelName} to ${level.name} for ${params.row.firstName} in ${workerSkill.skillName}.`,
				})
					.then(() => {
						dispatch(updateWorkerSkillLevel(obj));
						setState((state) => ({
							...state,
							level: level.level,
							levelId: level.levelId,
							repCode: level.repCode,
							backColorCode: level.backColorCode,
							foreColorCode: level.foreColorCode,
							value: level.value,
						}));
					})
					.catch(() => {
						/* ... */
					});
			}
		} else {
			confirm({
				title: "Are you sure?",
				description: `You are adding ${skill.name} with level ${level.name} for ${params.row.firstName}.`,
			})
				.then(() => {
					dispatch(updateWorkerSkillLevel(obj));
					setState((state) => ({
						...state,
						level: level.level,
						levelId: level.levelId,
						repCode: level.repCode,
						backColorCode: level.backColorCode,
						foreColorCode: level.foreColorCode,
						value: level.value,
					}));
				})
				.catch(() => {
					/* ... */
				});
		}
	};
	const handleAddSkillInterested = (params, skill, setState) => {
		if (
			skill &&
			hasPermission(userRolesWithPermission, "CROSS_SKILLING.ACCEPT_INTEREST")
		) {
			const defaultSkillLevel = getLowestLevelObject(allSkillLevels);
			const interestedSkill = params.row.skillInterests
				? params.row.skillInterests.find(
						(x) => parseInt(x.skillId) === parseInt(skill.skillId)
				  )
				: false;
			confirm({
				title: "Are you sure?",
				description: `You are adding ${skill.name} to ${params.row.firstName}'s skill set.`,
			})
				.then(() => {
					dispatch(addSkillInterested(interestedSkill.id));
					setState((state) => ({
						...state,
						level: defaultSkillLevel.level,
						levelId: defaultSkillLevel.levelId,
						repCode: defaultSkillLevel.repCode,
						backColorCode: defaultSkillLevel.backColorCode,
						foreColorCode: defaultSkillLevel.foreColorCode,
						value: defaultSkillLevel.value,
						isInterest: false,
						skillAssigned: true,
					}));
				})
				.catch(() => {
					/* ... */
				});
		}
	};

	const getFilter = useCallback(
		(type) => {
			let returnValue = {
				type: type,
			};
			returnValue[`${type}s`] = [];
			filters.forEach((filter) => {
				if (type === filter.type) {
					returnValue = filter;
				}
			});
			return returnValue;
		},
		[filters]
	);

	const { columns } = useCrossSkillColumn(
		filteredJobs,
		allSkills,
		allSkillLevels,
		handleSkillLevels,
		handleAddSkillInterested,
		searchText,
		getFilter,
		isWorkerSubTypeEnabled
	);
	let { defaultColumns, restColumns } = useColumnDivider(
		columns,
		fixedColumns,
		defaultCheckedColumns
	);
	let [{ selectedColumns, pickerColumns }, { handleSelectColumns }] =
		useColumnSelector(defaultColumns, restColumns);

	const filterColumns = useCallback(
		(columns, fixedSet, isEnabled) =>
			columns.filter(
				(col) =>
					fixedSet.has(col.field) &&
					(isEnabled || col.field !== "workerSubTypeText")
			),
		[]
	);

	useEffect(() => {
		const fixedSet = new Set(fixedColumns);
		const filteredColumns = filterColumns(
			pickerColumns,
			fixedSet,
			isWorkerSubTypeEnabled
		);
		setFinalPickerColumn(filteredColumns);
	}, [fixedColumns, pickerColumns, isWorkerSubTypeEnabled, filterColumns]);

	useEffect(() => {
		try {
			if (!allWorkCenters.length || !skillMatrixAppliedFilters.workcenters) {
				setSelectedWorkCenter([]);
				return;
			}

			const appliedWorkCenterIds = new Set(
				skillMatrixAppliedFilters.workcenters
					.split(",")
					.map((id) => parseInt(id))
			);

			const newSelectedWorkCenters = allWorkCenters
				.filter((wc) => appliedWorkCenterIds.has(wc.id))
				.map((wc) => wc.id);

			setSelectedWorkCenter(newSelectedWorkCenters);
		} catch (error) {
			console.error("Error processing workcenters:", error);
			setSelectedWorkCenter([]);
		}
	}, [allWorkCenters, skillMatrixAppliedFilters.workcenters]);

	useEffect(() => {
		setDataProcessed(false);
		dispatch(fetchSkillMatrixDefaultFilters());
		dispatch(fetchSkillMatrixFilters()); // Dedicated Skill Matrix Filter API
		dispatch(fetchNotAssignedSkillLevel());
	}, [dispatch]);

	useEffect(() => {
		if (
			skillMatrixDefaultFiltersStatus === "fulfilled" &&
			skillMatrixFiltersStatus === "fulfilled"
		) {
			dispatch(resetSkillMatrixDefaultFilters());
			dispatch(resetSkillMatrixFilter());
			dispatch(fetchManageWorkersData({ getAll: true, version: 1 }));
		}
	}, [
		dispatch,
		skillMatrixDefaultFiltersStatus,
		skillMatrixAppliedFilters,
		skillMatrixFiltersStatus,
	]);

	const memoizedHelpers = useMemo(
		() => ({
			getSkillName: (skillId) =>
				allSkills.find((x) => parseInt(x.id) === parseInt(skillId))?.name ?? "",

			getJobNames: (jobIds) => {
				const jobIdsArray = jobIds?.split(",") ?? [];
				return jobIdsArray.reduce(
					(names, jobId) => names + (allJobRoles[jobId]?.name ?? ""),
					""
				);
			},

			getLocationName: (locationId) =>
				allLocations.find((x) => x.id === parseInt(locationId))?.name ?? "-",

			getWorkerSubTypeText: (workerSubTypeId) =>
				skillMatrixFiltersWorkerSubTypes.find(
					(type) => type.id === workerSubTypeId
				)?.name ?? "-",

			getWorkCenterName: (workCenterId) =>
				allWorkCenters.find((x) => x.id === parseInt(workCenterId))?.name ??
				"-",

			getManagerName: (managerId) =>
				allManagers.find((x) => x.id === parseInt(managerId))?.name ?? "-",

			getShiftPatternName: (shiftPatternId) =>
				allShiftPatterns.find((x) => x.id === parseInt(shiftPatternId))?.name ??
				"-",
		}),
		[
			allSkills,
			allJobRoles,
			allLocations,
			skillMatrixFiltersWorkerSubTypes,
			allWorkCenters,
			allManagers,
			allShiftPatterns,
		]
	);

	const defaultExperienceLevel = useMemo(() => {
		const lowest = getLowestLevelObject(allSkillLevels);
		return allSkillLevels.find((level) => level === 0) ?? lowest;
	}, [allSkillLevels]);

	useEffect(() => {
		if (fetchManageWorkersDataStatus !== "fulfilled") {
			if (
				fetchManageWorkersDataStatus === "error" ||
				skillMatrixFiltersStatus === "error"
			) {
				setDataProcessed(true);
			}
			return;
		}

		try {
			const processedWorkers = allWorkers.map((worker) => {
				// Basic worker info
				const baseWorker = {
					...worker,
					workCenterIds: worker.workcenterId.toString(),
					workCenter: memoizedHelpers.getWorkCenterName(worker.workcenterId),
					managerName: memoizedHelpers.getManagerName(worker.managerId),
					workerType: workerTypes.find((type) => type.id === worker.typeId)
						?.name,
					workerSubTypeText: memoizedHelpers.getWorkerSubTypeText(
						worker.workerSubTypeId
					),
					shiftPatternName: memoizedHelpers.getShiftPatternName(
						worker.shiftPatternId
					),
					companyEmployeeId: worker.companyEmployeeId || "-",
					jobNames: memoizedHelpers.getJobNames(worker.jobIds),
					jobTitle: worker.jobTitle || "-",
					primaryJob: worker.primaryJobId
						? memoizedHelpers.getJobNames(worker.primaryJobId.toString())
						: "-",
					location: memoizedHelpers.getLocationName(worker.locationId),
					joiningDate:
						formatDate({
							dateStr: worker.joiningDate,
							formatType: "NUMERIC_DATE",
							ISOString: false,
						}) ?? "-",
					skills: [],
				};

				// Process skills
				const processedSkills = new Map();
				const jobIds = worker.jobIds?.split(",") ?? [];

				// Process existing skills
				worker.skills.forEach((skill) => {
					const levelDetails =
						allSkillLevels.find((level) => level.id === skill.levelId) ??
						defaultExperienceLevel;
					const jobWithSkill = jobIds.find((jobId) => {
						const job = allJobRoles[jobId];
						return job?.skills.some(
							(js) => parseInt(js.skillId) === parseInt(skill.skillId)
						);
					});

					const processedSkill = {
						...skill,
						skillName: memoizedHelpers.getSkillName(skill.skillId),
						jobName: allJobRoles[jobWithSkill]?.name ?? "",
						jobId: jobWithSkill ?? `generated-id-${processedSkills.size}`,
						jobAssigned: true,
						skillAssigned: true,
						interested: false,
						...levelDetails,
					};

					processedSkills.set(skill.skillId, processedSkill);
				});

				baseWorker.skills = Array.from(processedSkills.values());
				return baseWorker;
			});

			const uniqueWorkers = [
				...new Map(processedWorkers.map((w) => [w.id, w])).values(),
			];

			setAllData(uniqueWorkers);
			setFixedColumns([
				"companyEmployeeId",
				"jobTitle",
				"name",
				"workerType",
				"workerSubTypeText",
				"primaryJob",
				"managerName",
				"location",
				"workCenter",
				"shiftPatternName",
				"joiningDate",
				"skillScore",
			]);
			setDataProcessed(true);
		} catch (e) {
			console.error("Error processing worker data:", e);
			setDataProcessed(true);
		}
	}, [
		fetchManageWorkersDataStatus,
		skillMatrixFiltersStatus,
		memoizedHelpers,
		allWorkers,
		allSkillLevels,
		defaultExperienceLevel,
		workerTypes,
		allJobRoles,
	]);

	const filterWorkerData = useCallback(
		(worker, searchTextLower) => {
			const checks = {
				fullName: `${worker.firstName} ${worker.lastName}`
					.toLowerCase()
					.includes(searchTextLower),
				workerType:
					workerTypes
						.find((type) => type.id === worker.typeId)
						?.name.toLowerCase()
						.includes(searchTextLower) ?? false,
				workerSubTypeText:
					worker.workerSubTypeText?.toLowerCase().includes(searchTextLower) ??
					false,
				companyEmployeeId:
					worker.companyEmployeeId?.toLowerCase().includes(searchTextLower) ??
					false,
				managerName:
					worker.managerName?.toLowerCase().includes(searchTextLower) ?? false,
				workerSkills: worker.skills.some((skill) =>
					skill.skillName.toLowerCase().includes(searchTextLower)
				),
				workerJobs:
					worker.jobNames?.toLowerCase().includes(searchTextLower) ?? false,
				jobTitle:
					worker.jobTitle?.toLowerCase().includes(searchTextLower) ?? false,
				workCenter:
					worker.workCenter?.toLowerCase().includes(searchTextLower) ?? false,
				shiftPattern:
					worker.shiftPatternName?.toLowerCase().includes(searchTextLower) ??
					false,
				primaryJob:
					worker.primaryJob?.toLowerCase().includes(searchTextLower) ?? false,
				location:
					worker.location?.toLowerCase().includes(searchTextLower) ?? false,
				joiningDate:
					worker.joiningDate?.toLowerCase().includes(searchTextLower) ?? false,
			};

			return Object.values(checks).some(Boolean);
		},
		[workerTypes]
	);

	const processWorkerSkills = useCallback(
		(worker) => ({
			...worker,
			skills: worker.skills
				.filter(
					(skill, index, array) =>
						array.findIndex(
							(s) => parseInt(s.skillId) === parseInt(skill.skillId)
						) === index
				)
				.filter((skill) => skill.skillAssigned),
		}),
		[]
	);

	useEffect(() => {
		try {
			const searchTextLower = searchText.toLowerCase();
			const processedWorkers = allData.map(processWorkerSkills);

			const filteredWorkers =
				searchText === ""
					? processedWorkers
					: processedWorkers.filter((worker) =>
							filterWorkerData(worker, searchTextLower)
					  );
			setData(filteredWorkers);
		} catch (error) {
			console.error("Error processing worker data:", error);
		}
	}, [searchText, allData, filterWorkerData, processWorkerSkills]);

	return [
		{
			data,
			allData,
			filters,
			allJobRoles,
			allJobs,
			allManagers,
			allSkills,
			allSkillLevels,
			allWorkCenters,
			allShiftPatterns,
			allWorkers,
			allOrganizations,
			allOrganizationTypes,
			allLocations,
			workerTypes,
			filterStatus,
			selectedWorkCenter,
			updateWorkerSkillLevelStatus,
			fullTimeSchedule,
			width,
			dataProcessed,
			fetchManageWorkersDataStatus,
			fetchAllLocationsStatus,
			fetchSkillLevelsStatus,
			fetchAllOrganizationsStatus,
			fetchAllManagersStatus,
			fetchJobsByUserIdStatus,
			gotOrgs,
			searchText,
			containerRef,
			filterControllerProps,
			filterControllerEvents,
			filteredJobs,
			selectedColumns,
			columns,
			finalPickerColumn,
			skillMatrixFiltersStatus,
			skillMatrixAppliedFilters,
			totalWorkers,
			pageSize,
			selectedPage,
			skillInsightsEnabled,
			isSkillPriorityEnabled,
			notAssignedSkillLevel,
		},
		{
			setFilters,
			setFilterStatus,
			setSelectedWorkCenter,
			resetSkillLevelStatus,
			dispatch,
			handleSkillLevels,
			handleAddSkillInterested,
			handleSelectColumns,
			setSearchText,
			getFilter,
			setSelectedPage,
			setPageSize,
		},
	];
};

export default useCrossSkillController;
