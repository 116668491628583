import { DataGridPro, GridColumns, GridRowsProp } from "@mui/x-data-grid-pro";
import React from "react";

interface DataGridProps {
	rows: GridRowsProp;
	columns: GridColumns;
	[key: string]: any; // Additional props
}

const DataGrid: React.FC<DataGridProps> = ({ rows, columns, ...rest }) => {
	return (
		<DataGridPro rows={rows} columns={columns} disableColumnMenu {...rest} />
	);
};

export default DataGrid;
