import { useEffect, useState } from "react";
import { AnyIfEmpty, useDispatch } from "react-redux";

import {
	getAllTimeZones,
	getCompanyData,
	getCompanySettings,
	resetUpdateCompanySettingsLevel,
} from "../../../../export/gat-admin";
import {
	getCompanyNotAssignedSkillLevel,
	getLeaveTypes,
	getWorkerSubType,
	getWorkerTypeHours,
} from "../store/gat-admin-slice";
import { OneCompany } from "../store/gat-admin-slice-types";
import useGatAdminConfigData from "./use-gat-admin-config-data";

const useGatAdminConfigController = (companyData: OneCompany) => {
	const dispatch = useDispatch();
	const {
		creatingNewCompany,
		companySettingsObj,
		companyLocationClustersValue,
		getCompanySettingsStatus,
		updateCompanySettingsLevelStatus,
		getCompanyLocationClustersStatus,
		locations,
		companyDataValue,
		createTimeOffTypeStatus,
		editTimeOffTypeStatus,
	} = useGatAdminConfigData();
	const [companySettings, setCompanySettings] = useState({});
	const [companyLocationClusters, setCompanyLocationClusters] = useState([]);
	const [companyId, setCompanyId] = useState<number>(-1);

	useEffect(() => {
		if (companySettingsObj && !creatingNewCompany)
			setCompanySettings(companySettingsObj);
	}, [companySettingsObj, creatingNewCompany]);

	useEffect(() => {
		if (companyLocationClustersValue && !creatingNewCompany)
			setCompanyLocationClusters(companyLocationClustersValue);
	}, [companyLocationClustersValue, creatingNewCompany]);

	useEffect(() => {
		if (companyId > -1) {
			dispatch(getCompanySettings(companyId));
			dispatch(getCompanyData(companyId));
			dispatch(getLeaveTypes(companyId));
			dispatch(getAllTimeZones());
			dispatch(getCompanyNotAssignedSkillLevel(companyId.toString()));
			dispatch(getWorkerSubType(companyId));
			dispatch(getWorkerTypeHours(companyId));
		}
	}, [companyId, dispatch]);

	useEffect(() => {
		if (companyId > -1 && updateCompanySettingsLevelStatus === "fulfilled") {
			resetUpdateCompanySettingsLevel();
			dispatch(getCompanySettings(companyId));
			dispatch(getCompanyData(companyId));
			dispatch(getAllTimeZones());
			dispatch(getCompanyNotAssignedSkillLevel(companyId.toString()));
		}
	}, [companyId, updateCompanySettingsLevelStatus, dispatch]);

	useEffect(() => {
		if (Object.keys(companyData).length > 0 && companyData.id !== companyId) {
			if (companyData.id.toString() !== "" || companyData.id === null) {
				setCompanyId(companyData.id);
			}
		}
	}, [companyData, companyId]);

	return [
		{
			companySettings,
			companyLocationClusters,
			getCompanySettingsStatus,
			getCompanyLocationClustersStatus,
			creatingNewCompany,
			locations,
			companyId,
		},
		{
			setCompanySettings,
		},
	];
};

export default useGatAdminConfigController;
