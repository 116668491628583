import { useSelector } from "react-redux";

interface UserData {
  company: string;
  email: string;
  firstName: string;
  lastName: string;
  isDrawerOpen: boolean;
}

// TODO: Root State work is pending in redux
const useUserData = (): UserData => {
  const company = useSelector((state: any) => state.user.userData.company);
  const email = useSelector((state: any) => state.user.userData.username);
  const firstName = useSelector((state: any) => state.user.userData.firstName);
  const lastName = useSelector((state: any) => state.user.userData.lastName);
  const isDrawerOpen = useSelector(
    (state: { navigationDrawer: { isDrawerOpen: boolean } }) =>
      state.navigationDrawer.isDrawerOpen
  );

  return {
    company,
    email,
    firstName,
    lastName,
    isDrawerOpen,
  };
};

export default useUserData;
