import React from "react";

import {
	ColumnGroupJobHeader,
	ColumnPriorityHeader,
} from "../../../../../export/cross-skilling";

export const createJobGroupObject = (job, priorities, searchText) => {
	const obj = {
		groupId: `${job.id}`,
		headerName: job.name,
		headerClassName: "job-header",
		renderHeaderGroup: () => (
			<ColumnGroupJobHeader
				skill={{ ...job, jobName: job.name, jobColor: job.hexColor }}
				searchText={searchText}
			/>
		),
	};

	if (priorities) {
		obj.children = priorities.map((priority) =>
			createPriorityObject(priority, job.hexColor, job.id, searchText)
		);
	}

	return obj;
};

export const createPriorityObject = (
	priority,
	jobColor,
	jobId,
	searchText
) => ({
	groupId: `${priority.id}`,
	headerName: priority.name,
	headerClassName: "priority-header",
	children: priority.skills.map((skill) => ({
		field: `${skill.id}-${jobId}`,
	})),
	renderHeaderGroup: () => (
		<ColumnPriorityHeader
			priority={{ ...priority, jobColor }}
			searchText={searchText}
		/>
	),
});

export const createPrioritizedOtherSkillsGroup = (
	prioritiesWithSkills,
	job,
	searchText
) => ({
	groupId: "-1",
	headerName: "Other Skills",
	headerClassName: "job-header",
	children: prioritiesWithSkills.map((priority) => ({
		groupId: `weight--${priority.id}`,
		headerName: priority.name,
		headerClassName: "priority-header",
		children: priority.skills.map((skill) => ({
			field: `${skill.id}-${skill.jobId}`,
		})),
		renderHeaderGroup: () => (
			<ColumnPriorityHeader
				priority={{ ...priority, jobColor: job.hexColor }}
				searchText={searchText}
			/>
		),
	})),
	renderHeaderGroup: () => (
		<ColumnGroupJobHeader
			skill={{ ...job, jobName: "Other Skills" }}
			searchText={searchText}
		/>
	),
});

export const createNonPrioritizedOtherSkillsGroup = (
	skillsWithNoJobs,
	searchText
) => ({
	groupId: "-1",
	headerName: "Other Skills",
	headerClassName: "job-header",
	children: skillsWithNoJobs.map((skill) => ({
		field: `${skill.skillId}-${skill.jobId}`,
	})),
	renderHeaderGroup: () => (
		<ColumnGroupJobHeader
			skill={{ jobName: "Other Skills" }}
			searchText={searchText}
		/>
	),
});
