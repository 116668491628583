import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";

import WeeklySchedule from "../../js/components/weekly-schedule";
import MiniDrawer from "../components/drawer";

export default function DetectHome({ ...rest }) {
	const userRoles = useSelector(
		(state: any) => state.user?.userData?.userRoles ?? ["ShiftSupervisor"]
	);
	const fullTimeSchedule = useSelector(
		(state: any) => state.user.userData.fullTimeSchedule
	);

	if (userRoles.includes("ShiftSupervisor")) {
		if (fullTimeSchedule) {
			return <WeeklySchedule />;
		} else {
			return <MiniDrawer />;
		}
	}
	if (userRoles.includes("LocationAdmin")) {
		return <MiniDrawer />;
	}
	return <MiniDrawer />;
}
