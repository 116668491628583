import { Grid } from "@mui/material";
import React from "react";

import SectionWrapper from "../../../section-wrapper";
import SectionActions from "../../section-actions";
import CompanyCommunicationSettings from "./company";
import useCommunicationController from "./controllers/use-communication-controller";
import LocationCommunicationSettings from "./location";

const CommunicationSettings = ({ entity, getCompanySettingsStatus }: any) => {
	const [
		{
			communicationSettings,
			newCommunicationSettings,
			loading,
			getLocationSettingsStatus,
		},
		{ handleSave, setNewCommunicationSettings, setLoading },
	]: any = useCommunicationController(entity);

	return (
		<SectionWrapper
			title={"Communication Settings"}
			subtitle={"Decide on rules how workers and supervisors are notified."}
		>
			<Grid
				container
				sx={{
					paddingTop: "16px",
					paddingBottom: "16px",
				}}
			>
				{entity === "company" ? (
					<CompanyCommunicationSettings
						communicationSettings={communicationSettings}
						newCommunicationSettings={newCommunicationSettings}
						setNewCommunicationSettings={setNewCommunicationSettings}
						loading={Object.keys(newCommunicationSettings).length === 0}
					/>
				) : (
					<LocationCommunicationSettings
					communicationSettings={communicationSettings}
						newCommunicationSettings={newCommunicationSettings}
						setNewCommunicationSettings={setNewCommunicationSettings}
						loading={
							Object.keys(newCommunicationSettings).length === 0 ||
							getLocationSettingsStatus === "pending"
						}
					/>
				)}
			</Grid>
			<SectionActions
				oldData={communicationSettings}
				newData={newCommunicationSettings}
				resetChanges={() => {
					setNewCommunicationSettings(communicationSettings);
					setLoading(false);
				}}
				handleSave={handleSave}
				loading={loading}
				status={getCompanySettingsStatus}
			/>
		</SectionWrapper>
	);
};

export default CommunicationSettings;
