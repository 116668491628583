import { SxProps } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

interface LoadingButtonProps {
	type?: "button" | "submit";
	loading: boolean;
	children: React.ReactNode;
	variant: "contained" | "outlined" | "text";
	disabled: boolean;
	onClick?: () => void;
	loadingIndicator?: React.ReactNode;
	sx?: SxProps;
	color?:
		| "inherit"
		| "primary"
		| "secondary"
		| "error"
		| "info"
		| "success"
		| "warning";
	size?: "small" | "medium" | "large";
	startIcon?: React.ReactNode;
	endIcon?: React.ReactNode;
	fullWidth?: boolean;
	loadingPosition?: "start" | "end";
}

const LoadingButton: React.FC<LoadingButtonProps> = (props) => {
	return (
		<Button
			variant={props.variant}
			disabled={props.disabled}
			onClick={props.onClick}
			sx={props.sx}
			color={props.color}
			size={props.size}
			startIcon={props.startIcon}
			endIcon={props.endIcon}
			fullWidth={props.fullWidth}
			loadingPosition={props.loadingPosition}
			type={props.type}
			loading={props.loading}
			loadingIndicator={props.loadingIndicator}
		>
			{props.children}
		</Button>
	);
};

export default LoadingButton;
