/* eslint-disable simple-import-sort/imports */
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { useState } from "react";
import Loading from "../../../../../components/Loading";
import WorkerInsightDetailsSkillTab from "./worker-insight-details-skill-tab";
import WorkerInsightDetailsWorkerBackupTab from "./worker-insight-details-worker-backup-tab";
import {
	SkillLevel, WorkerInsightDetailsModel,
	WorkerInsightModel,
} from "../../../slices/insight-report-types";
import WorkerInsightDetailsPriorityTab from "./worker-insight-details-priority-tab";
import { useSelector } from "react-redux";
import WorkerInsightDetailsSkillsGapTab
	from "./worker-insight-details-skills-gap-tab";

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 1 }}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

interface IWorkerInsightDetailsTabsProps {
	allSkillLevels: SkillLevel[];
	workerId: string;
	workerInsightDetailsData: WorkerInsightDetailsModel;
	fetchWorkerInsightDetailsStatus: "idle" | "pending" | "fulfilled" | "error";
	allLocations: string[];
	selectedWorker: WorkerInsightModel;
}

export default function WorkerInsightDetailsTabs({
	allSkillLevels,
	workerId,
	workerInsightDetailsData,
	fetchWorkerInsightDetailsStatus,
	allLocations,
	selectedWorker,
}: IWorkerInsightDetailsTabsProps) {
	const [value, setValue] = useState(0);

	const onHandleTabChange = (event: any, newValue: number) => {
		setValue(newValue);
	};

	const workerProfileStatus = useSelector(
		(state: any) => state.manageWorkers.workerProfile.status
	);

	const isSkillPriorityEnabled = useSelector(
		(state: any) => state.user.userData.skillPriorityEnabled
	);

	const jobSkillLevelMapping = useSelector(
		(state: any) => state.user.userData.jobSkillLevelMapping
	);

	const allPriorities = useSelector((state: any) => state.skills.skillPriorities);

	const allSkills = useSelector((state: any) => state.skills.masterData.skills);

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={value}
					onChange={onHandleTabChange}
					aria-label='basic tabs example'
				>
					<Tab label='Skills' {...a11yProps(0)} />
					<Tab label='Worker Backup' {...a11yProps(1)} />
				</Tabs>
			</Box>

			<TabPanel value={value} index={0}>
				{jobSkillLevelMapping ?
					<WorkerInsightDetailsSkillsGapTab
						allSkillLevels={allSkillLevels}
						loading={fetchWorkerInsightDetailsStatus === "pending"
							|| workerProfileStatus === "pending" || !allPriorities || !allSkills}
					/> :
					isSkillPriorityEnabled ? (
						<WorkerInsightDetailsPriorityTab />
					) : fetchWorkerInsightDetailsStatus === "pending" ? (
						<Loading />
					) : (
						<WorkerInsightDetailsSkillTab allSkillLevels={allSkillLevels} />
					)
				}
			</TabPanel>

			<TabPanel value={value} index={1}>
				<Box sx={{ marginRight: 1, marginLeft: 1 }}>
					<WorkerInsightDetailsWorkerBackupTab
						workersReportData={workerInsightDetailsData}
						workerId={workerId}
						allLocations={allLocations}
					/>
				</Box>
			</TabPanel>
		</Box>
	);
}
