import { Grid2 } from "@mui/material";
import isPast from "date-fns/isPast";
import isSameDay from "date-fns/isSameDay";
import React, { useMemo } from "react";

import { DayColumn } from "./styled-components";

const MemoizedDayColumn = ({
  day,
  timeSlotHeight,
  ...props
}: {
  day: Date;
  timeSlotHeight: number;
} & React.ComponentProps<typeof Grid2>) => {
  const styles = useMemo(
    () => ({
      marginTop: `${timeSlotHeight-1}px`,
      marginBottom: `${timeSlotHeight}px`,
      backgroundColor: isSameDay(day, new Date())
        ? "#fff"
        : isPast(day)
        ? "rgba(0,0,0,0.01)"
        : "#fff",
      borderBottom: "1px solid rgba(224,224,224)",
      borderTop: "1px solid rgba(224,224,224)",
    }),
    [day, timeSlotHeight]
  );

  return <DayColumn style={styles} {...props} />;
};

export default React.memo(MemoizedDayColumn);
