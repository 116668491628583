import { Box, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import format from "date-fns/format";
import React from "react";
import { useSelector } from "react-redux";

import DialogWrapper from "../../../../../design/dialog-wrapper";
import LoadingButton from "../../../../../design/wilya-loading-button";
import usePublishDraftDialogController from "./controllers/use-publish-draft-dialog-controller";
import PublishDraftContent from "./publish-draft-content";

const PublishDraftDialog = ({
	onClose,
	open,
	shiftId,
}: {
	onClose: () => void;
	open: boolean;
	shiftId: string;
}) => {
	const [
		{ shift, allSkillsObj, workCenter, publishDraftShiftsAsyncStatus },
		{ formatedShiftDate, publishDraftShifts },
	] = usePublishDraftDialogController(shiftId, onClose);

	const workerCountByShift = useSelector(
		(state: any) => state.manageJobs.fetchMaxWorkersByLocationJob.data
	);

	const workerCountByShiftStatus = useSelector(
		(state: any) => state.manageJobs.fetchMaxWorkersByLocationJob.status
	);

	return (
		<Dialog
			onClose={(event, reason) => {
				if (reason !== "backdropClick" && onClose) {
					onClose();
				}
			}}
			aria-labelledby='add-new-shift'
			open={open}
			maxWidth='lg'
		>
			<DialogWrapper
				dialogTitle='Publish Shift'
				dialogContent={
					<PublishDraftContent
						shift={shift}
						allSkillsObj={allSkillsObj}
						formatedShiftDate={formatedShiftDate}
						workCenter={workCenter}
						workerCountByShift={workerCountByShift}
						workerCountByShiftStatus={workerCountByShiftStatus}
					/>
				}
				actionButtons={
					<Box>
						<Box display='flex' justifyContent='flex-end' gap={2} mt={-5}>
							<Button onClick={onClose}>Cancel</Button>
							<LoadingButton
								variant='contained'
								loading={publishDraftShiftsAsyncStatus === "pending"}
								disabled={
									workerCountByShift === 0 ||
									workerCountByShiftStatus === "pending"
								}
								onClick={publishDraftShifts}
							>
								Publish
							</LoadingButton>
						</Box>
					</Box>
				}
			/>
		</Dialog>
	);
};

export default PublishDraftDialog;
