import React from "react";

import SectionWrapper from "../../../section-wrapper";
import SectionActions from "../../section-actions";
import AttendanceManagement from "./attendance-management";
import useAddOnController from "./controllers/use-add-on-controllers";
import CrossSkilling from "./cross-skilling";
import FlexSchedule from "./flex-schedule/flex-schedule";
import Karma from "./karma/karma";
import ShiftSwapAndCallouts from "./shift-swap-callout";
import WorkerSubType from "./worker-subtype";

interface IAddOnFeaturesProps {
	companyId: any;
	entity: string;
}
const AddOnFeatures: React.FC<IAddOnFeaturesProps> = ({
	companyId,
	entity,
}) => {
	const [
		{
			updateCompanySettingsLevelStatus,
			companyDataValue,
			addOnSettings,
			newAddOnSettings,
			updateCompanySettingsStatus,
			loading,
		},
		{ setNewAddOnSettings, handleSave },
	]: any = useAddOnController();

	return (
		<SectionWrapper
			title='Add-On Features'
			subtitle='Additional to the existing core functionality'
		>
			<FlexSchedule
				newAddOnSettings={newAddOnSettings}
				setNewAddOnSettings={setNewAddOnSettings}
			/>
			<WorkerSubType
				newAddOnSettings={newAddOnSettings}
				setNewAddOnSettings={setNewAddOnSettings}
			/>
			<CrossSkilling
				newAddOnSettings={newAddOnSettings}
				setNewAddOnSettings={setNewAddOnSettings}
				companySkillLevels={companyDataValue.skillLevels ?? []}
				updateCompanySettingsLevelStatus={updateCompanySettingsLevelStatus}
				companyId={companyId}
				entity={entity}
				handleSave={handleSave}
			/>
			<Karma
				newAddOnSettings={newAddOnSettings}
				setNewAddOnSettings={setNewAddOnSettings}
			/>
			<ShiftSwapAndCallouts
				newAddOnSettings={newAddOnSettings}
				setNewAddOnSettings={setNewAddOnSettings}
			/>
			<AttendanceManagement
				newAddOnSettings={newAddOnSettings}
				setNewAddOnSettings={setNewAddOnSettings}
			/>
			<SectionActions
				oldData={addOnSettings}
				newData={newAddOnSettings}
				companyDataValue={companyDataValue}
				resetChanges={() => setNewAddOnSettings(addOnSettings)}
				handleSave={handleSave}
				status={updateCompanySettingsStatus}
				loading={loading}
			/>
		</SectionWrapper>
	);
};

export default AddOnFeatures;
