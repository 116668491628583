/* eslint-disable no-mixed-spaces-and-tabs */
import { useGridApiRef } from "@mui/x-data-grid-pro-6";
import { useConfirm } from "material-ui-confirm";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import {
	ColumnGroupJobHeader,
	ColumnPriorityHeader,
} from "../../../../export/cross-skilling";
import { updateWorkerSkillLevel } from "../../../../export/workers";
import { hasPermission } from "../../../../ts/utils/helpers";
import {
	addSkillInterested,
	//rejectSkillInterested,
} from "../../../features/ManageWorkers/ManageWorkersSlice";
import {
	filterWorkersBySkillLevel,
	formatSkillsByJobs,
	getLowestLevelObject,
} from "../../../utils/helpers";
import useViewport from "../../../utils/useViewport";
import {
	createJobGroupObject,
	createNonPrioritizedOtherSkillsGroup,
	createPrioritizedOtherSkillsGroup,
	createPriorityObject,
} from "../ui/cross-skill-table/helper";
import {
	filterWorkers,
	skillsWithoutJobs,
} from "../ui/cross-skill-table/utils";
import useCrossSkillData from "./use-cross-skill-data";

const useCrossSkillTableController = ({
	data,
	filters,
	allSkillLevels,
	allJobs,
	getFilter,
	searchText,
}) => {
	const confirm = useConfirm();
	const dispatch = useDispatch();
	let tempData = [...data];

	const {
		addSkillInterestStatus,
		rejectSkillInterestStatus,
		updateWorkerSkillLevelError,
		updateWorkerSkillLevelStatus,
		allSkills,
		fetchManageWorkersDataStatus,
		skillMatrixFiltersStatus,
		skillMatrixDefaultFiltersStatus,
		skillPriorities,
		isSkillPriorityEnabled,
		userRolesWithPermission,
		isJobSkillLevelMapping,
	} = useCrossSkillData();

	let tempWorkers = [];
	const { height, width } = useViewport();
	let columnGroup = [];
	const dataGridRef = useRef();
	const apiRef = useGridApiRef();
	const allSkillsOfAllLocations = allSkills.map((skill) => {
		return { ...skill, skillId: skill.id };
	});

	const isJobFilter = getFilter("job").jobs.length > 0;
	const isSkillFilter = getFilter("skill").skills.length > 0;
	const isDefaultFilter = !isJobFilter && !isSkillFilter;

	const [isColumnGroup, setIsColumnGroup] = useState(true);

	const isLocationTypeFilter = getFilter("location").locations.length > 0;
	const isWorkerTypeFilter = getFilter("workerType").workerTypes.length > 0;
	const isWorkerSubTypeFilter =
		getFilter("workerSubType").workerSubTypes.length > 0;

	const isSkillPriorityFilter =
		getFilter("skillPriority").skillPrioritys.length > 0;
	const isWorkCenterFilter = getFilter("workCenter").workCenters.length > 0;
	const isShiftPatternFilter =
		getFilter("shiftPattern").shiftPatterns.length > 0;
	const isManagerFilter = getFilter("manager").managers.length > 0;

	const workerTypeIds = isWorkerTypeFilter
		? getFilter("workerType").workerTypes.map((workerType) => workerType.id)
		: [];
	const workerSubTypeIds = isWorkerSubTypeFilter
		? getFilter("workerSubType").workerSubTypes.map(
				(workerSubType) => workerSubType.id
		  )
		: [];
	const skillPriorityIds = isSkillPriorityFilter
		? getFilter("skillPriority").skillPrioritys.map(
				(skillPriority) => skillPriority.id
		  )
		: [];
	const managerIds = isManagerFilter
		? getFilter("manager").managers.map((manager) => manager.id)
		: [];
	const workCenterIds = isWorkCenterFilter
		? getFilter("workCenter").workCenters.map((workCenter) => workCenter.id)
		: [];
	const shiftPatternIds = isShiftPatternFilter
		? getFilter("shiftPattern").shiftPatterns.map(
				(shiftPattern) => shiftPattern.id
		  )
		: [];

	// Replace all the individual filter blocks with this
	const typeFilters = [
		{
			isEnabled: isWorkerTypeFilter,
			field: "typeId",
			ids: workerTypeIds,
			parseIds: true,
		},
		{
			isEnabled: isWorkerSubTypeFilter,
			field: "workerSubTypeId",
			ids: workerSubTypeIds,
			parseIds: true,
		},
		{
			isEnabled: isManagerFilter,
			field: "managerId",
			ids: managerIds,
			parseIds: true,
			checkNonEmpty: true,
		},
		{
			isEnabled: isLocationTypeFilter,
			field: "locationId",
			ids: getFilter("location").locations.map((l) => l.id),
			checkNonEmpty: true,
		},
		{
			isEnabled: isWorkCenterFilter,
			field: "workcenterId",
			ids: workCenterIds,
			checkNonEmpty: true,
		},
		{
			isEnabled: isShiftPatternFilter,
			field: "shiftPatternId",
			ids: shiftPatternIds,
			checkNonEmpty: true,
		},
	];

	tempData = typeFilters.reduce(
		(filteredData, filterConfig) => {
			return filterWorkers(filteredData, filterConfig);
		},
		[...data]
	);

	tempWorkers = tempData;

	const skillIds = isSkillFilter
		? getFilter("skill").skills.map((skill) => skill.id)
		: [];
	const skillLevelIds = isSkillFilter
		? getFilter("skill").skills.filter((skill) => skill.levelIds?.length > 0)
		: [];

	const filteredSkills = allSkillsOfAllLocations.filter((skill) =>
		skillIds.includes(skill.skillId)
	);

	const jobIdsSkillsArr =
		isJobFilter || isDefaultFilter
			? []
			: isSkillFilter
			? filteredSkills
			: allSkillsOfAllLocations;

	const jobIds = isDefaultFilter
		? allJobs.map((job) => job.id)
		: isJobFilter
		? getFilter("job").jobs.map((job) => job.id)
		: [];

	(isJobFilter || isDefaultFilter) &&
		formatSkillsByJobs(
			allJobs,
			jobIdsSkillsArr,
			jobIds,
			isSkillFilter ? filteredSkills : [],
			skillPriorityIds
		);

	const filteredWorkersByLevel = isSkillFilter
		? filterWorkersBySkillLevel(tempWorkers, filters, skillIds)
		: [];
	const workers = tempWorkers;
	const handleAddSkillInterested = useCallback(
		(params, skill, setState) => {
			if (
				hasPermission(userRolesWithPermission, "CROSS_SKILLING.ACCEPT_INTEREST")
			) {
				const defaultSkillLevel = getLowestLevelObject(allSkillLevels);
				const interestedSkill = params.row.skillInterests
					? params.row.skillInterests.find(
							(x) => parseInt(x.skillId) === parseInt(skill.skillId)
					  )
					: false;
				confirm({
					title: "Are you sure?",
					description: `You are adding ${skill.name} to ${params.row.firstName}'s skill set.`,
				})
					.then(() => {
						dispatch(addSkillInterested(interestedSkill.id));
						setState((state) => ({
							...state,
							level: defaultSkillLevel.level,
							levelId: defaultSkillLevel.levelId,
							repCode: defaultSkillLevel.repCode,
							backColorCode: defaultSkillLevel.backColorCode,
							foreColorCode: defaultSkillLevel.foreColorCode,
							value: defaultSkillLevel.value,
							isInterest: false,
							skillAssigned: true,
						}));
					})
					.catch(() => {
						/* ... */
					});
			}
		},
		[dispatch, confirm, userRolesWithPermission, allSkillLevels]
	);

	const [skillsUpdatedListInSession, setOnUpdateSkillLevel] = useState([]);

	const onUpdateSkillLevel = useCallback(
		(state, obj, level) => {
			const updatedSkillLevels = skillsUpdatedListInSession.some(
				(x) => x.skillId === obj.skillId && x.workerId === obj.workerId
			)
				? skillsUpdatedListInSession.map((x) =>
						x.skillId === obj.skillId && x.workerId === obj.workerId
							? {
									...state,
									name: level.name,
									level: level.level,
									levelId: level.levelId,
									repCode: level.repCode,
									backColorCode: level.backColorCode,
									foreColorCode: level.foreColorCode,
									value: level.value,
									workerId: obj.workerId,
									skillId: obj.skillId,
							  }
							: x
				  )
				: [
						...skillsUpdatedListInSession,
						{
							...state,
							name: level.name,
							level: level.level,
							levelId: level.levelId,
							repCode: level.repCode,
							backColorCode: level.backColorCode,
							foreColorCode: level.foreColorCode,
							value: level.value,
							workerId: obj.workerId,
							skillId: obj.skillId,
						},
				  ];

			setOnUpdateSkillLevel(updatedSkillLevels);
		},
		[skillsUpdatedListInSession]
	);

	const handleSkillLevels = useCallback(
		(params, skill, level, setState, state) => {
			const obj = {
				skillId: skill.skillId,
				levelId: level.levelId,
				presentLevelId: state ? state.levelId : -1,
				workerId: params.id,
			};

			const workerSkill = params.row.skills.find(
				(x) => x.skillId === skill.skillId
			);

			if (workerSkill) {
				if (workerSkill.levelId !== level.levelId) {
					confirm({
						title: "Are you sure?",
						description: `You are updating skill level from ${workerSkill.levelName} to ${level.name} for ${params.row.firstName} in ${workerSkill.skillName}.`,
					})
						.then(() => {
							dispatch(updateWorkerSkillLevel(obj));
							setState((state) => ({
								...state,
								name: level.name,
								level: level.level,
								levelId: level.levelId,
								repCode: level.repCode,
								backColorCode: level.backColorCode,
								foreColorCode: level.foreColorCode,
								value: level.value,
							}));
							onUpdateSkillLevel(state, obj, level);
						})
						.catch(() => {
							/* ... */
						});
				}
			} else {
				const canConfirm = state ? state.levelId !== level.levelId : true;
				if (canConfirm) {
					if (!state) {
						confirm({
							title: "Are you sure?",
							description: `You are adding ${skill.name} with level ${level.name} for ${params.row.firstName}.`,
						})
							.then(() => {
								dispatch(updateWorkerSkillLevel(obj));
								setState((state) => ({
									...state,
									name: level.name,
									level: level.level,
									levelId: level.levelId,
									repCode: level.repCode,
									backColorCode: level.backColorCode,
									foreColorCode: level.foreColorCode,
									value: level.value,
								}));
								onUpdateSkillLevel(state, obj, level);
							})
							.catch(() => {
								/* ... */
							});
					} else {
						confirm({
							title: "Are you sure?",
							description: `You are updating skill level from ${state.name} to ${level.name} for ${params.row.firstName} in ${skill.name}.`,
						})
							.then(() => {
								dispatch(updateWorkerSkillLevel(obj));
								setState((state) => ({
									...state,
									name: level.name,
									level: level.level,
									levelId: level.levelId,
									repCode: level.repCode,
									backColorCode: level.backColorCode,
									foreColorCode: level.foreColorCode,
									value: level.value,
								}));
								onUpdateSkillLevel(state, obj, level);
							})
							.catch(() => {
								/* ... */
							});
					}
				}
			}
		},
		[dispatch, confirm, onUpdateSkillLevel]
	);

	const createNonPriorityJobGroup = (jobId, jobIdsSkillsArr) => {
		const temp = [];
		const obj = {};

		jobIdsSkillsArr.forEach((sk) => {
			if (jobId === sk.jobId) {
				temp.push({ field: `${sk.skillId}-${sk.jobId}` });
				obj.className = "job-header";
				obj.headerClassName = "job-header";
				obj.groupId = `${jobId}`;
				obj.headerName = sk.jobName;
				obj.children = temp;
				obj.renderHeaderGroup = () => (
					<ColumnGroupJobHeader skill={sk} searchText={searchText} />
				);
			}
		});

		return obj;
	};

	const createOtherSkillsGroup = (skillsWithNoJobs, skillPriorities, job) => {
		if (skillPriorities.length > 0 && isSkillPriorityEnabled) {
			const prioritiesWithSkills = getPrioritiesWithSkills(
				skillsWithNoJobs,
				skillPriorities
			);
			return createPrioritizedOtherSkillsGroup(
				prioritiesWithSkills,
				job,
				searchText
			);
		}

		return createNonPrioritizedOtherSkillsGroup(skillsWithNoJobs, searchText);
	};

	const getPrioritiesWithSkills = (skillsWithNoJobs, skillPriorities) => {
		return skillPriorities
			.map((priority) => ({
				...priority,
				skills: skillsWithNoJobs.filter(
					(skill) => skill.priority.id === priority.id
				),
			}))
			.filter((priority) => priority.skills.length > 0);
	};

	if ((isJobFilter || isDefaultFilter) && jobIds && jobIdsSkillsArr) {
		jobIds.forEach((jobId) => {
			const job = allJobs.find((j) => j.id === jobId);
			if (!job) return;

			const priorities = isSkillPriorityEnabled ? job.priorities : undefined;
			const groupObject = priorities
				? createJobGroupObject(job, priorities, searchText)
				: createNonPriorityJobGroup(jobId, jobIdsSkillsArr);

			if (Object.keys(groupObject).length > 0) {
				columnGroup.push(groupObject);
			}

			// Handle other skills group
			const shouldCreateOtherSkillsGroup =
				allSkillsOfAllLocations.length > 0 &&
				allJobs.length > 0 &&
				!columnGroup.find((x) => x.groupId === "-1") &&
				!isJobFilter &&
				isColumnGroup &&
				!isWorkCenterFilter;

			if (shouldCreateOtherSkillsGroup) {
				const skillsWithNoJobs =
					allJobs.length === 0
						? allSkillsOfAllLocations
						: skillsWithoutJobs(allSkillsOfAllLocations, allJobs);

				const otherSkillsGroup = createOtherSkillsGroup(
					skillsWithNoJobs,
					skillPriorities,
					job,
					searchText
				);
				if (Object.keys(otherSkillsGroup).length > 0) {
					columnGroup.push(otherSkillsGroup);
				}
			}
		});
	}
	// Handle jobs with no skills
	if (jobIds.length > 0) {
		const jobsWithNoSkills = jobIds.filter(
			(jobId) => !columnGroup.some((col) => col.groupId === jobId.toString())
		);

		const noSkillColumns = jobsWithNoSkills
			.map((jobId) => {
				const job = allJobs.find((j) => j.id === jobId);
				if (!job) return null;

				return {
					groupId: jobId.toString(),
					headerName: job.name,
					children: [{ field: `${jobId}--2` }], // -2 represents no skill
					noSkill: true,
					renderHeaderGroup: () => (
						<ColumnGroupJobHeader
							skill={{
								skillId: -2,
								skillName: "No Skill",
								jobName: job.name,
								jobColor: job.hexColor,
							}}
							searchText={searchText}
						/>
					),
				};
			})
			.filter(Boolean);

		columnGroup.push(...noSkillColumns);
	}

	useEffect(() => {
		if (isJobFilter || isDefaultFilter) {
			setIsColumnGroup(true);
		} else {
			setIsColumnGroup(false);
		}
	}, [isJobFilter, isDefaultFilter]);

	return {
		dataGridRef,
		apiRef,
		columnGroup,
		height,
		width,
		addSkillInterestStatus,
		rejectSkillInterestStatus,
		isJobFilter,
		isSkillPriorityFilter,
		skillPriorityIds,
		isSkillFilter,
		isDefaultFilter,
		isColumnGroup,
		skillLevelIds,
		jobIdsSkillsArr,
		jobIds,
		allSkillsOfAllLocations,
		filteredWorkersByLevel,
		filteredSkills,
		workers,
		handleAddSkillInterested,
		handleSkillLevels,
		updateWorkerSkillLevelError,
		updateWorkerSkillLevelStatus,
		fetchManageWorkersDataStatus,
		skillMatrixFiltersStatus,
		skillMatrixDefaultFiltersStatus,
		skillsUpdatedListInSession,
		isWorkCenterFilter,
		isJobSkillLevelMapping,
	};
};

export default useCrossSkillTableController;
