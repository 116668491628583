import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "../../../../js/utils/api-client";
import { setSupervisorRateLimitError } from "../../../features/user/user-slice";
import {normalizeSkillInsightsResponse} from "../../../utils/helpers";
import {
	SkillInsightReportSliceStateTypes,
} from "./insight-report-types";

const initialState: SkillInsightReportSliceStateTypes = {
	fetchSkillInsightReport: {
		status: "idle",
		errorMessage: "",
	},
	skillInsightReportData: [],

	fetchSkillInsightDetails: {
		status: "idle",
		errorMessage: "",
	},
	skillInsightDetailsData: {
		workersList: [],
		interestedWorkersList: [],
	},
	skillInsightPanelDetails: {
		isOpen: false,
		skillId: "",
		locations: [],
		selectedKPI: "",
	},
};

//Get all skill insight
export const getSkillInsightReport = createAsyncThunk(
	"skillInsightReport/getSkillInsightReport",
	async (payload, thunkAPI) => {
		try {
			const skillInsightResponse = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Insight/GetSkillInsightForUserV2/skillinsightV2`
			);

			return thunkAPI.fulfillWithValue(skillInsightResponse.data);
		} catch (error:any) {
			// return thunkAPI.rejectWithValue(error);
			const errorResponse = error?.response.data;
			errorResponse.status = error?.response?.status;
			try{
				if (errorResponse.status === 429) {
					thunkAPI.dispatch(setSupervisorRateLimitError());
					return thunkAPI.rejectWithValue(errorResponse);
				}
				return thunkAPI.rejectWithValue(error);
			} catch (e) {
				return thunkAPI.rejectWithValue("An error occurred");
			}
		}
	}
);

//Get skill insight details by skillId
export const fetchSkillInsightDetails = createAsyncThunk(
	"skillInsightReport/fetchSkillInsight",
	async (payload, thunkAPI) => {
		try {
			const skillDetails = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Insight/GetSkillInsightDetailsV2/skillinsightV2/${payload}`
			);

			return thunkAPI.fulfillWithValue(skillDetails.data);
		} catch (error:any) {
			const errorResponse = error?.response.data;
			errorResponse.status = error?.response?.status;
			try{
				if (errorResponse.status === 429) {
					thunkAPI.dispatch(setSupervisorRateLimitError());
					return thunkAPI.rejectWithValue(errorResponse);
				}
				return thunkAPI.rejectWithValue(error);
			} catch (e) {
				return thunkAPI.rejectWithValue("An error occurred");
			}
		}
	}
);

export const SkillInsightReportSlice = createSlice({
	name: "skillInsightReport",
	initialState,
	reducers: {
		openSkillInsightDetails: (state, action) => {
			state.skillInsightPanelDetails.isOpen = true;
			state.skillInsightPanelDetails.skillId = action.payload.skillId;
			state.skillInsightPanelDetails.locations = action.payload.locations;
			state.skillInsightPanelDetails.selectedKPI =
				action.payload.selectedKPI ?? "";
		},
		closeSkillInsightDetails: (state) => {
			state.skillInsightPanelDetails.isOpen = false;
			state.skillInsightPanelDetails.skillId = "";
			state.skillInsightPanelDetails.locations = [];
		},
		setSelectedKPI: (state, action) => {
			state.skillInsightPanelDetails.selectedKPI = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getSkillInsightReport.fulfilled, (state, action) => {
			state.fetchSkillInsightReport.status = "fulfilled";
			state.skillInsightReportData = normalizeSkillInsightsResponse(action.payload);
		});
		builder.addCase(getSkillInsightReport.rejected, (state, action: any) => {
            if (action.payload?.status === 429) {
                state.fetchSkillInsightReport.status = "error";
            } else {
                state.fetchSkillInsightReport.status = "error";
                state.fetchSkillInsightReport.errorMessage = action.payload ?? "An error occurred";
            }
		});

		builder.addCase(getSkillInsightReport.pending, (state) => {
			state.fetchSkillInsightReport.status = "pending";
		});

		builder.addCase(fetchSkillInsightDetails.fulfilled, (state, action) => {
			state.fetchSkillInsightDetails.status = "fulfilled";
			state.skillInsightDetailsData = action.payload;
		});

		builder.addCase(fetchSkillInsightDetails.rejected, (state, action:any) => {
			if (action.payload?.status === 429) {
				state.fetchSkillInsightDetails.status = "error";
			} else {
				state.fetchSkillInsightDetails.status = "error";
				state.fetchSkillInsightDetails.errorMessage =
					action.payload ?? "An error occurred";
			}
		});

		builder.addCase(fetchSkillInsightDetails.pending, (state) => {
			state.fetchSkillInsightDetails.status = "pending";
			state.fetchSkillInsightDetails.errorMessage = "";
			state.skillInsightDetailsData = {
				workersList: [],
				interestedWorkersList: [],
			};
		});
	},
});

export const {
	openSkillInsightDetails,
	closeSkillInsightDetails,
	setSelectedKPI,
} = SkillInsightReportSlice.actions;

export default SkillInsightReportSlice.reducer;
