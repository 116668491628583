import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logoutUser } from "../../../../export/user";
import {
	closeAddWorkerDialog,
	closeEditWorkerDialog,
	resetCreateNewWorker,
	resetEditWorker,
} from "../../../../js/features/ManageWorkers/ManageWorkersSlice";
import { openNavigationDrawer } from "../../../../ts/features/navigation-drawer/navigation-drawer-slice";
import { MenuItem, MenuItemsProps } from "../../drawer/types";
import useDrawerController from "../controllers/use-drawer-controller";
import useMouseEventsController from "../controllers/use-mouse-events-controller";
import UserDetails from "./user-details";

const secondaryColor = "#18253E";

const MenuItems: FC<MenuItemsProps> = ({
	open,
	keepSettingOpen,
	keepReportOpen,
	keepHelpOpen,
	selectedMenuItemId,
	updateSelectedMenuItemId,
}) => {
	const { topMenuItems, bottomMenuItems, defaultSelectedMenuItem } =
		useDrawerController();
	const [openSettingsMenu, setOpenSettingsMenu] = useState(keepSettingOpen);
	const [openReportsMenu, setOpenReportsMenu] = useState(keepReportOpen);
	const [openHelpMenu, setOpenHelpMenu] = useState(keepHelpOpen);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleSettingsClick = () => {
		if (open) {
			setOpenSettingsMenu(!openSettingsMenu);
		} else {
			dispatch(openNavigationDrawer());
			setOpenSettingsMenu(true);
		}
	};

	const handleReportsClick = () => {
		if (open) {
			setOpenReportsMenu(!openReportsMenu);
		} else {
			dispatch(openNavigationDrawer());
			setOpenReportsMenu(true);
		}
	};
	const handleHelpClick = () => {
		if (open) {
			setOpenHelpMenu(!openHelpMenu);
		} else {
			dispatch(openNavigationDrawer());
			setOpenHelpMenu(true);
		}
	};

	const logout = () => {
		dispatch(logoutUser());
		navigate("/login");
	};

	const expandIcon = ({
		subMenu,
		id,
	}: {
		subMenu?: MenuItem[];
		id: number;
	}) => {
		if (subMenu && subMenu.length > 0) {
			if (open) {
				if (id === 3) {
					return openSettingsMenu ? (
						<ExpandLess sx={{ color: secondaryColor }} />
					) : (
						<ExpandMore sx={{ color: secondaryColor }} />
					);
				} else if (id === 19) {
					return openReportsMenu ? (
						<ExpandLess sx={{ color: secondaryColor }} />
					) : (
						<ExpandMore sx={{ color: secondaryColor }} />
					);
				} else if (id === 77) {
					return openHelpMenu ? (
						<ExpandLess sx={{ color: secondaryColor }} />
					) : (
						<ExpandMore sx={{ color: secondaryColor }} />
					);
				}
			} else {
				return null;
			}
		}
	};

	const handleSubMenuClick = (url?: string) => {
		if (url === "/manage-workers") {
			dispatch(closeAddWorkerDialog());
			dispatch(closeEditWorkerDialog());
			dispatch(resetCreateNewWorker());
			dispatch(resetEditWorker());

			navigate(url);
		} else if (
			url &&
			url.length > 4 &&
			(url.substring(0, 4) === "http" || url.substring(0, 4) === "mail")
		) {
			// Is a URL
			window.open(url, "_blank");
		} else {
			navigate(url || "");
		}
	};

	const subMenuComponent = (id: number, name: string, url: string) => (
		<ListItemButton
			sx={{
				pl: 4,
				backgroundColor: selectedMenuItemId === id ? "#F8F8F8" : "",
			}}
			key={url}
			onClick={() => {
				if (id !== 14 && id !== 88) {
					updateSelectedMenuItemId(id);
				}
				handleSubMenuClick(url);
			}}
		>
			<ListItemText
				sx={{ marginLeft: "35px", color: secondaryColor }}
				primary={name}
			/>
		</ListItemButton>
	);

	const getMenuList = (menuItems: MenuItem[]) => {
		return menuItems.map((item) => {
			const { id, name, icon, url, subMenu } = item;
			const isSubMenu = subMenu && subMenu.length > 0;
			return (
				<ListItem
					key={`list-item-${id}`}
					disablePadding
					sx={{
						display: "block",
					}}
				>
					<ListItemButton
						sx={{
							minHeight: 48,
							justifyContent: open ? "initial" : "center",
							px: 2.5,
							backgroundColor: selectedMenuItemId === id ? "#F8F8F8" : "",
						}}
						onClick={() => {
							if (isSubMenu) {
								if (id === 3) return handleSettingsClick();
								if (id === 77) return handleHelpClick();
								return handleReportsClick();
							}

							if (!url) return;

							if (url === "/login") {
								return logout();
							}

							if (url.startsWith("http") || url.startsWith("mail")) {
								window.open(url, "_blank");
							} else {
								updateSelectedMenuItemId(id);
								navigate(url);
							}
						}}
					>
						<ListItemIcon
							sx={{
								minWidth: 0,
								mr: open ? 3 : "auto",
								justifyContent: "center",
								color: secondaryColor,
							}}
						>
							{icon}
						</ListItemIcon>
						<ListItemText
							primary={name}
							sx={{ opacity: open ? 1 : 0, color: secondaryColor }}
						/>
						{expandIcon({ subMenu, id })}
					</ListItemButton>
					{isSubMenu && open && (
						<Collapse
							in={
								id === 3
									? openSettingsMenu
									: id === 77
									? openHelpMenu
									: openReportsMenu
							}
							timeout='auto'
							unmountOnExit
						>
							<List component='div' disablePadding>
								{subMenu.map((menu) =>
									subMenuComponent(menu.id, menu.name, menu.url || "")
								)}
							</List>
						</Collapse>
					)}
				</ListItem>
			);
		});
	};

	useEffect(() => {
		if (
			selectedMenuItemId === 1 &&
			selectedMenuItemId !== defaultSelectedMenuItem
		) {
			// Update only selected menu id is of full time scheduler which is default
			// Change default if is changed in case full time scheduler is disabled
			updateSelectedMenuItemId(defaultSelectedMenuItem);
		}
	}, [selectedMenuItemId, defaultSelectedMenuItem, updateSelectedMenuItemId]);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					flex: 1,
				}}
			>
				<List>{getMenuList(topMenuItems)}</List>
				<List>{getMenuList(bottomMenuItems)}</List>
			</Box>
		</>
	);
};

export default MenuItems;
