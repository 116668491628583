/* eslint-disable simple-import-sort/imports */
import useGatAdminConfigData from "../../../../../../controllers/use-gat-admin-config-data";

import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FilledInput,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	Stack,
	Switch,
	TextField,
	Typography,
	styled,
} from "@mui/material";
import { Field, Form, Formik, FormikHelpers } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import NumberInput from "./number-input";
import { useDispatch } from "react-redux";
import {
	createTimeOffType,
	editTimeOffType,
} from "../../../../../../store/gat-admin-slice";
import LoadingButton from "../../../../../../../../design/wilya-loading-button";

const PaperPropsSelect = {
	PaperProps: {
		style: {
			maxHeight: 48 * 3 + 14,
			width: 250,
		},
	},
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "left",
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "left",
	},
};

export interface LeaveTypeFormValues {
	name: string;
	description: string;
	timeOffIncrementNumber: number | "";
	timeOffIncrementMetric: string;
	advancedNoticeNumber: number | "";
	advanceNoticeMetric: string;
	validFrom: string;
	locations: number[];
	isEnabledAcrossCompany: boolean;
	id?: number;
	locs: { id: number; name: string }[];
}

interface LeaveTypeFormProps {
	open: boolean;
	onClose: () => void;
	mode: "add" | "edit";
	initialData: LeaveTypeFormValues | null;
}

const validationSchema = Yup.object().shape({
	name: Yup.string().required("Name is required"),
	description: Yup.string().max(
		150,
		"Description must be 150 characters or less"
	),
	timeOffIncrementNumber: Yup.number()
		.min(1)
		.required("Time Off Increment is required"),
	advancedNoticeNumber: Yup.number(),
	advanceNoticeMetric: Yup.string().required("Advance Notice is required"),
	isEnabledAcrossCompany: Yup.boolean(),
	locations: Yup.array().when("isEnabledAcrossCompany", {
		is: false,
		then: Yup.array().min(1, "At least one location is required"),
	}),
});

const LeaveTypeForm: React.FC<LeaveTypeFormProps> = ({
	open,
	onClose,
	mode = "add",
	initialData = null,
}) => {
	const {
		locations,
		createTimeOffTypeStatus,
		createTimeOffTypeErrorMessage,
		editTimeOffTypeStatus,
		editTimeOffTypeErrorMessage,
		companyDataValue,
	} = useGatAdminConfigData();
	const dispatch = useDispatch();

	const locs = locations.map((loc: { id: number }) => loc.id);

	const initialValues: LeaveTypeFormValues = {
		name: "",
		description: "",
		timeOffIncrementNumber: "",
		timeOffIncrementMetric: "",
		advancedNoticeNumber: "",
		advanceNoticeMetric: "Hours",
		validFrom: "",
		isEnabledAcrossCompany: true,
		locations: locs,
		locs: [],
		...initialData,
	};

	const handleSubmit = (values: LeaveTypeFormValues) => {
		const payload = {
			name: values.name,
			description: values.description,
			companyId: companyDataValue.id,
			incrementHours: Number(values.timeOffIncrementNumber),
			applyBeforeHours: Number(values.advancedNoticeNumber),
			isEnabledAcrossCompany: values.isEnabledAcrossCompany,
			enabledLocations: values.locations,
			advanceNoticeMetric: values.advanceNoticeMetric,
			locs: values.locs,
		};
		// console.log(values, payload);
		if (mode === "add") {
			dispatch(createTimeOffType(payload));
		} else {
			dispatch(editTimeOffType({ ...payload, id: initialData?.id }));
		}
	};

	// console.log("initialValues", initialValues);

	useEffect(() => {
		if (
			createTimeOffTypeStatus === "fulfilled" ||
			editTimeOffTypeStatus === "fulfilled"
		) {
			onClose();
		}
	}, [createTimeOffTypeStatus, editTimeOffTypeStatus, onClose]);

	const isEditMode = mode === "edit";
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle
				sx={{
					padding: "25px 25px 5px 25px",
				}}
			>
				{isEditMode ? "Edit Time Off Type" : "Add Time Off Type"}
			</DialogTitle>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, isSubmitting, touched, values, dirty, setFieldValue }) => (
					<Form>
						{/* {console.log("values", values)} */}
						<DialogContent sx={{ height: 450, overflowY: "auto" }}>
							<Grid container spacing={1}>
								<Grid item xs={12} mb={1}>
									<Field
										as={TextField}
										fullWidth
										name='name'
										label='Name*'
										variant='filled'
										error={!!errors.name}
										helperText={errors.name}
									/>
								</Grid>
								<Grid item xs={12}>
									<FormControl fullWidth error={!!errors.description}>
										<Field
											as={TextField}
											variant='filled'
											fullWidth
											name='description'
											label='Description'
											multiline
											rows={2}
											inputProps={{ maxLength: 150 }}
											error={!!errors.description}
										/>
										<FormHelperText
											error={!!errors.description}
											style={{
												textAlign: errors.description ? "left" : "right",
											}}
										>
											{errors.description || `${values.description.length}/150`}
										</FormHelperText>
									</FormControl>
								</Grid>
								<Grid item xs={12} mb={1}>
									<Typography
										sx={{
											fontFamily: "Roboto",
											fontWeight: 400,
											fontSize: "16px",
											color: "rgba(0, 0, 0, 0.87)",
											lineHeight: "24px",
										}}
									>
										Advanced Notice Required
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<FormControl fullWidth>
										<Field name='advancedNoticeNumber'>
											{({ field, form }: any) => (
												<NumberInput
													{...field}
													fullWidth
													label='Number'
													variant='filled'
													value={field.value || 0}
													onChange={(value: number) =>
														form.setFieldValue(field.name, value)
													}
													error={!!errors.timeOffIncrementNumber}
													helperText={errors.timeOffIncrementNumber}
													min={0}
													max={100} // Set an appropriate maximum value
												/>
											)}
										</Field>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<FormControl fullWidth>
										<Field
											label='Metric'
											component={TextField}
											type='text'
											select
											variant='filled'
											name='advanceNoticeMetric'
											id='advanceNoticeMetric'
											error={!!errors.advanceNoticeMetric}
											value={values.advanceNoticeMetric}
											onChange={(e: any) =>
												setFieldValue("advanceNoticeMetric", e.target.value)
											}
										>
											<MenuItem value='Hours'>Hours</MenuItem>
											<MenuItem value='Days'>Days</MenuItem>
										</Field>
									</FormControl>
								</Grid>

								<Grid item xs={12}>
									<Typography
										sx={{
											fontFamily: "Roboto",
											fontWeight: 400,
											fontSize: "16px",
											color: "rgba(0, 0, 0, 0.87)",
											lineHeight: "24px",
											marginBottom: "16px",
											marginTop: "8px",
										}}
									>
										Time Off Increment*
									</Typography>
									<FormControl fullWidth>
										<Field name='timeOffIncrementNumber'>
											{({ field, form }: any) => (
												<NumberInput
													{...field}
													fullWidth
													label='Number (Hours)'
													variant='filled'
													value={field.value || 0}
													onChange={(value: number) =>
														form.setFieldValue(field.name, value)
													}
													error={!!errors.timeOffIncrementNumber}
													helperText={errors.timeOffIncrementNumber}
													min={0}
													max={100} // Set an appropriate maximum value
												/>
											)}
										</Field>
									</FormControl>
								</Grid>

								<Grid item xs={12}>
									<FormControlLabel
										control={
											<Switch
												checked={values.isEnabledAcrossCompany}
												onChange={(event) => {
													setFieldValue(
														"isEnabledAcrossCompany",
														event.target.checked
													);
													if (event.target.checked) {
														const locs = locations.map(
															(loc: { id: number }) => loc.id
														);
														setFieldValue("locations", locs);
													} else setFieldValue("locations", []);
												}}
												name='isEnabledAcrossCompany'
												color='primary'
											/>
										}
										label='Applies to all Locations in the Company'
									/>
									{!values.isEnabledAcrossCompany && (
										<FormControl
											fullWidth
											error={!!errors.locations && touched.locations}
											//sx={{ marginTop: "16px" }}
										>
											<InputLabel id='locations-label'>Locations*</InputLabel>
											<Field
												as={Select}
												varaint='filled'
												labelId='locations-label'
												name='locations'
												multiple
												input={<FilledInput />}
												MenuProps={PaperPropsSelect}
												renderValue={(selected: number[]) => {
													return selected.length > 3
														? `${selected.length} Locations`
														: selected
																.map(
																	(id) =>
																		locations.find(
																			(loc: { id: number }) => loc.id === id
																		)?.name
																)
																.join(", ");
												}}
											>
												{locations.length === 0 ? (
													<MenuItem disabled value=''>
														No Locations Found
													</MenuItem>
												) : (
													locations.map(
														(location: { id: number; name: string }) => (
															<MenuItem key={location.id} value={location.id}>
																<Checkbox
																	checked={
																		values.locations.indexOf(location.id) > -1
																	}
																/>
																<ListItemText primary={location.name} />
															</MenuItem>
														)
													)
												)}
											</Field>
											{errors.locations && touched.locations && (
												<FormHelperText>{errors.locations}</FormHelperText>
											)}
										</FormControl>
									)}
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions
							sx={{
								padding: "25px",
							}}
						>
							<Button onClick={onClose}>Cancel</Button>
							<LoadingButton
								type='submit'
								variant='contained'
								color='primary'
								disabled={
									Object.keys(errors).length > 0 ||
									!dirty ||
									values.locations.length === 0
								}
								loading={
									createTimeOffTypeStatus === "pending" ||
									editTimeOffTypeStatus === "pending"
								}
							>
								{isEditMode ? "Update" : "Create"}
							</LoadingButton>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default LeaveTypeForm;
