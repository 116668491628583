import { useEffect } from "react";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

interface AuthRouteProps {
	authenticated: boolean;
	workerApp: boolean;
}

const AuthRoute = ({ authenticated, workerApp }: AuthRouteProps) => {
	// const history = useHistory()
	// console.log('IN AuthRoute: ', Component, ' workerAPp; ', workerApp, ' authenticated: ', authenticated)
	useEffect(() => {
		return () => {
			if (localStorage.getItem("login-redirect")) {
				// console.log('removing login-redirect')
				localStorage.removeItem("login-redirect");
			}
		};
	}, []);

	const loginRedirect = localStorage.getItem("login-redirect") || "/";

	if (workerApp) {
		return authenticated ? <Navigate to='/w' replace /> : <Outlet />;
	}

	return authenticated ? <Navigate to={loginRedirect} replace /> : <Outlet />;
};

export default AuthRoute;
