/* eslint-disable no-mixed-spaces-and-tabs */
import {
	Box,
	Grid,
	MenuItem,
	Select,
	Skeleton,
	Switch,
	Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { AdapterDayjs } from "@mui/x-date-pickers-6/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers-6/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-6/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers-6/TimePicker";
import format from "date-fns/format";
import dayjs from "dayjs";
import React, { useState } from "react";

import { getTimeForTimePicker } from "../../../../../../utils/helpers";
import useGatAdminConfigData from "../../../../controllers/use-gat-admin-config-data";

const CompanyCommunicationSettings = ({
	communicationSettings,
	newCommunicationSettings,
	setNewCommunicationSettings,
	loading,
}: {
	communicationSettings:any
	newCommunicationSettings: any;
	setNewCommunicationSettings: any;
	loading: boolean;
}) => {
	const { timeZones } = useGatAdminConfigData();
	const [startTimeError, setStartTimeError] = useState("");

  const validateMinutes = (newTime: any) => {
    const minutes: any = format(new Date(newTime.format()), "mm");
    if (Number(minutes) !== 0 && Number(minutes) !== 30) {
      return false;
    }
    return true;
  };
	return (
		<Grid item xs={12}>
			<Box
				sx={{
					backgroundColor: "#F5F5F5",
					borderRadius: "8px",
					padding: "16px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Allow email notifications for Supervisors
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{loading ? (
							<Skeleton width={50} height={40} />
						) : (
							<Switch
								checked={
									newCommunicationSettings.allowSupervisorNotificationsCompany ??
									false
								}
								onChange={(e) =>
									setNewCommunicationSettings((prevSettings: any) => ({
										...prevSettings,
										allowSupervisorNotificationsCompany: e.target.checked,
									}))
								}
							/>
						)}
					</Box>
				</Box>

				{newCommunicationSettings.allowSupervisorNotificationsCompany ? (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "flex-start",
							marginTop: "16px",
							flexDirection: "column",
							marginLeft: "16px",
						}}
					>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "14px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Time when supervisor receives the daily email
						</Typography>
						<Box
							display='flex'
							alignItems='center'
							width={600}
							justifyContent='space-between'
						>
							<Box
								sx={{
									maxWidth: "200px",
									"& .MuiTextField-root": {
										width: "100px",
									},
								}}
							>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={["TimePicker"]}>
										<TimePicker
											className="custom-timepicker"
											label=''
											minutesStep={30}
											timeSteps={{
												hours: 1,
												minutes: 30,
												seconds: 60,
											}}
											slotProps={{
												textField: {
													variant: "filled",
													error: startTimeError !== "",
													helperText: startTimeError,
													InputLabelProps: {
													shrink: true,
													},
													sx: {
														minWidth: "168px !important",
														backgroundColor: "white",
													},
													InputProps: {
														readOnly:false
                                                      },

												},
											}}
											onError={(error) => {
												if (error === "invalidDate") {
													setStartTimeError("Invalid time");
												}
											}}
											onChange={(newValue) => {
												try {
													const validatedTime = validateMinutes(newValue);
												if(!validatedTime){
													setStartTimeError("30 minutes gap allowed");
													setNewCommunicationSettings(communicationSettings);
													return; 
												}
												else{
													setStartTimeError("");
													if (JSON.stringify(newValue).length !== 4) {
														setNewCommunicationSettings({
															...newCommunicationSettings,
															dailyReportToUserTimeCompany:
																getTimeForTimePicker(newValue),
														});
													} else if (newValue === null) {
														setNewCommunicationSettings({
															...newCommunicationSettings,
															dailyReportToUserTimeCompany: "",
														});
													}
												}
												} catch (e) {
													console.log("Having error", e);
												}
											}}
											value={
												newCommunicationSettings.dailyReportToUserTimeCompany
													? dayjs(
															`${format(new Date(), "yyyy-MM-dd")}T${
																newCommunicationSettings.dailyReportToUserTimeCompany
															}`
															// eslint-disable-next-line no-mixed-spaces-and-tabs
													  )
													: dayjs(
															`${format(
																new Date(),
																"yyyy-MM-dd"
															)}T${"08:00:00"}`
															// eslint-disable-next-line no-mixed-spaces-and-tabs
													  )
											}
										/>
									</DemoContainer>
								</LocalizationProvider>
								{!newCommunicationSettings.dailyReportToUserTimeCompany && (
									<Typography
										sx={{
											fontFamily: "Roboto",
											fontWeight: 400,
											fontSize: "12px",
											color: "rgba(0, 0, 0, 0.6)",
											marginTop: "4px",
											marginLeft: "16px",
										}}
									>
										Hours:Minutes AM/PM
									</Typography>
								)}
							</Box>
							<Box
								sx={{
									width: "400px",
									marginTop: "8px",
								}}
							>
								{timeZones?.length > 0 && (
									<FormControl variant='filled' fullWidth>
										<InputLabel id='location-timezone-select'>
											Select a time zone (required)
										</InputLabel>
										<Select
											labelId='location-timezone-select'
											onChange={(e) =>
												setNewCommunicationSettings((prevSettings: any) => ({
													...prevSettings,
													companyCommTimezone: e.target.value,
												}))
											}
											value={newCommunicationSettings.companyCommTimezone ?? ""}
											variant='filled'
											sx={{
												backgroundColor: "white",
											}}
										>
											{timeZones.map((timeZone: any) => (
												<MenuItem
													key={`timezone-${timeZone.id}`}
													value={timeZone.id}
												>
													{timeZone.displayName}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								)}
							</Box>
						</Box>
					</Box>
				) : null}
			</Box>
			<Box
				sx={{
					backgroundColor: "#F5F5F5",
					borderRadius: "8px",
					padding: "16px",
					marginTop: "28px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Allow SMS / in-app notifications for Workers
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{loading ? (
							<Skeleton width={50} height={40} />
						) : (
							<Switch
								checked={
									newCommunicationSettings.allowWorkerNotificationsCompany ??
									false
								}
								onChange={(e) =>
									setNewCommunicationSettings((prevSettings: any) => ({
										...prevSettings,
										allowWorkerNotificationsCompany: e.target.checked,
									}))
								}
							/>
						)}
					</Box>
				</Box>
			</Box>
		</Grid>
	);
};

export default CompanyCommunicationSettings;
