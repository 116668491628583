import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	Grid,
	IconButton,
	Snackbar,
	TextField,
	Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React from "react";

import { WorkerTypeHours } from "../../../../store/gat-admin-slice-types";
import { useWeeklyHourLimitController } from "../controllers/use-weekly-hour-limit-controller";

interface WorkerTypeHourLimitProps {
	workerTypeHour: WorkerTypeHours;
}

const MAX_HOURS = 168;
const WorkerTypeHourLimit = ({ workerTypeHour }: WorkerTypeHourLimitProps) => {
	const [
		{ workerTypeLimit, isFocused },
		{ setIsFocused, handleBlur, handleHoursChange, handleSave, handleCancel },
	] = useWeeklyHourLimitController(workerTypeHour);

	if (!workerTypeLimit) return null;
	return (
		<>
			<Grid
				container
				sx={{
					display: "flex",
					alignItems: "flex-start",
					p: 2,
				}}
				key={`${workerTypeLimit.workerTypeId}-${workerTypeLimit.workerSubTypeId}`}
			>
				<Grid item xs={4}>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "16px",
							color: "rgba(0, 0, 0, 0.87)",
						}}
					>
						{workerTypeLimit.workerTypeName}
						{workerTypeLimit.workerSubTypeName &&
							` - ${workerTypeLimit.workerSubTypeName}`}
					</Typography>
				</Grid>
				<Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
					<TextField
						id='number-of-maximum-hours'
						variant='filled'
						type='number'
						onFocus={() => setIsFocused && setIsFocused(true)}
						onBlur={handleBlur}
						sx={{
							width: "120px",
							"& .MuiInputBase-input": {
								p: 1,
								background: isFocused ? "#FFF" : "#F5F5F5",
							},
						}}
						onChange={(event) =>
							handleHoursChange &&
							handleHoursChange(parseInt(event.target.value))
						}
						value={workerTypeLimit.maxHours ?? MAX_HOURS}
						helperText={
							(workerTypeLimit.maxHours ?? 0) >= MAX_HOURS && isFocused
								? "Max. limit is 168"
								: " "
						}
					/>
					{isFocused && (
						<Box sx={{ display: "flex", p: 0.5 }}>
							<IconButton
								size='small'
								onClick={handleSave}
								sx={{ mx: 1, mb: 1 }}
							>
								<CheckIcon />
							</IconButton>
							<IconButton
								size='small'
								onClick={handleCancel}
								sx={{ mx: 1, mb: 1 }}
							>
								<CloseIcon />
							</IconButton>
						</Box>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default WorkerTypeHourLimit;
