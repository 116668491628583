import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

import TextField from "../../design/wilya-textfield";

interface SearchbarProps {
	width?: string;
	placeholder?: string;
	variant?: "filled" | "outlined" | "standard";
	value: string | undefined;
	setSearch?: undefined | ((value: string) => void);
	debounce?: boolean;
	debTime?: number;
	label?: boolean;
	hiddenLabel?: boolean;
}

const Searchbar: React.FC<SearchbarProps> = ({
	width,
	placeholder = "Search",
	variant = "filled",
	value,
	setSearch,
	debounce = false,
	debTime = 500,
	label = true,
	hiddenLabel = false,
	...props
}) => {
	const [displayText, setDisplayText] = useState(value ?? "");
	const [debouncedText] = useDebounce(displayText, debounce ? debTime : 0);

	useEffect(() => {
		setSearch && setSearch(debouncedText);
	}, [debouncedText, setSearch]);

	return (
		<Box
			sx={{
				"& .MuiTextField-root": {
					marginLeft: 1,
					marginRight: 1,
					width: width || "100%",
				},
				flex: width ? "initial" : 1,
			}}
		>
			<TextField
				label={label ? "Search" : undefined}
				hiddenLabel={hiddenLabel}
				placeholder={placeholder}
				variant={variant}
				value={displayText}
				size='small'
				InputProps={{
					endAdornment: displayText !== undefined && displayText.length > 0 && (
						<CancelOutlinedIcon
							sx={{ cursor: "pointer", color: "#92918f" }}
							onClick={() => setDisplayText("")}
						/>
					),
				}}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					setDisplayText(e.target.value);
				}}
				sx={{
					"& .MuiFilledInput-underline:before": {
						borderBottom: "none",
					},
					"& .MuiFilledInput-underline:after": {
						borderBottom: "none",
					},
					paddingTop: label ? 0 : "4px",
				}}
				{...props}
			/>
		</Box>
	);
};

export default Searchbar;
