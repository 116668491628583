/* eslint-disable no-mixed-spaces-and-tabs */
import {
	Box,
	CircularProgress,
	Grid,
	Snackbar,
	Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import useGatAdminConfigData from "../../../../controllers/use-gat-admin-config-data";
import { resetUpdateWorkerTypeHours } from "../../../../store/gat-admin-slice";
import WorkerTypeHourLimit from "./worker-type-hour-limit";

// Define types for better type safety
interface WorkerTypeHour {
	workerTypeId: number;
	workerSubTypeId: number | null;
	companyId: number;
	workerTypeName: string;
	workerSubTypeName: string | null;
	maxHours: number;
}

interface RootState {
	gatAdminConfiguration: {
		getWorkerTypeHours: {
			data: WorkerTypeHour[];
			status: string;
		};
	};
}

// Extract common styles
const styles = {
	container: {
		backgroundColor: "#F5F5F5",
		borderRadius: "8px",
		mt: 4,
		mb: 2,
		p: 2,
	},
	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	gridContainer: {
		display: "flex",
		alignItems: "center",
		p: 2,
	},
	divider: {
		mx: 2,
		my: 1,
	},
	loadingContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		p: 2,
	},
	text: {
		fontFamily: "Roboto",
		fontSize: "16px",
		color: "rgba(0, 0, 0, 0.87)",
	},
	subText: {
		fontFamily: "Roboto",
		fontSize: "12px",
		color: "rgba(0, 0, 0, 0.6)",
	},
} as const;

const WeeklyHourLimit = () => {
	const dispatch = useDispatch();
	const { data: initialWorkerTypeHours, status: workerTypeHoursStatus } =
		useSelector(
			(state: RootState) => state.gatAdminConfiguration.getWorkerTypeHours
		);
	const { updateWorkerTypeHoursStatus } = useGatAdminConfigData();

	const renderContent = () => {
		if (workerTypeHoursStatus === "pending") {
			return (
				<Box sx={styles.loadingContainer}>
					<CircularProgress />
					<Typography sx={{ ml: 2 }}>Loading...</Typography>
				</Box>
			);
		}

		return initialWorkerTypeHours?.map((workerTypeHour: WorkerTypeHour) => (
			<WorkerTypeHourLimit
				workerTypeHour={workerTypeHour}
				key={`${workerTypeHour.workerTypeId}-${workerTypeHour.workerSubTypeId}`}
			/>
		));
	};

	return (
		<Box sx={styles.container}>
			<Box sx={styles.header}>
				<Box>
					<Typography sx={styles.text}>Weekly hour limit</Typography>
					<Typography sx={styles.subText}>
						Minimum and maximum no. of hours a worker can work in a week.
					</Typography>
				</Box>
			</Box>

			<Box sx={{ mt: 4 }}>
				<Grid container sx={styles.gridContainer}>
					<Grid item xs={4}>
						<Typography sx={{ ...styles.text, fontWeight: 500 }}>
							Worker
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography sx={{ ...styles.text, fontWeight: 500 }}>
							Maximum (in hours)
						</Typography>
					</Grid>
				</Grid>
				<Divider sx={styles.divider} />
				{renderContent()}
			</Box>
			<Snackbar
				open={updateWorkerTypeHoursStatus === "fulfilled"}
				autoHideDuration={3000}
				onClose={() => dispatch(resetUpdateWorkerTypeHours())}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<MuiAlert elevation={6} variant='filled' severity='success'>
					Worker type hours updated successfully
				</MuiAlert>
			</Snackbar>
		</Box>
	);
};

export default WeeklyHourLimit;
