import { Box, Typography } from "@mui/material";
import React from "react";

import { FilterProvider } from "../../components/filters/filter-context";
import ReportTabs from "../../design/report-tabs";
import DailyActivityReport from "./daily-activity-report";
import MonthlyActivityReport from "./monthly-activity-report";

const WorkerActivityDashboard = () => {
	const tabs = [
		{
			label: "Monthly",
			component: (
				<FilterProvider filterKey="monthly-activity-report">
					<MonthlyActivityReport />
				</FilterProvider>
			),
		},
		{
			label: "Daily",
			component: (
				<FilterProvider filterKey="daily-activity-report">
					<DailyActivityReport />
				</FilterProvider>
			),
		},
	];
	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
					justifyContent: "center",
					background: "#fff",
					padding: "24px 24px 0px 24px",
					borderRadius: "15px 15px 0px 0px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "24px",
						fontWeight: 400,
						color: "rgba(0,0,0,0.87)",
					}}
				>
					Worker Activity Reports
				</Typography>
			</Box>

			<ReportTabs tabs={tabs} />
		</>
	);
};

export default WorkerActivityDashboard;
