import {Layers, Schedule} from "@mui/icons-material";
import { Box, Grid2, Skeleton } from "@mui/material";
import Fade from "@mui/material/Fade";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import React, { useEffect, useMemo } from "react";

import TooltipContent from "../../components/scheduler/ui/tooltip/tooltip-content";
import { dayHeaderHeight, gutterWidth } from "./grid-scheduler/constants";
import useGridSchedulerController from "./grid-scheduler/controllers/use-grid-scheduler-controller";
import MemoizedCurrentTimeIndicator from "./grid-scheduler/memoized-current-time-indicator";
import MemoizedDayColumn from "./grid-scheduler/memoized-day-column";
import MemoizedDayColumnHeader from "./grid-scheduler/memoized-day-column-header";
import { ShiftContainer } from "./grid-scheduler/shift-container";
import {
  DayHeader,
  TimeLabel,
  TimeSlot,
  TimeSlotHeader,
} from "./grid-scheduler/styled-components";
import TimeSlotContainer from "./grid-scheduler/time-slot-container";
import {getDayNumber, getLocalTimeZone, getUpdatedStackedShifts, hours} from "./grid-scheduler/utils";

export default function MyScheduler({
  openPublishDraftShiftDialog,
  openShiftCancelDialog,
  openDeleteDraftShiftDialog,
  openCreateNewShiftDialog,
  openShiftDetailsDialog,
  setShiftIdForShiftDetailsDialog,
  openShiftAttendanceDialog,
  changeWeekAsyncStatus,
  changeDayAsyncStatus,
  height,
  filterAccordionHeight,
  isLoading,
}: any) {
  const [
    {
      anchorEl,
      selectedShift,
      currentTime,
      timeSlotHeight,
      weekStartDay,
      startingDay,
      currentDate,
      isWeekView,
      todayPosition,
      pastWeekShifts,
      processedShifts,
      userRoles,
      canCreateOrEditShift,
      visible,
      isLayerPopoverOpen,
      layerEl,
      selectedHourDateInfo,
      hourPassingShifts,
      calendarRef,
      defaultCalendarScrollValue,
      recentShiftScrollValue,
    },
    {
      toggleVisibility,
      setVisible,
      generateSkeletonVisibility,
      openShiftPopover,
      closeLayerPopover,
      openLayerPopover,
      isLastDayOfWeek,
      setRecentShiftScrollValue,
    },
  ] = useGridSchedulerController(height);

  const showSkeleton = useMemo(() => {
    return (
      changeWeekAsyncStatus === "pending" ||
      !currentDate ||
      changeDayAsyncStatus === "pending" ||
      isLoading
    );
  }, [changeDayAsyncStatus, changeWeekAsyncStatus, currentDate, isLoading]);

  // Memoized Skeleton Header loader for performance improvement
  const memoizedDayHeadersWithSkeleton = useMemo(() => {
    return Array.from({ length: isWeekView ? 7 : 1 }).map((_, index) => {
      return (
        <MemoizedDayColumnHeader
          key={`day-header-skeleton-${index}`}
          day={addDays(new Date(), 1)}
          width={"100%"}
        >
          <DayHeader>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <Skeleton
                variant="rounded"
                width={30}
                height={14}
                sx={{
                  backgroundColor: "#F5F5F5",
                  marginRight: 1,
                }}
              />
              <Skeleton
                variant="rounded"
                width={40}
                height={23}
                sx={{ backgroundColor: "#F5F5F5"}}
              />
            </Box>
          </DayHeader>
        </MemoizedDayColumnHeader>
      );
    });
  }, [isWeekView]);

  // Memoized Skeleton Time slots loader for performance improvement
  const memoizedTimeSlotsWithSkeleton = useMemo(() => {
    return Array.from({ length: isWeekView ? 7 : 1 }).map((_, dayIndex) => {
      const day = isWeekView ? addDays(new Date(), 1) : new Date();
      const skeletonVisibility = generateSkeletonVisibility(
        hours.length,
        dayIndex
      );
      return (
        <MemoizedDayColumn
          key={dayIndex}
          day={day}
          timeSlotHeight={timeSlotHeight}
          width={"100%"}
        >
          {hours.map((_, hourIndex) => (
            <TimeSlot height={timeSlotHeight} key={hourIndex}>
              {skeletonVisibility[hourIndex] ? (
                <Skeleton
                  key={`shift-skeleton--${dayIndex}-${hourIndex}`}
                  variant="rounded"
                  width="100%"
                  animation="wave"
                  height={timeSlotHeight * 2}
                  sx={{
                    backgroundColor: "#F5F5F5",
                    marginLeft: "12px",
                    marginRight: "24px",
                  }}
                />
              ) : null}
            </TimeSlot>
          ))}
        </MemoizedDayColumn>
      );
    });
  }, [generateSkeletonVisibility, isWeekView, timeSlotHeight]);

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.scrollTo({
        top: defaultCalendarScrollValue,
        behavior: "smooth",
      });
    }
  }, [calendarRef, defaultCalendarScrollValue, currentDate]);

  useEffect(() => {
    try {
      if (calendarRef.current && recentShiftScrollValue !== 0) {
        calendarRef.current.scrollTo({
          top: recentShiftScrollValue,
          behavior: "smooth",
        });
        setRecentShiftScrollValue(0);
      }
    } catch (e) {
      // Do nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarRef, recentShiftScrollValue]);

  return (
    <>
      <Box
        ref={calendarRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: `${filterAccordionHeight}px`,
          maxHeight: `${filterAccordionHeight}px`,
          height: `${filterAccordionHeight}px`,
          overflow: "scroll",
          paddingRight: "12px",
          flex: 1,
          userSelect: "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "flex-start",
          }}
        >
          {/* Time Labels and Indicators */}
          <Box
            sx={{
              minWidth: "80px",
              maxWidth: "80px",
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                position: "sticky",
                top: 0,
                height: `${dayHeaderHeight}px`,
                minHeight: `${dayHeaderHeight}px`,
                backgroundColor: "rgba(255,255,255)",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
                zIndex: 1001,
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.7rem",
                  lineHeight: 1.66,
                  fontWeight: 400,
                  fontFamily: "Roboto",
                  color: "rgba(0,0,0,0.3)",
                  textAlign: "right",
                  paddingBottom: "12px",
                }}
              >
                {getLocalTimeZone()}
              </Typography>
            </Box>
            <TimeSlotHeader
              height={timeSlotHeight}
              key={"time-slot-header-first"}
            />
            {hours.map((hour, index) => (
              <TimeSlotHeader
                height={timeSlotHeight}
                key={`time-slot-header-${index}`}
              >
                <TimeLabel>
                  <Box
                    sx={{
                      width: "100%",
                      marginRight: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.7rem",
                        lineHeight: 1.66,
                        fontWeight: 400,
                        fontFamily: "Roboto",
                        marginTop: -1,
                        marginRight: 1,
                        color: "rgba(0,0,0,0.6)",
                        textAlign: "right",
                      }}
                    >
                      {hour}
                    </Typography>
                  </Box>
                </TimeLabel>
              </TimeSlotHeader>
            ))}
            <TimeSlotHeader
              height={timeSlotHeight}
              key={"time-slot-header-last"}
            >
              <TimeLabel>
                <Box
                  sx={{
                    width: "100%",
                    marginRight: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "0.7rem",
                      lineHeight: 1.66,
                      fontWeight: 400,
                      fontFamily: "Roboto",
                      marginTop: -1,
                      marginRight: 1,
                      color: "rgba(0,0,0,0.6)",
                      textAlign: "right",
                    }}
                  >
                    12 AM
                  </Typography>
                </Box>
              </TimeLabel>
            </TimeSlotHeader>
          </Box>

          {/* Day Columns */}
          <Grid2
            container
            width={"100%"}
            sx={{
              flexGrow: 1,
            }}
          >
            {/* Day headers */}
            <Grid2
              container
              width={"100%"}
              sx={{
                position: "sticky",
                top: 0,
                height: `${dayHeaderHeight}px`,
                minHeight: `${dayHeaderHeight}px`,
                backgroundColor: "rgba(255,255,255)",
                boxShadow: "0px 2px 4px -2px rgba(0, 0, 0, 0.2)",
                flexWrap: "nowrap",
                zIndex: 1000,
              }}
            >
              {showSkeleton
                ? memoizedDayHeadersWithSkeleton
                : currentDate &&
                  Array.from({ length: isWeekView ? 7 : 1 }).map((_, index) => {
                    const day = isWeekView
                      ? addDays(startingDay, index)
                      : new Date(new Date(currentDate).setHours(0, 0, 0, 0));
                    return (
                      <MemoizedDayColumnHeader key={index} day={day} width={"100%"}>
                        <DayHeader>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "flex-end",
                            }}>
                                <Typography
                                    sx={{
                                        fontSize: "0.875rem",
                                        lineHeight: 1.15,
                                        fontWeight: isSameDay(day, new Date())
                                            ? 600
                                            : 400,
                                        fontFamily: "Roboto",
                                        color: isSameDay(day, new Date())
                                            ? "#2F4D8B"
                                            : "rgba(0,0,0,0.6)",
                                        marginRight: "0.5rem"
                                    }}
                                >
                                    {format(day, "EEE")}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "1.25rem",
                                        lineHeight: 1,
                                        fontWeight: isSameDay(day, new Date())
                                            ? 600
                                            : 500,
                                        fontFamily: "Roboto",
                                        color: isSameDay(day, new Date())
                                            ? "#2F4D8B"
                                            : "rgba(0,0,0,0.6)",
                                    }}
                                >
                                    {format(day, "d")}
                                </Typography>
                            </Box>
                        </DayHeader>
                      </MemoizedDayColumnHeader>
                    );
                  })}
            </Grid2>
            <Grid2
              container
              width={"100%"}
              sx={{
                overflow: "hidden",
                flexWrap: "nowrap",
                backgroundColor: "#f1f1f139",
              }}
            >
              {/* Time slots */}
              {showSkeleton
                ? memoizedTimeSlotsWithSkeleton
                : Array.from({ length: isWeekView ? 7 : 1 }).map((_, index) => {
                    const day = isWeekView
                      ? addDays(startingDay, index)
                      : currentDate
                      ? new Date(currentDate)
                      : new Date();
                    const dayIndex = isWeekView
                      ? index
                      : (day.getDay() + 7 - getDayNumber(weekStartDay)) % 7;
                    return (
                      <MemoizedDayColumn
                        key={dayIndex}
                        day={day}
                        timeSlotHeight={timeSlotHeight}
                        width={"100%"}
                      >
                        {dayIndex === todayPosition && (
                          <MemoizedCurrentTimeIndicator
                            currentTime={currentTime}
                          />
                        )}
                        {hours.map((hour, hourIndex) => {
                          // all shifts passing, ending or originating on this hour
                          const allPassingShiftsPre = processedShifts?.filter(
                            (shift: any) => {
                              const startDate = new Date(
                                shift.startDateTime
                              ).getTime();
                              const endDate = new Date(
                                shift.endDateTime
                              ).getTime();
                              const zoneStartDate = new Date(day).setHours(
                                hourIndex,
                                0,
                                0,
                                0
                              );
                              const zoneEndDate = new Date(day).setHours(
                                hourIndex + 1,
                                0,
                                0,
                                0
                              );
                              return (
                                (startDate >= zoneStartDate &&
                                  startDate < zoneEndDate) ||
                                (endDate > zoneStartDate &&
                                  endDate <= zoneEndDate) ||
                                (startDate <= zoneStartDate &&
                                  endDate >= zoneEndDate)
                              );
                            }
                          ) ?? [];

                          // Sort all passing shifts by start time
                          const allPassingShifts = allPassingShiftsPre?.sort(
                            (a: any, b: any) => {
                              const aStart = new Date(a.startDateTime).getTime();
                              const bStart = new Date(b.startDateTime).getTime();
                              return aStart - bStart;
                            }
                          ) ?? [];

                          let validShifts = processedShifts.map(
                            (shift: any, shiftIndex: number) => {
                              const isPastWeekShifts = pastWeekShifts.some(
                                (s: { id: any }) => s.id === shift.id
                              );
                              if (
                                (!isPastWeekShifts &&
                                  shift.shiftHourIndex === hourIndex &&
                                  shift.shiftDayIndex === dayIndex) ||
                                (shift.shiftEndHourIndex !== -1 &&
                                  shift.shiftEndHourIndex === hourIndex &&
                                  shift.shiftNextDayIndex === dayIndex)
                              ) {
                                return (
                                  <Box
                                    key={`shift-${shiftIndex}`}
                                    sx={{
                                      position: "absolute",
                                      top:
                                        shift.shiftEndHourIndex !== -1 &&
                                        shift.shiftEndHourIndex === hourIndex &&
                                        shift.shiftNextDayIndex === dayIndex
                                          ? "auto"
                                          : `${shift.shiftTop}%`,
                                      bottom:
                                        shift.shiftEndHourIndex !== -1 &&
                                        shift.shiftEndHourIndex === hourIndex &&
                                        shift.shiftNextDayIndex === dayIndex
                                          ? `${shift.shiftBottom}%`
                                          : "auto",
                                      height: `${shift.shiftHeight}px`,
                                      left:
                                        shift.shiftWidthPercent === 50 &&
                                        shift.changedMarginOfShift === 50
                                          ? `calc(${
                                              shift.shiftIndexInOverlapping > 0
                                                ? shift.changedMarginOfShift
                                                : 0
                                            }% - ${gutterWidth / 4}px)`
                                          : `calc(${
                                              shift.shiftIndexInOverlapping > 0
                                                ? shift.changedMarginOfShift
                                                : 0
                                            }% + ${gutterWidth / 2}px)`,
                                      width:
                                        shift.shiftWidthPercent === 50
                                          ? `calc(${
                                              shift.shiftWidthPercent
                                            }% - ${(gutterWidth * 3) / 4}px)`
                                          : `calc(${
                                              shift.shiftWidthPercent
                                            }% - ${
                                              gutterWidth / 2
                                            }px - ${gutterWidth}px 
                                                    + 4px)`, // 4px is the minimum offset of
                                      // last row shift card while overlapping
                                      cursor: "pointer",
                                      zIndex: 26 + shiftIndex, // To ensure it is above time slots
                                    }}
                                    onClick={(e) => {
                                      anchorEl.current = e.currentTarget;
                                      selectedShift.current = {
                                        ...shift,
                                        dayIndex: dayIndex,
                                      };

                                      if (!visible) {
                                        setVisible(true);
                                      }
                                    }}
                                  >
                                    <ShiftContainer
                                      data={shift}
                                      infoAtBottom={
                                        shift.shiftEndHourIndex !== -1 &&
                                        shift.shiftNextDayIndex === dayIndex &&
                                        new Date(
                                          shift.startDateTime
                                        ).getHours() !== 23
                                      }
                                      isSelected={
                                        shift.id === selectedShift.current?.id
                                      }
                                      timeSlotHeight={timeSlotHeight}
                                    />
                                  </Box>
                                );
                              }
                              return null;
                            }
                          );

                          validShifts = validShifts.filter(
                            (shift: any) => shift !== null
                          );
                          return (
                            <TimeSlotContainer
                              openCreateNewShiftDialog={
                                openCreateNewShiftDialog
                              }
                              canCreateOrEditShift={
                                !userRoles.includes("ReadOnly") &&
                                canCreateOrEditShift
                              }
                              dayIndex={dayIndex}
                              todayPosition={todayPosition}
                              hourIndex={hourIndex}
                              day={day}
                              timeSlotHeight={timeSlotHeight}
                              allPassingShifts={allPassingShifts}
                              openLayerPopover={openLayerPopover}
                              selectedHourDateInfo={selectedHourDateInfo}
                              key={hourIndex}
                            >
                              {validShifts ? validShifts : null}
                            </TimeSlotContainer>
                          );
                        })}
                      </MemoizedDayColumn>
                    );
                  })}
            </Grid2>
          </Grid2>
        </Box>
      </Box>
      {anchorEl.current && selectedShift.current && (
        <Popover
          open={visible}
          anchorEl={anchorEl.current}
          onClose={() => {
            selectedShift.current = null;
            setVisible(false);
          }}
          TransitionComponent={Fade}
        >
          <TooltipContent
            shiftData={selectedShift.current}
            setShiftIdForShiftDetailsDialog={setShiftIdForShiftDetailsDialog}
            openPublishDraftShiftDialog={openPublishDraftShiftDialog}
            openShiftCancelDialog={openShiftCancelDialog}
            openShiftDetailsDialog={openShiftDetailsDialog}
            openDeleteDraftShiftDialog={openDeleteDraftShiftDialog}
            openShiftAttendanceDialog={openShiftAttendanceDialog}
            toggleVisibility={toggleVisibility}
            visible={visible}
          />
        </Popover>
      )}
      {layerEl && (
        <Popover
          open={isLayerPopoverOpen}
          anchorEl={layerEl}
          onClose={closeLayerPopover}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "240px",
              maxHeight: "560px",
              overflow: "scroll",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                position: "sticky",
                top: 0,
                left: 0,
                width: "100%",
                backgroundColor: "#fff",
                zIndex: 999,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  backgroundColor: "#2F4D8029",
                  padding: "12px 20px",
                  gap: "4px"
                }}
              >
                  <Box sx={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                  }}>
                      <Schedule sx={{
                          fontSize: "18px",
                          fontWeight: 500,
                      }} />
                      <Typography
                          sx={{
                              fontSize: "14px",
                              lineHeight: "20px",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              color: "#000",
                              marginLeft: "8px"
                          }}
                      >
                          {`${selectedHourDateInfo?.hourText}, ${selectedHourDateInfo?.dayText} ${selectedHourDateInfo?.dateText}`}
                      </Typography>
                  </Box>
                  <Box sx={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                  }}>
                      <Layers sx={{
                          fontSize: "18px",
                          fontWeight: 500,
                      }} />
                      <Typography
                          sx={{
                              fontSize: "14px",
                              lineHeight: "20px",
                              fontFamily: "Roboto",
                              fontWeight: 500,
                              color: "#000",
                              marginLeft: "8px"
                          }}
                      >
                          {`${getUpdatedStackedShifts(hourPassingShifts, processedShifts)?.length ??
                          selectedHourDateInfo.stackedShiftsText} Stacked Shifts`}
                      </Typography>
                  </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
            >
              {getUpdatedStackedShifts(hourPassingShifts, processedShifts).map((shift: any, index: number) => (
                <Box
                  key={`hour-passing-shift-${index}`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    width: "100%",
                    paddingTop: index === 0 ? "12px" : "6px",
                    paddingBottom:
                      index === getUpdatedStackedShifts(hourPassingShifts, processedShifts).length - 1 ? "12px" : "6px",
                  }}
                  onClick={(e) => {
                    openShiftPopover(e.currentTarget, shift);
                  }}
                >
                  <ShiftContainer
                    data={shift}
                    isLayer={true}
                    isSelected={selectedShift?.current?.id === shift?.id}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Popover>
      )}
    </>
  );
}
