import "./index.css";
import "@fontsource/roboto/400.css";

import CssBaseline from "@mui/material/CssBaseline";
import { LicenseInfo } from "@mui/x-license-pro";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import i18n from "./i18n";
import store from "./js/redux/store";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./ts/components/ErrorBoundary";

LicenseInfo.setLicenseKey(
	"6c4dedd06e9d7d92cee5094d21679177Tz0xMDY1ODAsRT0xNzY5Mjk5MTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI="
);

Sentry.init({
	dsn: "https://dede659462b84010859940b9f123b411@o932761.ingest.sentry.io/5881670",
	integrations: [new Integrations.BrowserTracing()],
	environment: `${process.env.REACT_APP_ENV ?? "err"}`,

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

const root = createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<CssBaseline />
		<Provider store={store}>
			<ErrorBoundary>
				<App />
			</ErrorBoundary>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
