/* eslint-disable sort-imports */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { ReactNode } from "react";

const theme = createTheme({
	palette: {
		primary: {
			main: "#2F4D8B",
		},
		error: {
			main: "#E44B48",
		},
	},
});

type Props = { children: ReactNode };

const CustomThemeProvider = ({ children }: Props) => {
	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
