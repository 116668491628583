import { Box } from "@mui/material";
import React from "react";

import { useMonthActivityController } from "../controllers/use-month-activity-controller";
import MonthlyActivityTable from "./ui/monthly-activity-table";
import TableHeader from "./ui/table-header";

const MonthlyActivityReport = () => {
	const [
		{
			date,
			filteredData,
			exportData,
			search,
			columnSelector,
			open,
			allFilters,
			selectedKPI,
			KPICount,
		},
		{
			setDate,
			setSearch,
			setColumnSelector,
			setFilterOpen,
			setAllFilters,
			setSelectedKPI,
		},
	] = useMonthActivityController();

	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<TableHeader
				date={date}
				setDate={setDate}
				search={search}
				setSearch={setSearch}
				columnSelector={columnSelector}
				setColumnSelector={setColumnSelector}
				exportData={exportData}
				open={open}
				setFilterOpen={setFilterOpen}
			/>

			<Box sx={{ mt: 2 }}>
				<MonthlyActivityTable
					data={filteredData}
					search={search}
					columnSelector={columnSelector}
					isFilterOpen={open}
					allFilters={allFilters}
					setAllFilters={setAllFilters}
					selectedKPI={selectedKPI}
					setSelectedKPI={setSelectedKPI}
					KPICount={KPICount}
				/>
			</Box>
		</Box>
	);
};

export default MonthlyActivityReport;
