import { Box, CircularProgress, Grid, Switch, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { AdapterDayjs } from "@mui/x-date-pickers-6/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers-6/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-6/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers-6/TimePicker";
import format from "date-fns/format";
import dayjs from "dayjs";
import React, { useState } from "react";

import { getTimeForTimePicker } from "../../../../../../utils/helpers";
import useGatAdminConfigData from "../../../../controllers/use-gat-admin-config-data";

const NOTIS: { [key: number]: { label: string; name: string } } = {
	1: {
		label: "Notify Workers of any broadcast message announcements.",
		name: "workerLocationComapanyAnnouncements",
	},
	2: {
		label:
			"Notify Workers of any updates to Shifts - new Shifts, interested, or confirmed Shifts.",
		name: "workerLocationShiftUpdates",
	},
	3: {
		label: "Notify Workers of any updates to their Shift Attendance.",
		name: "workerLocationAttendanceUpdates",
	},
	4: {
		label:
			"Notify Workers of any updates to their Profile - Jobs, Skills, Certifications, or HR data.",
		name: "workerLocationprofileUpdates",
	},
};

const LocationCommunicationSettings = ({
	newCommunicationSettings,
	setNewCommunicationSettings,
	loading,
	communicationSettings
}: {
	communicationSettings: any;
	newCommunicationSettings: any;
	setNewCommunicationSettings: any;
	loading: boolean;
}) => {
	const { workerNotifyCats } = useGatAdminConfigData();
	const [startTimeError, setStartTimeError] = useState("");

  const validateMinutes = (newTime: any) => {
    const minutes: any = format(new Date(newTime.format()), "mm");
    if (Number(minutes) !== 0 && Number(minutes) !== 30) {
      return false;
    }
    return true;
  };
	

	

	return (
		<Grid item xs={12}>
			<Box
				sx={{
					backgroundColor: "#F5F5F5",
					borderRadius: "8px",
					padding: "16px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Allow email notifications for Supervisors in this Location
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{loading ? (
							<Skeleton width={50} height={40} />
						) : (
							<Switch
								checked={
									newCommunicationSettings.allowSupervisorNotificationsLocation ??
									false
								}
								onChange={(e) =>
									setNewCommunicationSettings((prevSettings: any) => ({
										...prevSettings,
										allowSupervisorNotificationsLocation: e.target.checked,
									}))
								}
							/>
						)}
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					backgroundColor: "#F5F5F5",
					borderRadius: "8px",
					padding: "16px",
					marginTop: "28px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Allow SMS / in-app notifications for Workers in this Location
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{loading ? (
							<Skeleton width={50} height={40} />
						) : (
							<Switch
								checked={
									newCommunicationSettings.allowWorkerNotificationsLocation ??
									false
								}
								onChange={(e) =>
									setNewCommunicationSettings((prevSettings: any) => ({
										...prevSettings,
										allowWorkerNotificationsLocation: e.target.checked,
									}))
								}
							/>
						)}
					</Box>
				</Box>
				<>
					{newCommunicationSettings.allowWorkerNotificationsLocation &&
						workerNotifyCats.map((cat: any) => (
							<Box
								key={cat.id}
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "space-between",
									marginTop: "16px",
									marginLeft: "16px",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									<Typography
										sx={{
											fontFamily: "Roboto",
											fontWeight: 400,
											fontSize: "16px",
											color: "rgba(0, 0, 0, 0.87)",
										}}
									>
										{cat.categoryName}
									</Typography>
									<Typography
										sx={{
											fontFamily: "Roboto",
											fontWeight: 400,
											fontSize: "12px",
											color: "rgba(0, 0, 0, 0.6)",
										}}
									>
										{NOTIS[cat.id].label}
									</Typography>
								</Box>
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									{loading ? (
										<Skeleton width={50} height={40} />
									) : (
										<Switch
											checked={
												newCommunicationSettings[NOTIS[cat.id].name] ?? false
											}
											onChange={(e) =>
												setNewCommunicationSettings((prevSettings: any) => ({
													...prevSettings,
													[NOTIS[cat.id].name]: e.target.checked,
												}))
											}
										/>
									)}
								</Box>
							</Box>
						))}
				</>
				{newCommunicationSettings.allowWorkerNotificationsLocation && (
					<Box sx={{ marginTop: "16px", marginLeft: "16px" }}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "16px",
										color: "rgba(0, 0, 0, 0.87)",
									}}
								>
									Daily SMS
								</Typography>
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "12px",
										color: "rgba(0, 0, 0, 0.6)",
									}}
								>
									Notify Workers of any confirmed Shifts for the next day.
								</Typography>
							</Box>
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								{loading ? (
									<Skeleton width={50} height={40} />
								) : (
									<Switch
										checked={newCommunicationSettings.dailySmsToWorker ?? false}
										onChange={(e) =>
											setNewCommunicationSettings((prevSettings: any) => ({
												...prevSettings,
												dailySmsToWorker: e.target.checked,
											}))
										}
									/>
								)}
							</Box>
						</Box>
						{newCommunicationSettings.dailySmsToWorker && loading && (
							<Skeleton
								width={160}
								height={80}
								sx={{
									marginTop: "16px",
									marginLeft: "16px",
								}}
							/>
						)}
						{newCommunicationSettings.dailySmsToWorker && !loading && (
							<Box
								sx={{
									maxWidth: "400px",
									"& .MuiTextField-root": {
										width: "100px",
									},
									marginTop: "16px",
									marginLeft: "16px",
								}}
							>
								<Typography
									sx={{
										fontFamily: "Roboto",
										fontWeight: 400,
										fontSize: "12px",
										color: "rgba(0, 0, 0, 0.87)",
									}}
								>
									Time when Worker receives the daily SMS
								</Typography>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={["TimePicker"]}>
										<TimePicker
											className="location-custom-timepicker"
											label=''
											minutesStep={30}
											timeSteps={{
												hours: 1,
												minutes: 30,
												seconds: 60,
											}}
											slotProps={{
												textField: {
													variant: "filled",
													error: startTimeError !== "",
													helperText: startTimeError,
													InputLabelProps: {
													shrink: true,
													},
													sx: {
														minWidth: "168px !important",
														backgroundColor: "white",
													},
													InputProps: {
														readOnly: true,
													}
												},
											}}
											onError={(error) => {
												if (error === "invalidDate") {
													setStartTimeError("Invalid time");
												}
											}}
											onChange={(newValue) => {
												try {
													const validatedTime = validateMinutes(newValue);
													if(!validatedTime){
														setStartTimeError("30 minutes gap allowed");
														setNewCommunicationSettings(communicationSettings);
														return; 
													}
													else{
														setStartTimeError("");
													if (JSON.stringify(newValue).length !== 4) {
														setNewCommunicationSettings({
															...newCommunicationSettings,
															dailySmsToWorkerTime:
																getTimeForTimePicker(newValue),
														});
													} else if (newValue === null) {
														setNewCommunicationSettings({
															...newCommunicationSettings,
															dailySmsToWorkerTime: "",
														});
													}
												}
												} catch (e) {
													console.log("Having error", e);
												}
											}}
											value={
												newCommunicationSettings.dailySmsToWorkerTime
													? dayjs(
															`${format(new Date(), "yyyy-MM-dd")}T${
																newCommunicationSettings.dailySmsToWorkerTime
															}`
															// eslint-disable-next-line no-mixed-spaces-and-tabs
													  )
													: dayjs(
															`${format(
																new Date(),
																"yyyy-MM-dd"
															)}T${"08:00:00"}`
															// eslint-disable-next-line no-mixed-spaces-and-tabs
													  )
											}
										/>
									</DemoContainer>
								</LocalizationProvider>
								{!newCommunicationSettings.dailySmsToWorkerTime && (
									<Typography
										sx={{
											fontFamily: "Roboto",
											fontWeight: 400,
											fontSize: "12px",
											color: "rgba(0, 0, 0, 0.6)",
											marginTop: "4px",
											marginLeft: "16px",
										}}
									>
										Hours:Minutes AM/PM
									</Typography>
								)}
							</Box>
						)}
					</Box>
				)}
			</Box>
		</Grid>
	);
};

export default LocationCommunicationSettings;
