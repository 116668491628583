import {
	Box,
	Button,
	CircularProgress,
	MenuItem,
	Select,
	SelectChangeEvent,
	Skeleton,
	Switch,
	Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import LoadingButton from "../../../../../../design/wilya-loading-button";

const ActionButtons = ({
	oldData,
	newData,
	resetChanges,
	handleSave,
	loading,
	getLocationSettingsStatus,
	setLoading,
}: {
	oldData: any;
	newData: any;
	resetChanges: () => void;
	handleSave: () => Promise<void>;
	loading: boolean;
	getLocationSettingsStatus: string;
	setLoading: (loading: boolean) => void;
}) => {
	const oldGapData = {
		minShiftsGapEnabled: oldData["minShiftsGapEnabled"],
		minShiftsGapHours: oldData["minShiftsGapHours"],
	};

	const newGapData = {
		minShiftsGapEnabled: newData["minShiftsGapEnabled"],
		minShiftsGapHours: newData["minShiftsGapHours"],
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "flex-end",
				paddingTop: "16px",
				paddingBottom: "12px",
			}}
		>
			<Button
				variant='outlined'
				disabled={
					JSON.stringify(oldGapData) === JSON.stringify(newGapData) ||
					loading ||
					getLocationSettingsStatus === "pending"
				}
				onClick={() => {
					resetChanges();
				}}
			>
				Discard all new changes
			</Button>
			<LoadingButton
				variant='contained'
				disabled={
					JSON.stringify(oldGapData) === JSON.stringify(newGapData) ||
					loading ||
					getLocationSettingsStatus === "pending"
				}
				sx={{ marginLeft: "16px" }}
				loading={loading}
				onClick={() => {
					setLoading(true);
					handleSave();
				}}
				loadingIndicator={
					<CircularProgress size={16} sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
				}
			>
				Save
			</LoadingButton>
		</Box>
	);
};

export default ActionButtons;
