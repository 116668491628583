// TODO - Type 'any' needs to be fixed.
import { Box, Button, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
	createNewCompany,
	resetCreateNewCompany,
	updateCreateNewCompany,
} from "../../../../../../../export/gat-admin";
import useGatAdminConfigData from "../../../../../../../ts/components/gat-admin/controllers/use-gat-admin-config-data";
import LoadingButton from "../../../../../../design/wilya-loading-button";

const CoreActions = ({ newCompany }: any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { createNewCompanyStatus, currentCompanyData } =
		useGatAdminConfigData();

	useEffect(() => {
		if (createNewCompanyStatus === "fulfilled") {
			dispatch(updateCreateNewCompany(false));
			dispatch(resetCreateNewCompany());
			navigate("/admin-settings", {
				state: { data: currentCompanyData, creatingNewCompany: false },
			});
		}
	}, [createNewCompanyStatus, currentCompanyData, dispatch, navigate]);

	return (
		<Box display='flex' justifyContent='flex-end' mt={4} mb={1.75}>
			<Box mr={1}>
				<Button variant='outlined' onClick={() => navigate(-1)}>
					Cancel
				</Button>
			</Box>
			<Box ml={1}>
				<LoadingButton
					variant='contained'
					onClick={() => dispatch(createNewCompany(newCompany))}
					disabled={!newCompany.name}
					loading={createNewCompanyStatus === "pending"}
					loadingIndicator={
						<CircularProgress size={16} sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
					}
				>
					Create & Continue
				</LoadingButton>
			</Box>
		</Box>
	);
};

export default CoreActions;
