/* eslint-disable no-mixed-spaces-and-tabs */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import LockOutlined from "@mui/icons-material/LockOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import {
	Box,
	Button,
	Container,
	Grid,
	InputAdornment,
	Link,
	Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
	authenticateSSO,
	getCustomLogo,
	loginUser,
	resetLoginErrors,
	setAuthenticated,
	verifySSO,
} from "../../../../export/user";
import LoadingButton from "../../../design/wilya-loading-button";
import Footer from "./../../../components/Footer";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo: string = require("../../../assets/logo_wilya_dark.svg").default;

const DEFAULT_LOGIN_METHOD = "VERIFY_SSO";

const VERIFY_SSO = "VERIFY_SSO";
const STANDARD_LOGIN = "STANDARD_LOGIN";
const SSO_LOGIN = "SSO_LOGIN";

const DO_NOT_SHOW_LOCKOUT_WARNING_THRESHOLD = 1;
const FORGOT_PASSWORD_BUTTON_TEXT = "Forgot Password";

const useStyles = makeStyles(() => ({
	paper: {
		marginTop: "64px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: "8px",
	},
	submit: {
		marginTop: "16px",
	},
	logo: {
		marginBottom: "16px",
	},
	btnLabel: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
	progressIndicator: {
		position: "absolute",
		left: "50%",
	},
	errorMessage: {
		color: "#ff1744",
	},
}));

export default function Login() {
	const classes = useStyles();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [loginProcess, setLoginProcess] =
		useState<string>(DEFAULT_LOGIN_METHOD);
	const [username, setUsername] = useState<string>(
		localStorage.getItem("login_username") ?? ""
	);
	const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

	/* START - Redux States */

	const loginResponse = useSelector((state: any) => state.user.login);
	const status = loginResponse.status;
	const errorMessage = loginResponse.errorMessage;
	const maxFailedAttempts = loginResponse.maxFailedAttempts;
	const failedAttempt = loginResponse.failedAttempt;
	const isLocked = loginResponse.isLocked;
	const accountLockoutEnabled = maxFailedAttempts > 0;

	const customizedBrandingEnabled = useSelector(
		(state: any) => state.user.userData.customizedBrandingEnabled
	);
	const getCustomLogoStatus = useSelector(
		(state: any) => state.user.getCustomLogo.status
	);
	const customLogo = useSelector((state: any) => state.user.customLogo);

	const verifySSOStatus = useSelector(
		(state: any) => state.user.verifySSO.status
	);
	const verifySSOErrorMessage = useSelector(
		(state: any) => state.user.verifySSO.errorMessage
	);

	const isSSOEnabled = useSelector(
		(state: any) => state.user.verifySSO.isSSOEnabled
	);
	const ssoLoginUrl = useSelector(
		(state: any) => state.user.verifySSO.ssoLoginUrl
	);

	const authenticateSSOStatus = useSelector(
		(state: any) => state.user.authenticateSSO.status
	);
	const authenticateSSOErrorMessage = useSelector(
		(state: any) => state.user.authenticateSSO.errorMessage
	);
	/* END - Redux States */

	useEffect(() => {
		setLoginProcess(DEFAULT_LOGIN_METHOD);
		setUsername(getStoredUsername());
		setPasswordVisible(false);
		dispatch(resetLoginErrors());
	}, [dispatch]);

	useEffect(() => {
		if (customizedBrandingEnabled) {
			dispatch(getCustomLogo());
		}
	}, [customizedBrandingEnabled, dispatch]);

	useEffect(() => {
		if (verifySSOStatus === "fulfilled") {
			if (isSSOEnabled) {
				setLoginProcess(SSO_LOGIN);
			} else {
				// Change login layout to standard login
				setLoginProcess(STANDARD_LOGIN);
			}
		} else if (verifySSOStatus === "error") {
			clearUsername();
		}
	}, [verifySSOStatus, isSSOEnabled, ssoLoginUrl, username]);

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const code = params.get("code"); // Required
		if (code) {
			if (authenticateSSOStatus === "idle") {
				const ssoData: any = {};
				for (const param of params) {
					// Adding all params
					ssoData[param[0]] = param[1];
				}
				clearUsername();
				setUsername("");
				setLoginProcess(VERIFY_SSO);
				dispatch(authenticateSSO(ssoData));
			}
		}
	}, [location.search, dispatch, authenticateSSOStatus]);

	useEffect(() => {
		if (authenticateSSOStatus === "fulfilled") {
			if (customizedBrandingEnabled) {
				dispatch(getCustomLogo());
			}
		} else if (authenticateSSOStatus === "error") {
			navigate(window.location.pathname);
			clearUsername();
			setLoginProcess(VERIFY_SSO);
		}
	}, [authenticateSSOStatus, dispatch, navigate, customizedBrandingEnabled]);

	useEffect(() => {
		try {
			if (
				getCustomLogoStatus === "fulfilled" ||
				getCustomLogoStatus === "error"
			) {
				dispatch(setAuthenticated(true));
			}
		} catch (e) {
			// Do nothing
		}
	}, [customLogo, dispatch, getCustomLogoStatus, navigate]);

	const handleClick = (e: any) => {
		// Generalising the click handler
		if (e.target.innerText === `${FORGOT_PASSWORD_BUTTON_TEXT}?`)
			navigate("/forgot-password");
	};

	const clearUsername = () => {
		localStorage.removeItem("login_username");
	};

	const storeUsername = (username: string) => {
		localStorage.setItem("login_username", username);
	};

	const getStoredUsername = () => {
		const loginUsername = localStorage.getItem("login_username");
		return loginUsername ?? "";
	};

	const resetErrors = () => {
		dispatch(resetLoginErrors());
	};

	return (
		<>
			<Container maxWidth='xs'>
				<Box
					sx={{
						marginTop: "64px",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					{/* <Typography component="h1" variant="h4" className={classes.logo}>
          Gig And Take Logo
        </Typography> */}
					<Box mb={5}>
						<img src={logo} alt='' height={90} />
					</Box>
					{/* <Typography component="h2" variant="h5">
          Sign In
        </Typography> */}
					<Formik
						key={`login-${loginProcess}`}
						initialValues={{ username: username, password: "" }}
						validationSchema={Yup.object(
							loginProcess === STANDARD_LOGIN
								? {
										password: Yup.string().required("Password is required"),
										username: Yup.string()
											.email("Invalid email address")
											.required("Email is required"),
								  }
								: {
										username: Yup.string()
											.email("Invalid email address")
											.required("Email is required"),
								  }
						)}
						onSubmit={(values, { setSubmitting }) => {
							resetErrors();
							if (loginProcess === VERIFY_SSO) {
								// Storing username for future use
								storeUsername(values.username);
								setUsername(values.username);
								// Calling API to verify if user for SSO
								dispatch(verifySSO(values));
								setSubmitting(false);
							} else if (loginProcess === STANDARD_LOGIN) {
								dispatch(loginUser(values));
								setSubmitting(false);
							} else if (loginProcess === SSO_LOGIN) {
								setSubmitting(false);
								// Navigate to SSO login URL
								window.location.replace(ssoLoginUrl);
							}
						}}
					>
						{({ isValid, values, setFieldValue }) => (
							<Form className={classes.form}>
								{/* value will be use as placeholder during SSO authentication process*/}
								{/* If server throw any error user can change this email or try again */}
								<Field
									id='username'
									component={TextField}
									placeholder='Email'
									name='username'
									type='email'
									label={loginProcess === SSO_LOGIN ? "" : "Email"}
									fullWidth
									variant='outlined'
									margin='normal'
									value={
										loginProcess === SSO_LOGIN
											? username ?? ""
											: values.username
									}
									onChange={(event: any) => {
										setFieldValue("username", event.target.value);
										resetErrors();
										// Check SSO again if username changed
										// In case SSO enabled after first attempt
										setLoginProcess(DEFAULT_LOGIN_METHOD);
									}}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										readOnly: loginProcess === SSO_LOGIN,
										style: {
											WebkitBoxShadow: "0 0 0 1000px #FFF inset !important",
										},
										endAdornment:
											values.username.length > 0 ? (
												<InputAdornment position='end'>
													{loginProcess === SSO_LOGIN ? (
														<CheckCircleIcon sx={{ color: "#2E7D32" }} />
													) : (
														<ClearOutlinedIcon
															sx={{
																color: "rgba(0, 0, 0, 0.56)",
																cursor: "pointer",
															}}
															onClick={() => {
																clearUsername();
																setFieldValue("username", "");
																setUsername("");
																resetErrors();
																setLoginProcess(DEFAULT_LOGIN_METHOD);
															}}
														/>
													)}
												</InputAdornment>
											) : null,
									}}
								/>
								{loginProcess === STANDARD_LOGIN && !isLocked && (
									<Box mt={2}>
										<Field
											id='password'
											component={TextField}
											type={passwordVisible ? "text" : "password"}
											autoComplete='on'
											placeholder='Password'
											label='Password'
											name='password'
											fullWidth
											variant='outlined'
											margin='normal'
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												style: {
													WebkitBoxShadow: "0 0 0 1000px #FFF inset !important",
												},
												endAdornment: values.password.length > 0 && (
													<InputAdornment
														position='end'
														sx={{ cursor: "pointer" }}
														onClick={() =>
															setPasswordVisible((prevState) => !prevState)
														}
													>
														{passwordVisible ? (
															<VisibilityOutlinedIcon
																sx={{ color: "rgba(0, 0, 0, 0.56)" }}
															/>
														) : (
															<VisibilityOffOutlinedIcon
																sx={{ color: "rgba(0, 0, 0, 0.56)" }}
															/>
														)}
													</InputAdornment>
												),
											}}
										/>
									</Box>
								)}
								{/* START - Error Box */}
								{/* When account is locked */}
								{isLocked && (
									<Box
										sx={{
											display: "flex",
											padding: "12px",
											flexDirection: "row",
											backgroundColor: "#FBEAEA",
											borderRadius: "4px",
										}}
									>
										<LockOutlined sx={{ color: "#E44B48" }} />
										<Box sx={{ marginLeft: "12px", marginTop: "2px" }}>
											<Typography
												sx={{
													fontSize: "16px",
													fontFamily: "Roboto",
													fontWeight: 500,
													color: "#541313",
												}}
											>
												Account locked
											</Typography>
											<Typography
												sx={{
													fontFamily: "Roboto",
													fontWeight: 400,
													fontSize: "14px",
													color: "#541313",
												}}
											>
												Please reach out to{" "}
												<Link href='mailto:help@wilya.com' underline='hover'>
													help@wilya.com
												</Link>{" "}
												to unlock your account.
											</Typography>
										</Box>
									</Box>
								)}
								{/* When account is active but got error */}
								{!isLocked &&
									(status === "error" ||
										verifySSOStatus === "error" ||
										authenticateSSOStatus === "error") &&
									(errorMessage !== "" ||
										verifySSOErrorMessage !== "" ||
										authenticateSSOErrorMessage !== "") && (
										<Box mt={2.5} key={`error-${loginProcess}`}>
											{/* Normal password or email login failed */}
											{status === "error" &&
												(!accountLockoutEnabled ||
													(accountLockoutEnabled &&
														failedAttempt ===
															DO_NOT_SHOW_LOCKOUT_WARNING_THRESHOLD)) && (
													<Box
														sx={{
															display: "flex",
															padding: "12px",
															flexDirection: "row",
															backgroundColor: "#FBEAEA",
															borderRadius: "4px",
														}}
													>
														<ErrorOutlineOutlinedIcon
															sx={{ color: "#E44B48" }}
														/>
														<Box sx={{ marginLeft: "12px", marginTop: "2px" }}>
															<Typography
																sx={{
																	fontSize: "16px",
																	fontFamily: "Roboto",
																	fontWeight: 500,
																	color: "#541313",
																}}
															>
																Login failed
															</Typography>
															<Typography
																sx={{
																	fontFamily: "Roboto",
																	fontWeight: 400,
																	fontSize: "14px",
																	color: "#541313",
																}}
															>
																{errorMessage}
															</Typography>
														</Box>
													</Box>
												)}
											{status === "error" &&
												accountLockoutEnabled &&
												failedAttempt >
													DO_NOT_SHOW_LOCKOUT_WARNING_THRESHOLD && (
													<Box
														sx={{
															display: "flex",
															padding: "12px",
															flexDirection: "row",
															backgroundColor: "#FDF0E6",
															borderRadius: "4px",
														}}
													>
														<WarningAmberOutlined sx={{ color: "#ED6C02" }} />
														<Box sx={{ marginLeft: "12px", marginTop: "2px" }}>
															<Typography
																sx={{
																	fontSize: "16px",
																	fontFamily: "Roboto",
																	fontWeight: 500,
																	color: "#5F2B01",
																}}
															>
																Login failed
															</Typography>
															<Typography
																sx={{
																	fontSize: "16px",
																	fontFamily: "Roboto",
																	fontWeight: 500,
																	color: "#5F2B01",
																}}
															>
																Account could be locked
															</Typography>
															<Typography
																sx={{
																	fontFamily: "Roboto",
																	fontWeight: 400,
																	fontSize: "14px",
																	color: "#541313",
																}}
															>
																{`${maxFailedAttempts - failedAttempt} attempt${
																	maxFailedAttempts - failedAttempt === 1
																		? ""
																		: "s"
																} remaining.`}
															</Typography>
															<Typography
																sx={{
																	fontFamily: "Roboto",
																	fontWeight: 400,
																	fontSize: "14px",
																	color: "#541313",
																}}
															>
																<li>{errorMessage}</li>
																<li>{`Reset your password using the "${FORGOT_PASSWORD_BUTTON_TEXT}" link below`}</li>
															</Typography>
														</Box>
													</Box>
												)}
											{verifySSOStatus === "error" && (
												<Box
													sx={{
														display: "flex",
														padding: "12px",
														flexDirection: "row",
														backgroundColor: "#FBEAEA",
														borderRadius: "4px",
													}}
												>
													<ErrorOutlineOutlinedIcon sx={{ color: "#E44B48" }} />
													<Box sx={{ marginLeft: "12px", marginTop: "2px" }}>
														<Typography
															sx={{
																fontSize: "14px",
																fontFamily: "Roboto",
																fontWeight: 500,
																color: "#541313",
															}}
														>
															Login failed
														</Typography>
														<Typography
															sx={{
																fontFamily: "Roboto",
																fontWeight: 400,
																fontSize: "14px",
																color: "#541313",
															}}
														>
															{verifySSOErrorMessage}
														</Typography>
													</Box>
												</Box>
											)}
											{authenticateSSOStatus === "error" && (
												<Box
													sx={{
														display: "flex",
														padding: "12px",
														flexDirection: "row",
														backgroundColor: "#FBEAEA",
														borderRadius: "4px",
													}}
												>
													<ErrorOutlineOutlinedIcon sx={{ color: "#E44B48" }} />
													<Box sx={{ marginLeft: "12px", marginTop: "2px" }}>
														<Typography
															sx={{
																fontSize: "14px",
																fontFamily: "Roboto",
																fontWeight: 500,
																color: "#541313",
															}}
														>
															Login with SSO failed
														</Typography>
														<Typography
															sx={{
																fontFamily: "Roboto",
																fontWeight: 400,
																fontSize: "14px",
																color: "#541313",
															}}
														>
															{authenticateSSOErrorMessage}
														</Typography>
													</Box>
												</Box>
											)}
										</Box>
									)}
								{/* END - Error Box */}
								<Grid container pt={5} key={`buttons-${loginProcess}`}>
									{loginProcess === SSO_LOGIN && (
										<Grid item xs={6} pr={1.5}>
											<Button
												fullWidth
												variant='outlined'
												color='primary'
												size='large'
												onClick={() => {
													clearUsername();
													resetErrors();
													setUsername("");
													setFieldValue("username", "");
													setLoginProcess(VERIFY_SSO);
												}}
											>
												<span
													style={{
														fontFamily: "Roboto",
														fontWeight: 500,
														fontSize: "15px",
														lineHeight: "26px",
														letterSpacing: "0.46px",
														textTransform: "uppercase",
														color: "#2F4D8B",
													}}
												>
													RESET
												</span>
											</Button>
										</Grid>
									)}
									<Grid
										item
										xs={loginProcess === SSO_LOGIN ? 6 : 12}
										pl={loginProcess === SSO_LOGIN ? 1.5 : 0}
									>
										<LoadingButton
											type='submit'
											fullWidth
											variant='contained'
											color='primary'
											size='large'
											disabled={
												isLocked ||
												!isValid ||
												(loginProcess === VERIFY_SSO
													? values.username === ""
													: false) ||
												(loginProcess === STANDARD_LOGIN
													? values.password === ""
													: false) ||
												status === "pending" ||
												verifySSOStatus === "pending" ||
												authenticateSSOStatus === "pending"
											}
											loading={
												verifySSOStatus === "pending" ||
												authenticateSSOStatus === "pending" ||
												status === "pending"
											}
											loadingPosition='start'
											loadingIndicator={
												<CircularProgress
													size={16}
													sx={{ color: "rgba(0, 0, 0, 0.38)" }}
												/>
											}
											startIcon={<></>}
										>
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												<span
													style={{
														fontFamily: "Roboto",
														fontWeight: 500,
														fontSize: "15px",
														lineHeight: "26px",
														letterSpacing: "0.46px",
														textTransform: "uppercase",
													}}
												>
													{verifySSOStatus === "pending" ||
													authenticateSSOStatus === "pending" ||
													status === "pending"
														? "LOADING"
														: `LOGIN${
																loginProcess === SSO_LOGIN ? " WITH SSO" : ""
														  }`}
												</span>
											</div>
										</LoadingButton>
									</Grid>
								</Grid>
								{(loginProcess === STANDARD_LOGIN || isLocked) && (
									<Grid container mt={5} justifyContent='center'>
										<Grid item>
											<Link
												variant='body2'
												component='button'
												onClick={handleClick}
												sx={{
													fontFamily: "Roboto",
													fontSize: "16px",
													fontWeight: 400,
													color: "#2F4D8B",
												}}
											>
												{`${FORGOT_PASSWORD_BUTTON_TEXT}?`}
											</Link>
										</Grid>
									</Grid>
								)}
							</Form>
						)}
					</Formik>
				</Box>
			</Container>
			<Footer />
		</>
	);
}
