import { Layers } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import LightModeIcon from "@mui/icons-material/LightMode";
import { Box, Grid2 } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { differenceInMilliseconds } from "date-fns";
import format from "date-fns/format";
import isPast from "date-fns/isPast";
import { DateTime } from "luxon";
import React, {useEffect, useMemo, useRef, useState} from "react";

import { gutterWidth, minTimeSlotHeight, shiftSpacing } from "./constants";
import { TimeSlot } from "./styled-components";
import { calculateIndicatorPosition } from "./utils";

const TimeSlotContainer = ({
  openCreateNewShiftDialog,
  canCreateOrEditShift,
  dayIndex,
  todayPosition,
  hourIndex,
  day,
  timeSlotHeight,
  allPassingShifts,
  openLayerPopover,
  selectedHourDateInfo,
  ...props
}: {
  openCreateNewShiftDialog: any;
  canCreateOrEditShift: boolean;
  dayIndex: number;
  todayPosition: number;
  hourIndex: number;
  day: Date;
  timeSlotHeight: number;
  allPassingShifts: any;
  openLayerPopover: any;
  selectedHourDateInfo: any;
} & React.ComponentProps<typeof Grid2>) => {
  const [currentTimeHourIndex, setCurrentTimeHourIndex] = useState(-1);
  const timeoutIdRef = useRef<any>(null); // Use a ref to store the timeout ID
  const isFirstRender = useRef(true); // Track the first render

  // One hour string based on hourIndex (0-23) like "4:00 AM - 5:00 AM"
  const timeDateInfo = useMemo(() => {
    const startHour = hourIndex;
    const endHour = hourIndex + 1;
    // Change military time to standard time
    let startHourString = startHour % 12 === 0 ? "12" : `${startHour % 12}`;
    let endHourString = endHour % 12 === 0 ? "12" : `${endHour % 12}`;
    // Add AM and PM
    if (startHour < 12) {
      startHourString = startHourString + " AM";
    } else {
      startHourString = startHourString + " PM";
    }
    if (endHour < 12) {
      endHourString = endHourString + " AM";
    } else {
      endHourString = endHourString + " PM";
    }
    return {
      hourText: `${startHourString} - ${endHourString}`,
      dayText: format(day, "EEE") ?? "-",
      dateText: format(day, "dd") ?? "-",
    };
  }, [hourIndex, day]);

  const [isHovered, setIsHovered] = useState(false);

  const isInPast = useMemo(() => isPast(day), [day]);
  const isToday = useMemo(
    () => todayPosition === dayIndex,
    [todayPosition, dayIndex]
  );
  const isPastHour = useMemo(
    () => isInPast && hourIndex - 1 < new Date().getHours(),
    [isInPast, hourIndex]
  );

  useEffect(() => {

    const calculateIndex = () => {
      const newCurrentTimeHourIndex = Math.floor(
          calculateIndicatorPosition(new Date(), timeSlotHeight) / timeSlotHeight
      );
      if (currentTimeHourIndex !== newCurrentTimeHourIndex) {
        setCurrentTimeHourIndex(newCurrentTimeHourIndex);
      }
    };

    const scheduleNextUpdate = () => {
      const timeUntilTarget = differenceInMilliseconds(
          new Date(day).setHours(hourIndex, 0, 0, 0),
          new Date()
      );

      if (timeUntilTarget > 0) {
        // Clear any existing timeout before setting a new one
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
        }

        timeoutIdRef.current = setTimeout(() => {
          calculateIndex();
          timeoutIdRef.current = null; // Clear the ref after the timeout fires
        }, timeUntilTarget);
      } else {
        calculateIndex();
      }
    };
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (
      currentTimeHourIndex === -1 &&
      timeoutIdRef.current === null &&
      isToday &&
      !isPastHour
    ) {
      scheduleNextUpdate();
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        // Page is now hidden
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
          timeoutIdRef.current = null;
        }
      } else {
        // Page is now visible
        if (
            currentTimeHourIndex === -1 &&
            timeoutIdRef.current === null &&
            isToday &&
            !isPastHour
        ) {
          scheduleNextUpdate(); // Restart your timeout logic
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => { // Cleanup function
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null; // Good practice to also clear the ref
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimeHourIndex, timeSlotHeight, day, isToday, isPastHour, hourIndex]);

  const dateWithHour = useMemo(
    () => new Date(day).setHours(hourIndex),
    [day, hourIndex]
  );

  const handleClick = () => {
    if (canCreateOrEditShift) {
      openCreateNewShiftDialog(new Date(dateWithHour));
    }
  };

  const isDayLightSavingHour = useMemo(() => {
    if (!dateWithHour) {
      return { isDayLightSavingHour: false, shiftDirection: 0 };
    }

    const dt = DateTime.fromJSDate(new Date(dateWithHour));

    const currentOffset = dt.offset;
    const nextOffset = dt.plus({ hours: 1 }).offset;
    const shiftDirection = nextOffset - currentOffset;

    const isDayLightSavingHour = shiftDirection !== 0;
    if (isDayLightSavingHour) {
      console.log(new Date(dateWithHour));
    }

    return { isDayLightSavingHour, shiftDirection: -shiftDirection };
  },[dateWithHour]);

  let isPropsEmpty = true;

  // Check if props is empty or not by evaluating props contain children or not
  if (Array.isArray(props.children) ? props.children.length > 0 : false) {
    isPropsEmpty = false;
  }

  // Check if allPassingShifts is empty or not by evaluating allPassingShifts contain children or not
  isPropsEmpty = Array.isArray(allPassingShifts)
    ? allPassingShifts.length === 0
    : isPropsEmpty;
  let canShowLayerIcon = false;

  const isClicked =
    selectedHourDateInfo?.hourIndex === hourIndex &&
    selectedHourDateInfo?.dayIndex === dayIndex;
  if (isClicked) {
    // Show layer icon when clicked
    canShowLayerIcon = true;
  }

  const canShowAddIcon =
    isHovered &&
    !(isInPast && !isToday) &&
    !(isToday && (isPastHour|| currentTimeHourIndex >= hourIndex));

  // Display layer icon if shifts are eclipsing each other under 10min
  if (!canShowLayerIcon) {
    if (Array.isArray(allPassingShifts) && allPassingShifts.length >= 2) {
      const minMargin = 50;
      const minWidth = shiftSpacing * 2;
      const minDifferenceInMinutes = 9; // if shifts difference is 9 min or less then display layer icon
      allPassingShifts.forEach((shift: any, index: number) => {
        if (index + 1 < allPassingShifts.length) {
          const currentShift = shift;
          const nextShift = allPassingShifts[index + 1];
          const currentShiftStartTime = new Date(currentShift.startDateTime);
          const nextShiftStartTime = new Date(nextShift.startDateTime);
          const differenceInMinutes =
            (nextShiftStartTime.getTime() - currentShiftStartTime.getTime()) /
            60000;
          if (
            currentShift.changedMarginOfShift > minMargin &&
            currentShift.shiftWidthPercent <= minWidth &&
            nextShift.changedMarginOfShift > minMargin &&
            nextShift.shiftWidthPercent <= minWidth &&
            differenceInMinutes <= minDifferenceInMinutes
          ) {
            canShowLayerIcon = true;
          }
        }
      });
    }
  }

  if ((isInPast && !isToday) || (isToday && (isPastHour || currentTimeHourIndex >= hourIndex)) || !isPropsEmpty) {
    return (
      <TimeSlot
        {...props}
        height={timeSlotHeight}
        boxShadow={
          selectedHourDateInfo?.hourIndex === hourIndex &&
          selectedHourDateInfo.dayIndex === dayIndex
            ? `inset 0 0 0 ${timeSlotHeight / 2}px #2F4D8029`
            : "none"
        }
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor:
              (isToday && (isPastHour || currentTimeHourIndex >= hourIndex))
              || (isDayLightSavingHour.isDayLightSavingHour && isDayLightSavingHour.shiftDirection < 0)
                ? "rgba(0,0,0,0.02)"
                : "transparent",
          }}
        >
          {props.children}
          {!isPropsEmpty && isDayLightSavingHour.shiftDirection >= 0 ? (
            <Box
              sx={{
                position: "absolute",
                right: 0,
                bottom: 0,
                width: `${gutterWidth}px`,
                height: `${timeSlotHeight}px`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor:
                    !isToday && isInPast ? "rgba(0,0,0,0.02)" : "rgba(0,0,0,0.02)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                  marginTop: "1px",
                  marginBottom: "1px",
                }}
              >
                {canShowAddIcon && (
                  <IconButton
                    aria-label="add"
                    size="small"
                    sx={{
                      width: `${gutterWidth}px`,
                      height: `${(minTimeSlotHeight - 2) / 2}px`,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleClick}
                  >
                    <AddIcon
                      sx={{
                        fontSize: `${(gutterWidth * 4.5) / 5}px`,
                        color: "#2F4D80",
                      }}
                    />
                  </IconButton>
                )}
                {/* Spacer */}
                {!canShowAddIcon && (
                  <Box
                    sx={{
                      width: `${gutterWidth}px`,
                      height: `${(minTimeSlotHeight - 2) / 2}px`,
                    }}
                  />
                )}
                {(((isHovered && allPassingShifts.length >= 2) ||
                  canShowLayerIcon) || (isDayLightSavingHour.isDayLightSavingHour
                    && isDayLightSavingHour.shiftDirection > 0)) && (
                  <Tooltip title={<>
                    {isDayLightSavingHour.shiftDirection > 0 ? "Daylight Saving Hour : Fall Back" : ""}
                    {allPassingShifts.length > 1 && <br />}
                    {allPassingShifts.length > 1 ? `${allPassingShifts.length} Stacked Shifts` : ""}
                  </>}>
                    <IconButton
                      aria-label="view-shift"
                      size="small"
                      sx={{
                        width: `${gutterWidth}px`,
                        height: `${(minTimeSlotHeight - 2) / 2}px`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        if (allPassingShifts.length > 1) {
                          openLayerPopover(
                              e,
                              allPassingShifts,
                              hourIndex,
                              dayIndex,
                              timeDateInfo
                          );
                        }
                      }}
                    >
                      {isDayLightSavingHour.shiftDirection > 0 ?
                            <LightModeIcon
                                sx={{
                                    fontSize: `${(gutterWidth * 4.5) / 5}px`,
                                    color: "#2F4D80",
                                }}
                            />
                          :
                          <Layers
                              sx={{
                                fontSize: `${(gutterWidth * 7) / 9}px`,
                                color: "#2F4D80",
                              }}
                          />
                      }
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                position: "absolute",
                right: 0,
                bottom: 0,
                width: `${gutterWidth}px`,
                height: `${timeSlotHeight}px`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isDayLightSavingHour.isDayLightSavingHour ?
                  <Tooltip title={`Daylight Saving Hour : ${
                        isDayLightSavingHour.shiftDirection > 0 ? "Fall Back" : "Spring Forward"
                  }`}>
                    <LightModeIcon
                        sx={{
                          fontSize: `${(gutterWidth * 4.5) / 5}px`,
                          color: "#2F4D80",
                        }}
                    />
                  </Tooltip>
                  :
                  <Box
                      sx={{
                        backgroundColor:
                            !isToday && isInPast ? "rgba(0,0,0,0.02)" : "rgba(0,0,0,0.02)",
                        height: "100%",
                        width: "100%",
                        marginTop: "1px",
                        marginBottom: "1px",
                      }}
                  />
              }
            </Box>
          )}
        </Box>
      </TimeSlot>
    );
  } else {
    return (
      <TimeSlot
        {...props}
        height={timeSlotHeight}
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor:
              (isToday && (isPastHour || currentTimeHourIndex >= hourIndex)) ||
                (isDayLightSavingHour.isDayLightSavingHour && isDayLightSavingHour.shiftDirection < 0)
                ? "rgba(0,0,0,0.02)"
                : "#fff",
          }}
        >
          {isDayLightSavingHour.isDayLightSavingHour && isDayLightSavingHour.shiftDirection < 0 ?
              null
              : isHovered ? (
                  <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                        backgroundColor: isHovered ? "rgb(251,251,253)" : "#fff",
                        flexDirection: "row",
                      }}
                  >
                    <AddIcon
                        sx={{
                          fontSize: "20px",
                          textTransform: "uppercase",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          color: "#2F4D80",
                        }}
                    />
                    <Typography
                        sx={{
                          marginLeft: "5px",
                          fontSize: "14px",
                          textTransform: "uppercase",
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          color: "#2F4D80",
                        }}
                    >
                      Shift
                    </Typography>
                  </Box>
              ) : null
          }
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: `${gutterWidth}px`,
            height: `${timeSlotHeight}px`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: isHovered ? "transparent" : "rgb(251,251,253)",
                height: "100%",
                width: "100%",
                marginTop: "1px",
                marginBottom: "1px",
              }}
          >
            {isDayLightSavingHour.isDayLightSavingHour ?
                <Tooltip title={`Daylight Saving Hour : ${isDayLightSavingHour.shiftDirection < 0
                    ? "Spring Forward" : "Fall Back"}`}>
                  <LightModeIcon
                      sx={{
                        fontSize: `${(gutterWidth * 4.5) / 5}px`,
                        color: "#2F4D80",
                      }}
                  />
                </Tooltip>
                : null}
          </Box>
        </Box>
      </TimeSlot>
    );
  }
};

export default React.memo(TimeSlotContainer);
