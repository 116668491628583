import Box from "@mui/material/Box";
import React, { FC, ReactNode } from "react";

type TabPanelProps = {
	children?: React.ReactNode;
	index: number;
	value: number;
};

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
		</div>
	);
};
export default TabPanel;
