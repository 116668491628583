// TODO - Type 'any' needs to be fixed.
import { Box, Collapse, Grid2, LinearProgress } from "@mui/material";
// import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { JOB, SKILLS } from "../../../../assets/constants";
import AvailabilityMap from "../../../../features/Availability/AvailabilityMap";
import AvailabilityFilters from "../../../../features/scheduler/availability/availability-filters";
import {
  getHourlyWorkerCount,
  setSelectedAvailabilityValue,
} from "../../store/scheduler-slice";

// import { Job } from "../../store/types";

interface IAvailabilityProps {
  shiftBySkills: boolean;
  shiftsByFilter: any;
  changeWeekAvailabilityStatus: string;
  checked: boolean;
  height: number;
}

const Availability: React.FC<IAvailabilityProps> = ({
  shiftBySkills,
  shiftsByFilter,
  changeWeekAvailabilityStatus,
  checked,
  height,
}) => {
  const dispatch = useDispatch();
  const value = useSelector(
    (state: any) => state.supervisor?.schedulerData?.selectedAvailabilityValue
  );

  const setValue = (newValue: number | null) => {
    dispatch(setSelectedAvailabilityValue(newValue));
  };
  const allJobRoles = useSelector(
    (state: any) => state.supervisor.filterData.allJobRoles
  );
  const allSkills = useSelector(
    (state: any) => state.supervisor.filterData.allSkills
  );
  const currentDate = useSelector(
    (state: any) => state.supervisor?.schedulerData?.currentDate
  );
  // const fetchInitialDataStatus = useSelector(
  //   (state: any) => state.supervisor?.fetchInitialData.status
  // );
  const fetchFilterAndShiftDataStatus = useSelector(
    (state: any) => state.supervisor?.fetchFilterAndShiftData.status
  );
  useEffect(() => {
    try {
      if (
        dispatch &&
        shiftsByFilter &&
        currentDate &&
        value !== null &&
        fetchFilterAndShiftDataStatus === "fulfilled"
      ) {
        if (shiftsByFilter === JOB) {
          dispatch(
            getHourlyWorkerCount({
              date: currentDate,
              type: "job",
              id: value,
            })
          );
        } else if (shiftsByFilter === SKILLS) {
          dispatch(
            getHourlyWorkerCount({
              date: currentDate,
              type: "skill",
              id: value,
            })
          );
        }
      }
    } catch (e) {
      // Do nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  if (
    // fetchInitialDataStatus === "pending" ||
    fetchFilterAndShiftDataStatus === "pending"
  ) {
    return (
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: height,
        }}
      >
        <LinearProgress />
      </Box>
    );
  } else {
    return (
      <Grid2 container wrap="nowrap" spacing={0}>
          <Box>
              <Collapse orientation="horizontal" in={checked}>
                  <AvailabilityFilters
                      shiftBySkills={shiftBySkills}
                      shiftsByFilter={shiftsByFilter}
                      value={value}
                      setValue={(id) => setValue(id)}
                      height={height}
                      allJobRoles={allJobRoles}
                      fetchFilterAndShiftDataStatus={fetchFilterAndShiftDataStatus}
                      allSkills={allSkills}
                  />
              </Collapse>
          </Box>
        <Grid2
          width={"100%"}
          sx={{
            display: "flex",
            flexDirection: "row",
            height: height,
          }}
        >
          <Box
            sx={{
              borderRadius: "15px",
              width: "100%",
            }}
          >
            <AvailabilityMap
              shiftsByFilter={shiftsByFilter}
              value={value}
              changeWeekAvailabilityStatus={changeWeekAvailabilityStatus}
              height={height}
            />
          </Box>
        </Grid2>
      </Grid2>
    );
  }
};

export default Availability;
