import React from "react";
import { useSelector } from "react-redux";

import { WorkerInsightDetailsModel, WorkerInsightRootState } from "../slices/insight-report-types";

const useInsightWorkerDetailsByLevelController = () => {

    const workerInsightDetailsData = useSelector((state: WorkerInsightRootState) => state.workerInsightReportData.workerInsightDetailsData)
    const fetchWorkerInsightReportStatus = useSelector((state: WorkerInsightRootState) => state.workerInsightReportData.fetchWorkerInsightReport.status);

    const [searchText, setSearchText] = React.useState("")
    const [expanded, setExpanded] = React.useState<string[]>([])

    let searchWorkerInsightDetailsData: WorkerInsightDetailsModel  = workerInsightDetailsData;

    if (searchText?.length > 0) {

        let skills: any = [];

        const searchTextLowerCase = searchText.toLowerCase().replace(/\s/g, "");

        skills = workerInsightDetailsData?.workerSkillsByLevel?.filter((skill) => {
            return skill.skillName.toLowerCase().replace(/\s/g, "").includes(searchTextLowerCase)
        });

        searchWorkerInsightDetailsData = {
            ...workerInsightDetailsData,
            workerSkillsByLevel: skills,
            workerId: workerInsightDetailsData.workerId }
    }

    return [
        {
            searchText,
            searchWorkerInsightDetailsData,
            expanded,
            fetchWorkerInsightReportStatus
        },
        {
            setSearchText,
            setExpanded
        }
    ]
}

export default useInsightWorkerDetailsByLevelController;