import {  differenceInMinutes, format, isBefore, max, min,parseISO } from "date-fns";
import addDays from "date-fns/addDays";
import { enUS,es, fr } from "date-fns/locale";
import i18next from "i18next";

import { Skill } from "../../../components/workers/types";
import {
  FLEX_WORKER,
  FULL_TIME_WORKER,
  PART_TIME_WORKER,
} from "../config/constants";
import { SettingsData, Shift } from "../types";


export const getLanguage = (language: any) => {
  let returnValue;
  switch (language) {
    case "en":
      returnValue = "en-US";
      break;
    case "es":
      returnValue = "es-ES";
      break;
    case "fr":
      returnValue = "fr-FR";
      break;
      default:
        returnValue = "en-US";
        break;
    }
    return returnValue;
  };

  export const getLanguageCode = (locale :any) => {
    return locale ? locale?.split("-")[0] : "en";
  }

  export const getTranslatedDate = ({
    dateStr,
    language = "en",
    formatString = "MM/DD/YYYY",
  }: {
    dateStr: any;
    language?: string;
    formatString?: string;
  }): string => {
    let formattedDate = "";
    const locales: any = { en: enUS, fr, es };
    const locale: any = locales[language] || enUS;
  
    formattedDate = `${format(new Date(dateStr), formatString, { locale })}`;
  
    return formattedDate;
  };

export const getLocationFullAddressString = (locationAddress: {
  name: string;
  address1: string;
  city: string;
  state: string;
}) => {
  let returnValue = "-";
  try {
    let name = "";
    let address = "";
    let city = "";
    let state = "";
    if (locationAddress.name) {
      name = locationAddress.name ?? "";
      returnValue = `${name !== "" ? name : ""}`;
    }
    if (locationAddress.address1) {
      address = locationAddress.address1 ?? "";
      returnValue = `${
        returnValue !== "" && address !== ""
          ? `${returnValue}, ${address}`
          : returnValue
      }`;
    }
    if (locationAddress.city) {
      city = locationAddress.city ?? "";
      returnValue = `${
        returnValue !== "" && city !== ""
          ? `${returnValue}, ${city}`
          : returnValue
      }`;
    }
    if (locationAddress.state) {
      state = locationAddress.state ?? "";
      returnValue = `${
        returnValue !== "" && state !== ""
          ? `${returnValue}, ${state}`
          : `${returnValue === "" ? (state !== "" ? state : "-") : returnValue}`
      }`;
    }
    return returnValue;
  } catch (e) {
    return returnValue;
  }
};

export const isFullTimeWorker = (settingsData: any): boolean => {
  try {
    let returnValue = false;
    if (settingsData) {
      if (
        Object.prototype.hasOwnProperty.call(settingsData, "workerProfileInfo")
      ) {
        if (settingsData.workerProfileInfo) {
          if (
            Object.prototype.hasOwnProperty.call(
              settingsData.workerProfileInfo,
              "workerCompanies"
            )
          ) {
            if (settingsData.workerProfileInfo.workerCompanies.length > 0) {
              const selectedCompany =
                settingsData.workerProfileInfo.workerCompanies.find(
                  (company: any) =>
                    parseInt(company.companyId) ===
                    parseInt(settingsData.companyId)
                );
              if (selectedCompany) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    selectedCompany,
                    "workerTypeName"
                  )
                ) {
                  if (selectedCompany.workerTypeName === FULL_TIME_WORKER) {
                    returnValue = true;
                  }
                }
              }
            }
          }
        }
      }
    }
    return returnValue;
  } catch (e) {
    return false;
  }
};

export const isPartTimeWorker = (settingsData: any): boolean => {
  try {
    let returnValue = false;
    if (settingsData) {
      if (
        Object.prototype.hasOwnProperty.call(settingsData, "workerProfileInfo")
      ) {
        if (settingsData.workerProfileInfo) {
          if (
            Object.prototype.hasOwnProperty.call(
              settingsData.workerProfileInfo,
              "workerCompanies"
            )
          ) {
            if (settingsData.workerProfileInfo.workerCompanies.length > 0) {
              const selectedCompany =
                settingsData.workerProfileInfo.workerCompanies.find(
                  (company: any) =>
                    parseInt(company.companyId) ===
                    parseInt(settingsData.companyId)
                );
              if (selectedCompany) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    selectedCompany,
                    "workerTypeName"
                  )
                ) {
                  if (selectedCompany.workerTypeName === PART_TIME_WORKER) {
                    returnValue = true;
                  }
                }
              }
            }
          }
        }
      }
    }
    return returnValue;
  } catch (e) {
    return false;
  }
};

export const getSelectedCompany = (settingsData: SettingsData): any => {
  let returnValue: any = {};
  try {
    if (settingsData) {
      const companyId = settingsData.companyId;
      let companyFound = false;
      if (
        Object.prototype.hasOwnProperty.call(settingsData, "workerProfileInfo")
      ) {
        if (
          Object.prototype.hasOwnProperty.call(
            settingsData.workerProfileInfo,
            "workerCompanies"
          )
        ) {
          if (settingsData.workerProfileInfo.workerCompanies.length > 0) {
            settingsData.workerProfileInfo.workerCompanies.forEach(
              (company: any, index: number) => {
                if (companyId !== undefined) {
                  if (parseInt(companyId) === parseInt(company.companyId)) {
                    returnValue = company;
                    companyFound = true;
                  }
                }
                if (
                  Object.prototype.hasOwnProperty.call(
                    company,
                    "workerTypeName"
                  ) &&
                  !companyFound
                ) {
                  if (company.workerTypeName === FULL_TIME_WORKER) {
                    returnValue = company;
                    companyFound = true;
                  } else if (
                    settingsData.workerProfileInfo.workerCompanies.length -
                      1 ===
                      index &&
                    !companyFound
                  ) {
                    returnValue = company;
                  }
                }
              }
            );
          }
        }
      }
    }
    return returnValue;
  } catch (e) {
    return returnValue;
  }
};

//Add date time as per the api specification to a bunch of shifts in an array
export const addStartDateEndDate = (allShiftsData: any) => {
  if (allShiftsData) {
    return allShiftsData.map((shift: any) => {
      let startDate;
      let endDate;
      if (
        Object.prototype.hasOwnProperty.call(shift, "startDateTime") &&
        Object.prototype.hasOwnProperty.call(shift, "endDateTime")
      ) {
        // For upcoming and available shift getting these start and end time keys in response
        startDate = shift.startDateTime;
        endDate = shift.endDateTime;
      } else if (
        Object.prototype.hasOwnProperty.call(shift, "shiftStartTime") &&
        Object.prototype.hasOwnProperty.call(shift, "shiftEndTime")
      ) {
        // For regular shift getting these start and end time keys in response
        startDate = shift.shiftStartTime;
        endDate = shift.shiftEndTime;
        // Modifying shift object so that both regular and normal shift array can be used together
        shift.startDateTime = shift.shiftStartTime;
        shift.endDateTime = shift.shiftEndTime;
      }
      shift.startDate = startDate;
      shift.endDate = endDate;
      return shift;
    });
  } else {
    return [];
  }
};

export const getWeeklyHoursLimitMessage = (
  settingsData: SettingsData,
  startDateTime: string,
  endDateTime: string
): string => {
  try {
    let returnValue = "";
    const startDate = new Date(startDateTime);
    const endDate = new Date(endDateTime);
    const shiftDurationInHours = Math.abs(
      Math.ceil((endDate.getTime() - startDate.getTime()) / (60 * 60 * 1000))
    );
    let workerTypeName = FLEX_WORKER;
    let maxWorkHours = 0;
    let remainingWorkHours = 0;

    // Get selected company weekly hours limit and worker type
    if (settingsData) {
      if (
        Object.prototype.hasOwnProperty.call(settingsData, "maxWorkHours") &&
        Object.prototype.hasOwnProperty.call(
          settingsData,
          "remainingWorkHours"
        ) &&
        Object.prototype.hasOwnProperty.call(settingsData, "workerTypeName")
      ) {
        workerTypeName = settingsData.workerTypeName ?? FLEX_WORKER;
        maxWorkHours = settingsData.maxWorkHours ?? 0;
        remainingWorkHours = settingsData.remainingWorkHours ?? 0;
      } else {
        const selectedCompany = getSelectedCompany(settingsData);
        if (
          selectedCompany &&
          Object.prototype.hasOwnProperty.call(
            selectedCompany,
            "maxWorkHours"
          ) &&
          Object.prototype.hasOwnProperty.call(
            selectedCompany,
            "remainingWorkHours"
          ) &&
          Object.prototype.hasOwnProperty.call(
            selectedCompany,
            "workerTypeName"
          )
        ) {
          workerTypeName = selectedCompany.workerTypeName ?? FLEX_WORKER;
          maxWorkHours = selectedCompany.maxWorkHours ?? 0;
          remainingWorkHours = selectedCompany.remainingWorkHours ?? 0;
        }
      }
    }
    const fullTimeOrPartTimeWorker =
      workerTypeName === FULL_TIME_WORKER ||
      workerTypeName === PART_TIME_WORKER;
    if (fullTimeOrPartTimeWorker) {
      if (remainingWorkHours > 0) {
        if (shiftDurationInHours > remainingWorkHours) {
          // If shift exceed remaining work hours
          returnValue =  i18next.t("helper_full-time-worker-weekly-hour-limit-message", { maxWorkHours, remainingWorkHours});
        }
      } else {
        // If there is no remaining work hours
        returnValue = i18next.t("helper_full-time-worker-weekly-max-hour-limit-message",{maxWorkHours});
      }
    } else {
      // For flex workers
      if (remainingWorkHours > 0) {
        if (shiftDurationInHours > remainingWorkHours) {
          // If shift exceed remaining work hours
          returnValue = i18next.t("helper_flex-time-worker-weekly-hour-limit-message", { maxWorkHours, remainingWorkHours});
          
        }
      } else {
        // If there is no remaining work hours
        returnValue =  i18next.t("helper_flex-time-worker-weekly-max-hour-limit-message",{maxWorkHours});
      }
    }
    return returnValue;
  } catch (e) {
    return "";
  }
};

export const jobExpirationNoticeText = (
  jobName: string | undefined,
  bySupervisor = false,
  days = 5,
  isMyShift = false,
) => {
  let returnValue;
  if (bySupervisor) {
    returnValue = i18next.t("helper_job-inactive-message", {jobName});    
  } else {
    returnValue = i18next.t("helper_job-expiration-message", {jobName , days})
  }
  if (isMyShift) {
    returnValue = returnValue + i18next.t("helper_job-expected-to-attend");
  }
  return returnValue;
};

export const overwriteShiftObjectsWithIds = (
  array: Shift[],
  newDataArray: { id: number; [key: string]: any }[]
) => {
  newDataArray.forEach((newData) => {
    const index = array.findIndex((item) => item.id === newData.id);

    if (index !== -1) {
      array[index] = newData as Shift;
    } else {
      array.push(newData as Shift);
    }
  });

  return array;
};

export const parseLocalDate = (dateString: string): Date => {
  const isoRegex =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})$/;
  const simpleDateRegex = /^\d{4}-\d{2}-\d{2}$/;

  if (isoRegex.test(dateString)) {
    return new Date(dateString);
  } else if (simpleDateRegex.test(dateString)) {
    const dateParts = dateString.split("-");
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Month is zero-based
    const day = parseInt(dateParts[2], 10);
    return new Date(year, month, day);
  } else {
    return addDays(new Date(), 1);
  }
};

export const skillExpirationNoticeText = (shiftSkills: Skill[], isMyShift = false): string => {
  let returnValue =i18next.t("helper_skill-expired-message");

  const expiredSkills = shiftSkills.filter(
    (skill) => skill.isWorkerSkillExpired
  );

  if (expiredSkills.length > 0) {
    // const expiredSkillsString = expiredSkills
    //   .map((skill) => skill.skillName)
    //   .join(", ");
    returnValue = i18next.t("helper_skill-expired-message-with-skill");
  }

  if (isMyShift) {
    returnValue = returnValue + i18next.t("helper_skill-expected-to-attend");
  }
  return returnValue;
};

export const areDatesOneMonthApart = (dateStr1: string, dateStr2: string) => {
  // Extract year and month components from the date strings
  const [year1, month1] = dateStr1.split("-").map(Number);
  const [year2, month2] = dateStr2.split("-").map(Number);

  // Calculate the difference in months
  const monthDiff = (year2 - year1) * 12 + (month2 - month1);

  // Check if the difference is one month or above
  return Math.abs(monthDiff) >= 2;
};

export const getHourMinuteDifference = (
    startDate: Date,
    endDate: Date
) => {
  const returnValue = {
    differenceInText: "0 hour",
    differenceInMinutes: 0,
  };
  if (startDate.getTime() - endDate.getTime() <= 0) {
    return returnValue;
  }
  const timeDifference = Math.abs(startDate.getTime() - endDate.getTime());
  returnValue.differenceInMinutes = Math.ceil(timeDifference / (1000 * 60));

  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  if (hours === 0) {
    returnValue.differenceInText = `${minutes} ${
        minutes !== 1 ? i18next.t("helper_minutes-text") : i18next.t("helper_minute-text")
    }`;
  } else {
    if (minutes === 0) {
      returnValue.differenceInText = `${hours} ${hours !== 1 ? i18next.t("helper_hours-text") : i18next.t("helper_hour-text")}`;
    } else {
      returnValue.differenceInText = `${hours} ${
          hours !== 1 ? i18next.t("helper_hours-text") : i18next.t("helper_hour-text")
      } ${minutes} ${minutes !== 1 ? i18next.t("helper_minutes-text") : i18next.t("helper_minute-text")}`;
    }
  }

  return returnValue;
};

export const getOverlapDuration = ({
  flexStart,
  flexEnd,
  ftStart,
  ftEnd,
}: {
  flexStart: string;
  flexEnd: string;
  ftStart: string;
  ftEnd: string;
}) => {
  const start1 = parseISO(flexStart);
  const end1 = parseISO(flexEnd);
  const start2 = parseISO(ftStart);
  const end2 = parseISO(ftEnd);

  // No overlap case
  if (isBefore(end1, start2) || isBefore(end2, start1)) {
    return 0;
  }

  // Calculate overlap period
  const overlapStart = max([start1, start2]);
  const overlapEnd = min([end1, end2]);
  
  // Return overlap in minutes
  return differenceInMinutes(overlapEnd, overlapStart);
};

export const formatOverlapMessage = ({
  flexStart,
  flexEnd,
  ftStart,
  ftEnd,
  ftShiftPatternName,
}: {
  flexStart: string;
  flexEnd: string;
  ftStart: string;
  ftEnd: string;
  ftShiftPatternName: string;
}) => {

  const overlapMinutes = getOverlapDuration({
    flexStart,
    flexEnd,
    ftStart,
    ftEnd,
  });

  const start2 = parseISO(ftStart);
  const end2 = parseISO(ftEnd);
  const start1 = parseISO(flexStart);
  const end1 = parseISO(flexEnd);
  let detailsMessage = "";
  
  // Calculate overlap period (needed to determine if starts or ends at shift2)
  const overlapStart = max([start1, start2]);
  
  // Format Shift 2's start and end times
  const formattedStart2 = format(start2, "h:mm a");
  const formattedEnd2 = format(end2, "h:mm a");

  // Determine if overlap is "starting at" or "ending at" Shift 2's time
  const overlapPoint =
    overlapStart.getTime() === start2.getTime()
      ? detailsMessage = i18next.t("shift-sign-up_overlapping-message-starting-at", {overlappingMinutes:overlapMinutes , shiftPatternName: ftShiftPatternName, overlapTime : formattedStart2})
      : detailsMessage = i18next.t("shift-sign-up_overlapping-message-ending-at", {overlappingMinutes:overlapMinutes , shiftPatternName: ftShiftPatternName, overlapTime: formattedEnd2});
      return detailsMessage;
};
