import { Divider } from "@mui/material";
import React from "react";

import { PageHeader } from "../../../../../export/page-header";
import PageLayout from "../../../../../ts/design/wilya-page-layout";
import {
	PRODUCTION_DEMAND_SUB_TITLE,
	PRODUCTION_DEMAND_TITLE,
} from "../../constants/titles";
import ShiftsRequirementTable from "./ui/shifts-requirement-table";

const ShiftsRequirement = ({ changePage, callAlgo }) => {
	return (
		<PageLayout>
			<PageHeader
				title={PRODUCTION_DEMAND_TITLE}
				subtitle={PRODUCTION_DEMAND_SUB_TITLE}
			/>
			<Divider />
			<ShiftsRequirementTable changePage={changePage} callAlgo={callAlgo} />
		</PageLayout>
	);
};

export default ShiftsRequirement;
