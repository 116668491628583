import React, {useMemo} from "react";

import {CurrentTimeIndicator} from "./styled-components";

const MemoizedCurrentTimeIndicator = (
    ({ currentTime }: { currentTime: number }) => {
        const styles = useMemo(
            () => ({
                top: `${currentTime}px`,
            }),
            [currentTime]
        );

        return <CurrentTimeIndicator style={styles} />;
    }
);

export default React.memo(MemoizedCurrentTimeIndicator);
