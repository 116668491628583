import MUIButton from "@mui/material/Button";
import React, { ReactNode } from "react";

import CustomThemeProvider from "../custom-theme-provider";

type Color =
	| "inherit"
	| "primary"
	| "secondary"
	| "success"
	| "error"
	| "info"
	| "warning";

type Props = {
	color?: Color;
	type?: "button" | "submit" | "reset";
	variant?: "contained" | "outlined" | "text";
	iconBefore?: JSX.Element;
	onClick?: () => void;
	disabled?: boolean;
	customStyles?: object;
	size?: "small" | "medium" | "large";
	className?: string;
	children: ReactNode;
};

const Button = ({
	color,
	type,
	variant,
	iconBefore,
	onClick,
	disabled = false,
	customStyles,
	size,
	className,
	children,
}: Props) => {
	const buttonProps = {
		sx: customStyles || {},
		variant: variant || "contained",
		color: color,
		startIcon: iconBefore,
		onClick: onClick,
		disabled: disabled,
		size: size || "medium",
		className: className,
		type: type || "button",
	};

	return (
		<CustomThemeProvider>
			<MUIButton {...buttonProps}>{children}</MUIButton>
		</CustomThemeProvider>
	);
};
export default Button;
