// TODO - Type 'any' needs to be fixed.
import { Alert, Box, Collapse, Divider, Grid2, Snackbar } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  SchedulerHeader,
  WilyaScheduler,
} from "../../../export/scheduler";
import {
  useScheduleHeader,
} from "../../../export/scheduler";
import useViewport from "../../../ts/utils/use-viewport";
import { JOB } from "../../assets/constants";
import FilterAccordion from "../filters/filter-accordion";
import { FilterProvider } from "../filters/filter-context";
import UseSchedulerController from "./controllers/use-scheduler-controller";
import useSchedulerData from "./controllers/use-scheduler-data";
import {
  editDraftShiftDialogClose,
  resetAssignWorkersResponse,
  resetCancelShiftWithReason,
  resetCreateDraftShiftAsyncState,
  resetEditDraftShift,
  resetMarkAttendance,
  resetPublishDraftShiftsState,
  resetSetOTWorkers,
  resetSetOTWorkersResponse,
} from "./store/scheduler-slice";
import Availability from "./ui/availability";
import CalendarHeader from "./ui/calendar-header";
import SchedulerActions from "./ui/scheduler-actions";

const Calendar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { height } = useViewport();
  const boxRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const {
    shiftBySkills,
    broadcastMessageStatus,
    createDraftShiftAsyncStatus,
    createDraftShiftAsyncError,
    changeWeekAsyncStatus,
    changeDayAsyncStatus,
    changeWeekAvailabilityStatus,
    deleteDraftShiftAsyncStatus,
    editDraftShiftStatus,
    fetchFilterAndShiftDataStatus,
    isHeatMapVisible,
    filterJobRoles,
    renotifyWorkersStatus,
    assignWorkersStatus,
    assignWorkersErrorMessage,
    shiftsByFilter,
    publishDraftShiftsAsyncSuccessMessage,
    cancelShiftWithReasonStatus,
    cancelShiftWithReasonErrorMessage,
    publishDraftShiftsAsyncStatus,
    setOTWorkersStatus,
    setOTWorkersErrorMessage,
  } = useSchedulerData();
  const [
    {
      isCreateNewShiftDialogOpen,
      weekPasteDate,
      draftShiftDate,
      state,
      skillState,
      isShiftDetailsDialogOpen,
      shiftIdForShiftDetailsDialog,
      isCreateWeekShiftDialogOpen,
      isPastShiftDialogOpen,
      isMarkAttendanceDialogOpen,
      isInitSet,
      isSkillInitSet,
      filters,
      defaultAppliedFilters,
      isShiftCancelDialogOpen,
      shiftsData,
      isDeleteDraftShiftDialogOpen,
      isPublishDraftShiftDialogOpen,
    },
    {
      openCreateWeekShiftDialog,
      closeCreateWeekShiftDialog,
      openCreateNewShiftDialog,
      setShiftIdForShiftDetailsDialog,
      setIsShiftDetailsDialogOpen,
      closeCreateNewShiftDialog,
      resetDeleteDraftShiftAsync,
      dispatchLocal,
      dispatchLocalSkill,
      resetBroadcastMessageState,
      resetAssignWorkers,
      openPastShiftDialog,
      closePastShiftDialog,
      openMarkAttendanceDialog,
      closeMarkAttendanceDialog,
      resetRenotifyWorkers,
      setFilters,
      openShiftCancelDialog,
      closeShiftCancelDialog,
      openDeleteDraftShiftDialog,
      closeDeleteDraftShiftDialog,
      openPublishDraftShiftDialog,
      closePublishDraftShiftDialog,
    },
  ]: any = UseSchedulerController();

  const [
    {
      isReviewPublishingShiftsDialogOpen,
      isBMessageDialogOpen,
      isEditShiftDialogOpen,
    },
    {
      openReviewPublishShiftDialog,
      closeReviewPublishShiftDialog,
      closeBMessageDialog,
      openBMessageDialog,
    },
  ]: any = useScheduleHeader();

  const {
    createNewShiftDialog,
    editShiftDialog,
    reviewPublicShiftDialog,
    bMessageDialog,
    shiftDetailsDialog,
    createWeekShiftDialog,
    pastShiftDialog,
    markAttendanceDialog,
    shiftCancelDialog,
    deleteDraftShiftDialog,
    publishDraftShiftDialog,
  } = SchedulerActions({
    shiftsData,
    isDeleteDraftShiftDialogOpen,
    isShiftCancelDialogOpen,
    isCreateWeekShiftDialogOpen,
    isEditShiftDialogOpen,
    shiftIdForShiftDetailsDialog,
    isShiftDetailsDialogOpen,
    isBMessageDialogOpen,
    isReviewPublishingShiftsDialogOpen,
    isCreateNewShiftDialogOpen,
    isPastShiftDialogOpen,
    weekPasteDate,
    draftShiftDate,
    state,
    skillState,
    closeCreateWeekShiftDialog,
    closeCreateNewShiftDialog,
    closeReviewPublishShiftDialog,
    closeBMessageDialog,
    dispatchLocal,
    dispatchLocalSkill,
    setIsShiftDetailsDialogOpen,
    editDraftShiftDialogClose,
    changeWeekAsyncStatus,
    closePastShiftDialog,
    isMarkAttendanceDialogOpen,
    closeMarkAttendanceDialog,
    openShiftCancelDialog,
    closeShiftCancelDialog,
    openDeleteDraftShiftDialog,
    closeDeleteDraftShiftDialog,
    openPublishDraftShiftDialog,
    closePublishDraftShiftDialog,
    isPublishDraftShiftDialogOpen,
  });

  const [schedulerContainerHeight, setSchedulerContainerHeight] = useState(0);
  const [checked, setChecked] = useState(false);
  const [toggleScheduler, setToggleScheduler] = useState(false);

  useEffect(() => {
    // This function calculate X and Y
    const getPosition = () => {
      if (boxRef) {
        try {
          const y = boxRef.current.offsetTop;
          setSchedulerContainerHeight(height - y);
        } catch (error) {
          // Do nothing
        }
      }
    };
    getPosition();
  }, [height]);

  useEffect(() => {
    setIsLoading(true);
    if (filterJobRoles && filterJobRoles.length > 0) {
      // Close loader
      setIsLoading(false);
    } else if (fetchFilterAndShiftDataStatus === "fulfilled") {
      setIsLoading(false);
    }
  }, [filterJobRoles, fetchFilterAndShiftDataStatus]);

  useEffect(() => {
    if (createDraftShiftAsyncStatus === "fulfilled") {
      closeCreateNewShiftDialog();
      closeCreateWeekShiftDialog();
    }
  }, [
    closeCreateNewShiftDialog,
    closeCreateWeekShiftDialog,
    createDraftShiftAsyncStatus,
  ]);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleAssignWorkerClose = () => {
    dispatch(resetAssignWorkers());
    dispatch(resetAssignWorkersResponse());
    navigate("/login");
  };

  const handleSetOTWorkerClose = () => {
    dispatch(resetSetOTWorkers());
    dispatch(resetSetOTWorkersResponse());
    navigate("/login");
  };

  const handleCreateDraftShiftClose = () => {
    dispatch(resetCreateDraftShiftAsyncState());
    navigate("/login");
  };

  useEffect(() => {
    // Open filters when availability open
    if (toggleScheduler) {
      // True when availability tab is clicked
      // Open filters
      setChecked(true);
    } else {
      // Close when Flex scheduler is clicked
      setChecked(false);
    }
  }, [toggleScheduler]);

  return (
    <>
      <FilterProvider filterKey={"flex-scheduler"}>
        <SchedulerHeader
          openBMessageDialog={openBMessageDialog}
          toggleScheduler={toggleScheduler}
          setToggleScheduler={setToggleScheduler}
          isHeatMapVisible={isHeatMapVisible}
        />
        <Divider />
        <CalendarHeader
          collapseFilter={handleChange}
          checked={checked}
          toggleScheduler={toggleScheduler}
          openCreateWeekShiftDialog={openCreateWeekShiftDialog}
          openCreateNewShiftDialog={openCreateNewShiftDialog}
          openReviewPublishShiftDialog={openReviewPublishShiftDialog}
          type={
            toggleScheduler ? (shiftsByFilter === JOB ? "job" : "skill") : null
          }
        />
        <Divider />
        <Box ref={boxRef} sx={{ flexGrow: 1 }}>
          {toggleScheduler ? (
            <Availability
              shiftBySkills={shiftBySkills}
              shiftsByFilter={shiftsByFilter}
              checked={checked}
              changeWeekAvailabilityStatus={changeWeekAvailabilityStatus}
              height={schedulerContainerHeight - 24}
            />
          ) : (
            <Grid2 container wrap="nowrap" spacing={0}>
              <Box><Collapse orientation="horizontal" in={checked}> <FilterAccordion
                  filters={filters}
                  setFilters={setFilters}
                  defaultAppliedFilters={defaultAppliedFilters}
                  minFilterAccordionHeight={schedulerContainerHeight - 24}
              /> </Collapse></Box>
              <Grid2 width={"100%"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    flex: 1,
                  }}
                >
                  <WilyaScheduler
                    openCreateNewShiftDialog={openCreateNewShiftDialog}
                    openShiftCancelDialog={openShiftCancelDialog}
                    openDeleteDraftShiftDialog={openDeleteDraftShiftDialog}
                    openPastShiftDialog={openPastShiftDialog}
                    openPublishDraftShiftDialog={openPublishDraftShiftDialog}
                    setShiftIdForShiftDetailsDialog={
                      setShiftIdForShiftDetailsDialog
                    }
                    openShiftDetailsDialog={() => {
                      dispatch(resetRenotifyWorkers());
                      setIsShiftDetailsDialogOpen(true);
                    }}
                    openShiftAttendanceDialog={() => {
                      dispatch(resetMarkAttendance());
                      openMarkAttendanceDialog();
                    }}
                    changeWeekAsyncStatus={changeWeekAsyncStatus}
                    changeDayAsyncStatus={changeDayAsyncStatus}
                    height={schedulerContainerHeight - 24}
                    isLoading={isLoading}
                    filters={filters}
                    checked
                    toggleScheduler={toggleScheduler}
                  />
                </Box>
              </Grid2>
            </Grid2>
          )}
        </Box>
        {editDraftShiftStatus === "fulfilled" ? (
          <Snackbar
            open={editDraftShiftStatus === "fulfilled"}
            autoHideDuration={3000}
            onClose={() => dispatch(resetEditDraftShift())}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert elevation={6} variant="filled" severity="success">
              Shift Edited Successfully
            </Alert>
          </Snackbar>
        ) : null}

        {deleteDraftShiftAsyncStatus === "fulfilled" ? (
          <Snackbar
            open={deleteDraftShiftAsyncStatus === "fulfilled"}
            autoHideDuration={3000}
            onClose={() => dispatch(resetDeleteDraftShiftAsync())}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert elevation={6} variant="filled" severity="success">
              Shift Deleted
            </Alert>
          </Snackbar>
        ) : null}

        {broadcastMessageStatus === "fulfilled" ? (
          <Snackbar
            open={broadcastMessageStatus === "fulfilled"}
            autoHideDuration={3000}
            onClose={() => dispatch(resetBroadcastMessageState())}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert elevation={6} variant="filled" severity="success">
              Message Sent Successfully.
            </Alert>
          </Snackbar>
        ) : null}

        {assignWorkersStatus === "error" ? (
          <Snackbar
            open={assignWorkersStatus === "error"}
            autoHideDuration={3000}
            onClose={handleAssignWorkerClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              severity="error"
              onClose={handleAssignWorkerClose}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box mb={1}>
                  {assignWorkersErrorMessage ?? "An error occurred"}
                </Box>
              </Box>
            </Alert>
          </Snackbar>
        ) : null}

        {setOTWorkersStatus === "error" ? (
          <Snackbar
            open={setOTWorkersStatus === "error"}
            autoHideDuration={3000}
            onClose={handleSetOTWorkerClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              severity="error"
              onClose={handleSetOTWorkerClose}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box mb={1}>
                  {setOTWorkersErrorMessage ?? "An error occurred"}
                </Box>
              </Box>
            </Alert>
          </Snackbar>
        ) : null}

        {createDraftShiftAsyncStatus === "fulfilled" ? (
          <Snackbar
            open={createDraftShiftAsyncStatus === "fulfilled"}
            autoHideDuration={3000}
            onClose={() => dispatch(resetCreateDraftShiftAsyncState())}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert elevation={6} variant="filled" severity="success">
              Draft Shift Created Successfully.
            </Alert>
          </Snackbar>
        ) : null}

        {createDraftShiftAsyncStatus === "error" ? (
          <Snackbar
            open={createDraftShiftAsyncStatus === "error"}
            autoHideDuration={6000}
            onClose={handleCreateDraftShiftClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              severity="error"
              onClose={handleCreateDraftShiftClose}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box mb={1}>
                  {createDraftShiftAsyncError ?? "An error occurred"}
                </Box>
                {/*<Button*/}
                {/*    variant="contained"*/}
                {/*    color="primary"*/}
                {/*    onClick={() => history.push("/login")}*/}
                {/*>*/}
                {/*    Try Login Again*/}
                {/*</Button>*/}
              </Box>
            </Alert>
          </Snackbar>
        ) : null}

        {renotifyWorkersStatus === "fulfilled" ? (
          <Snackbar
            open={renotifyWorkersStatus === "fulfilled"}
            autoHideDuration={3000}
            onClose={() => dispatch(resetRenotifyWorkers())}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert elevation={6} variant="filled" severity="success">
              Workers Notified Successfully.
            </Alert>
          </Snackbar>
        ) : null}
        {renotifyWorkersStatus === "error" ? (
          <Snackbar
            open={renotifyWorkersStatus === "error"}
            autoHideDuration={5000}
            onClose={() => dispatch(resetRenotifyWorkers(undefined))}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              severity="error"
              onClose={() => dispatch(resetRenotifyWorkers())}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box mb={1}>
                  Error while notifying workers. Please try again.
                </Box>
              </Box>
            </Alert>
          </Snackbar>
        ) : null}

        {cancelShiftWithReasonStatus === "fulfilled" ? (
          <Snackbar
            open={cancelShiftWithReasonStatus === "fulfilled"}
            autoHideDuration={3000}
            onClose={() => dispatch(resetCancelShiftWithReason())}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert elevation={6} variant="filled" severity="success">
              Shift Cancelled Successfully.
            </Alert>
          </Snackbar>
        ) : null}
        {cancelShiftWithReasonStatus === "error" ? (
          <Snackbar
            open={cancelShiftWithReasonStatus === "error"}
            autoHideDuration={5000}
            onClose={() => dispatch(resetCancelShiftWithReason())}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              severity="error"
              onClose={() => dispatch(resetCancelShiftWithReason())}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box mb={1}>
                  {cancelShiftWithReasonErrorMessage ??
                    "Error while cancelling shift. Please try again."}{" "}
                </Box>
              </Box>
            </Alert>
          </Snackbar>
        ) : null}

        {publishDraftShiftsAsyncStatus === "fulfilled" ? (
          <Snackbar
            open={publishDraftShiftsAsyncStatus === "fulfilled"}
            autoHideDuration={3000}
            onClose={() => dispatch(resetPublishDraftShiftsState())}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert elevation={6} variant="filled" severity="success">
              {publishDraftShiftsAsyncSuccessMessage}
            </Alert>
          </Snackbar>
        ) : null}

        {isPastShiftDialogOpen && pastShiftDialog}
        {isCreateNewShiftDialogOpen && createNewShiftDialog}
        {isEditShiftDialogOpen && editShiftDialog}
        {isReviewPublishingShiftsDialogOpen && reviewPublicShiftDialog}
        {isShiftDetailsDialogOpen && shiftDetailsDialog}
        {isBMessageDialogOpen && bMessageDialog}
        {isCreateWeekShiftDialogOpen && createWeekShiftDialog}
        {isMarkAttendanceDialogOpen && markAttendanceDialog}
        {isShiftCancelDialogOpen && shiftCancelDialog}
        {isDeleteDraftShiftDialogOpen && deleteDraftShiftDialog}
        {isPublishDraftShiftDialogOpen && publishDraftShiftDialog}
      </FilterProvider>
    </>
  );
};

export default Calendar;
