import {useEffect, useRef, useState} from "react";

import { calculateIndicatorPosition } from "../utils";

const useCurrentTime = (timeSlotHeight: number): number => {
    const [currentTime, setCurrentTime] = useState(
        calculateIndicatorPosition(new Date(), timeSlotHeight)
    );
    const timeSlotHeightRef = useRef(timeSlotHeight);

    useEffect(() => {
        timeSlotHeightRef.current = timeSlotHeight; // Update ref with latest value
    }, [timeSlotHeight]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(calculateIndicatorPosition(new Date(), timeSlotHeight));
        }, 60000); // Update every minute
        return () => clearInterval(interval); // Cleanup
    }, [timeSlotHeight]);

    return currentTime;
};

export default useCurrentTime;