/* eslint-disable no-mixed-spaces-and-tabs */
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import format from "date-fns/format";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import LoadingButton from "../../../../../../ts/design/wilya-loading-button";
import useViewport from "../../../../../utils/useViewport";
import Loading from "../../../../Loading";
import { BORDER_COLOR } from "../../../constants/colors";
import {
	REQUEST_NEW_SHIFTS,
	REQUIRED_WORKERS,
	WORKERS_AVAILABILITY,
} from "../../../constants/pages";
import {
	PRODUCTION_DEMAND_TITLE,
	REQUEST_FLEX_WORKER_TITLE,
	WORKERS_AVAILABILITY_TITLE,
} from "../../../constants/titles";
import useScheduleController from "../../../controllers/schedule-controller";
import {
	resetAllWorkers,
	resetJobs,
	resetShifts,
	resetWorkCenters,
} from "../../../store/weekly-schedule-slice";
import { WeekHeaderRow } from "../../week-header-row";
import { WeeklyScheduleDateRangePicker } from "../../weekly-schedule-date-range-picker";
import { Row } from "./scheduled-shifts-table-row";
import { UnallocatedWorkersRow } from "./scheduled-shifts-unallocated-workers";
import ScheduledShiftsViewDetails from "./scheduled-shifts-view-details";

const ScheduledShiftsTable = ({
	resetCallAlgo,
	changePage,
	regenerateShifts,
}) => {
	const [
		{
			anyDateOfSelectedWeek,
			workCenters,
			weekShiftsData,
			allJobRoles,
			fetchAllWorkersStatus,
			fetchShiftsForThisWeekStatus,
			fetchJobsStatus,
			fetchWorkCentersStatus,
			generateScheduleStatus,
			fetchUnallocatedWorkersStatus,
			assignWorkerStatus,
			fetchAvailabilityStatus,
			fetchShiftsRequirementStatus,
			updateShiftsRequirementStatus,
			fetchShiftsError,
			fetchUnallocatedWorkersError,
			fetchShiftsRequirementError,
			fetchJobsError,
			fetchAllWorkersError,
			fetchAvailabilityError,
			fetchWorkCentersError,
			assignWorkerError,
			updateShiftsRequirementError,
			renotifyWorkersStatus,
			renotifyWorkersErrorMessage,
		},
		{
			selectWeek,
			getShiftByJobId,
			fetchShiftsForThisWeek,
			fetchAllJobs,
			fetchWorkers,
			fetchAllWorkCenters,
			fetchUnallocatedWorkersForDateRange,
			callAlgorithm,
			getTotalRequirementHours,
			getTotalAvailabilityHours,
			fetchAvailabilityForThisWeek,
			fetchShiftsRequirementForThisWeek,
			resetUpdateShiftsRequirement,
			resetRenotifyWorkers,
		},
	] = useScheduleController();

	const { height } = useViewport();
	const boxRef = useRef();
	const dispatch = useDispatch();
	const [scheduleTableHeight, setScheduleTableHeight] = useState();
	const [selectedWC, setSelectedWC] = useState();
	const [localShifts, setLocalShifts] = useState([]);
	const [localJobs, setLocalJobs] = useState([]);
	const [loadTimestamp, setLoadTimestamp] = useState(0);
	const [algoTimestamp, setAlgoTimestamp] = useState(0);
	const [loadAvailabilityAndRequirement, setLoadAvailabilityAndRequirement] =
		useState(0);
	const [isShiftDetailsDialogOpen, setIsShiftDetailsDialogOpen] =
		useState(false);
	const [shiftIdForShiftDetailsDialog, setShiftIdForShiftDetailsDialog] =
		useState(null);

	const shiftDetailsDialog = (
		<ScheduledShiftsViewDetails
			open={isShiftDetailsDialogOpen}
			handleClose={() => {
				setIsShiftDetailsDialogOpen(false);
			}}
			shiftId={shiftIdForShiftDetailsDialog}
		/>
	);

	const getLocalTotalRequiredHours = (localDate) => {
		try {
			let returnValue = 0;
			const datesArrayOfLocalSelectedWeek = selectWeek(localDate);
			const startDateOfLocalSelectedWeek = format(
				datesArrayOfLocalSelectedWeek[0],
				"yyyy-MM-dd"
			);
			returnValue = getTotalRequirementHours(startDateOfLocalSelectedWeek);
			return returnValue;
		} catch (e) {
			return 0;
		}
	};

	const getLocalTotalAvailabilityHours = (localDate) => {
		try {
			let returnValue = 0;
			const datesArrayOfLocalSelectedWeek = selectWeek(localDate);
			const startDateOfLocalSelectedWeek = format(
				datesArrayOfLocalSelectedWeek[0],
				"yyyy-MM-dd"
			);
			returnValue = getTotalAvailabilityHours(startDateOfLocalSelectedWeek);
			return returnValue;
		} catch (e) {
			return 0;
		}
	};

	useEffect(() => {
		//console.log("API STATUS", {
		// 	generateScheduleStatus,
		// 	fetchJobsStatus,
		// 	fetchWorkCentersStatus,
		// 	fetchAvailabilityStatus,
		// 	fetchShiftsRequirementStatus,
		// 	fetchUnallocatedWorkersStatus,
		// 	assignWorkerStatus
		// });
		let canRun = true;
		if (
			generateScheduleStatus === "error" ||
			fetchJobsStatus === "error" ||
			fetchWorkCentersStatus === "error" ||
			fetchAvailabilityStatus === "error" ||
			fetchShiftsRequirementStatus === "error" ||
			fetchUnallocatedWorkersStatus === "error" ||
			assignWorkerStatus === "error"
		) {
			canRun = false;
		}
		const getShifts = () => {
			setLoadTimestamp(anyDateOfSelectedWeek.getTime());
			const datesArrayOfSelectedWeek = selectWeek(anyDateOfSelectedWeek);
			// Fetch all shifts on this page load
			fetchShiftsForThisWeek({
				startDate: format(datesArrayOfSelectedWeek[0], "yyyy-MM-dd"),
				endDate: format(
					datesArrayOfSelectedWeek[datesArrayOfSelectedWeek.length - 1],
					"yyyy-MM-dd"
				),
			});
		};
		const getUnallocatedWorkers = () => {
			const datesArrayOfSelectedWeek = selectWeek(anyDateOfSelectedWeek);
			fetchUnallocatedWorkersForDateRange({
				fromDate: datesArrayOfSelectedWeek[0],
				toDate: datesArrayOfSelectedWeek[datesArrayOfSelectedWeek.length - 1],
			});
		};
		const fetchRequirementAndAvailability = () => {
			setLoadAvailabilityAndRequirement(anyDateOfSelectedWeek.getTime());
			// Calling availability
			const datesArrayOfSelectedWeek = selectWeek(anyDateOfSelectedWeek);
			fetchAvailabilityForThisWeek({
				fromDate: format(datesArrayOfSelectedWeek[0], "yyyy-MM-dd"),
				toDate: format(
					datesArrayOfSelectedWeek[datesArrayOfSelectedWeek.length - 1],
					"yyyy-MM-dd"
				),
			});

			// Calling requirements api
			fetchShiftsRequirementForThisWeek({
				fromDate: format(datesArrayOfSelectedWeek[0], "yyyy-MM-dd"),
				toDate: format(
					datesArrayOfSelectedWeek[datesArrayOfSelectedWeek.length - 1],
					"yyyy-MM-dd"
				),
			});
		};
		const arTimeCheck =
			loadAvailabilityAndRequirement !== anyDateOfSelectedWeek.getTime();
		const timeCheck = loadTimestamp !== anyDateOfSelectedWeek.getTime();
		if (canRun) {
			if (
				fetchJobsStatus === "idle" &&
				fetchWorkCentersStatus === "idle" &&
				fetchAllWorkersStatus === "idle"
			) {
				//console.log("Calling all 1");
				fetchAllWorkCenters();
				fetchAllJobs();
				fetchWorkers();
			} else if (
				fetchJobsStatus === "fulfilled" &&
				fetchWorkCentersStatus === "fulfilled" &&
				fetchAllWorkersStatus === "fulfilled" &&
				generateScheduleStatus !== "pending"
			) {
				if (
					fetchAvailabilityStatus === "idle" &&
					fetchShiftsRequirementStatus === "idle"
				) {
					if (arTimeCheck) {
						//console.log("Calling availability and requirement api");
						fetchRequirementAndAvailability();
					}
				}
				if (
					fetchUnallocatedWorkersStatus !== "pending" ||
					generateScheduleStatus === "fulfilled" ||
					assignWorkerStatus === "fulfilled"
				) {
					if (
						timeCheck ||
						generateScheduleStatus === "fulfilled" ||
						assignWorkerStatus === "fulfilled"
					) {
						if (generateScheduleStatus === "fulfilled") {
							if (fetchUnallocatedWorkersStatus === "idle") {
								//console.log("Call Unallocated worker 1");
								getUnallocatedWorkers();
							} else if (fetchUnallocatedWorkersStatus === "fulfilled") {
								if (generateScheduleStatus === "fulfilled") {
									//console.log("Call shift 1");
									getShifts();
								} else if (timeCheck) {
									getShifts();
								}
							}
						} else if (assignWorkerStatus === "fulfilled") {
							if (fetchUnallocatedWorkersStatus === "idle") {
								//console.log("Call Unallocated worker 2");
								getUnallocatedWorkers();
							} else if (fetchUnallocatedWorkersStatus === "fulfilled") {
								if (assignWorkerStatus === "fulfilled") {
									//console.log("Call shift 2");
									getShifts();
								} else if (timeCheck) {
									//console.log("Call shift 3");
									getShifts();
								}
							}
						} else if (timeCheck) {
							if (fetchUnallocatedWorkersStatus === "idle") {
								//console.log("Call Unallocated worker 3")
								getUnallocatedWorkers();
							} else if (fetchUnallocatedWorkersStatus === "fulfilled") {
								if (timeCheck) {
									//console.log("Call shift 3");
									getShifts();
								}
							}
						}
					}
				}
			}
		}
	}, [
		generateScheduleStatus,
		fetchJobsStatus,
		fetchWorkCentersStatus,
		fetchAllWorkersStatus,
		fetchAvailabilityStatus,
		fetchShiftsRequirementStatus,
		fetchUnallocatedWorkersStatus,
		assignWorkerStatus,
		loadAvailabilityAndRequirement,
		anyDateOfSelectedWeek,
		loadTimestamp,
		selectWeek,
		fetchShiftsForThisWeek,
		fetchUnallocatedWorkersForDateRange,
		fetchAllWorkCenters,
		fetchAllJobs,
		fetchWorkers,
		fetchAvailabilityForThisWeek,
		fetchShiftsRequirementForThisWeek,
	]);

	useEffect(() => {
		if (workCenters.length > 0) {
			setSelectedWC(workCenters[0].id);
		}
	}, [workCenters]);

	useEffect(() => {
		if (weekShiftsData !== undefined) {
			setLocalShifts(weekShiftsData);
		}
	}, [weekShiftsData]);

	useEffect(() => {
		if (allJobRoles !== undefined) {
			setLocalJobs(allJobRoles);
		}
	}, [allJobRoles]);

	useEffect(() => {
		if (regenerateShifts && algoTimestamp !== anyDateOfSelectedWeek.getTime()) {
			setAlgoTimestamp(anyDateOfSelectedWeek.getTime());
			const datesArrayOfSelectedWeek = selectWeek(anyDateOfSelectedWeek);
			resetCallAlgo();
			//console.log("Calling algo 2");
			callAlgorithm({
				fromDate: datesArrayOfSelectedWeek[0],
				toDate: datesArrayOfSelectedWeek[datesArrayOfSelectedWeek.length - 1],
			});
		}
	}, [
		resetCallAlgo,
		algoTimestamp,
		anyDateOfSelectedWeek,
		callAlgorithm,
		regenerateShifts,
		selectWeek,
	]);

	useEffect(() => {
		// This function calculate X and Y
		const getPosition = () => {
			if (boxRef) {
				try {
					const y = boxRef.current.offsetTop;
					setScheduleTableHeight(height - y - 24);
				} catch (error) {
					// Do nothing
				}
			}
		};
		getPosition();
	}, [height]);

	return (
		<Box>
			<Box
				sx={{
					height: "68px",
					borderBottom: 1,
					borderBottomWidth: 1,
					borderBottomColor: BORDER_COLOR,
					padding: "16px 24px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					zIndex: 9999,
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<WeeklyScheduleDateRangePicker />
					<LoadingButton
						fullWidth
						onClick={() => {
							setAlgoTimestamp(anyDateOfSelectedWeek.getTime());
							setLoadTimestamp(0);
							const datesArrayOfSelectedWeek = selectWeek(
								anyDateOfSelectedWeek
							);
							resetCallAlgo();
							//console.log("Calling algo 1");
							callAlgorithm({
								fromDate: datesArrayOfSelectedWeek[0],
								toDate:
									datesArrayOfSelectedWeek[datesArrayOfSelectedWeek.length - 1],
							});
						}}
						sx={{
							fontFamily: "Roboto",
							fontSize: "12px",
							padding: "5px",
							color: "#2F4D8B",
							borderColor: "#2F4D8B",
							marginLeft: "16px",
						}}
						loading={generateScheduleStatus === "pending"}
					>
						<RefreshIcon />
					</LoadingButton>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Button
						onClick={() => changePage(REQUIRED_WORKERS)}
						startIcon={<EditOutlinedIcon />}
						sx={{
							fontFamily: "Roboto",
							fontSize: "12px",
							padding: "4px 16px",
							color: "#2F4D8B",
							borderColor: "#2F4D8B",
							whiteSpace: "noWrap",
							marginLeft: "16px",
						}}
						variant='outlined'
					>
						{getLocalTotalRequiredHours(anyDateOfSelectedWeek) > 0
							? `${PRODUCTION_DEMAND_TITLE} (${getLocalTotalRequiredHours(
									anyDateOfSelectedWeek
							  )} hours)`
							: PRODUCTION_DEMAND_TITLE}
					</Button>
					<Button
						onClick={() => changePage(WORKERS_AVAILABILITY)}
						startIcon={<EditOutlinedIcon />}
						sx={{
							fontFamily: "Roboto",
							fontSize: "12px",
							padding: "4px 16px",
							color: "#2F4D8B",
							borderColor: "#2F4D8B",
							marginLeft: "16px",
							whiteSpace: "noWrap",
						}}
						variant='outlined'
					>
						{getLocalTotalAvailabilityHours(anyDateOfSelectedWeek) > 0
							? `${WORKERS_AVAILABILITY_TITLE} (${getLocalTotalAvailabilityHours(
									anyDateOfSelectedWeek
							  )} hours)`
							: WORKERS_AVAILABILITY_TITLE}
					</Button>
					<Button
						onClick={() => changePage(REQUEST_NEW_SHIFTS)}
						startIcon={<PersonAddAltOutlinedIcon />}
						sx={{
							fontFamily: "Roboto",
							fontSize: "12px",
							padding: "4px 16px",
							color: "#FFFFFF",
							borderColor: "#2F4D8B",
							marginLeft: "16px",
							whiteSpace: "noWrap",
						}}
						variant='contained'
					>
						{REQUEST_FLEX_WORKER_TITLE}
					</Button>
				</Box>
			</Box>
			<Box
				sx={{
					overflowY: "auto",
					scrollbarGutter: "stable",
					borderRadius: "0px 0px 15px 15px",
					boxShadow: "inset 0px -11px 8px -10px #CCC",
				}}
			>
				<Box
					sx={{
						position: "sticky",
						top: 0,
						boxShadow: "0px 11px 8px -10px rgba(204,204,204,0.5)",
						zIndex: 999,
					}}
				>
					<WeekHeaderRow
						allJobRoles={allJobRoles}
						datesArrayOfSelectedWeek={selectWeek(anyDateOfSelectedWeek)}
						selectedWC={selectedWC}
						setSelectedWC={setSelectedWC}
						workCenters={workCenters}
					/>
					<UnallocatedWorkersRow
						datesArrayOfSelectedWeek={selectWeek(anyDateOfSelectedWeek)}
					/>
					{generateScheduleStatus === "pending" && <LinearProgress />}
				</Box>
				<Box
					ref={boxRef}
					sx={{
						height: scheduleTableHeight,
					}}
				>
					{fetchShiftsForThisWeekStatus === "pending" ||
					fetchUnallocatedWorkersStatus === "pending" ||
					fetchAllWorkersStatus === "pending" ||
					assignWorkerStatus === "pending" ||
					generateScheduleStatus === "pending" ||
					fetchShiftsRequirementStatus === "pending" ||
					fetchAvailabilityStatus === "pending" ? (
						<Box
							sx={{
								height: scheduleTableHeight,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Loading />
						</Box>
					) : (
						<>
							{localJobs.map((job, index) => (
								<Box
									key={`job-week-row-${index}-${job.id}`}
									borderTop={index === 0 ? 0 : 1}
									borderColor={BORDER_COLOR}
								>
									<Row
										jobName={job.name}
										scheduledShifts={getShiftByJobId(job.id, localShifts)}
										datesArrayOfSelectedWeek={selectWeek(anyDateOfSelectedWeek)}
										setShiftIdForShiftDetailsDialog={(shiftId) =>
											setShiftIdForShiftDetailsDialog(shiftId)
										}
										openShiftDetailsDialog={() => {
											dispatch(resetRenotifyWorkers());
											setIsShiftDetailsDialogOpen(true);
										}}
									/>
								</Box>
							))}
						</>
					)}
				</Box>
			</Box>
			{(fetchShiftsError !== "" ||
				fetchUnallocatedWorkersError !== "" ||
				fetchShiftsRequirementError !== "" ||
				fetchAvailabilityError !== "" ||
				assignWorkerError !== "") && (
				<Snackbar
					open={
						fetchShiftsError !== "" ||
						fetchUnallocatedWorkersError !== "" ||
						fetchShiftsRequirementError !== "" ||
						fetchAvailabilityError !== "" ||
						assignWorkerError !== ""
					}
					autoHideDuration={2000}
					onClose={() => dispatch(resetShifts())}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='error'>
						{fetchShiftsError} {fetchUnallocatedWorkersError}{" "}
						{fetchShiftsRequirementError} {fetchAvailabilityError}{" "}
						{assignWorkerError}
					</MuiAlert>
				</Snackbar>
			)}
			{fetchWorkCentersError !== "" && (
				<Snackbar
					open={fetchWorkCentersError !== ""}
					autoHideDuration={2000}
					onClose={() => dispatch(resetWorkCenters())}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='error'>
						{fetchWorkCentersError}
					</MuiAlert>
				</Snackbar>
			)}
			{fetchAllWorkersError !== "" && (
				<Snackbar
					open={fetchAllWorkersError !== ""}
					autoHideDuration={2000}
					onClose={() => dispatch(resetAllWorkers())}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='error'>
						{fetchAllWorkersError}
					</MuiAlert>
				</Snackbar>
			)}
			{fetchJobsError !== "" && (
				<Snackbar
					open={fetchJobsError !== ""}
					autoHideDuration={2000}
					onClose={() => dispatch(resetJobs())}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='error'>
						{fetchJobsError}
					</MuiAlert>
				</Snackbar>
			)}
			{updateShiftsRequirementError !== "" && (
				<Snackbar
					open={updateShiftsRequirementError !== ""}
					autoHideDuration={2000}
					onClose={() => dispatch(resetUpdateShiftsRequirement())}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='error'>
						{updateShiftsRequirementError}
					</MuiAlert>
				</Snackbar>
			)}
			{renotifyWorkersErrorMessage !== "" && (
				<Snackbar
					open={renotifyWorkersErrorMessage !== ""}
					autoHideDuration={2000}
					onClose={() => dispatch(resetRenotifyWorkers())}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='error'>
						{renotifyWorkersErrorMessage}
					</MuiAlert>
				</Snackbar>
			)}
			{updateShiftsRequirementStatus === "fulfilled" && (
				<Snackbar
					open={updateShiftsRequirementStatus === "fulfilled"}
					autoHideDuration={1000}
					onClose={() => {
						try {
							dispatch(resetUpdateShiftsRequirement());
						} catch (e) {
							// Do nothing
						}
					}}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='success'>
						Shifts Requirement Updated Successfully
					</MuiAlert>
				</Snackbar>
			)}
			{renotifyWorkersStatus === "fulfilled" ? (
				<Snackbar
					open={renotifyWorkersStatus === "fulfilled"}
					autoHideDuration={3000}
					onClose={() => dispatch(resetRenotifyWorkers())}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='success'>
						Workers Notified Successfully.
					</MuiAlert>
				</Snackbar>
			) : null}
			{isShiftDetailsDialogOpen && shiftDetailsDialog}
		</Box>
	);
};

export default ScheduledShiftsTable;
