import { Box } from "@mui/material";
import React from "react";

import { useDayActivityController } from "../controllers/use-day-activity-controller";
import DailyActivityTable from "./ui/daily-activity-table";
import TableHeader from "./ui/table-header";

const DailyActivityReport = () => {
	const [
		{
			currentDate,
			filteredData,
			exportData,
			search,
			columnSelector,
			open,
			allFilters,
			selectedKPI,
			KPICount,
		},
		{
			setCurrentDate,
			setSearch,
			setColumnSelector,
			setFilterOpen,
			setAllFilters,
			setSelectedKPI,
		},
	] = useDayActivityController();
	return (
		<Box>
			<TableHeader
				date={currentDate}
				setDate={setCurrentDate}
				search={search}
				setSearch={setSearch}
				columnSelector={columnSelector}
				setColumnSelector={setColumnSelector}
				exportData={exportData}
				open={open}
				setFilterOpen={setFilterOpen}
			/>

			<DailyActivityTable
				data={filteredData}
				search={search}
				columnSelector={columnSelector}
				allFilters={allFilters}
				setAllFilters={setAllFilters}
				open={open}
				selectedKPI={selectedKPI}
				setSelectedKPI={setSelectedKPI}
				KPICount={KPICount}
			/>
		</Box>
	);
};

export default DailyActivityReport;
