/* eslint-disable simple-import-sort/imports */
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {Accordion, Box, Typography, styled} from "@mui/material";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {AccordionSummaryProps,} from "@mui/material/AccordionSummary";
import React from "react";
import CrossSkillLegends from "../../../../cross-skilling/ui/cross-skill-header/ui/cross-skill-ledgends";
import Highlight from "../../../../Highlight";
import useInsightWorkerDetailsByLevelController
  from "../../../controllers/use-insight-worker-details-levels-controller";
import BuildIcon from "@mui/icons-material/Build";
import {SkillLevel, interestedSkillModel,} from "../../../slices/insight-report-types";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import TextField from "@mui/material/TextField";

const WorkerInsightDetailsSkillTab = ({
  allSkillLevels,
}: {
  allSkillLevels: SkillLevel[];
}) => {
  const [
    { searchText, searchWorkerInsightDetailsData, expanded },
    { setSearchText, setExpanded },
  ]: any = useInsightWorkerDetailsByLevelController();

  const handleAccordianChange = (level: string) => {
    setExpanded((prevExpanded: string[]) => {
      if (prevExpanded.includes(level)) {
        return prevExpanded.filter(
          (accordionName: string) => accordionName !== level
        );
      } else {
        return [...prevExpanded, level];
      }
    });
  };

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    "&.Mui-expanded": {
      minHeight: "0px",
      marginTop: 10,
    },
    backgroundColor: "transparent",
    height: 30,
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },

    paddingLeft: 10,
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    marginLeft: 55,
  }));

  const StyledAccordion = styled(Accordion)(({ theme }) => ({
    borderRadius: 0, // Remove border radius
    boxShadow: "none", // Remove elevation
  }));

  const displaySkillsBySkillLevel = () => {
    const skillsBySkillLevels: any = {};

    allSkillLevels !== undefined &&
      allSkillLevels.length > 0 &&
      allSkillLevels?.map((skillLevel) => {
        skillsBySkillLevels[skillLevel.level] = [];
      });

    searchWorkerInsightDetailsData?.workerSkillsByLevel?.map(
      (skill: any, index: number) => {
        skillsBySkillLevels[skill.level]?.push(
          <Box key={`${skill.level}-${index}`} mb={1} fontWeight="normal">
            <Highlight searchText={searchText} value={skill.skillName} />
          </Box>
        );
      }
    );

    return Object.keys(skillsBySkillLevels)?.map((level: any) =>
      skillsBySkillLevels[level].length > 0 ? (
        <Box key={level} style={{ fontWeight: "bold" }}>
          <StyledAccordion
            expanded={searchText.length > 0 || expanded.includes(level)}
            onChange={() => handleAccordianChange(`${level}`)}
          >
            <AccordionSummary
              aria-controls={`${level}d-content`}
              id={`${level}-header`}
            >
              <Box sx={{ display: "flex", alignItems: "center", height: 10 }}>
                <CrossSkillLegends
                  isSkillLevelShownInTableHeader={true}
                  allSkillLevels={[allSkillLevels[level]]}
                />
                <Typography
                  style={{ fontSize: "14px" }}
                  ml={1}
                >{`(${skillsBySkillLevels[level].length})`}</Typography>
              </Box>
            </AccordionSummary>

            <AccordionDetails style={{ display: "grid" }}>
              {skillsBySkillLevels[level]}
            </AccordionDetails>
          </StyledAccordion>
        </Box>
      ) : null
    );
  };

  const displayInterestedSkills = () => {
    return (
      searchWorkerInsightDetailsData?.interestedSkills?.length !== 0 && (
        <Box key={"interestedSkill"}>
          <StyledAccordion
            expanded={
              searchText.length > 0 || expanded.includes("interested skills")
            }
            onChange={() => handleAccordianChange("interested skills")}
          >
            <AccordionSummary
              aria-controls={"interestedSkilld-content"}
              id={"interestedSkill-header"}
            >
              <Box sx={{ display: "flex", alignItems: "center", height: 10 }}>
                <Typography
                  ml={2}
                  style={{ font: "0.9rem", fontWeight: "500" }}
                >
                  Interested Skills
                </Typography>
                <Typography
                  style={{ fontSize: "14px" }}
                  ml={1}
                >{`(${searchWorkerInsightDetailsData?.interestedSkills?.length})`}</Typography>
              </Box>
            </AccordionSummary>

            <AccordionDetails style={{ display: "grid" }}>
              {searchWorkerInsightDetailsData?.interestedSkills?.map(
                (interestedSkill: interestedSkillModel) => {
                  return (
                    <Box
                      key={`interested-skill-${interestedSkill.skillId}`}
                      mb={1}
                      fontWeight="normal"
                    >
                      <Highlight
                        searchText={searchText}
                        value={interestedSkill.skillName}
                      />
                    </Box>
                  );
                }
              )}
            </AccordionDetails>
          </StyledAccordion>
        </Box>
      )
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: "10px",
      }}
    >
      <Box sx={{ padding: "8px 16px", width: "100%" }}>
        {(searchWorkerInsightDetailsData?.workerSkillsByLevel.length > 0 ||
          searchWorkerInsightDetailsData.interestedSkills.length > 0 ||
          searchText.length > 0) && (
          <TextField
            label="Search"
            placeholder="Skill Name"
            fullWidth
            variant="filled"
            onChange={(e) =>
              setSearchText(e.target.value.toLowerCase())
            }
            size="small"
            value={searchText}
            slotProps={{
              input: {
                endAdornment: searchText !== undefined &&
                    searchText.length > 0 && (
                        <CancelOutlinedIcon
                            sx={{ cursor: "pointer", color: "#92918f" }}
                            onClick={() => setSearchText("")}
                        />
                    ),
              },
            }}
            sx={{
              "& .MuiFilledInput-underline:before": {
                borderBottom: "none",
              },
              "& .MuiFilledInput-underline:after": {
                borderBottom: "none",
              },
              //paddingTop: "4px",
              marginBottom: "16px",
              // marginLeft: "24px",
            }}
          />
        )}
        {searchWorkerInsightDetailsData?.workerSkillsByLevel.length > 0 ||
        searchWorkerInsightDetailsData.interestedSkills.length > 0 ? (
          <>
            {displaySkillsBySkillLevel()}

            {displayInterestedSkills()}
          </>
        ) : (
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              mt: 10,
            }}
          >
            <BuildIcon sx={{ color: "#6c6d6e", fontSize: "40px" }} />
            No skills {searchText.length > 0 ? "found" : "available"}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default WorkerInsightDetailsSkillTab;
