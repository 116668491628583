import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import { Box, Checkbox, Popover, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface Column {
	field: string;
	checked: boolean;
	headerName: string;
}

interface ColumnSelectorProps {
	columns: Column[] | undefined;
	handleSelectColumns?: (checked: boolean, field: string) => void;
	disabledColumns?: string[];
}

const ColumnSelector: React.FC<ColumnSelectorProps> = ({
	columns,
	handleSelectColumns,
	disabledColumns = [],
}) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const isDrawerOpen = useSelector(
		(state: { navigationDrawer: { isDrawerOpen: boolean } }) =>
			state.navigationDrawer.isDrawerOpen
	);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	useEffect(() => {
		if (isDrawerOpen) {
			handleClose();
		}
	}, [isDrawerOpen]);

	return (
		<>
			<IconButton
				aria-label="worker-column-filter"
				sx={{
					backgroundColor: anchorEl ? "#2F4D8B0A" : "none",
					height: "48px",
					width: "48px",
					borderRadius: "5px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					cursor: "pointer",
					marginLeft: "8px",
					"&:hover": {
						backgroundColor: "#2F4D8B0A"
					}
				}}
				onClick={handleClick}
			>
				<ViewColumnOutlinedIcon
				//size={24}
				/>
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Box p={2}>
					{columns &&
						columns.map((column) => (
							<Box key={column.field} display='flex' alignItems='center'>
								<Checkbox
									checked={
										column.checked || disabledColumns.includes(column.field)
									}
									disabled={disabledColumns.includes(column.field)}
									onChange={(e) =>
										handleSelectColumns &&
										handleSelectColumns(e.target.checked, column.field)
									}
									inputProps={{ "aria-label": "controlled" }}
									sx={{
										"& .MuiSvgIcon-root": {
											color: disabledColumns.includes(column.field)
												? "#9e9e9e"
												: "",
										},
									}}
								/>
								<Typography sx={{ marginLeft: 1 }}>
									{column.headerName}
								</Typography>
							</Box>
						))}
				</Box>
			</Popover>
		</>
	);
};

export default ColumnSelector;
