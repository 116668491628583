import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import React from "react";

import Button from "../../../../../../ts/design/button";
import DialogWrapper from "../../../../../../ts/design/wilya-dialog";
import { REQUEST_NEW_SHIFTS_COLUMN } from "../../../constants/columns";
import { useStyles } from "../../../constants/styles";
import useScheduleController from "../../../controllers/schedule-controller";
import { ReviewCheckbox } from "./request-new-shifts-review-dialog-checkbox";
import { ReviewPublishingNewShiftsTable } from "./request-new-shifts-review-dialog-table";

export const RequestNewShiftsReviewDialog = ({ open, handleClose }) => {
	const classes = useStyles();
	const [
		{
			allowFlexibleWorkersForShiftsStatus,
			allowFlexibleWorkersForShiftsErrorMessage,
			weeklyDraftShifts,
			allJobRoles,
		},
		{ createFlexDraftShift },
	] = useScheduleController();
	const [amISure, setAmISure] = useState(false);
	const [shifts, setShifts] = useState([]);
	const [selectedShiftIds, setSelectedShiftIds] = useState([]);
	const handleShiftSelection = (shiftIds) => {
		setSelectedShiftIds(shiftIds);
	};

	useEffect(() => {
		const getJobRole = (id) => {
			try {
				const selectedJob = allJobRoles.filter((job) => job.id === id);
				return selectedJob[0];
			} catch (e) {
				return {};
			}
		};
		const getJobHours = (startDateTime, endDateTime) => {
			try {
				const duration =
					new Date(endDateTime).getTime() - new Date(startDateTime).getTime();
				let minutes = Math.floor((duration / (1000 * 60)) % 60),
					hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
				minutes = minutes < 10 ? "0" + minutes : minutes;

				return hours + " hours " + (minutes > 0 ? minutes + " minutes" : "");
			} catch (e) {
				return 8;
			}
		};
		let newShifts = [];
		weeklyDraftShifts.forEach((draftShift) => {
			let newShift = {
				...draftShift,
				jobRole: getJobRole(draftShift.jobId),
				hoursScheduled: getJobHours(
					draftShift.startDateTime,
					draftShift.endDateTime
				),
			};
			newShifts.push(newShift);
		});
		setShifts(newShifts);
	}, [weeklyDraftShifts, allJobRoles]);
	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby='add-new-shift'
			open={open}
			maxWidth={false}
		>
			<DialogWrapper
				dialogTitle='Review Publishing Shifts'
				dialogSubTitle='Select which shifts should be published. Workers will be notified.'
				dialogContent={
					<>
						{" "}
						<ReviewPublishingNewShiftsTable
							rows={shifts}
							columns={REQUEST_NEW_SHIFTS_COLUMN}
							handleShiftSelection={handleShiftSelection}
						/>
					</>
				}
				actionButtons={
					<>
						<ReviewCheckbox amISure={amISure} setAmISure={setAmISure} />
						{allowFlexibleWorkersForShiftsErrorMessage ? (
							<Typography color='secondary'>
								{allowFlexibleWorkersForShiftsErrorMessage}.
							</Typography>
						) : null}
						<Button
							color='primary'
							variant='text'
							onClick={handleClose}
							customStyles={{ marginRight: "16px" }}
						>
							Cancel
						</Button>
						<Button
							onClick={() => {
								createFlexDraftShift(selectedShiftIds);
								//dispatch(publishDraftShiftsAsync(state));
							}}
							color='primary'
							variant='contained'
							disabled={
								allowFlexibleWorkersForShiftsStatus === "pending" ||
								allowFlexibleWorkersForShiftsStatus === "error" ||
								amISure === false ||
								selectedShiftIds.length < 1
							}
						>
							{allowFlexibleWorkersForShiftsStatus === "pending" ? (
								<div className={classes.btnLabel}>
									<CircularProgress
										size={25}
										className={classes.progressIndicator}
									/>
									Publishing Shifts
								</div>
							) : (
								"Publish & Notify"
							)}
						</Button>
					</>
				}
			/>
		</Dialog>
	);
};
