import { Box } from "@mui/material";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

import { isTooDark } from "../../../utils/helpers";
import { TimeSlot } from "./styled-components";

const Availability = ({ data, timeSlotHeight, ...restProps }: any) => {
  const color = data.hexColor ? `#${data.hexColor}` : "#808080";
  const ratio = data.maxWorkers
    ? isTooDark(color)
      ? data.numberOfWorkers / data.maxWorkers
      : 0.2 + data.numberOfWorkers / data.maxWorkers / 1.25
    : 0;
  const backgroundColor = alpha(color, ratio);
  return (
    <TimeSlot {...restProps} height={timeSlotHeight}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          backgroundColor,
        }}
      >
        <Typography
          sx={{
            color: "black",
            textAlign: "center",
            fontSize: "1rem",
            fontFamily: "Roboto",
          }}
        >
          {data.numberOfWorkers ? data.numberOfWorkers : ""}
        </Typography>
      </Box>
    </TimeSlot>
  );
};

export default React.memo(Availability);
