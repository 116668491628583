import { makeStyles } from "@mui/styles";
import { CountryCode } from "libphonenumber-js/types";
import { isArray } from "lodash";
import MaterialUiPhoneNumber from "mui-phone-number";
import React, { useState } from "react";

import isValidPhoneNumberForCountry from "../../../../utils/helpers";

const useStyles = makeStyles(() => ({
	formRoot: {
		width: "100%",
	},
	formControlRoot: {
		width: "100%",
		//   backgroundColor: colors.light,
	},
	box: {
		height: "30px",
		width: "30px",
		padding: 0,
		backgroundColor: "red",
		position: "absolute",
		marginTop: "22px",
		zIndex: 999,
		opacity: 0,
	},
}));

interface PhoneTextFieldProps {
	values: { mobilePhoneNumber: string };
	setFieldValue: (field: string, value: any) => void;
	checkPhoneAlreadyExists: (value: string) => boolean;
}

const PhoneTextField: React.FC<PhoneTextFieldProps> = ({
	values,
	setFieldValue,
	checkPhoneAlreadyExists,
}) => {
	const classes = useStyles();
	const [alreadyExist, setAlreadyExist] = useState(false);
	//Initially set to true to not show any error in the beginning.
	const [isValid, setIsValid] = useState(true);
	return (
		<div style={{ width: "300px" }}>
			<div className={classes.box}></div>
			<MaterialUiPhoneNumber
				label='Phone Number'
				name='mobilePhoneNumber'
				value={values.mobilePhoneNumber}
				variant='filled'
				InputLabelProps={{
					shrink: true,
				}}
				fullWidth
				margin='dense'
				defaultCountry={"us"}
				onlyCountries={["us", "in", "om", "mx", "gb", "br"]}
				disableAreaCodes={true}
				onChange={(...phoneInfo): void => {
					try {
						const phoneInfoArray = phoneInfo as any[];
						const phoneNumber = phoneInfoArray[0];
						const { countryCode } = phoneInfoArray[1];
						setFieldValue("mobilePhoneNumber", String(phoneNumber));
						setIsValid(
							isValidPhoneNumberForCountry(
								phoneNumber,
								countryCode.toUpperCase()
							)
						);
						if (checkPhoneAlreadyExists(phoneNumber)) {
							setAlreadyExist(true);
						} else {
							setAlreadyExist(false);
						}
					} catch (e) {
						console.log("e", e);
						// Do nothing
					}
				}}
				error={!isValid || alreadyExist}
				// helperText={alreadyExist ? "Phone number already exists.":""}
				helperText={
					isValid
						? alreadyExist
							? "Phone Number already exists"
							: ""
						: "Phone Number is not valid"
				}
			></MaterialUiPhoneNumber>
		</div>
	);
};

export default PhoneTextField;
