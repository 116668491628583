import { TextField as Input } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const Field = styled((props) => (
	<Input InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
	"& .MuiFilledInput-root": {
		border: "0px",
		overflow: "hidden",
		borderRadius: 4,
		height: 50,
		backgroundColor: theme.palette.mode === "light" ? "#F8F8F8" : "#2b2b2b",
		transition: theme.transitions.create(["background-color"]),
		"&:hover": {
			backgroundColor: "#F8F8F8",
		},
		"&.Mui-focused": {
			backgroundColor: "#F8F8F8",
			border: "0px",
		},
	},
}));

const TextField = (props: any) => {
	return <Field {...props} />;
};

export default TextField;
