import React from "react";
import { useSelector } from "react-redux";

import { StoreType } from "@/ts/utils/types";

export const useWorkerActivityData = () => {
	const start = useSelector(
		(state: StoreType) => state.workerActivity.startMonthDate
	);

	const end = useSelector(
		(state: StoreType) => state.workerActivity.endMonthDate
	);

	const currentDate = useSelector(
		(state: StoreType) => state.workerActivity.currentDate
	);

	const data = useSelector((state: StoreType) => state.workerActivity.tempData);

	const monthlyActivityReportData = useSelector(
		(state: StoreType) => state.workerActivity.monthlyActivityReport.kpisData
	);

	const allMonthlyActivityReportData = useSelector(
		(state: StoreType) => state.workerActivity.monthlyActivityReport.allData
	);

	const monthlyActivityReportStatus = useSelector(
		(state: StoreType) => state.workerActivity.monthlyActivityReport.status
	);

	const dailyActivityReportData = useSelector(
		(state: StoreType) => state.workerActivity.dailyActivityReport.kpisData
	);
	const dailyActivityReportStatus = useSelector(
		(state: StoreType) => state.workerActivity.dailyActivityReport.status
	);

	const monthlyKPISummaries = useSelector(
		(state: StoreType) => state.workerActivity.monthlyKPISummaries
	);
	const dayKPISummaries = useSelector(
		(state: StoreType) => state.workerActivity.dayKPISummaries
	);

	const selectedKPI = useSelector(
		(state: StoreType) => state.workerActivity.selectedKPI
	);

	const allDailyActivityReportData = useSelector(
		(state: StoreType) => state.workerActivity.dailyActivityReport.allData
	);

	const selectedDayKPI = useSelector(
		(state: StoreType) => state.workerActivity.selectedDayKPI
	);

	const workerActivityFilters = useSelector(
		(state: StoreType) => state.workerActivity.workerActivityFilters
	);

	const workerActivityFiltersStatus = useSelector(
		(state: StoreType) => state.workerActivity.getWorkerActivityFilters.status
	);

	return {
		start,
		end,
		currentDate,
		data,
		monthlyActivityReportData,
		monthlyActivityReportStatus,
		dailyActivityReportData,
		dailyActivityReportStatus,
		monthlyKPISummaries,
		selectedKPI,
		allMonthlyActivityReportData,
		allDailyActivityReportData,
		selectedDayKPI,
		dayKPISummaries,
		workerActivityFilters,
		workerActivityFiltersStatus,
	};
};
