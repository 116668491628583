import { Typography } from "@mui/material";
import format from "date-fns/format";
import React from "react";

import { JobRole } from "../../../../ts/design/wilya-job-roles";

const dayInMonthComparator = (v1, v2) => v1.getDate() - v2.getDate();
export const REQUEST_NEW_SHIFTS_COLUMN = [
	{
		field: "shiftTime",
		headerName: "Shift Time",
		width: 220,
		valueGetter: (params) => {
			const dateRawString = params.row.startDateTime;
			return new Date(dateRawString);
		},
		sortComparator: dayInMonthComparator,
		renderCell: (params) => {
			const dateString = format(
				new Date(params.row.startDateTime),
				"EEE, MMMM do yyyy"
			);
			const startTime = format(new Date(params.row.startDateTime), "H:mm");
			const endTime = format(new Date(params.row.endDateTime), "H:mm");
			return (
				<div style={{ display: "block" }}>
					<Typography variant='subtitle2'>{dateString}</Typography>
					<Typography variant='body2'>
						{startTime} - {endTime}
					</Typography>
				</div>
			);
		},
	},
	{
		field: "jobRole",
		headerName: "Job Role",
		width: 240,
		renderCell: (params) => {
			return <JobRole jobRole={params.row.jobRole} />;
		},
	},
	{
		field: "numberOfWorkersNeeded",
		headerName: "Workers Required",
		width: 150,
	},
	{ field: "hoursScheduled", headerName: "Hours Scheduled", width: 150 },
	{ field: "createdByName", headerName: "Created by", width: 170 },
];
