import { Box, ListItemIcon } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import makeStyles from "@mui/styles/makeStyles";
import React, { SetStateAction } from "react";

import { Location } from "../slices/insight-report-types";

const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 1;

const MenuProps: any = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 200,
		},
	},
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "left",
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "left",
	},
	variant: "menu",
};

const useStyles = makeStyles(() => ({
	formControl: {
		margin: "8px",
		width: 300,
	},
	indeterminateColor: {
		color: "#f50057",
	},
	selectAllText: {
		fontWeight: 500,
	},
	selectedAll: {
		backgroundColor: "rgba(0, 0, 0, 0.08)",
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.08)",
		},
	},
	menuList: {
		paddingTop: "0 !important",
		paddingBottom: "0 !important",
	},
}));

interface IInsightReportLocationFilterProps {
	allLocations: Location[];
	setSelectedLocations: React.Dispatch<React.SetStateAction<number[]>>;
	selectedLocations: number[];
	setKPI?: React.Dispatch<React.SetStateAction<string>>;
}
const InsightReportLocationFilter: React.FC<
	IInsightReportLocationFilterProps
> = ({ allLocations, setSelectedLocations, selectedLocations, setKPI }) => {
	const isAllLocationsSelected =
		allLocations.length > 0 && selectedLocations.length === allLocations.length;

	const handleChange = (event: any) => {
		if (setKPI) {
			setKPI("");
		}
		const {
			target: { value },
		} = event;

		if (value[value.length - 1] === "all") {
			setSelectedLocations(
				selectedLocations.length === allLocations.length
					? []
					: allLocations.map((item: any) => item.id)
			);
		} else {
			setSelectedLocations(value);
		}
	};

	const getLocationNames = (selected: number[]) => {
		return selected.map((id) => {
			const location = allLocations.find((item) => item.id === id);
			return location?.name;
		});
	}

	const classes = useStyles();

	return (
		<Box sx={{ "& .MuiTextField-root": { width: "35ch" } }}>
			<FormControl sx={{ width: 242 }}>
				<InputLabel variant='filled' id='mutiple-select-label'>
					Locations
				</InputLabel>

				<Select
					labelId='demo-multiple-checkbox-label'
					id='demo-multiple-checkbox'
					multiple
					variant='filled'
					sx={{
						backgroundColor: "rgba(0, 0, 0, 0.04)",
						height: "48px",
						paddingTop: "5px",
						"& .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input":
							{
								paddingTop: "20px",
							},
					}}
					value={selectedLocations}
					onChange={handleChange}
					renderValue={(selected) =>
						selected.length > 2
							? selected.length + " Selected"
							: getLocationNames(selected).join(", ")
					}
					MenuProps={{ ...MenuProps, classes: { list: classes.menuList } }}
				>
					{/* Add all Menu Item by default to select all locations */}
					<MenuItem
						value='all'
						classes={{
							root: isAllLocationsSelected ? classes.selectedAll : "",
						}}
					>
						<ListItemIcon>
							<Checkbox
								classes={{ indeterminate: classes.indeterminateColor }}
								checked={isAllLocationsSelected}
								indeterminate={
									selectedLocations.length > 0 &&
									selectedLocations.length < allLocations.length
								}
							/>
						</ListItemIcon>

						<ListItemText
							classes={{ primary: classes.selectAllText }}
							primary='Select All'
						/>
					</MenuItem>

					{allLocations.map((location: any) => (
						<MenuItem key={location.id} value={location.id}>
							<ListItemIcon>
								<Checkbox
									sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}
									checked={selectedLocations.indexOf(location.id) > -1}
								/>
							</ListItemIcon>

							<ListItemText primary={location.name} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);
};

export default InsightReportLocationFilter;
