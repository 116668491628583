// TODO - Type 'any' needs to be fixed.
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import React from "react";
import { useDispatch } from "react-redux";

import { switchCalendarView } from "../../store/scheduler-slice";

const options = ["Week", "Day"];
const SwitchViews = ({ isWeekView = true }: { isWeekView: boolean }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef: any = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(isWeekView ? 0 : 1);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClick = () => {
    if (options[selectedIndex] === "Week") {
      dispatch(switchCalendarView(true));
    } else {
      dispatch(switchCalendarView(false));
    }
  };

  const handleMenuItemClick = (event: any, index: any) => {
    if (options[index] === "Week") {
      dispatch(switchCalendarView(true));
    } else {
      dispatch(switchCalendarView(false));
    }
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <ButtonGroup
      ref={anchorRef}
      variant="text"
      aria-label="outlined primary button group"
      sx={{
        display: "flex",
        justifyContent: "center",
        border: "1px solid rgba(0,0,0,0.08)",
        borderColor: "rgba(0,0,0,0.08) !important",
        marginLeft: "24px",
      }}
    >
      <Button
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "95px",
          height: "36px",
          paddingLeft: "12px",
          color: "rgba(0,0,0,0.6)",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "24px",
          letterSpacing: "0.4px",
          textTransform: "uppercase",
          border: "0px !important",
        }}
        onClick={handleToggle}
      >
        {options[selectedIndex]}
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Button>
      <Popper
        sx={{
          zIndex: 9999,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper
              sx={{
                borderRadius: "0px",
                marginTop: "1px",
                paddingTop: "0px !important",
                paddingBottom: "0px !important",
                width: "95px",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="day-week-split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      sx={{
                        paddingLeft: "8px",
                      }}
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      <Typography
                        sx={{
                          color: "rgba(0,0,0,0.6)",
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "24px",
                          letterSpacing: "0.4px",
                          textTransform: "uppercase",
                          marginLeft: "8px",
                        }}
                      >
                        {option}
                      </Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </ButtonGroup>
  );
};

export default SwitchViews;
