import Tab from "@mui/material/Tab";
import React from "react";

const a11yProps = (index: number) => {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
};

type TabProps = { label: string; key: string; onClick?: () => void };

export const tabPanelMapper = ({
	label,
	index,
	onClickHandlers,
}: {
	label: string;
	index: number;
	onClickHandlers: { [key: string]: () => void };
}) => {
	let tabProps: TabProps = {
		label: label,
		key: `label-${label}-${index}`,
	};
	if (onClickHandlers && onClickHandlers[label]) {
		const onClickHandler = onClickHandlers[label];
		tabProps = { ...tabProps, onClick: onClickHandler };
	}
	return <Tab {...tabProps} {...a11yProps(index)} />;
};
