import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React from "react";

import { generateUsername } from "../../../../js/utils/helpers";
import useUserData from "./use-user-data";

const UserDetails: React.FC = () => {
  const { company, email, firstName, lastName, isDrawerOpen } = useUserData();

  return (
    <Box
      px={2}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "64px",
      }}
    >
      <Box
        sx={{
          height: "32px",
          width: "32px ",
          borderRadius: "50%",
          background: "#00000099",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexShrink: 0,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "20px",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          {generateUsername(firstName, lastName)}
        </Typography>
      </Box>
      {isDrawerOpen && (
        <Box
          sx={{
            color: "#00000099",
            marginLeft: "20px",
          }}
        >
          <Tooltip title={email}>
            <Typography
              sx={{
                fontSize: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "180px",
                fontWeight: "400",
                alignSelf: "center",
                lineHeight: "20px",
                justifyContent: "center",
              }}
            >
              {email}
            </Typography>
          </Tooltip>
          <Tooltip title={company}>
            <Typography
              sx={{
                fontSize: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "180px",
                fontWeight: "400",
                alignSelf: "flex-end",
                lineHeight: "20px",
                justifyContent: "flex-end",
              }}
            >
              {company}
            </Typography>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default UserDetails;
