import {AccessTime} from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import format from "date-fns/format";
import React from "react";
import { useSelector } from "react-redux";

import { ShiftBySkillsTag } from "../../../../export/shift-scheduler";
import { JOB, SKILLS } from "../../../assets/constants";
import { getTotalWorkersCount, isTooDark } from "../../../utils/helpers";
import {minTimeSlotHeight} from "./constants";

type BoxRowProps = {
  icon: React.ReactNode;
  content: string;
  fontWeight?: number;
  fontColor: string;
  fontSize: number;
};
const BoxRow = ({
  icon,
  content,
  fontWeight,
  fontColor,
  fontSize,
}: BoxRowProps) => {
  return (
    <Box display="flex" alignItems="flex-start" pb={1}>
      {icon}
      <Box ml={1}>
        <Typography
          flexWrap={"wrap"}
          sx={{
            color: fontColor,
            whiteSpace: "normal",
            fontFamily: "Roboto",
            fontSize: `${fontSize}px`,
            lineHeight: `${fontSize + 8}px`,
            fontWeight: `${fontWeight || 400}`,
            wordBreak: "break-word",
            maxHeight: "20px",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {content}
        </Typography>
      </Box>
    </Box>
  );
};

export const Shift = ({ data, isLayer = false, infoAtBottom = false, timeSlotHeight = minTimeSlotHeight}: any): JSX.Element => {
  const shiftByType = data.jobId === 0 ? SKILLS : JOB;

  const textColor = useSelector((state: any) => {
    if (shiftByType === SKILLS) {
      // Shift by Skills
      return "rgba(0, 0, 0, 0.87)";
    } else {
      // Shift by Job
      const jobRole =
        state.supervisor?.filterData?.allJobRoles.length > 0 &&
        state.supervisor?.filterData?.allJobRoles?.filter(
          (jobRole: { id: string }) => jobRole.id === data.jobId
        );
      return isTooDark(`#${jobRole[0]?.hexColor}`) ? "#FFFFFF" : "#030303";
    }
  });

  const shiftJob = useSelector((state: any) => {
    if (shiftByType === JOB) {
      // Shift by Job
      const jobRole =
        state.supervisor?.filterData?.allJobRoles.length > 0 &&
        state.supervisor?.filterData?.allJobRoles?.find(
          (jobRole: { id: string }) => jobRole.id === data.jobId
        );
      if (jobRole) return jobRole;
      else return { name: "-", maxWorkers: 0 };
    } else {
      return { name: "-", maxWorkers: 0 };
    }
  });

  const shiftSkills = useSelector((state: any) => {
    if (shiftByType === SKILLS) {
      return data.skills ?? [];
    } else {
      return [];
    }
  });

  const locationName = useSelector((state: any) => {
    const workcenter = state.supervisor?.filterData?.allWorkcenters?.find(
      (workcenter: any) => workcenter.id === data.workCenterId
    );
    if (workcenter && Object.prototype.hasOwnProperty.call(workcenter, "locationId")) {
      const location = state.supervisor?.filterData?.allLocations?.find(
        (location: any) => location.id === workcenter.locationId
      );
      if (location) {
        return location.name;
      } else return "Location";
    }
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent={infoAtBottom ? "flex-end" : "flex-start"}
      width={1}
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        padding: "6px 2px",
        paddingBottom: infoAtBottom ? `${data.shiftEndHourIndex > 0 ?
            data.shiftEndHourIndex*timeSlotHeight - timeSlotHeight*(data.shiftBottom/100) - 3
           : 6}px`: "6px",
      }}
    >
        <></>
      {!data.isPublished ? (
          <Box display="flex" flexDirection="column">
              <Box
                  mb={1}
                  sx={{
                      padding: "1px 7px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: 1,
                      borderWidth: "1px",
                      borderColor: "rgba(0,0,0,0.12)",
                      borderRadius: "4px",
                      backgroundColor: "#FFF",
                      maxWidth: "68px",
                  }}
              >
                  <Typography
                      sx={{
                          fontFamily: "Roboto",
                          color: "rgba(0,0,0,0.6)",
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "20px",
                          letterSpacing: "0.4px",
                          whiteSpace: "noWrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                      }}
                  >
                      Draft
                  </Typography>
                  <EditOutlinedIcon
                      sx={{
                          color: "#424242",
                          fontSize: "16px",
                          marginLeft: "4px",
                      }}
                  />
              </Box>
              {shiftByType === JOB && (
                  <BoxRow
                      content={shiftJob.name ?? "JOB ROLE"}
                      icon={
                          <WorkHistoryOutlinedIcon
                              style={{
                                  color: "rgba(0,0,0,0.87)",
                                  fontSize: "16px",
                                  marginTop: "2px",
                              }}
                          />
                      }
                      fontWeight={500}
                      fontSize={14}
                      fontColor={"rgba(0,0,0,0.87)"}
                  />
              )}
              {shiftByType === SKILLS && (
                  <ShiftBySkillsTag
                      skills={shiftSkills}
                      fontSize={12}
                      iconSize={16}
                      iconColor={"rgba(0,0,0,0.87)"}
                  />
              )}
              <BoxRow
                  content={locationName ?? "Location"}
                  icon={
                      <LocationOnIcon
                          style={{
                              color: "rgba(0,0,0,0.87)",
                              fontSize: "16px",
                              marginTop: "2px",
                          }}
                      />
                  }
                  fontWeight={400}
                  fontSize={12}
                  fontColor={textColor}
              />
              {isLayer && (
                  <BoxRow
                      content={`${format(
                          new Date(data.startDateTime),
                          "h:mm a"
                      )} - ${format(new Date(data.endDateTime), "h:mm a")}`}
                      icon={
                          <AccessTime
                              style={{
                                  color: shiftByType === SKILLS ? "#757575" : textColor,
                                  fontSize: "16px",
                                  marginTop: "2px",
                              }}
                          />
                      }
                      fontWeight={400}
                      fontSize={12}
                      fontColor={textColor}
                  />
              )}
          </Box>
      ) : (
        <Tooltip
          title={
            data.hasWorkersFromOtherLocations
              ? "Workers from multiple locations bid for the shift"
              : null
          }
        >
            <Box display="flex"
                 flexDirection="column"
                 width={"100%"}
                 flex={infoAtBottom ? "unset" : 1}
                 justifyContent={infoAtBottom  ? "flex-end" : "space-between"}
            >
                <Box sx={{
                    flex: 1,
                    width: "100%"
                }}>
                  {shiftByType === JOB && (
                    <BoxRow
                      content={shiftJob.name ?? "JOB ROLE"}
                      icon={
                        <WorkHistoryOutlinedIcon
                          style={{
                            color: textColor,
                            fontSize: "16px",
                            marginTop: "2px",
                          }}
                        />
                      }
                      fontWeight={500}
                      fontSize={14}
                      fontColor={textColor}
                    />
                  )}
                  {shiftByType === SKILLS && (
                    <ShiftBySkillsTag
                      skills={shiftSkills}
                      iconSize={16}
                      fontSize={12}
                      iconColor={"#757575"}
                    />
                  )}
                  <BoxRow
                    content={`${data?.slotsFilled ?? "0"} / ${getTotalWorkersCount(
                      parseInt(data?.slotsFilled ?? "0"),
                      parseInt(data?.numberOfWorkersNeeded ?? "0")
                    )}`}
                    icon={
                      <ThumbUpOutlinedIcon
                        style={{
                          color: shiftByType === SKILLS ? "#757575" : textColor,
                          fontSize: "16px",
                          marginTop: "2px",
                        }}
                      />
                    }
                    fontWeight={400}
                    fontSize={12}
                    fontColor={textColor}
                  />
                  <BoxRow
                    content={locationName ?? "Location"}
                    icon={
                      <LocationOnIcon
                        style={{
                          color: shiftByType === SKILLS ? "#757575" : textColor,
                          fontSize: "16px",
                          marginTop: "2px",
                        }}
                      />
                    }
                    fontWeight={400}
                    fontSize={12}
                    fontColor={textColor}
                  />
                    <Box sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        {isLayer && (
                            <BoxRow
                                content={`${format(
                                    new Date(data.startDateTime),
                                    "h:mm a"
                                )} - ${format(new Date(data.endDateTime), "h:mm a")}`}
                                icon={
                                    <AccessTime
                                        style={{
                                            color: shiftByType === SKILLS ? "#757575" : textColor,
                                            fontSize: "16px",
                                            marginTop: "2px",
                                        }}
                                    />
                                }
                                fontWeight={400}
                                fontSize={12}
                                fontColor={textColor}
                            />
                        )}
                        {data.hasWorkersFromOtherLocations && isLayer && (
                            <GroupsOutlinedIcon
                                fontSize="small"
                                style={{
                                    whiteSpace: "normal",
                                    color: shiftByType === SKILLS ? "#757575" : textColor,
                                    fontSize: "16px",
                                    marginRight: "6px",
                                }}
                            />
                        )}
                    </Box>
                </Box>
                <Box>
                  {data.hasWorkersFromOtherLocations && !isLayer && (
                    <GroupsOutlinedIcon
                      fontSize="small"
                      style={{
                        whiteSpace: "normal",
                        color: shiftByType === SKILLS ? "#757575" : textColor,
                        fontSize: "16px",
                      }}
                    />
                  )}
                </Box>
            </Box>
        </Tooltip>
      )}
    </Box>
  );
};
