import { CheckOutlined } from "@mui/icons-material";
import { Box, Button, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import format from "date-fns/format";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { useDispatch } from "react-redux";

import LoadingButton from "../../../../../../ts/design/wilya-loading-button";
import useViewport from "../../../../../utils/useViewport";
import Loading from "../../../../Loading";
import { BORDER_COLOR } from "../../../constants/colors";
import { SCHEDULED_SHIFTS } from "../../../constants/pages";
import { FULL_TIME_WORKER } from "../../../constants/usertypes";
import useScheduleController from "../../../controllers/schedule-controller";
//import {fetchAllWorkers} from "../../../store/weekly-schedule-slice";
import { WeeklyScheduleDateRangePicker } from "../../weekly-schedule-date-range-picker";
import { AvailabilityHeaderRow } from "./workers-availability-table-header-row";
import { Row } from "./workers-availability-table-row";

const WorkersAvailabilityTable = ({ changePage, callAlgo }) => {
	const [
		{
			allWorkers,
			anyDateOfSelectedWeek,
			updateAvailabilityStatus,
			fetchAvailabilityStatus,
			fetchAllWorkersStatus,
		},
		{
			selectWeek,
			fetchAvailabilityForThisWeek,
			updateAvailabilityForThisWeek,
			resetUpdateAvailability,
		},
	] = useScheduleController();
	const { height } = useViewport();
	const boxRef = useRef();
	const dispatch = useDispatch();

	const [scheduleTableHeight, setScheduleTableHeight] = useState();
	const [loadTimestamp, setLoadTimestamp] = useState(0);

	const [sortedWorkers, setSortedWorkers] = useState([]);
	const [localWorkers, setLocalWorkers] = useState([]);

	const [availabilityChanged, setAvailabilityChanged] = useState(false);
	const [searchValue, setSearchValue] = useState("");

	const changeLocalWorkers = (workers) => {
		setLocalWorkers(workers);
	};

	useEffect(() => {
		setAvailabilityChanged(false);
	}, []);

	useEffect(() => {
		if (updateAvailabilityStatus === "fulfilled") {
			callAlgo(true);
		}
	}, [callAlgo, updateAvailabilityStatus]);

	useEffect(() => {
		let sortedWorkers = [...allWorkers];
		sortedWorkers = sortedWorkers.sort((a, b) =>
			(a.firstName + a.lastName).localeCompare(b.firstName + b.lastName)
		);
		sortedWorkers = sortedWorkers.filter(
			(worker) => worker.type === FULL_TIME_WORKER
		);
		setSortedWorkers(sortedWorkers);
		setLocalWorkers(sortedWorkers);
	}, [allWorkers]);

	useEffect(() => {
		if (loadTimestamp !== anyDateOfSelectedWeek.getTime()) {
			// console.log("Calling available API 3");
			setLoadTimestamp(anyDateOfSelectedWeek.getTime());
			const datesArrayOfSelectedWeek = selectWeek(anyDateOfSelectedWeek);
			// Fetch all requirement on this page load
			fetchAvailabilityForThisWeek({
				fromDate: format(datesArrayOfSelectedWeek[0], "yyyy-MM-dd"),
				toDate: format(
					datesArrayOfSelectedWeek[datesArrayOfSelectedWeek.length - 1],
					"yyyy-MM-dd"
				),
			});
		}
	}, [
		anyDateOfSelectedWeek,
		fetchAvailabilityForThisWeek,
		selectWeek,
		loadTimestamp,
	]);

	useEffect(() => {
		// This function calculate X and Y
		const getPosition = () => {
			if (boxRef) {
				try {
					const y = boxRef.current.offsetTop;
					setScheduleTableHeight(height - y - 92);
				} catch (error) {
					// Do nothing
				}
			}
		};
		getPosition();
	}, [height]);

	return (
		<Box>
			<Box
				sx={{
					height: "68px",
					borderBottom: 1,
					borderBottomWidth: 1,
					borderBottomColor: BORDER_COLOR,
					padding: "16px 24px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					zIndex: 9999,
				}}
			>
				<WeeklyScheduleDateRangePicker />
			</Box>
			<Box
				sx={{
					overflowY: "auto",
					scrollbarGutter: "stable",
				}}
			>
				<Box
					sx={{
						position: "sticky",
						top: 0,
						boxShadow: "0px 11px 8px -10px rgba(204,204,204,0.5)",
						zIndex: 999,
					}}
				>
					<AvailabilityHeaderRow
						searchValue={searchValue}
						setSearchValue={setSearchValue}
						sortedWorkers={sortedWorkers}
						changeLocalWorkers={changeLocalWorkers}
						datesArrayOfSelectedWeek={selectWeek(anyDateOfSelectedWeek)}
					/>
				</Box>
				<Box
					ref={boxRef}
					sx={{
						height: scheduleTableHeight,
					}}
				>
					{fetchAvailabilityStatus === "pending" ||
					fetchAllWorkersStatus === "pending" ? (
						<Box
							sx={{
								height: scheduleTableHeight,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Loading />
						</Box>
					) : (
						<>
							{localWorkers.map((worker, index) => (
								<Box
									key={`worker-week-row-${index}-${worker.id}`}
									borderTop={index !== 0 ? 1 : 0}
									borderBottom={index === localWorkers.length - 1 ? 1 : 0}
									borderColor={BORDER_COLOR}
								>
									<Row
										searchValue={searchValue}
										workerId={worker.id}
										workerName={`${worker.firstName} ${worker.lastName}`}
										datesArrayOfSelectedWeek={selectWeek(anyDateOfSelectedWeek)}
										availabilityChanged={() => setAvailabilityChanged(true)}
									/>
								</Box>
							))}
						</>
					)}
				</Box>
			</Box>
			<Box
				sx={{
					height: "72px",
					boxShadow: "0px -11px 8px -10px rgba(204,204,204,0.5)",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					<Box
						sx={{
							flex: 1,
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
							padding: "17px 24px",
							borderTop: 1,
							borderTopWidth: 1,
							borderTopColor: BORDER_COLOR,
							borderRadius: "0px 0px 15px 15px",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Button
								onClick={() => changePage(SCHEDULED_SHIFTS)}
								sx={{
									fontFamily: "Roboto",
									fontSize: "12px",
									padding: "4px 0px",
									color: "#2F4D8B",
								}}
							>
								Cancel
							</Button>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
								marginLeft: "16px",
							}}
						>
							<LoadingButton
								fullWidth
								onClick={() => {
									if (availabilityChanged) {
										updateAvailabilityForThisWeek();
									} else {
										changePage(SCHEDULED_SHIFTS);
									}
								}}
								loading={updateAvailabilityStatus === "pending"}
								loadingPosition='end'
								loadingIndicator={
									<CircularProgress
										size={16}
										sx={{ color: "rgba(0, 0, 0, 0.38)" }}
									/>
								}
								endIcon={<CheckOutlined />}
								sx={{
									fontFamily: "Roboto",
									fontSize: "12px",
									color: "#FFFFFF",
									borderColor: "#2F4D8B",
								}}
								variant='contained'
							>
								Done
							</LoadingButton>
						</Box>
					</Box>
				</Box>
			</Box>
			{updateAvailabilityStatus === "fulfilled" && (
				<Snackbar
					open={updateAvailabilityStatus === "fulfilled"}
					autoHideDuration={1000}
					onClose={() => {
						dispatch(resetUpdateAvailability());
						changePage(SCHEDULED_SHIFTS);
					}}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<MuiAlert elevation={6} variant='filled' severity='success'>
						Worker Availability Updated Successfully
					</MuiAlert>
				</Snackbar>
			)}
		</Box>
	);
};

export default WorkersAvailabilityTable;
