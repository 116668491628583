import {Grid2} from "@mui/material";
import isPast from "date-fns/isPast";
import isSameDay from "date-fns/isSameDay";
import React, {useMemo} from "react";

import {DayColumnHeader} from "./styled-components";

const MemoizedDayColumnHeader = (({ day, ...props }: { day: Date } & React.ComponentProps<typeof Grid2>) => {
    const styles = useMemo(
        () => ({
            backgroundColor: isSameDay(day, new Date())
                ? "#2F4D8B29"
                : isPast(day)
                    ? "rgba(0,0,0,0.02)"
                    : "#fff",
        }),
        [day]
    );

    return <DayColumnHeader style={styles} {...props}/>;
});

export default React.memo(MemoizedDayColumnHeader);