import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Calendar, NavDrawer } from "../../../export/drawer";
import { changeSelectedMenuItemId } from "../../../export/drawer";
import PageLayout from "../../design/wilya-page-layout";

const MiniDrawer = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		// To select Flex schedule menu item
		dispatch(changeSelectedMenuItemId(2));
	}, [dispatch]);

	return (
		<Box
			sx={{
				display: "flex",
				backgroundColor: "#F5F5F5",
				width: "100%",
				height: navigator.userAgent.indexOf("Edg") > -1 ? "100vh" : "100%",
				overflow: navigator.userAgent.indexOf("Edg") > -1 ? "hidden" : "auto",
			}}
		>
			<CssBaseline />
			<NavDrawer keepSettingOpen={false} />
			<PageLayout>
				<Calendar />
			</PageLayout>
		</Box>
	);
};

export default MiniDrawer;
