import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import React, { FC } from "react";

type props = {
	dialogTitle: string;
	dialogSubTitle: string;
	dialogContent: JSX.Element;
	actionButtons: JSX.Element;
	customStyles: object;
};

const Dialog: FC<props> = ({
	dialogContent,
	dialogTitle,
	dialogSubTitle,
	actionButtons,
	customStyles = {},
}: props) => {
	return (
		<Box sx={customStyles}>
			<DialogTitle
				id='customized-dialog-title'
				sx={{ padding: "45px 45px 30px" }}
			>
				<Box
					display='flex'
					flexDirection='row'
					justifyContent='space-between'
					alignItems='center'
				>
					<Box>
						<Typography variant='h5'>{dialogTitle}</Typography>
						{dialogSubTitle && (
							<Typography variant='subtitle1' sx={{ paddingTop: "6px" }}>
								{dialogSubTitle}
							</Typography>
						)}
					</Box>
				</Box>
			</DialogTitle>
			<DialogContent sx={{ padding: "45px 45px" }}>
				{dialogContent}
			</DialogContent>
			{actionButtons && (
				<DialogActions sx={{ padding: "0" }}>
					<Box
						display='flex'
						width='100%'
						justifyContent='flex-end'
						alignItems='center'
						sx={{ padding: "45px 45px" }}
					>
						{actionButtons}
					</Box>
				</DialogActions>
			)}
		</Box>
	);
};

export default Dialog;
