/* eslint-disable sort-imports */
import { format } from "date-fns";
import { differenceInMinutes } from "date-fns";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import {
	ALL,
	HOURS_COMPLETED,
	NO_CALL_NO_SHOWS,
	WORKER_CANCELLATIONS,
	WORKERS_WITH_ATLEAST_1_SHIFT,
	WORKERS_WITH_NO_SHIFTS,
} from "../../../assets/constants";
import { formatDate } from "../../../utils/helpers";
import { Filter } from "../../filters/filter-accordion/types";
import { FilterContext } from "../../filters/filter-context";
import {
	getMonthlyActivityReport,
	getWorkerActivityFilters,
} from "../store/worker-activity-slice";
import {
	ColumnSelector,
	Metrics,
	Shift,
	Skill,
	Worker,
} from "../store/worker-activity-slice-types";
import { useWorkerActivityData } from "./use-worker-activity-data";

export const useMonthActivityController = () => {
	const {
		start,
		end,
		monthlyActivityReportData,
		workerActivityFilters,
		workerActivityFiltersStatus,
		allMonthlyActivityReportData,
	} = useWorkerActivityData();

	const [columnSelector, setColumnSelector] =
		useState<ColumnSelector[]>(DEFAULT_COLUMNS);
	const [date, setDate] = useState<[string, string]>([start, end]);
	const [search, setSearch] = useState<string>("");
	const [allFilters, setAllFilters] = useState<Filter[]>([]);
	const [selectedKPI, setSelectedKPI] = useState(ALL);
	const [KPICount, setKPICount] = useState({
		"WORKERS WITH AT LEAST 1 SHIFT": { count: 0, percentage: 0 },
		"WORKERS WITH NO SHIFTS": { count: 0, percentage: 0 },
		"HOURS COMPLETED": { count: 0, percentage: 0 },
		"NO CALL NO SHOWS": { count: 0, percentage: 0 },
		"WORKER CANCELLATIONS": { count: 0, percentage: 0 },
		ALL: { count: 0, percentage: 100 },
	});
	const dispatch = useDispatch();
	const filterContext = useContext(FilterContext);
	if (!filterContext) {
		throw new Error("FilterAccordion must be used within a FilterProvider");
	}
	const { appliedFilters, open, setFilterOpen } = filterContext;

	const searchTerm = useMemo(() => search.toLowerCase(), [search]);

	const mapWorkerToShiftData = (item: Worker) => {
		if (item.shifts.length > 0) {
			return item.shifts.map((shift: Shift) => ({
				id: `${item.workerId}-${shift.shiftId}`,
				workerId: item.workerId,
				workerName: `${item.firstName} ${item.lastName}`,
				isShiftAvailable: true,
				...shift,
			}));
		}

		return [
			{
				id: `${item.workerId}`,
				workerId: item.workerId,
				workerName: `${item.firstName} ${item.lastName}`,
				isShiftAvailable: false,
				...DEFAULT_SHIFT,
			},
		];
	};

	// Get unique worker IDs from shift data
	const getUniqueWorkerIds = (shiftData: any[]) => {
		return [...new Set(shiftData.map((item) => item.workerId))];
	};

	const tableData = useMemo(() => {
		if (selectedKPI === NO_CALL_NO_SHOWS) {
			const workersWithShifts = allMonthlyActivityReportData.filter(
				(item) =>
					item.shifts.length > 0 &&
					item.shifts.some(
						(shift) => shift.attendanceStatus.toLowerCase() === "a"
					)
			);
			const finalWorkers = workersWithShifts.map((item) => ({
				...item,
				shifts: item.shifts.filter(
					(shift) => shift.attendanceStatus.toLowerCase() === "a"
				),
			}));
			return finalWorkers.flatMap(mapWorkerToShiftData);
		}
		if (selectedKPI === WORKERS_WITH_NO_SHIFTS) {
			return allMonthlyActivityReportData
				.filter((worker: Worker) => worker.shifts.length === 0)
				.flatMap(mapWorkerToShiftData);
		}
		if (selectedKPI === WORKERS_WITH_ATLEAST_1_SHIFT) {
			return allMonthlyActivityReportData
				.filter((worker: Worker) => worker.shifts.length > 0)
				.flatMap(mapWorkerToShiftData);
		}
		if (selectedKPI === HOURS_COMPLETED) {
			const workersWithShifts = allMonthlyActivityReportData.filter(
				(item) => item.shifts.length > 0
			);
			const finalWorkers = workersWithShifts.map((item) => ({
				...item,
				shifts: item.shifts.filter(
					(shift) => shift.attendanceStatus.toLowerCase() !== "a"
				),
			}));
			return finalWorkers.flatMap(mapWorkerToShiftData);
		}
		if (selectedKPI === WORKER_CANCELLATIONS) {
			const workersWithShifts = allMonthlyActivityReportData.filter(
				(item) =>
					item.shifts.length > 0 &&
					item.shifts.some(
						(shift) => shift.attendanceStatus.toLowerCase() === "cancelled"
					)
			);
			const finalWorkers = workersWithShifts.map((item) => ({
				...item,
				shifts: item.shifts.filter(
					(shift) => shift.attendanceStatus.toLowerCase() === "cancelled"
				),
			}));
			return finalWorkers.flatMap(mapWorkerToShiftData);
		}
		return allMonthlyActivityReportData.flatMap(mapWorkerToShiftData);
	}, [allMonthlyActivityReportData, selectedKPI]);

	const getSearchableFields = (
		item: Shift & { workerName: string; isShiftAvailable: boolean }
	) => {
		const baseFields = [
			item.workerName,
			item.shiftLocationName,
			item.shiftWorkcenterName,
			item.publishedByName,
			item.jobName,
			item.skills
				? item.skills.map((skill: Skill) => skill.skillName).join(", ")
				: "",
			statusObj[item.attendanceStatus?.toLowerCase()]?.title || "",
			item.supervisorComments,
		];

		if (item.isShiftAvailable) {
			const shiftDate = formatDate({
				dateStr: item.shiftStartDateTime,
				formatType: "NUMERIC_DATE",
			});
			const shiftTime = formatDate({
				dateStr: item.shiftStartDateTime,
				formatType: "TIME_RANGE",
				endDateStr: item.shiftEndDateTime,
			});
			return [...baseFields, shiftTime, shiftDate];
		}

		return [...baseFields, "", ""];
	};

	const filteredData = useMemo(() => {
		let filteredResult = tableData;
		if (appliedFilters.length > 0) {
			filteredResult = filteredResult.filter((item: Shift) => {
				return appliedFilters.every((filter) => {
					switch (filter.type) {
						case "Shift Location":
							return filter.data.some(
								(location) => location?.name === item?.shiftLocationName
							);
						case "Shift Workcenter":
							return filter.data.some(
								(workCenter) => workCenter?.name === item?.shiftWorkcenterName
							);
						case "Published By":
							return filter.data.some(
								(manager) => manager?.name === item?.publishedByName
							);
						case "Status":
							return filter.data.some(
								(status) =>
									status?.name ===
									statusObj[item?.attendanceStatus as keyof typeof statusObj]
										?.title
							);
						default:
							return true;
					}
				});
			});
		}
		return filteredResult.filter((item) =>
			getSearchableFields(item)
				.map((field) => (field ?? "").toLowerCase())
				.some((field) => field.includes(searchTerm))
		);
	}, [tableData, searchTerm, appliedFilters]);

	// Calculate KPI counts based on the filtered data
	const calculateKPICounts = useCallback((data: any[]) => {
		// Get unique workers from the filtered data
		const uniqueWorkerIds = getUniqueWorkerIds(data);
		const totalWorkers = uniqueWorkerIds.length;
		const totalShifts = data.filter((item) => item.isShiftAvailable).length;
		const totalHours = data.reduce((acc, item) => {
			if (
				item.isShiftAvailable &&
				item.shiftStartDateTime &&
				item.shiftEndDateTime
			) {
				const hours = Math.round(
					Math.abs(
						differenceInMinutes(
							new Date(item.shiftEndDateTime),
							new Date(item.shiftStartDateTime)
						)
					) / 60
				);
				acc += hours;
			}
			return acc;
		}, 0);

		// Group shifts by worker ID
		const shiftsByWorker = data.reduce((acc, item) => {
			if (!acc[item.workerId]) {
				acc[item.workerId] = [];
			}
			if (item.isShiftAvailable) {
				acc[item.workerId].push(item);
			}
			return acc;
		}, {});

		// Calculate metrics
		let workersWithShifts = 0;
		let hoursCompleted = 0;
		let noCallNoShows = 0;
		let workerCancelled = 0;

		Object.keys(shiftsByWorker).forEach((workerId) => {
			const workerShifts = shiftsByWorker[workerId];

			if (workerShifts.length > 0) {
				workersWithShifts++;

				workerShifts.forEach((shift: any) => {
					if (shift.shiftStatus?.toLowerCase() === "c") {
						workerCancelled++;
					} else if (shift.attendanceStatus?.toLowerCase() === "a") {
						noCallNoShows++;
					} else if (shift.shiftStartDateTime && shift.shiftEndDateTime) {
						const hours = Math.round(
							Math.abs(
								differenceInMinutes(
									new Date(shift.shiftEndDateTime),
									new Date(shift.shiftStartDateTime)
								)
							) / 60
						);
						hoursCompleted += hours;
					}
				});
			}
		});

		const workersWithNoShifts = totalWorkers - workersWithShifts;

		return {
			"WORKERS WITH AT LEAST 1 SHIFT": {
				count: workersWithShifts,
				percentage:
					totalWorkers > 0
						? Math.round((workersWithShifts / totalWorkers) * 100)
						: 0,
			},
			"WORKERS WITH NO SHIFTS": {
				count: workersWithNoShifts,
				percentage:
					totalWorkers > 0
						? Math.round((workersWithNoShifts / totalWorkers) * 100)
						: 0,
			},
			"HOURS COMPLETED": {
				count: hoursCompleted,
				percentage:
					hoursCompleted > 0
						? Math.round((hoursCompleted / totalHours) * 100)
						: 0,
			},
			"NO CALL NO SHOWS": {
				count: noCallNoShows,
				percentage:
					noCallNoShows > 0
						? Math.round((noCallNoShows / totalShifts) * 100)
						: 0,
			},
			"WORKER CANCELLATIONS": {
				count: workerCancelled,
				percentage:
					workerCancelled > 0
						? Math.round((workerCancelled / totalShifts) * 100)
						: 0,
			},
			ALL: {
				count: totalWorkers,
				percentage: 100,
			},
		};
	}, []);

	useEffect(() => {
		if (date[0] && date[1]) {
			setSelectedKPI(ALL);
			dispatch(
				getMonthlyActivityReport({ startDate: date[0], endDate: date[1] })
			);
		}
	}, [date, dispatch]);

	useEffect(() => {
		dispatch(getWorkerActivityFilters());
	}, [dispatch]);

	// Update KPI count whenever filtered data changes
	useEffect(() => {
		const newKPICount = calculateKPICounts(filteredData);
		setKPICount(newKPICount);
	}, [filteredData, calculateKPICounts]);

	useEffect(() => {
		if (workerActivityFiltersStatus === "fulfilled") {
			const newFilters: Filter[] = [];
			if (workerActivityFilters?.attendanceStatuses?.length > 0) {
				newFilters.push({
					id: 1,
					name: "Status",
					data: workerActivityFilters.attendanceStatuses,
					reducedData: workerActivityFilters.attendanceStatuses,
					searchedData: workerActivityFilters.attendanceStatuses,
				});
			}
			if (workerActivityFilters?.locations?.length > 0) {
				newFilters.push({
					id: 2,
					name: "Shift Location",
					data: workerActivityFilters.locations,
					reducedData: workerActivityFilters.locations,
					searchedData: workerActivityFilters.locations,
				});
			}
			if (workerActivityFilters?.workCenters?.length > 0) {
				newFilters.push({
					id: 3,
					name: "Shift Workcenter",
					data: workerActivityFilters.workCenters,
					reducedData: workerActivityFilters.workCenters,
					searchedData: workerActivityFilters.workCenters,
				});
			}
			if (workerActivityFilters?.managers?.length > 0) {
				newFilters.push({
					id: 4,
					name: "Published By",
					data: workerActivityFilters.managers,
					reducedData: workerActivityFilters.managers,
					searchedData: workerActivityFilters.managers,
				});
			}
			setAllFilters(newFilters);
		}
	}, [workerActivityFilters, workerActivityFiltersStatus]);

	const exportData = () => {
		const data = filteredData.map((item) => {
			const baseExport = {
				"Worker Name": item.workerName,
				"Shift Date": "-",
				"Shift Time": "-",
				"Shift Location": "-",
				Workcenter: "-",
				"Published By": "-",
				"Job Role/Skill": "-",
				Status: "-",
				"Supervisor Comments": "-",
			};

			if (!item.isShiftAvailable) return baseExport;

			const shiftTime = formatDate({
				dateStr: item.shiftStartDateTime,
				formatType: "TIME_RANGE",
				endDateStr: item.shiftEndDateTime,
			});

			return {
				...baseExport,
				"Shift Date": formatDate({
					dateStr: item.shiftStartDateTime,
					formatType: "NUMERIC_DATE",
				}),
				"Shift Time": shiftTime,
				"Shift Location": item.shiftLocationName,
				Workcenter: item.shiftWorkcenterName,
				"Published By": item.publishedByName,
				"Job Role/Skill":
					item.jobName ||
					item.skills?.map((skill) => skill.skillName).join(", ") ||
					"-",
				Status: statusObj[item.attendanceStatus?.toLowerCase()]?.title || "-",
				"Supervisor Comments": item.supervisorComments || "-",
			};
		});
		// sort by worker name
		data.sort((a, b) => a["Worker Name"].localeCompare(b["Worker Name"]));
		return data;
	};

	return [
		{
			date,
			exportData,
			filteredData,
			search,
			columnSelector,
			open,
			allFilters,
			selectedKPI,
			KPICount,
		},
		{
			setDate,
			setSearch,
			setColumnSelector,
			setFilterOpen,
			setAllFilters,
			setSelectedKPI,
		},
	] as const;
};

const DEFAULT_COLUMNS: ColumnSelector[] = [
	{ headerName: "Worker Name", field: "workerName", checked: true },
	{ headerName: "Shift Start Date", field: "shiftStartDate", checked: true },
	{ headerName: "Shift Hours", field: "shiftHours", checked: true },
	{ headerName: "Shift Location", field: "shiftLocation", checked: true },
	{ headerName: "Workcenter", field: "workcenter", checked: true },
	{ headerName: "Published By", field: "publishedByName", checked: true },
	{ headerName: "Job Role/Skill", field: "jobRoleSkills", checked: true },
	{ headerName: "Status", field: "status", checked: true },
	{
		headerName: "Supervisor Comments",
		field: "supervisorComments",
		checked: true,
	},
];

const DEFAULT_SHIFT: Shift = {
	shiftId: "",
	shiftStartDateTime: "",
	shiftEndDateTime: "",
	shiftLocationName: "",
	shiftWorkcenterName: "",
	publishedByName: "",
	jobName: "",
	skills: [],
	attendanceStatus: "",
	supervisorComments: "",
	shiftStatus: "",
};

interface IStatusObjType {
	[key: string]: {
		title: string;
	};
}

const statusObj: IStatusObjType = {
	p: {
		title: "Present",
	},
	a: {
		title: "No Show",
	},
	t: {
		title: "Tardy",
	},
	e: {
		title: "Excused Absence",
	},
	x: {
		title: "Excused Tardy",
	},
	cancelled: {
		title: "Cancelled",
	},
};
