import { WarningAmber } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BlockIcon from "@mui/icons-material/Block";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	MenuItem,
	Radio,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { trim } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DUMMY_PRIORITIES_ARRAY } from "../../../../../../assets/constants";
import { colors } from "../../../../../../config/colors";
import LoadingButton from "../../../../../../design/wilya-loading-button";
import { Priority } from "../../../../../../utils/types";
import { saveSkillPriorities } from "../../../../store/gat-admin-slice";
import { colors as priorityColors } from "./assets/colors";

interface SkillWeightConfigDialogProps {
	onClose: () => void;
	handleSave: () => void;
	openCrossSkillingSkillPrioritiesDialog: boolean;
	companyId: number;
}

const DEFAULT_PRIORITY_TOOLTIP =
	"All skills will be assigned this level by default.";

const SkillPriorityConfigDialog: React.FC<SkillWeightConfigDialogProps> = ({
	onClose,
	openCrossSkillingSkillPrioritiesDialog,
	companyId,
	handleSave,
}) => {
	const dispatch = useDispatch();
	const existingSkillPriorities: Priority[] = useSelector(
		(state: any) => state.gatAdminConfiguration.skillPriorities
	);
	const saveSkillPrioritiesStatus = useSelector(
		(state: any) => state.gatAdminConfiguration.saveSkillPriorities.status
	);
	const [priorityCount, setPriorityCount] = useState(1);
	const [skillPriorities, setSkillPriorities] = useState<Priority[]>([
		{
			id: 1,
			code: "",
			name: "",
			description: "",
			multiplier: 1,
			isDefault: true,
			hexColor: "",
		},
	]);
	const [skillPrioritiesToDelete, setSkillPrioritiesToDelete] = useState<
		number[]
	>([]);
	const maxSkillPriorities = 5;

	useEffect(() => {
		if (openCrossSkillingSkillPrioritiesDialog) {
			if (existingSkillPriorities) {
				setSkillPriorities(existingSkillPriorities);
			} else {
				setSkillPriorities(DUMMY_PRIORITIES_ARRAY);
			}
			setSkillPrioritiesToDelete([]);
		}
	}, [existingSkillPriorities, openCrossSkillingSkillPrioritiesDialog]);

	useEffect(() => {
		return () => {
			onClose();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleAddSkillPriority = () => {
		if (
			skillPriorities.length - skillPrioritiesToDelete.length <
			maxSkillPriorities
		) {
			const newSkillPriorities = [
				...skillPriorities,
				{
					id: -(priorityCount + 1),
					code: "",
					name: "",
					description: "",
					multiplier: 1,
					isDefault: false,
				},
			];
			setPriorityCount((prevState) => prevState + 1);
			setSkillPriorities(newSkillPriorities);
		}
	};

	const handleRemoveSkillPriority = (id: number) => {
		if (skillPriorities.length > 1 && id > 0) {
			setSkillPrioritiesToDelete((prevState) => [...prevState, id]);
		} else {
			setSkillPriorities((prevState) =>
				prevState.filter((priority) => priority.id !== id)
			);
		}
	};

	const handleChange = (
		id: number,
		field: keyof Priority,
		value: string | number | boolean
	) => {
		const newSkillPriorities = skillPriorities.map((priority) =>
			priority.id === id ? { ...priority, [field]: value } : priority
		);
		setSkillPriorities(newSkillPriorities);
	};

	const handleDefaultChange = (id: number) => {
		const newSkillPriorities = skillPriorities.map((priority) =>
			priority.id === id
				? { ...priority, isDefault: true }
				: { ...priority, isDefault: false }
		);
		setSkillPriorities(newSkillPriorities);
	};

	const handlePrioritySave = () => {
		try {
			const skillPrioritiesToSave = skillPriorities
				.filter(
					(priority) =>
						trim(priority.code) !== "" &&
						trim(priority.name) !== "" &&
						trim(priority.description) !== "" &&
						priority.multiplier > 0 &&
						priority.id <= 0
				)
				.map((priority) => ({
					companyId: companyId,
					code: priority.code,
					name: priority.name,
					description: priority.description,
					multiplier: priority.multiplier,
					isDefault: priority.isDefault,
					hexColor: priority.hexColor ?? "",
				}));
			const skillPrioritiesToUpdate = skillPriorities
				.filter(
					(priority) =>
						!skillPrioritiesToDelete.includes(priority.id) &&
						trim(priority.code) !== "" &&
						trim(priority.name) !== "" &&
						trim(priority.description) !== "" &&
						priority.multiplier > 0 &&
						priority.id > 0
				)
				.map((priority) => ({
					id: priority.id,
					companyId: companyId,
					code: priority.code,
					name: priority.name,
					description: priority.description,
					multiplier: priority.multiplier,
					isDefault: priority.isDefault,
					hexColor: priority.hexColor ?? "",
				}));
			handleSave();
			dispatch(
				saveSkillPriorities({
					companyId,
					skillPrioritiesToSave,
					skillPrioritiesToUpdate,
					skillPrioritiesToDelete,
				})
			);
		} catch (e) {
			// Do nothing
		}
	};

	const isSaveDisabled = () => {
		const validatedFields = skillPriorities.some(
			(priority) =>
				trim(priority.code) === "" ||
				trim(priority.name) === "" ||
				trim(priority.description) === "" ||
				Number(priority.multiplier) <= 0 ||
				validateMultiplier(priority.multiplier) ||
				validateUniqueness("code", priority.code) ||
				validateUniqueness("name", priority.name) ||
				validateMinCharacters(priority.name) ||
				validateMinCharacters(priority.description)
		);
		const isSame =
			JSON.stringify(existingSkillPriorities) ===
			JSON.stringify(skillPriorities);
		const isDeleting = skillPrioritiesToDelete.length > 0;
		return validatedFields || (isSame && !isDeleting);
	};

	const validateMultiplier = (multiplier: number) => {
		try {
			let gotError = false;
			if (multiplier <= 0) {
				// Multiplier can not be 0 or negative
				gotError = true;
			}
			if (multiplier.toString().split(".")[1]?.length > 2) {
				// Multiplier can not have more than 2 decimal places
				gotError = true;
			}
			if (multiplier.toString().split(".")[0]?.length > 3) {
				// Multiplier can not have more than 3 digits before decimal
				gotError = true;
			}
			if (
				skillPriorities
					.filter((priority) => !skillPrioritiesToDelete.includes(priority.id))
					.filter((priority) => priority.multiplier === multiplier).length > 1
			) {
				// Multiplier should be unique
				gotError = true;
			}
			return gotError;
		} catch (e) {
			return true;
		}
	};

	const validateUniqueness = (
		field: keyof Priority,
		value: string,
		isNumber = false
	) => {
		try {
			let gotError = false;
			const skillPrioritiesToCheckFrom = skillPriorities.filter(
				(priority) => !skillPrioritiesToDelete.includes(priority.id)
			);
			if (
				skillPrioritiesToCheckFrom.filter((priority) =>
					isNumber
						? priority[field] === Number(value)
						: priority[field] === value
				).length > 1
			) {
				// Field should be unique
				gotError = true;
			}
			return gotError;
		} catch (e) {
			return true;
		}
	};

	const validateMinCharacters = (value: string) => {
		try {
			let gotError = false;
			if (value.length < 3) {
				// Field should have at least 3 characters
				gotError = true;
			}
			return gotError;
		} catch (e) {
			return true;
		}
	};

	const canDelete = (selectedPriority: Priority) => {
		try {
			let canDelete = true;
			// Do not allow deletion of priority having lowest multiplier
			if (
				selectedPriority.id > 0 &&
				selectedPriority.multiplier ===
					Math.min(
						...skillPriorities
							.filter(
								(priority) => !skillPrioritiesToDelete.includes(priority.id)
							)
							.map((priority) => priority.multiplier)
					)
			) {
				canDelete = false;
			}
			return canDelete;
		} catch (e) {
			return false;
		}
	};

	return (
		<Dialog
			onClose={onClose}
			open={openCrossSkillingSkillPrioritiesDialog}
			maxWidth={false}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Box sx={{ width: "930px", maxWidth: "100%" }}>
				<DialogTitle
					id='skill-priority-configuration-dialog-title'
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "24px",
							fontWeight: 500,
							color: "rgba(0, 0, 0, 0.87)",
							paddingTop: "29px",
							paddingLeft: "8px",
							paddingRight: "8px",
						}}
					>
						Skill Priority Configuration
					</Typography>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "12px",
							fontWeight: 400,
							color: "rgba(0, 0, 0, 0.6)",
							paddingLeft: "8px",
							paddingRight: "8px",
							paddingBottom: "12px",
						}}
					>
						Configure up to 5 levels of Skill Priority.
					</Typography>
				</DialogTitle>
				<Divider />
				{skillPrioritiesToDelete.length > 0 && (
					<Box
						sx={{
							width: "100%",
							backgroundColor: colors.infoBoxBackground,
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "flex-start",
							padding: "14px 16px",
						}}
					>
						<WarningAmber
							sx={{
								color: colors.warningColor,
							}}
						/>
						<Typography
							sx={{
								color: colors.errorText,
								fontFamily: "Roboto",
								fontSize: "14px",
								fontWeight: 400,
								marginLeft: "12px",
							}}
						>
							Deleting priorities from the existing skill priorities will result
							in data loss. All remaining skill priorities will be downgraded to
							the highest remaining priority based on the multiplier.
						</Typography>
					</Box>
				)}
				<DialogContent
					sx={{
						paddingTop: "24px",
						paddingBottom: "24px",
						paddingLeft: "48px",
						paddingRight: "48px",
					}}
				>
					<Box sx={{ overflowX: "auto" }}>
						<Grid container spacing={2} sx={{ minWidth: 700 }}>
							{/* Sticky Header Row */}
							<Grid
								item
								xs={12}
								sx={{
									marginBottom: "8px",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										width: "100%",
										position: "sticky",
										top: "0",
										backgroundColor: "#ffffff", // Adjust as per your design
										zIndex: 1, // Ensure it stays above content
										borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
										paddingTop: "16px",
										paddingBottom: "16px",
									}}
								>
									<Typography
										sx={{
											fontFamily: "Roboto",
											fontWeight: 500,
											fontSize: "14px",
											color: "rgba(0, 0, 0, 0.87)",
											width: "10%",
											paddingLeft: "4px",
										}}
									>
										Code
									</Typography>
									<Typography
										sx={{
											fontFamily: "Roboto",
											fontWeight: 500,
											fontSize: "14px",
											color: "rgba(0, 0, 0, 0.87)",
											width: "10%",
											marginLeft: "8px",
											paddingLeft: "8px",
										}}
									>
										Color
									</Typography>
									<Typography
										sx={{
											fontFamily: "Roboto",
											fontWeight: 500,
											fontSize: "14px",
											color: "rgba(0, 0, 0, 0.87)",
											width: "25%",
											marginLeft: "8px",
											paddingLeft: "16px",
										}}
									>
										Name
									</Typography>
									<Typography
										sx={{
											fontFamily: "Roboto",
											fontWeight: 500,
											fontSize: "14px",
											color: "rgba(0, 0, 0, 0.87)",
											width: "25%",
											marginLeft: "8px",
											paddingLeft: "24px",
										}}
									>
										Description
									</Typography>
									<Typography
										sx={{
											fontFamily: "Roboto",
											fontWeight: 500,
											fontSize: "14px",
											color: "rgba(0, 0, 0, 0.87)",
											width: "10%",
											marginLeft: "8px",
											paddingLeft: "36px",
										}}
									>
										Multiplier
									</Typography>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center",
											width: "10%",
											marginLeft: "8px",
											paddingLeft: "56px",
										}}
									>
										<Typography
											sx={{
												fontFamily: "Roboto",
												fontWeight: 500,
												fontSize: "14px",
												color: "rgba(0, 0, 0, 0.87)",
											}}
										>
											Default
										</Typography>
										<Tooltip
											title={DEFAULT_PRIORITY_TOOLTIP}
											placement='bottom'
											arrow
										>
											<InfoOutlinedIcon
												sx={{
													color: "#6c6d6e",
													marginLeft: "8px",
												}}
											/>
										</Tooltip>
									</Box>
									<Box sx={{ width: "124px" }} />
								</Box>
							</Grid>
							{/* Field Rows */}
							{skillPriorities.map((priority, index) => (
								<Grid item xs={12} key={priority.id}>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											width: "100%",
										}}
									>
										<TextField
											placeholder='Code'
											error={
												!skillPrioritiesToDelete.includes(priority.id)
													? validateUniqueness("code", priority.code)
													: false
											}
											value={priority.code}
											onChange={(e) => {
												const alphanumericValue = e.target.value.replace(
													/[^a-zA-Z0-9]/g,
													""
												);
												handleChange(priority.id, "code", alphanumericValue);
											}}
											inputProps={{ maxLength: 2, style: { padding: 16.5 } }}
											variant='filled'
											InputLabelProps={{ shrink: false }}
											sx={{ width: "10%" }}
										/>
										<Select
											value={priority.hexColor ?? ""}
											onChange={(e) =>
												handleChange(priority.id, "hexColor", e.target.value)
											}
											variant='filled'
											sx={{ width: "10%", marginLeft: "8px" }}
											renderValue={(value) => (
												<Box
													sx={{
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
														height: "10px",
													}}
												>
													{value === "" && <BlockIcon height={10} width={10} />}
													{value !== "" && (
														<Box
															sx={{
																width: "18px",
																height: "18px",
																backgroundColor: `#${value}`,
																border: 1,
																borderWidth: "1px",
																borderColor: "rgba(0,0,0,0.4)",
																borderRadius: "9px",
															}}
														/>
													)}
												</Box>
											)}
										>
											{priorityColors.map((colorInfo) => (
												<MenuItem
													value={colorInfo.colorCode}
													key={`${colorInfo.colorCode}`}
												>
													<Box
														sx={{
															display: "flex",
															flexDirection: "row",
															alignItems: "center",
															justifyContent: "flex-start",
														}}
													>
														{colorInfo.colorCode === "" && (
															<BlockIcon
																sx={{
																	width: "18px",
																	height: "18px",
																}}
															/>
														)}
														{colorInfo.colorCode !== "" && (
															<Box
																sx={{
																	width: "18px",
																	height: "18px",
																	backgroundColor: `#${colorInfo.colorCode}`,
																	borderRadius: "9px",
																}}
															/>
														)}
														<Typography
															sx={{
																fontFamily: "Roboto",
																fontWeight: 400,
																fontSize: "14px",
																color: "rgba(0, 0, 0, 0.87)",
																marginLeft: "8px",
															}}
														>
															{colorInfo.colorName}
														</Typography>
													</Box>
												</MenuItem>
											))}
										</Select>
										<TextField
											placeholder='Name'
											error={
												(!skillPrioritiesToDelete.includes(priority.id)
													? validateUniqueness("name", priority.name)
													: false) || validateMinCharacters(priority.name)
											}
											value={priority.name}
											onChange={(e) => {
												const alphanumericValue = e.target.value.replace(
													/[^a-zA-Z0-9\s]/g,
													""
												);
												handleChange(priority.id, "name", alphanumericValue);
											}}
											inputProps={{ maxLength: 30, style: { padding: 16.5 } }}
											variant='filled'
											InputLabelProps={{ shrink: false }}
											sx={{ width: "25%", marginLeft: "8px" }}
										/>
										<TextField
											placeholder='Description'
											value={priority.description}
											error={validateMinCharacters(priority.description)}
											onChange={(e) => {
												const alphanumericValue = e.target.value.replace(
													/[^a-zA-Z0-9\s]/g,
													""
												);
												handleChange(
													priority.id,
													"description",
													alphanumericValue
												);
											}}
											inputProps={{ maxLength: 200, style: { padding: 16.5 } }}
											variant='filled'
											InputLabelProps={{ shrink: false }}
											sx={{ width: "25%", marginLeft: "8px" }}
										/>
										<TextField
											placeholder=''
											error={
												!skillPrioritiesToDelete.includes(priority.id)
													? validateMultiplier(priority.multiplier)
													: false
											}
											value={priority.multiplier}
											onChange={(e) => {
												try {
													const validNumber = /^\d*\.?\d{0,2}$/;
													if (validNumber.test(e.target.value)) {
														handleChange(
															priority.id,
															"multiplier",
															e.target.value
														);
													}
												} catch (e) {
													// Do nothing
												}
											}}
											inputProps={{ maxLength: 50, style: { padding: 16.5 } }}
											variant='filled'
											InputLabelProps={{ shrink: false }}
											sx={{ width: "10%", marginLeft: "8px" }}
										/>
										<Tooltip
											title={
												priority.id <= 0
													? "Cannot set this as default. Please save it first."
													: null
											}
											placement='bottom'
											arrow
										>
											<Box sx={{ width: "10%", marginLeft: "32px" }}>
												<Radio
													disabled={
														priority.id <= 0 ||
														skillPrioritiesToDelete.includes(priority.id)
													}
													checked={priority.isDefault}
													onChange={() => handleDefaultChange(priority.id)}
												/>
											</Box>
										</Tooltip>
										{priority.id !== undefined &&
											skillPrioritiesToDelete.includes(priority.id) && (
												<Box
													sx={{
														display: "flex",
														padding: "4px 10px",
														flexDirection: "column",
														justifyContent: "center",
														alignItems: "center",
														borderRadius: "4px",
														border: 1,
														borderWidth: "1px",
														width: "60px",
														marginLeft: "18px",
														borderColor:
															skillPriorities.filter(
																(priority) =>
																	!skillPrioritiesToDelete.includes(priority.id)
															).length < maxSkillPriorities
																? colors.stepperBackground
																: colors.gray,
														cursor:
															skillPriorities.filter(
																(priority) =>
																	!skillPrioritiesToDelete.includes(priority.id)
															).length < maxSkillPriorities
																? "pointer"
																: "no-drop",
													}}
													onClick={() => {
														if (
															skillPriorities.filter(
																(priority) =>
																	!skillPrioritiesToDelete.includes(priority.id)
															).length < maxSkillPriorities
														) {
															setSkillPrioritiesToDelete((prevState) =>
																prevState.filter((id) => id !== priority.id)
															);
														}
													}}
												>
													<Typography
														sx={{
															fontFamily: "Roboto",
															fontSize: "13px",
															fontWeight: 500,
															textTransform: "uppercase",
															color:
																skillPriorities.filter(
																	(priority) =>
																		!skillPrioritiesToDelete.includes(
																			priority.id
																		)
																).length < maxSkillPriorities
																	? colors.stepperBackground
																	: colors.gray,
														}}
													>
														UNDO
													</Typography>
												</Box>
											)}
										{(priority.id <= 0 ||
											!skillPrioritiesToDelete.includes(priority.id)) && (
											<DeleteOutlineIcon
												sx={{
													marginLeft: "16px",
													cursor:
														skillPriorities.filter(
															(priority) =>
																!skillPrioritiesToDelete.includes(priority.id)
														).length === 1 ||
														priority.isDefault ||
														!canDelete(priority)
															? "no-drop"
															: "pointer",
													color:
														skillPriorities.filter(
															(priority) =>
																!skillPrioritiesToDelete.includes(priority.id)
														).length === 1 ||
														priority.isDefault ||
														!canDelete(priority)
															? "rgba(0,0,0,0.38)"
															: "rgba(0,0,0,0.56)",
													width: "60px",
												}}
												onClick={() => {
													if (!priority.isDefault && canDelete(priority)) {
														handleRemoveSkillPriority(priority.id);
													}
												}}
											/>
										)}
									</Box>
								</Grid>
							))}
							{/* Add priority Button */}
							{skillPriorities.length - skillPrioritiesToDelete.length <
								maxSkillPriorities && (
								<Grid item xs={12}>
									<Button
										startIcon={<AddCircleIcon />}
										onClick={handleAddSkillPriority}
									>
										Add priority
									</Button>
								</Grid>
							)}
						</Grid>
					</Box>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "flex-end",
							marginTop: "16px",
							marginBottom: "16px",
							marginRight: "24px",
							marginLeft: "24px",
							width: "100%",
						}}
					>
						<Button variant='outlined' onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							variant='contained'
							onClick={handlePrioritySave}
							disabled={isSaveDisabled()}
							loading={saveSkillPrioritiesStatus === "pending"}
							sx={{ marginLeft: "16px" }}
						>
							Save
						</LoadingButton>
					</Box>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default SkillPriorityConfigDialog;
