import Box from "@mui/material/Box";
import React from "react";

type Props = {
	children: JSX.Element;
};

const PageLayout = ({ children }: Props) => {
	return (
		<Box
			component='main'
			mt={3}
			ml={3}
			mr={3}
			mb={3}
			sx={{
				flexGrow: 1,
				backgroundColor: "#fff",
				borderRadius: "15px",
			}}
		>
			{children}
		</Box>
	);
};

export default PageLayout;
