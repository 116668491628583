import EditIcon from "@mui/icons-material/Edit";
import { Box, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro-6";
import React from "react";
const useWorkerSubtypeColumn = (
	openEditDialog: (type: "add" | "edit", id?: number) => void
) => {
	const columns: GridColDef[] = [
		{
			field: "workerSubType",
			headerName: "Worker Sub Type",
			minWidth: 200,
			flex: 1,
			sortable: false,
			align: "left",
			renderHeader: () => (
				<span style={{ fontWeight: "bold" }}>{"Worker Sub Type"}</span>
			),
			renderCell: (params) => {
				return (
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "14px",
							fontWeight: "400",
							lineHeight: "20.02px",
							letterSpacing: "0.17000000178813934px",
							textAlign: "left",
							textDecorationSkipInk: "none",
						}}
					>
						{params.row.name}
					</Typography>
				);
			},
			valueGetter: (params) => params.row.name,
		},
		{
			field: "description",
			headerName: "Description",
			minWidth: 400,
			flex: 2,
			sortable: false,
			renderHeader: () => (
				<span style={{ fontWeight: "bold" }}>{"Description"}</span>
			),
			renderCell: (params) => {
				return (
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "14px",
							fontWeight: "400",
							lineHeight: "20.02px",
							letterSpacing: "0.17000000178813934px",
							textAlign: "left",
						}}
					>
						{params.row.description}
					</Typography>
				);
			},
			valueGetter: (params) => params.row.description,
		},
		{
			field: "workerType",
			headerName: "Worker Type",
			minWidth: 100,
			flex: 1,
			sortable: false,
			renderHeader: () => (
				<span style={{ fontWeight: "bold" }}>{"Worker Type"}</span>
			),
			renderCell: (params) => {
				return (
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "14px",
							fontWeight: "400",
							lineHeight: "20.02px",
							letterSpacing: "0.17000000178813934px",
							textAlign: "left",
						}}
					>
						{params.row.workerType ? params.row.workerType.name : "-"}
					</Typography>
				);
			},
			valueGetter: (params) =>
				params.row.workerType ? params.row.workerType.name : "-",
		},
		{
			field: "action",
			headerName: "",
			minWidth: 100,
			flex: 1,
			sortable: false,
			cellClassName: "hover-column-cell",
			align: "center",
			renderCell: (params) => {
				return (
					<Box
						className='hover-content'
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							width: "100px",
							padding: "6px 0px 2px 0px",
							borderRadius: "4px",
							zIndex: 1,
						}}
					>
						<Box
							sx={{
								color: "#6c6c6c",
								"&:hover": {
									color: "#000000",
									transform: "scale(1.2)",
								},
								cursor: "pointer",
							}}
						>
							<Tooltip title='Edit'>
								<EditIcon
									id={`edit-job-${params?.row?.id}-enabled`}
									fontSize='small'
									onClick={(e) => {
										openEditDialog("edit", params?.row?.id);
									}}
								/>
							</Tooltip>
						</Box>
					</Box>
				);
			},
		},
	];

	return columns;
};

export default useWorkerSubtypeColumn;
