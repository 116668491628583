import React, { useMemo } from "react";

import {
	ColumnCell,
	ColumnEmployeeCell,
	ColumnHeader,
	ColumnSkillCell,
	ColumnSkillHeader,
} from "../../../../export/cross-skilling";
import { formatDate, formatSkillScore } from "../../../../ts/utils/helpers";
import { formatSkillsByJobs } from "../../../utils/helpers";

const useCrossSkillColumn = (
	filteredJobs,
	allSkills,
	allSkillLevels,
	handleSkillLevels,
	handleAddSkillInterested,
	searchText,
	getFilter,
	isWorkerSubTypeEnabled
) => {
	const filterState = useMemo(() => {
		const jobFilter = getFilter("job");
		const skillFilter = getFilter("skill");
		return {
			isJobFilter: jobFilter?.jobs.length > 0 ?? false,
			isSkillFilter: skillFilter?.skills.length > 0 ?? false,
			jobIds: jobFilter?.jobs.map((job) => job.id) ?? [],
			skillIds: skillFilter?.skills.map((skill) => skill.id) ?? [],
		};
	}, [getFilter]);

	const { jobIdsSkillsArr, filteredSkills } = useMemo(() => {
		const filteredSkills = filterState.isSkillFilter
			? allSkills.filter((skill) => filterState.skillIds.includes(skill.id))
			: [];

		const jobIdsSkillsArr = filterState.isJobFilter
			? []
			: filterState.isSkillFilter
			? filteredSkills
			: allSkills;

		if (filterState.isJobFilter) {
			formatSkillsByJobs(filteredJobs, jobIdsSkillsArr, filterState.jobIds);
		}

		return { jobIdsSkillsArr, filteredSkills };
	}, [filterState, allSkills, filteredJobs]);

	const baseColumns = useMemo(() => {
		const numericComparator = (v1, v2) => {
			const getNumericPart = (value) => {
				if (!value) return null;
				const match = value.trim().match(/^\d+$/);
				return match ? parseInt(match[0], 10) : null;
			};

			if (!v1?.trim() && !v2?.trim()) return 0;
			if (!v1?.trim()) return 1;
			if (!v2?.trim()) return -1;

			const num1 = getNumericPart(v1);
			const num2 = getNumericPart(v2);

			if (num1 !== null && num2 !== null) return num1 - num2;
			if (num1 !== null) return -1;
			if (num2 !== null) return 1;
			return v1.localeCompare(v2);
		};

		const createBaseColumn = (
			field,
			headerName,
			width = 200,
			customProps = {}
		) => ({
			field,
			headerName,
			width,
			headerAlign: "center",
			renderHeader: () => <ColumnHeader text={headerName} />,
			renderCell: (params) => (
				<ColumnCell text={params.row[field] ?? "-"} searchText={searchText} />
			),
			valueGetter: (params) => params.row[field],
			...customProps,
		});

		const columns = [
			createBaseColumn("companyEmployeeId", "Worker ID", 200, {
				sortComparator: numericComparator,
				order: 4,
			}),
			createBaseColumn("jobTitle", "Job Title", 200, { order: 5 }),
			{
				...createBaseColumn("name", "Worker Name", 200, {
					valueGetter: (params) =>
						`${params.row.firstName} ${params.row.lastName}`,
					order: 6,
				}),
				renderCell: (params) => (
					<ColumnEmployeeCell params={params} searchText={searchText} />
				),
			},
			createBaseColumn("workerType", "Worker Type", 200, { order: 6 }),
			createBaseColumn("managerName", "Reporting Manager", 200, {
				valueGetter: (params) => params.row.managerName?.toLowerCase(),
				order: 7,
			}),
			createBaseColumn("primaryJob", "Primary Job", 200, { order: 8 }),
			createBaseColumn("workCenter", "Work Center", 200, { order: 9 }),
			createBaseColumn("location", "Location", 200, { order: 10 }),
			createBaseColumn("shiftPatternName", "Shift Pattern", 200, { order: 10 }),
			{
				...createBaseColumn("skillScore", "Total Skill Score", 160, {
					order: 10,
				}),
				renderCell: (params) => (
					<ColumnCell
						text={formatSkillScore(params.row.skillScore) ?? "-"}
						searchText={searchText}
					/>
				),
			},
			{
				...createBaseColumn("joiningDate", "Joining Date", 200, { order: 11 }),
				valueGetter: (params) => {
					return new Date(params.row.joiningDate).getTime();
				},
				renderCell: (params) => {
					const joiningDate = formatDate({
						dateStr: params?.row?.joiningDate,
						formatType: "NUMERIC_DATE",
						ISOString: false,
					});
					return (
						<ColumnCell text={joiningDate ?? "-"} searchText={searchText} />
					);
				},
			},
		];

		if (isWorkerSubTypeEnabled) {
			columns.splice(
				4,
				0,
				createBaseColumn("workerSubTypeText", "Worker Sub Type", 200, {
					order: 6,
				})
			);
		}

		return columns;
	}, [searchText, isWorkerSubTypeEnabled]);

	const skillColumns = useMemo(
		() =>
			jobIdsSkillsArr.map((skill, idx) => ({
				field: filterState.isJobFilter
					? `${skill.id}${skill.jobColor}`
					: skill.id.toString(),
				headerName: skill.name,
				width: 100,
				type: "actions",
				headerAlign: "center",
				sortable: true,
				renderCell: (params) => (
					<ColumnSkillCell
						skill={
							filterState.isJobFilter ? { ...skill, id: skill.skillId } : skill
						}
						params={params}
						handleSkillLevels={handleSkillLevels}
						allSkillLevels={allSkillLevels}
						handleAddSkillInterested={handleAddSkillInterested}
					/>
				),
				renderHeader: () => (
					<ColumnSkillHeader
						skill={skill}
						isJobFilter={filterState.isJobFilter}
						searchText={searchText}
					/>
				),
				valueGetter: (params) => {
					const skillInCell = params.row.skills.find(
						(x) => x.skillId === parseInt(skill.skillId)
					);
					return skillInCell ? skillInCell.level + 2 : 1;
				},
				order: 11 + idx,
			})),
		[
			jobIdsSkillsArr,
			filterState.isJobFilter,
			handleSkillLevels,
			handleAddSkillInterested,
			allSkillLevels,
			searchText,
		]
	);

	return { columns: [...baseColumns, ...skillColumns] };
};

export default useCrossSkillColumn;
