import { Box, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import LoadingButton from "../../../../design/wilya-loading-button";
import { Priority } from "../../../../utils/types";

interface ISectionActionsProps {
	oldData?: any;
	newData: any;
	resetChanges?: any;
	handleSave: any;
	status?: any;
	loading?: any;
	companyDataValue?: any;
}
const SectionActions: React.FC<ISectionActionsProps> = ({
	oldData,
	newData,
	resetChanges,
	handleSave,
	status,
	loading,
	companyDataValue,
}) => {
	const [canNotSave, setCanNotSave] = useState(false);
	const skillPriorities: Priority[] = useSelector(
		(state: any) => state.gatAdminConfiguration.skillPriorities
	);

	useEffect(() => {
		try {
			const skillLevels = companyDataValue.skillLevels;
			const skillPriorityEnabled = newData.skillPriorityEnabled;
			const crossSkillingEnabled = newData.crossSkillingEnabled;
			if (crossSkillingEnabled && skillLevels.length === 0) {
				setCanNotSave(true);
			} else if (skillPriorityEnabled && skillPriorities.length === 0) {
				setCanNotSave(true);
			} else {
				setCanNotSave(false);
			}
		} catch (e) {
			// Do nothing
		}
	}, [companyDataValue, skillPriorities, newData]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "flex-end",
				paddingTop: "16px",
				paddingBottom: "12px",
			}}
		>
			<Button
				variant='outlined'
				disabled={JSON.stringify(oldData) === JSON.stringify(newData)}
				onClick={resetChanges}
			>
				Discard all new changes
			</Button>
			<LoadingButton
				variant='contained'
				disabled={
					canNotSave ||
					JSON.stringify(oldData) === JSON.stringify(newData) ||
					loading
				}
				sx={{ marginLeft: "16px" }}
				loading={status === "pending" || loading}
				onClick={handleSave}
				loadingIndicator={
					<CircularProgress size={16} sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
				}
			>
				Save
			</LoadingButton>
		</Box>
	);
};

export default SectionActions;
