import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { GridRowId } from "@mui/x-data-grid";
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import React, { useCallback, useEffect, useState } from "react";

import {
  FULL_TIME_WORKER,
  LOCATION,
  PRIMARYJOB,
  WORKCENTER,
} from "../../../../assets/constants";
import {parseSeniority} from "../../../../utils/helpers";
import useViewport from "../../../../utils/use-viewport";
import useSearch from "../../controllers/use-search";
import {
  ShiftDetailsTableProps,
  WorkersPropsV2,
} from "../dialog/shift-details-dialog/types";

const AssignShiftTable: React.FC<ShiftDetailsTableProps> = ({
  rows,
  columns,
  workersSelected,
  setWorkersSelected,
  readOnly = false,
  searchValue = "",
  detailPanelExpandedRowIds,
  totalWorkersRequired = undefined,
  shiftMetadata,
}) => {
  const filteredRows = useSearch({
    data: rows,
    searchValue,
    searchField: "workerName",
  });
  const apiRef = useGridApiRef();
  const { height } = useViewport();

  const getDetailPanelContent = useCallback(({ row }: any) => {
    return row.errorMessage ? (
      <Alert severity="error">{row.errorMessage}</Alert>
    ) : null;
  }, []);

  const getDetailPanelHeight = useCallback(() => 50, []);
  const [canSelectRow, setCanSelectRow] = useState(true);
  const [sortedRows, setSortedRows] = useState<any[] | undefined>(undefined);

  useEffect(() => {
    setSortedRows(undefined);
    return () => setSortedRows(undefined);
  }, []);

  useEffect(() => {
    try {
      if (filteredRows.length > 0 && shiftMetadata) {
        // Show Workers in same work center first sort
        const sortByWorkCenter = (workers: WorkersPropsV2[]) => {
          try {
            return [...workers].sort((a, b) => {
              const isAInSameWorkCenter =
                a.workCenter?.name === shiftMetadata.shiftWorkcenterName;
              const isBInSameWorkCenter =
                b.workCenter?.name === shiftMetadata.shiftWorkcenterName;

              // Workers in the same work center should come first
              if (isAInSameWorkCenter && !isBInSameWorkCenter) {
                return -1;
              } else if (!isAInSameWorkCenter && isBInSameWorkCenter) {
                return 1;
              } else {
                return 0; // If both are the same (either in or out), keep original order
              }
            });
          } catch (e) {
            return workers;
          }
        };

        // Sort by showing workers with same location first
        const sortByLocation = (workers: WorkersPropsV2[]) => {
          try {
            return [...workers].sort((a, b) => {
              const isAInSameLocation =
                a.location === shiftMetadata.shiftLocationName;
              const isBInSameLocation =
                b.location === shiftMetadata.shiftLocationName;

              // Workers in the same work center should come first
              if (isAInSameLocation && !isBInSameLocation) {
                return -1;
              } else if (!isAInSameLocation && isBInSameLocation) {
                return 1;
              } else {
                return 0; // If both are the same (either in or out), keep original order
              }
            });
          } catch (e) {
            return workers;
          }
        };

        // Sort by showing workers with same location cluster first
        const sortByLocationCluster = (workers: WorkersPropsV2[]) => {
          try {
            return [...workers].sort((a, b) => {
              const isAInSameLocation =
                a.locationCluster === shiftMetadata.shiftLocationClusterName;
              const isBInSameLocation =
                b.locationCluster === shiftMetadata.shiftLocationClusterName;

              // Workers in the same work center should come first
              if (isAInSameLocation && !isBInSameLocation) {
                return -1;
              } else if (!isAInSameLocation && isBInSameLocation) {
                return 1;
              } else {
                return 0; // If both are the same (either in or out), keep original order
              }
            });
          } catch (e) {
            return workers;
          }
        };

        // Lowest seniority to longer seniority
        const sortBySeniority = (workers: WorkersPropsV2[]) => {
          try {
            return [...workers].sort((a: any, b: any) => {
              return (
                parseSeniority(a.seniorityYears) -
                parseSeniority(b.seniorityYears)
              );
            });
          } catch (e) {
            return workers ?? [];
          }
        };

        // Lowest overtime first and then with more overtime
        const sortByOvertime = (workers: WorkersPropsV2[]) => {
          try {
            return [...workers].sort((a, b) => a.overtimeMins - b.overtimeMins);
          } catch (e) {
            return workers ?? [];
          }
        };

        // full time workers first
        const sortByFullTime = (workers: WorkersPropsV2[]) => {
          try {
            return [...workers].sort((a, b) => {
              const isAFullTime = a.workerType === FULL_TIME_WORKER;
              const isBFullTime = b.workerType === FULL_TIME_WORKER;

              // Worker who is full time comes first
              if (isAFullTime && !isBFullTime) {
                return -1;
              } else if (!isAFullTime && isBFullTime) {
                return 1;
              } else {
                return 0; // If both are the same (either in or out), keep original order
              }
            });
          } catch (e) {
            return workers ?? [];
          }
        };

        // sort by work center and primary job
        const sortByWorkCenterAndPrimaryJob = (workers: WorkersPropsV2[]) => {
          try {
            return [...workers].sort((a, b) => {
              const isAInSameWorkCenter =
                a.workCenter?.name === shiftMetadata.shiftWorkcenterName;
              const isBInSameWorkCenter =
                b.workCenter?.name === shiftMetadata.shiftWorkcenterName;

              const isAInSamePrimaryJob =
                a.primaryJobId === shiftMetadata.jobId;
              const isBInSamePrimaryJob =
                b.primaryJobId === shiftMetadata.jobId;

              // Workers in the same work center should come first
              if (isAInSameWorkCenter && !isBInSameWorkCenter) {
                return -1;
              } else if (!isAInSameWorkCenter && isBInSameWorkCenter) {
                return 1;
              } else {
                // If both are the same (either in or out), keep original order
                return isAInSamePrimaryJob && !isBInSamePrimaryJob ? -1 : 1;
              }
            });
          } catch (e) {
            return workers;
          }
        };

        // sort by primary job id
        const sortByPrimaryJob = (workers: WorkersPropsV2[]) => {
          try {
            return [...workers].sort((a, b) => {
              const isAInSamePrimaryJob =
                a.primaryJobId === shiftMetadata.jobId;
              const isBInSamePrimaryJob =
                b.primaryJobId === shiftMetadata.jobId;

              // Workers in the same work center should come first
              if (isAInSamePrimaryJob && !isBInSamePrimaryJob) {
                return -1;
              } else if (!isAInSamePrimaryJob && isBInSamePrimaryJob) {
                return 1;
              } else {
                return 0; // If both are the same (either in or out), keep original order
              }
            });
          } catch (e) {
            return workers;
          }
        };

        // sort by companyEmployeeId Z to A and 9 to 0
        const sortByCompanyEmployeeId = (workers: WorkersPropsV2[]) => {
          try {
            return [...workers].sort((a, b) => {
              const idA = a.companyEmployeeId?.trim() || ""; // Trim and default to empty string
              const idB = b.companyEmployeeId?.trim() || ""; // Trim and default to empty string

              // Handle cases where IDs are blank
              if (!idA && !idB) return 0; // Both are blank
              if (!idA) return 1; // `a` is blank, comes after `b`
              if (!idB) return -1; // `b` is blank, comes after `a`

              const isNumericA = /^\d+$/.test(idA); // Check if `idA` is numeric
              const isNumericB = /^\d+$/.test(idB); // Check if `idB` is numeric

              // If both IDs are numeric, sort in descending numeric order
              if (isNumericA && isNumericB) {
                return Number(idB) - Number(idA);
              }

              // If IDs are alphanumeric or alphabetic, sort in descending alphabetical order
              return idB.localeCompare(idA);
            });
          } catch (e) {
            console.error("Error sorting companyEmployeeId:", e);
            return workers; // Return unsorted workers if an error occurs
          }
        };

        // sort by lowest overtime and Z to A or 9 to 0 companyEmployeeId
        const sortByOvertimeAndCompanyEmployeeId = (
          workers: WorkersPropsV2[]
        ) => {
          try {
            return [...workers].sort((a, b) => {
              // Sort overtime in ascending order (lower overtime first)
              const overtimeSort = a.overtimeMins - b.overtimeMins;
              if (overtimeSort !== 0) return overtimeSort;

              const idA = a.companyEmployeeId?.trim() || ""; // Trim and default to empty string
              const idB = b.companyEmployeeId?.trim() || ""; // Trim and default to empty string

              // Handle cases where IDs are blank
              if (!idA && !idB) return 0; // Both are blank
              if (!idA) return 1; // `a` is blank, comes after `b`
              if (!idB) return -1; // `b` is blank, comes after `a`

              const isNumericA = /^\d+$/.test(idA); // Check if `idA` is numeric
              const isNumericB = /^\d+$/.test(idB); // Check if `idB` is numeric

              // If both IDs are numeric, sort in descending numeric order
              if (isNumericA && isNumericB) {
                return Number(idB) - Number(idA);
              }

              // If IDs are alphanumeric or alphabetic, sort in descending alphabetical order
              return idB.localeCompare(idA);
            });
          } catch (e) {
            console.error("Error sorting workers:", e);
            return workers; // Return unsorted workers if an error occurs
          }
        };

        const shiftAllocationType = shiftMetadata.shiftAllocationType;
        switch (shiftAllocationType) {
          case WORKCENTER:
            setSortedRows(
              sortByWorkCenter(
                sortByLocation(sortBySeniority(sortByOvertime(filteredRows)))
              )
            );
            break;
          case LOCATION:
            setSortedRows(
              sortByFullTime(
                sortByLocation(
                  sortByLocationCluster(sortBySeniority(filteredRows))
                )
              )
            );
            break;
          case PRIMARYJOB:
            setSortedRows(
              sortByWorkCenterAndPrimaryJob(
                sortByWorkCenter(
                  sortByPrimaryJob(
                    sortByOvertimeAndCompanyEmployeeId(
                      sortByOvertime(sortByCompanyEmployeeId(filteredRows))
                    )
                  )
                )
              )
            );
            break;
          default:
            setSortedRows(undefined);
            break;
        }
      } else {
        setSortedRows(undefined);
      }
    } catch (e) {
      // Do nothing
    }
  }, [filteredRows, shiftMetadata]);

  useEffect(() => {
    try {
      // Removing check from disabled rows
      setWorkersSelected((prevState: GridRowId[]) => {
        const newState: GridRowId[] = [];
        prevState.forEach((state: GridRowId) => {
          rows.forEach((row) => {
            if (state === row.workerId && row.errorMessage === "") {
              newState.push(state);
            }
          });
        });
        // ascending sort newState got all string values
        newState.sort();
        return newState;
      });
    } catch (e) {
      // Do nothing
    }
  }, [rows, setWorkersSelected]);

  useEffect(() => {
    if (
      totalWorkersRequired === undefined ||
      totalWorkersRequired > workersSelected.length
    ) {
      setCanSelectRow(true);
    } else {
      setCanSelectRow(false);
    }
  }, [workersSelected, totalWorkersRequired]);

  return (
    <Box
      width={1150}
      mr="auto"
      ml="auto"
      height={"100%"}
      sx={{
        overflowX: "hidden",
      }}
    >
      <DataGridPro
        apiRef={apiRef}
        key={`workers-for-assign-shift-${detailPanelExpandedRowIds.length}`}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No workers found
            </Stack>
          ),
        }}
        rows={sortedRows ?? filteredRows}
        columns={[
          ...columns.filter((column: any) => column.checked),
          {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            type: "string",
            renderCell: (params: any) => <></>,
          },
        ]}
        isRowSelectable={(params) =>
            !readOnly &&
          (workersSelected.includes(params.row.id) || canSelectRow)
        }
        hideFooter={true}
        checkboxSelection={true}
        selectionModel={workersSelected}
        onSelectionModelChange={(e) => {
          setWorkersSelected(e?.sort());
        }}
        disableColumnSelector
        sx={{
          border: 0,
          paddingLeft: "21px",
          paddingRight: "21px",
          marginBottom: "-20px",
          overflowX: "hidden",
          height: height - 425,
          "& .MuiDataGrid-checkboxInput": {
            margin: "auto", // Center the checkbox vertically
          },
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-checkboxInput": {
            margin: "auto", // Center the header checkbox vertically
          },
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            width: "100%",
          },
          "& .MuiDataGrid-columnHeader:last-child": {
            width: "0px",
          },
          "& .MuiDataGrid-columnSeparator": {
            opacity: "0 !important",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
            {
              outline: "none !important",
            },
        }}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
      />
    </Box>
  );
};

export default AssignShiftTable;
