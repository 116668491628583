import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import {Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

type SkillsProps = {
    shiftId?: number;
    skillId: number;
    skillLevelId?: number;
    skillName: string;
    skillLevel?: string;
    level: number;
};

type ShiftBySkillsTagProps = {
    skills: SkillsProps[];
    iconSize?: number;
    iconColor?: string;
    fontSize?: number;
};

const defaultValuesForShiftBySkillsTagProps = {
    iconSize: 24,
    fontSize: 12,
    iconColor: "#000"
}

const ShiftBySkillsTag = (props: ShiftBySkillsTagProps) => {
    const {skills} = props;
    let { iconSize, iconColor, fontSize} = props;
    if (iconSize === undefined) {
        iconSize = defaultValuesForShiftBySkillsTagProps.iconSize;
    }
    if (iconColor === undefined) {
        iconColor = defaultValuesForShiftBySkillsTagProps.iconColor;
    }
    if (fontSize === undefined) {
        fontSize = defaultValuesForShiftBySkillsTagProps.fontSize;
    }
    const primarySkillName = skills ? skills.length > 0 ? skills[0].skillName : "-" : "-";
    const remainingSkillsArray = skills.length > 1 ? skills.slice(1,skills.length) : [];
    const remainingSkillsNames = remainingSkillsArray.map((skill: SkillsProps) => skill.skillName);
    return (
        <Box display="flex" alignItems="flex-start" pb={0.5}>
            <BuildOutlinedIcon sx={{
                width: `${iconSize}px`,
                height: `${iconSize}px`,
                color: iconColor,
                marginTop: "2px"
            }} />
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignContent: "center",
                flexWrap: "wrap",
            }}>
                <Tooltip title={primarySkillName}>
                    <Box ml={1} mb={0.5} sx={{
                        backgroundColor: "rgba(47, 77, 139, 0.08)",
                        borderRadius: "4px",
                        padding: "1px 4px",
                        cursor: "default"
                    }}>
                        <Typography
                            sx={{
                                color: "rgba(0,0,0,0.87)",
                                whiteSpace: "normal",
                                fontFamily: "Roboto",
                                fontSize: `${fontSize}px`,
                                lineHeight: `${fontSize*1.5}px`,
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                fontWeight: 400,
                                wordBreak: "break-word",
                                maxHeight: "20px",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 1,
                                letterSpacing: "0.4px"
                            }}
                        >
                            {primarySkillName}
                        </Typography>
                    </Box>
                </Tooltip>
                {remainingSkillsNames.length > 0 && (
                    <Tooltip
                        title={remainingSkillsNames.toString().replaceAll(",", ", ")}
                    >
                        <Box ml={1} mb={0.5} sx={{
                            backgroundColor: "#FFF",
                            borderRadius: "4px",
                            padding: "1px 4px",
                            cursor: "default"
                        }}>
                            <Typography
                                sx={{
                                    color: "rgba(0,0,0,0.6)",
                                    whiteSpace: "normal",
                                    fontFamily: "Roboto",
                                    fontSize: `${fontSize}px`,
                                    lineHeight: `${fontSize+8}px`,
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    fontWeight: 400,
                                    textDecoration: "underline",
                                    letterSpacing: "0.4px",
                                    wordBreak: "break-word",
                                    maxHeight: "20px",
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 1,
                                }}
                            >
                                {`+ ${remainingSkillsNames.length} more`}
                            </Typography>
                        </Box>
                    </Tooltip>
                )}
            </Box>
        </Box>
    );
};

export default ShiftBySkillsTag;