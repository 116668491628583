import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React from "react";

import LoadingButton from "../../../../../../../design/wilya-loading-button";
import useShiftScheduleRulesData from "../../controllers/use-shift-schedule-rules-data";

interface ActionButtonsProps {
	closeScheduleRulesDialog: () => void;
	isValid: boolean;
	isSubmitting: boolean;
	dirty: boolean;
	status: string;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
	closeScheduleRulesDialog,
	isValid,
	isSubmitting,
	//onSubmit,
	dirty,
	status,
}) => {
	const { createNewScheduleError, editShiftScheduleError } =
		useShiftScheduleRulesData();

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				marginTop: "16px",
				marginBottom: "16px",
				marginRight: "21px",
				alignItems: "center",
			}}
		>
			{createNewScheduleError ? (
				<Typography color='secondary'>{createNewScheduleError}</Typography>
			) : null}
			{editShiftScheduleError ? (
				<Typography color='secondary'>{editShiftScheduleError}</Typography>
			) : null}
			<Box />
			<Box>
				<Button variant='outlined' onClick={closeScheduleRulesDialog}>
					Cancel
				</Button>
				<LoadingButton
					variant='contained'
					disabled={!isValid || !dirty || isSubmitting}
					sx={{ marginLeft: "16px" }}
					loading={status === "pending"}
					type='submit'
					// onClick={onSubmit}
					loadingIndicator={
						<CircularProgress size={16} sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
					}
				>
					Save
				</LoadingButton>
			</Box>
		</Box>
	);
};

export default ActionButtons;
