import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";

import useCrossSkillData from "../../../../../../js/components/cross-skilling/controllers/use-cross-skill-data";
import { getRepLevel, getRepType } from "../../../../../../js/utils/helpers";
import Highlight from "../../../../Highlight";
import SkillRepresentationCell from "./column-skill-representations-cell";

interface ColumnSkillHeaderProps {
	skill: {
		name: string;
		jobId: string;
		id: string;
	};
	searchText: string;
	isGroup: boolean;
}

const ColumnSkillHeader: React.FC<ColumnSkillHeaderProps> = ({
	skill,
	searchText,
	isGroup,
}) => {
	const { allSkillLevels, isJobSkillLevelMapping, jobSkillLevelMap } =
		useCrossSkillData();

	const jobSkillLevel = jobSkillLevelMap[`${skill.jobId}-${skill.id}`];

	const level = allSkillLevels.find(
		(level: { id: number }) => level.id === jobSkillLevel
	);
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems:
						isJobSkillLevelMapping && isGroup && level
							? "center"
							: "flex-start",
					justifyContent: "center",
					width: "100%",
					paddingTop: "8px",
					paddingBottom: "8px",
				}}
			>
				{isJobSkillLevelMapping && level && isGroup && (
					<Box pl={1}>
						<SkillRepresentationCell
							level={level?.level}
							value={String(level?.value)}
							representationType={getRepType(String(level?.repCode))}
							representationLevel={getRepLevel(level?.repCode, level?.level)}
							height={15}
							width={15}
							backgroundColor={level?.backColorCode}
							foregroundColor={level?.foreColorCode}
							backgroundWidth={"22px"}
							backgroundHeight={"22px"}
						/>
					</Box>
				)}
				<Tooltip title={skill.name}>
					<Typography
						sx={{ wordBreak: "break-word" }}
						whiteSpace='normal'
						style={{
							fontSize: "12px",
							fontWeight: "bold",
							padding: "4px",
							overflow: "hidden",
							textOverflow: "ellipsis",
							display: "-webkit-box",
							WebkitLineClamp: "2",
							WebkitBoxOrient: "vertical",
							marginLeft: "10px",
						}}
					>
						<Highlight value={skill.name} searchText={searchText} />
					</Typography>
				</Tooltip>
			</Box>
		</Box>
	);
};

export default ColumnSkillHeader;
