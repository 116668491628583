import Box from "@mui/material/Box";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MyScheduler } from "../../../export/scheduler";
import AppliedFilters from "../../components/filters/applied-filters";
import {
  JOB_ROLES,
  LOCATIONS,
  OVERTIME_AVAILABILITY,
  PUBLISHED_BY,
  SHIFT_BY,
  SKILLS,
  STATUS,
  WORK_CENTERS,
} from "../../components/filters/constants";
import { FilterContext } from "../../components/filters/filter-context";
import { setFilteredShifts } from "../../components/scheduler/store/scheduler-slice";
import { Shift } from "../../components/scheduler/store/types";

const APPLIED_FILTERS_HEIGHT = 57;

const WilyaScheduler = ({ ...props }: any) => {
  const dispatch = useDispatch();
  const shiftsData = useSelector(
    (state: any) => state.supervisor?.schedulerData?.shiftsData
  );
  const shiftPatternsWithDates = useSelector(
    (state: any) => state.supervisor?.filterData?.shiftPatternsWithDates
  );
  const filterContext = useContext(FilterContext);
  if (!filterContext) {
    throw new Error("FilterAccordion must be used within a FilterProvider");
  }

  const { filterAccordionHeight, appliedFilters, setFilterOpen } = filterContext;

  useEffect(() => {
    // Hide filter by default
    setFilterOpen(false);
  }, [setFilterOpen]);

  useEffect(() => {
    try {
      if (shiftsData.length >= 0) {
        const getWorkCenterIdsByLocationId = (locationId: number) => {
          const workCenters = props.filters.find(
              (filter: { name: string }) => filter.name === WORK_CENTERS
          )?.data;
          if (workCenters) {
            return workCenters
                .filter(
                    (workCenter: { locationId: number }) =>
                        workCenter.locationId === locationId
                )
                .map((workCenter: { id: any }) => workCenter.id);
          }
          return "";
        };

        const filteredShiftsByJob = appliedFilters
            .find((appliedFilter) => appliedFilter.type === JOB_ROLES)
            ?.data.map((job: any) => job.id);

        const filteredShiftsBySkill = appliedFilters
            .find((appliedFilter) => appliedFilter.type === SKILLS)
            ?.data.map((skill: any) => skill.id);

        const allWorkCentersFromLocations = appliedFilters
            .find((appliedFilter) => appliedFilter.type === LOCATIONS)
            ?.data.map((location: any) => getWorkCenterIdsByLocationId(location.id))
            .flat();

        const filteredShiftsByWorkCenter = appliedFilters
            .find((appliedFilter) => appliedFilter.type === WORK_CENTERS)
            ?.data.map((workCenter: any) => workCenter.id);

        const filteredShiftsByStatus = appliedFilters
            .find((appliedFilter) => appliedFilter.type === STATUS)
            ?.data.map((status: any) => status.id);

        const filteredShiftsByPublishedBy = appliedFilters
            .find((appliedFilter) => appliedFilter.type === PUBLISHED_BY)
            ?.data.map((publishedBy: any) => publishedBy.id);

        const filteredShiftsByShiftBy = appliedFilters
            .find((appliedFilter) => appliedFilter.type === SHIFT_BY)
            ?.data.map((shiftBy: any) => shiftBy.id);

        const filteredShiftsByShiftPattern = appliedFilters
            .find((appliedFilter) => appliedFilter.type === OVERTIME_AVAILABILITY)
            ?.data.map((shiftPattern: any) => shiftPattern.id);

        const filteredShifts = shiftsData.filter((shift: Shift) => {
          let includeShift = true;

          // Union condition for job and skills
          if (filteredShiftsByJob && filteredShiftsBySkill) {
            includeShift =
                filteredShiftsByJob.includes(shift.jobId) ||
                shift.shiftSkills.some((skill: { skillId: any }) =>
                    filteredShiftsBySkill.includes(skill.skillId)
                );
          } else {
            if (
                filteredShiftsByJob &&
                !filteredShiftsByJob.includes(shift.jobId)
            ) {
              includeShift = false;
            }
            if (
                filteredShiftsBySkill &&
                !shift.shiftSkills.some((skill: { skillId: any }) =>
                    filteredShiftsBySkill.includes(skill.skillId)
                )
            ) {
              includeShift = false;
            }
          }

          // Intersection conditions for other filters
          if (
              includeShift &&
              allWorkCentersFromLocations &&
              !allWorkCentersFromLocations.includes(shift.workCenterId)
          ) {
            includeShift = false;
          }
          if (
              includeShift &&
              filteredShiftsByWorkCenter &&
              !filteredShiftsByWorkCenter.includes(shift.workCenterId)
          ) {
            includeShift = false;
          }
          if (
              includeShift &&
              filteredShiftsByStatus &&
              !filteredShiftsByStatus.includes(shift.isPublished ? 2 : 1)
          ) {
            includeShift = false;
          }
          if (
              includeShift &&
              filteredShiftsByPublishedBy &&
              !filteredShiftsByPublishedBy.includes(shift.createdBy)
          ) {
            includeShift = false;
          }
          if (
              includeShift &&
              filteredShiftsByShiftBy &&
              !filteredShiftsByShiftBy.includes(
                  shift.shiftSkills.length > 0 ? 2 : 1
              )
          ) {
            includeShift = false;
          }
          const scheduleOfAppliedShiftPatterns = shiftPatternsWithDates.filter(
              (shiftPattern: any) => filteredShiftsByShiftPattern?.includes(shiftPattern.id)
                  && Array.isArray(shiftPattern.schedule) && shiftPattern.schedule?.length > 0
          )?.map((shiftPattern: any) => shiftPattern.schedule);

          const isShiftOutsideDateRanges = (
            shift: {
              startDateTime: string ;
              endDateTime: string;
            },
            dateRanges: any
          ) => {
            const shiftStart = new Date(shift.startDateTime);
            const shiftEnd = new Date(shift.endDateTime);

            for (const dayRanges of dateRanges) {
              for (const range of dayRanges) {
                const rangeStart = new Date(range.startDateTime);
                const rangeEnd = new Date(range.endDateTime);

                // Check for overlap:
                if (shiftStart < rangeEnd && shiftEnd > rangeStart) {
                  return false; // Shift falls within a range
                }
              }
            }

            return true; // Shift is outside all ranges
          };

          if (
              includeShift &&
              filteredShiftsByShiftPattern &&
              scheduleOfAppliedShiftPatterns &&
              !isShiftOutsideDateRanges(shift, scheduleOfAppliedShiftPatterns)
          ) {
              includeShift = false;
          }
          
          return includeShift;
        });

        dispatch(setFilteredShifts(filteredShifts));
      }
    } catch (e) {
      // Do nothing
    }
  }, [dispatch, shiftsData, appliedFilters, props.filters, shiftPatternsWithDates]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "15px",
        minHeight: `${filterAccordionHeight}px`,
        flex: 1,
      }}
    >
      <AppliedFilters />
      <MyScheduler
        {...props}
        filterAccordionHeight={filterAccordionHeight - APPLIED_FILTERS_HEIGHT}
      />
    </Box>
  );
};
export default WilyaScheduler;
