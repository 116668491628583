import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 0 }}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

interface ReportTabsProps {
	tabs: { label: string; component: React.ReactNode }[];
	queryParam?: string;
}

const ReportTabs: React.FC<ReportTabsProps> = ({ tabs, queryParam }) => {
	const [value, setValue] = React.useState(0);
	const location = useLocation();

	useEffect(() => {
		if (queryParam) {
			try {
				const params = new URLSearchParams(location.search);
				if (params.get(queryParam)) {
					setValue(1);
				}
			} catch (e) {
				// Do nothing
			}
		}
	}, [location.search, queryParam]);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: "100%", height: "100%", marginTop: -0.9 }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }} pl={1}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label='report tabs'
					sx={{
						".MuiTab-root": {
							paddingBottom: 0.8,
						},
					}}
					TabIndicatorProps={{
						sx: {
							height: "1px",
							marginLeft: "15px",
						},
					}}
				>
					{tabs.map((tab, index) => (
						<Tab
							key={index}
							label={tab.label}
							{...a11yProps(index)}
							sx={{
								fontFamily: "Roboto",
								fontSize: "14px",
								fontWeight: "500",
								lineHeight: "24px",
								letterSpacing: "0.4px",
							}}
						/>
					))}
				</Tabs>
			</Box>
			{tabs.map((tab, index) => (
				<TabPanel key={index} value={value} index={index}>
					{tab.component}
				</TabPanel>
			))}
		</Box>
	);
};

export default ReportTabs;
