import {
	Box,
	Button,
	Grid,
	MenuItem,
	Radio,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { isValidPhoneNumber } from "libphonenumber-js";
import MuiPhoneNumber from "mui-phone-number";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	createCompanyUser,
	getCompanyUsers,
	updateCompanyUser,
} from "../../../../../../../export/gat-admin";
import LoadingButton from "../../../../../../design/wilya-loading-button";
import { deCamelCase } from "../../../../../../utils/helpers";
import useGatAdminConfigData from "../../../../controllers/use-gat-admin-config-data";
import useCompanyUserController from "../controllers/use-company-user-controller";
import WorkcenterTransfer from "./workcenter-transfer";
//import isEmail from "validator/lib/isEmail";

const isEmail = (email: string | null | undefined): boolean => {
	if (!email) return false;

	// Basic email regex pattern
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(email);
};

interface CompanyUserDialogProps {
	creatingNewCompanyUser: any;
	companyUserData: any;
	setCompanyUserData: any;
	onClose: any;
}

const CompanyUserDialog: React.FC<CompanyUserDialogProps> = ({
	creatingNewCompanyUser,
	companyUserData,
	setCompanyUserData,
	onClose,
}) => {
	const dispatch = useDispatch();
	const [initialCompanyUserData, setInitialCompanyUserData] = useState({});
	const [activeStep, setActiveStep] = useState(0);
	const steps = ["User Details", "Associated Workcenters"];

	const [
		{
			createNewCompanyUserStatus,
			updateCompanyUserStatus,
			openCompanyUserDialog,
			allCompanyUserRoles,
			allCompanyWorkCenters,
		},
		{
			checkRoleId,
			getUserRole,
			workCenterIncluded,
			addWorkCenterFromCompany,
			removeWorkCenterFromCompany,
		},
	] = useCompanyUserController({ companyUserData, setCompanyUserData });

	const [phoneNumberError, setPhoneNumberError] = useState("");

	useEffect(() => {
		try {
			// Only while editing we will get email in companyUserData
			if (Object.prototype.hasOwnProperty.call(companyUserData, "email")) {
				if (
					JSON.stringify(initialCompanyUserData) === "{}" &&
					JSON.stringify(companyUserData) !== "{}"
				) {
					setInitialCompanyUserData(companyUserData);
				}
			}
		} catch (e) {
			// Do nothing
		}
	}, [companyUserData, initialCompanyUserData]);

	const closeCompanyUserDialog = useCallback(() => {
		setActiveStep(0);
		setPhoneNumberError("");
		setInitialCompanyUserData({});
		onClose();
	}, [onClose]);

	useEffect(() => {
		const isSuccessful =
			createNewCompanyUserStatus === "fulfilled" ||
			updateCompanyUserStatus === "fulfilled";
		const hasCompanyId =
			initialCompanyUserData && "companyId" in initialCompanyUserData;

		if (isSuccessful && hasCompanyId) {
			dispatch(getCompanyUsers(initialCompanyUserData.companyId as number));
			closeCompanyUserDialog();
		}
	}, [
		createNewCompanyUserStatus,
		updateCompanyUserStatus,
		initialCompanyUserData,
		closeCompanyUserDialog,
		dispatch,
	]);
	// console.log(
	// 	"CompanyUserDialog",
	// 	companyUserData,
	// 	"initialCompanyUserData",
	// 	initialCompanyUserData
	// );
	return (
		<Dialog
			onClose={() => closeCompanyUserDialog()}
			aria-labelledby='add-new-company-user'
			open={openCompanyUserDialog}
			maxWidth={false}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<DialogTitle
				id='company-dialog-title'
				sx={{
					width: "900px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "20px",
						fontWeight: 500,
						color: "rgba(0, 0, 0, 0.87)",
						paddingTop: "29px",
						paddingLeft: "21px",
						paddingBottom: "9px",
						paddingRight: "21px",
					}}
				>
					{creatingNewCompanyUser ? "Add User" : "Edit User"}
				</Typography>
			</DialogTitle>
			<Divider />
			<DialogContent>
				{/*<Grid container sx={{*/}
				{/*    paddingTop: "12px",*/}
				{/*    paddingLeft: "21px",*/}
				{/*    paddingRight: "21px",*/}
				{/*    paddingBottom: "16px"*/}
				{/*}}>*/}
				{/*    <Grid item xs={8}>*/}
				{/*        <TextField*/}
				{/*            label='Username'*/}
				{/*            value={companyUserData.username ?? ""}*/}
				{/*            onChange={e => setCompanyUserData({...companyUserData, username: e.target.value})}*/}
				{/*            variant='filled'*/}
				{/*            fullWidth*/}
				{/*        />*/}
				{/*    </Grid>*/}
				{/*</Grid>*/}
				<Stepper activeStep={activeStep} alternativeLabel>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				{activeStep === 0 && (
					<>
						<Grid
							container
							sx={{
								paddingTop: "12px",
								paddingLeft: "21px",
								paddingRight: "21px",
								paddingBottom: "16px",
							}}
						>
							<Grid item xs={6} pr={0.75}>
								<TextField
									label='First Name *'
									inputProps={{ maxLength: 50 }}
									value={companyUserData.firstName ?? ""}
									onChange={(e) =>
										setCompanyUserData({
											...companyUserData,
											firstName: e.target.value,
										})
									}
									variant='filled'
									fullWidth
								/>
							</Grid>
							<Grid item xs={6} pl={0.75}>
								<TextField
									label='Last Name *'
									inputProps={{ maxLength: 50 }}
									value={companyUserData.lastName ?? ""}
									onChange={(e) =>
										setCompanyUserData({
											...companyUserData,
											lastName: e.target.value,
										})
									}
									variant='filled'
									fullWidth
								/>
							</Grid>
						</Grid>
						<Grid
							container
							sx={{
								paddingTop: "12px",
								paddingLeft: "21px",
								paddingRight: "21px",
								paddingBottom: "16px",
							}}
						>
							<Grid item xs={6} pr={0.75}>
								<TextField
									label='Email ID *'
									disabled={!creatingNewCompanyUser}
									value={companyUserData.email ?? ""}
									inputProps={{ maxLength: 255 }}
									onChange={(e) =>
										setCompanyUserData({
											...companyUserData,
											email: e.target.value.trim(),
										})
									}
									variant='filled'
									fullWidth
								/>
							</Grid>

							<Grid item xs={6}>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-start",
										justifyContent: "center",
									}}
								>
									<TextField
										label='User Role *'
										value={getUserRole && getUserRole(companyUserData.roleId)}
										variant='filled'
										fullWidth
										select
										onChange={(e) => {
											const roleId = allCompanyUserRoles.filter(
												(role: { name: string }) =>
													deCamelCase(role.name) === e.target.value
											)[0].id;

											setCompanyUserData({
												...companyUserData,
												roleId: roleId,
											});
										}}
									>
										{allCompanyUserRoles &&
											allCompanyUserRoles.map(
												(role: { id: number; name: string }) => (
													<MenuItem
														key={`company-user-role-${role.id}`}
														value={deCamelCase(role.name)}
													>
														{deCamelCase(role.name)}
													</MenuItem>
												)
											)}
									</TextField>
								</Box>
							</Grid>
						</Grid>

						<Grid
							container
							sx={{
								paddingTop: "16px",
								paddingLeft: "21px",
								paddingRight: "21px",
								paddingBottom: "16px",
							}}
						>
							<Grid item xs={6} pl={0.75}>
								<MuiPhoneNumber
									defaultCountry={"us"}
									onlyCountries={["us", "mx", "in", "om", "gb", "br"]}
									label='Phone Number'
									disableAreaCodes={true}
									autoFormat={false}
									value={companyUserData.phoneNumber ?? ""}
									onChange={(phone) => {
										if (
											isValidPhoneNumber(phone as string) ||
											(phone as string).trim() === ""
										) {
											setPhoneNumberError("");
										} else {
											setPhoneNumberError(
												"Please enter valid phone number with area code."
											);
										}
										setCompanyUserData({
											...companyUserData,
											phoneNumber: phone,
										});
									}}
									error={phoneNumberError !== ""}
									helperText={phoneNumberError}
									variant='filled'
									fullWidth
								/>
							</Grid>
						</Grid>
						<Grid
							container
							sx={{
								paddingTop: "16px",
								paddingLeft: "21px",
								paddingRight: "21px",
								paddingBottom: "16px",
							}}
						>
							<Grid item xs={12}>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "flex-start",
									}}
								>
									<Switch
										checked={companyUserData.emailNotificationsEnabled ?? true}
										onChange={(e) =>
											setCompanyUserData({
												...companyUserData,
												emailNotificationsEnabled: e.target.checked,
											})
										}
									/>
									<Typography
										sx={{
											fontFamily: "Roboto",
											fontWeight: 400,
											fontSize: "16px",
											color: "rgba(0, 0, 0, 0.87)",
										}}
									>
										User will receive all email notifications
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</>
				)}
				{activeStep === 1 && (
					<WorkcenterTransfer
						allCompanyWorkCenters={allCompanyWorkCenters}
						companyUserData={initialCompanyUserData}
						setCompanyUserData={setCompanyUserData}
					/>
				)}
			</DialogContent>
			<Divider />

			<Box
				sx={{
					display: "flex",
					//flexDirection: "row",
					justifyContent: "space-between",
					marginTop: "16px",
					marginBottom: "16px",
					marginRight: "21px",
					marginLeft: "21px",
				}}
			>
				<Box>
					<Button
						disabled={activeStep === 0}
						onClick={() =>
							setActiveStep((prevActiveStep) => prevActiveStep - 1)
						}
					>
						Back
					</Button>
				</Box>
				<Box>
					<Button
						variant='text'
						onClick={() => {
							setActiveStep(0);
							closeCompanyUserDialog();
						}}
						sx={{ marginRight: "16px" }}
					>
						Cancel
					</Button>
					{/*First Name, Last Name, Email, at least a Work center and Phone number required */}
					{activeStep === steps.length - 1 ? (
						<LoadingButton
							variant='contained'
							disabled={
								companyUserData?.workCenterIds === "" ||
								JSON.stringify(initialCompanyUserData) ===
									JSON.stringify(companyUserData)
							}
							sx={{ marginLeft: "16px" }}
							loading={
								createNewCompanyUserStatus === "pending" ||
								updateCompanyUserStatus === "pending"
							}
							onClick={() => {
								// Trim extra spaces
								companyUserData = {
									...companyUserData,
									firstName: companyUserData.firstName.trim(),
									lastName: companyUserData.lastName.trim(),
								};

								if (creatingNewCompanyUser) {
									dispatch(createCompanyUser(companyUserData));
								} else {
									dispatch(updateCompanyUser(companyUserData));
								}
							}}
							loadingIndicator={
								<CircularProgress
									size={16}
									sx={{ color: "rgba(0, 0, 0, 0.38)" }}
								/>
							}
						>
							{creatingNewCompanyUser ? "CREATE" : "UPDATE"}
						</LoadingButton>
					) : (
						<Button
							variant='contained'
							onClick={() =>
								setActiveStep((prevActiveStep) => prevActiveStep + 1)
							}
							disabled={
								!isEmail(companyUserData.email) ||
								phoneNumberError !== "" ||
								companyUserData?.firstName === "" ||
								companyUserData?.lastName === ""
							}
						>
							Next
						</Button>
					)}
				</Box>
			</Box>
		</Dialog>
	);
};

export default CompanyUserDialog;
