import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { setSupervisorRateLimitError } from "../../../features/user/user-slice";
import axios from "../../../utils/api-client";
import { appendPriorityToSkills } from "../../../utils/helpers";
import { SkillLevelRequirement } from "../../scheduler/store/types";
import { SkillSliceStateTypes } from "./skills-types";

const initialState: SkillSliceStateTypes = {
	masterData: {
		status: "idle",
		error: "",
		skills: [],
		certificates: [],
		obsoleteCertificates: [],
	},
	getCertifications: {
		status: "idle",
		error: "",
	},
	isCreateSkillDiaogOpen: false,
	isEditSkillDialogOpen: false,
	skillToEdit: {
		name: "",
		description: "",
		certificates: [],
		id: 0,
		priorityId: 0,
		certificationIds: "",
		isActive: undefined,
		skillLevelRequirements: [],
	},
	createNewSkill: {
		status: "idle",
		errorMessage: "",
	},

	editSkill: {
		status: "idle",
		errorMessage: "",
	},
	getSkillPriorities: {
		status: "idle",
		error: "",
	},
	skillPriorities: [],
	skillDrawer: {
		isOpen: false,
		skillId: null,
	},
	skillLevelRequirements: {
		skillId: null,
		data: [],
		status: "idle",
		errorMessage: "",
	},
};

export const getSkillPriorities = createAsyncThunk(
	"skills/getSkillPriorities",
	async (payload, thunkAPI) => {
		const { fulfillWithValue, rejectWithValue } = thunkAPI;
		try {
			const skillPriorityEnabled =
				(thunkAPI.getState() as any).user.userData.skillPriorityEnabled;
			if (skillPriorityEnabled) {
				const res = await axios.get(
					`${process.env.REACT_APP_API_END_POINT}/Skill/Priorities`
				);
				return fulfillWithValue(res.data);
			} else {
				return fulfillWithValue([]);
			}
		} catch (error: any) {
			// return rejectWithValue(error.response.data.message);
			try {
				const errorResponse = error?.response.data;
				errorResponse.status = error?.response?.status;
				if (errorResponse.status === 429) {
					thunkAPI.dispatch(setSupervisorRateLimitError());
					return rejectWithValue(errorResponse);
				}
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

export const fetchSkills = createAsyncThunk(
	"skills/fetchSkillsByUser",
	async (payload, thunkAPI) => {
		const { dispatch, fulfillWithValue, rejectWithValue } = thunkAPI;
		try {
			const skillPriorityEnabled = (thunkAPI.getState() as any).user.userData
				.skillPriorityEnabled;
			let skillPrioritiesResponse = null;
			let skillPriorities = [];
			if (skillPriorityEnabled) {
				skillPrioritiesResponse = await dispatch(getSkillPriorities());
				if (getSkillPriorities.fulfilled.match(skillPrioritiesResponse)) {
					skillPriorities = skillPrioritiesResponse.payload;
				}
			}
			const skillsResponse = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Skill/GetSkillsByUser`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: localStorage.getItem("token"),
					},
				}
			);
			return fulfillWithValue({
				skills: skillsResponse.data ?? [],
				skillPriorities: skillPriorities ?? [],
			});
		} catch (error: any) {
			try {
				const errorResponse = error?.response.data;
				errorResponse.status = error?.response?.status;
				if (errorResponse.status === 429) {
					dispatch(setSupervisorRateLimitError());
					return rejectWithValue(errorResponse);
				}
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("Error fetching skills");
			}
		}
	}
);

export const fetchCertifications = createAsyncThunk(
	"skills/fetchCertifications",
	async (payload, { fulfillWithValue, rejectWithValue, dispatch }) => {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Company/Certs`
			);
			return fulfillWithValue(res.data);
		} catch (error: any) {
			try {
				const errorResponse = error?.response.data;
				errorResponse.status = error?.response?.status;
				if (errorResponse.status === 429) {
					dispatch(setSupervisorRateLimitError());
					return rejectWithValue(errorResponse);
				}
				return rejectWithValue(error.response.data.message);
			} catch (e) {
				return rejectWithValue("An error occurred");
			}
		}
	}
);

interface CreateNewSkillPayload {
	name: string;
	description: string;
	certificationIds: string;
	priorityId: number;
	SkillLevelRequirements: SkillLevelRequirement[];
}

export const createSkill = createAsyncThunk(
	"skills/createSkill",
	async (
		payload: CreateNewSkillPayload,
		{ fulfillWithValue, rejectWithValue, dispatch }
	) => {
		try {
			console.log("payload", payload);

			const res = await axios.post(
				`${process.env.REACT_APP_API_END_POINT}/Skill/Create`,
				payload
			);
			return fulfillWithValue(res.data);
		} catch (error: any) {
			if (error.response.status === 404) {
				return rejectWithValue(error.response.data.message);
			}
			const errorResponse = error?.response.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			return rejectWithValue(error.response.data.message);
		}
	}
);

interface EditSkillPayload {
	id: number;
	name: string;
	description: string;
	certificationIds: string;
	priorityId: number;
	isActive: boolean | undefined;
	skillLevelRequirements: SkillLevelRequirement[];
}

export const editSkill = createAsyncThunk(
	"skills/editSkill",
	async (
		payload: EditSkillPayload,
		{ fulfillWithValue, rejectWithValue, dispatch }
	) => {
		const {
			id,
			name,
			description,
			certificationIds,
			isActive,
			priorityId,
			skillLevelRequirements,
		} = payload;

		try {
			const res = await axios.put(
				`${process.env.REACT_APP_API_END_POINT}/Skill/Update`,
				{
					id,
					name,
					description,
					priorityId,
					certificationIds: certificationIds,
					isObsolete: !isActive,
					SkillLevelRequirements: skillLevelRequirements,
				}
			);
			return fulfillWithValue(res.data);
		} catch (error: any) {
			if (error.response.status === 404) {
				return rejectWithValue(error.response.data.message);
			}
			const errorResponse = error?.response.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			return rejectWithValue(error.response.data.message);
		}
	}
);

export const fetchSkillLevelRequirements = createAsyncThunk(
	"skills/fetchSkillLevelRequirements",
	async (payload: number, { fulfillWithValue, rejectWithValue, dispatch }) => {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_API_END_POINT}/Skill/GetSkillLevelRequirements/${payload}`
			);
			return fulfillWithValue({
				skillId: payload,
				skillLevelRequirements: res.data,
			});
		} catch (error: any) {
			if (error.response.status === 404) {
				return rejectWithValue(error.response.data.message);
			}
			const errorResponse = error?.response.data;
			errorResponse.status = error?.response?.status;
			if (errorResponse.status === 429) {
				dispatch(setSupervisorRateLimitError());
				return rejectWithValue(errorResponse);
			}
			return rejectWithValue(error.response.data.message);
		}
	}
);

export const Skills = createSlice({
	name: "skills",
	initialState,
	reducers: {
		openEditDialog: (state, action) => {
			state.isEditSkillDialogOpen = true;
			state.skillToEdit = action.payload;
			state.skillLevelRequirements.skillId = action.payload.id;
		},
		closeEditDialog: (state) => {
			state.isEditSkillDialogOpen = false;
			state.skillLevelRequirements.skillId = null;
			state.skillLevelRequirements.data = [];
		},

		openCreateDialog: (state) => {
			state.isCreateSkillDiaogOpen = true;
		},
		closeCreateDialog: (state) => {
			state.isCreateSkillDiaogOpen = false;
		},
		resetEditSkill: (state) => {
			state.skillToEdit = {
				name: "",
				description: "",
				certificates: [],
				id: 0,
				priorityId: 0,
				certificationIds: "",
				isActive: undefined,
				skillLevelRequirements: [],
			};
			state.isEditSkillDialogOpen = false;
			state.skillLevelRequirements.skillId = null;
			state.skillLevelRequirements.data = [];
			state.editSkill.status = "idle";
			state.editSkill.errorMessage = "";
		},
		resetCreateSkill: (state) => {
			state.createNewSkill.status = "idle";
			state.createNewSkill.errorMessage = "";
			state.isCreateSkillDiaogOpen = false;
		},
		resetFetchCertifications: (state) => {
			state.getCertifications.status = "idle";
			state.getCertifications.error = "";
		},
		openSkillDrawer: (state, action) => {
			state.skillDrawer.isOpen = true;
			state.skillDrawer.skillId = action.payload;
		},
		closeSkillDrawer: (state) => {
			state.skillDrawer.isOpen = false;
		},
		setSkillLevelRequirementsSkillId: (state, action) => {
			state.skillLevelRequirements.skillId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(fetchSkills.pending, (state) => {
				state.masterData.status = "pending";
			})
			.addCase(fetchSkills.fulfilled, (state, action) => {
				state.masterData.status = "fulfilled";

				let { skills } = action.payload;
				const { skillPriorities } = action.payload;
				skills = skills.map((skill: { isObsolete: boolean }) => ({
					...skill,
					isActive: !skill.isObsolete,
				}));
				skills = appendPriorityToSkills(
					skills,
					Array.isArray(skillPriorities) ? skillPriorities : []
				);
				state.masterData.skills = skills;
			})
			.addCase(fetchSkills.rejected, (state, action: any) => {
				if (action.payload?.status === 429) {
					state.masterData.status = "error";
				} else {
					state.masterData.status = "rejected";
					state.masterData.error = action.error.message ?? "";
				}
			})
			.addCase(fetchCertifications.pending, (state) => {
				state.getCertifications.status = "pending";
			})
			.addCase(fetchCertifications.fulfilled, (state, action) => {
				state.getCertifications.status = "fulfilled";
				const certs = action.payload.map((cert: { isObsolete: boolean }) => ({
					...cert,
					isActive: !cert.isObsolete,
				}));
				state.masterData.certificates = certs;
				state.masterData.obsoleteCertificates = action.payload
					.filter((cert: { isObsolete: boolean }) => cert.isObsolete)
					.map((cert: { id: number }) => cert.id);
			})
			.addCase(fetchCertifications.rejected, (state, action: any) => {
				if (action.payload?.status === 429) {
					state.getCertifications.status = "error";
				} else {
					state.getCertifications.status = "rejected";
					state.getCertifications.error = action.error.message ?? "";
				}
			})
			.addCase(editSkill.pending, (state) => {
				state.editSkill.status = "pending";
			})
			.addCase(editSkill.fulfilled, (state) => {
				state.editSkill.status = "fulfilled";
				state.isEditSkillDialogOpen = false;
			})
			.addCase(editSkill.rejected, (state, action: any) => {
				if (action.payload?.status === 429) {
					state.editSkill.status = "error";
				} else {
					state.editSkill.status = "rejected";
					state.editSkill.errorMessage =
						action.error.message ?? "An error occurred";
				}
			})
			.addCase(createSkill.pending, (state) => {
				state.createNewSkill.status = "pending";
			})
			.addCase(createSkill.fulfilled, (state) => {
				state.createNewSkill.status = "fulfilled";
				state.isCreateSkillDiaogOpen = false;
			})
			.addCase(createSkill.rejected, (state, action: any) => {
				if (action.payload?.status === 429) {
					state.createNewSkill.status = "error";
				} else {
					state.createNewSkill.status = "rejected";
					state.createNewSkill.errorMessage =
						action.error.message ?? "An error occurred";
				}
			})
			.addCase(getSkillPriorities.pending, (state) => {
				state.getSkillPriorities.status = "pending";
			})
			.addCase(getSkillPriorities.fulfilled, (state, action) => {
				state.getSkillPriorities.status = "fulfilled";
				state.skillPriorities = Array.isArray(action.payload)
					? action.payload
					: [];
			})
			.addCase(getSkillPriorities.rejected, (state, action: any) => {
				if (action.payload?.status === 429) {
					state.getSkillPriorities.status = "error";
				} else {
					state.getSkillPriorities.status = "rejected";
					state.getSkillPriorities.error =
						action.error.message ?? "An error occurred";
				}
			})
			.addCase(fetchSkillLevelRequirements.pending, (state) => {
				state.skillLevelRequirements.status = "pending";
			})

			.addCase(fetchSkillLevelRequirements.fulfilled, (state, action) => {
				state.skillLevelRequirements.status = "fulfilled";
				state.skillLevelRequirements.data =
					action.payload.skillLevelRequirements;
				state.skillLevelRequirements.skillId = action.payload.skillId;
			})

			.addCase(fetchSkillLevelRequirements.rejected, (state, action: any) => {
				if (action.payload?.status === 429) {
					state.skillLevelRequirements.status = "error";
				} else {
					state.skillLevelRequirements.status = "rejected";
					state.skillLevelRequirements.errorMessage =
						action.error.message ?? "An error occurred";
				}
			});
	},
});

export const {
	openEditDialog,
	closeEditDialog,
	openCreateDialog,
	closeCreateDialog,
	resetCreateSkill,
	resetEditSkill,
	resetFetchCertifications,
	openSkillDrawer,
	closeSkillDrawer,
	setSkillLevelRequirementsSkillId,
} = Skills.actions;

export default Skills.reducer;
