import { Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

import ShiftCancelForm from "./shift-cancel-form";

interface ShiftCancelDialogProps {
	open: boolean;
	handleClose: () => void;
	shiftId: number;
}

const ShiftCancelDialog = ({
	open,
	handleClose,
	shiftId,
}: ShiftCancelDialogProps) => {
	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby='add-new-shift'
			open={open}
			maxWidth={false}
		>
			<DialogTitle
				id='location-dialog-title'
				sx={{
					width: "600px",
					padding: "45px 45px 14px 45px",
				}}
			>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontSize: "20px",
						fontWeight: 500,
						color: "rgba(0, 0, 0, 0.87)",
					}}
				>
					Cancel Shift
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						//	paddingTop: "12px",
						paddingLeft: "21px",
						paddingRight: "21px",
						paddingBottom: "12px",
						//height: 540,
					}}
				>
					<ShiftCancelForm shiftId={shiftId} handleClose={handleClose} />
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default ShiftCancelDialog;
