import GroupIcon from "@mui/icons-material/Group";
import { Box, LinearProgress, Typography } from "@mui/material";
import React from "react";

import SortedDataGridPro6 from "../../../../../../../design/sorted-datagrid-pro-6";
import useGatAdminConfigData from "../../../../../controllers/use-gat-admin-config-data";
import useWorkerSubtypeColumn from "../controllers/use-worker-subtype-column";

interface WorkerSubTypeTableProps {
	openEditDialog: (type: "add" | "edit", id?: number) => void;
	closeEditDialog: () => void;
}

export const WorkerSubTypeTable = ({
	openEditDialog,
	closeEditDialog,
}: WorkerSubTypeTableProps) => {
	const { workerSubTypes, getWorkerSubTypeStatus } = useGatAdminConfigData();
	const columns = useWorkerSubtypeColumn(openEditDialog);

	return (
		<Box
			sx={{
				overflow: "hidden",
				width: "100%",
				height: workerSubTypes.length === 0 ? "200px" : "auto",
			}}
		>
			<SortedDataGridPro6
				rows={workerSubTypes}
				columns={columns}
				initialState={{
					pagination: { paginationModel: { pageSize: 25 } },
				}}
				pagination
				// disableSelectionOnClick
				disableColumnSelector
				disableDensitySelector
				disableColumnReorder
				disableColumnMenu
				loading={getWorkerSubTypeStatus === "pending"}
				slots={{
					loadingOverlay: () => <LinearProgress />,
					noRowsOverlay: () => (
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
							}}
						>
							<GroupIcon />
							<Typography sx={{ marginLeft: "10px" }}>
								No Worker Sub Types
							</Typography>
						</Box>
					),
				}}
				hideFooter
				disableRowSelectionOnClick
				sx={{
					overflow: "hidden",
					border: "none !important",
					"& .MuiDataGrid-columnHeader": {
						"&:first-of-type": {
							paddingLeft: "24px",
						},
						"&.hover-column-header": {
							"& .MuiDataGrid-columnSeparator": {
								display: "none",
							},
							"& .MuiDataGrid-sortIcon": {
								display: "none",
							},
						},
						"& .MuiDataGrid-columnSeparator": {
							display: "none",
						},
					},
					"& .MuiDataGrid-cell": {
						"&:first-of-type": {
							paddingLeft: "24px",
						},
					},
					".MuiDataGrid-cell:focus": {
						outline: "none",
					},
					"& .MuiDataGrid-row": {
						cursor: "pointer",
					},
					"& .MuiTablePagination-root": {
						marginRight: "60px",
					},
					".MuiDataGrid-cell": {
						"&:focus": {
							outline: "none",
						},
						"&.hover-column-cell": {
							position: "sticky",
							right: 0,
							zIndex: 1,
						},
						"& .hover-content": {
							transition: "opacity 0.3s ease-in-out",
							opacity: 0,
						},
					},
					".MuiDataGrid-columnHeader:hover": {
						"& .MuiDataGrid-columnSeparator": {
							display: "flex",
						},
					},
					".MuiDataGrid-row:hover .hover-column-cell .hover-content": {
						opacity: 1,
					},
					"& .MuiDataGrid-row.Mui-selected .hover-column-cell .hover-content": {
						backgroundColor: "#e7e9f0",
					},
				}}
			/>
		</Box>
	);
};
