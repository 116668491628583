import React, {useMemo} from "react";
import { useSelector } from "react-redux";

import {WorkerInsightRootState} from "../slices/insight-report-types";

interface SkillWithLevel {
    skillName: string | null;
    skillId: number | null;
    level: number | null;
    minSkillLevel?: number | null;
    isPrimaryJobSkill?: boolean;
    isInterested?: boolean;
}

const useInsightWorkerDetailsByGapController = () => {

    const workerInsightDetailsData = useSelector(
        (state: WorkerInsightRootState) => state.workerInsightReportData.workerInsightDetailsData);
    const fetchWorkerInsightReportStatus = useSelector(
        (state: WorkerInsightRootState) => state.workerInsightReportData.fetchWorkerInsightReport.status);
    const allSkillLevels = useSelector(
        (state: WorkerInsightRootState) =>
            state.workerInsightReportData.skillsLevelLegendsData
    );

    const [searchText, setSearchText] = React.useState("");
    const [expanded, setExpanded] = React.useState<string[]>([]);

    const primaryJobSkills: SkillWithLevel[] = useMemo(() => {
        const getSkillLevelByLevelId = (levelId: number) => {
            try {
                const skillLevel = allSkillLevels.find(
                    (allSkillLevelObj: any) => allSkillLevelObj.id === levelId
                );
                if (skillLevel) {
                    return skillLevel.level ?? null;
                }
                return null;
            } catch (e) {
                return null;
            }
        };

        const getSkillLevel = (skillId: number | null) => {
            try {
                const skill = workerInsightDetailsData?.workerSkillsByLevel?.find(
                    (workerSkillByLevelObj: any) => workerSkillByLevelObj.skillId === skillId
                );
                if (skill) {
                    return skill.level ?? null;
                }
                return null;
            } catch (e) {
                return null;
            }
        };
        return workerInsightDetailsData?.jobSkillMinSkillLevel?.map(
            (jobSkillMinSkillLevelObj: { skillName?: string; skillId: number | null; minSkillLevelId: number }) => {
                return {
                    skillName: jobSkillMinSkillLevelObj.skillName?.trim() ?? "-",
                    skillId: jobSkillMinSkillLevelObj.skillId ?? null,
                    level: getSkillLevel(jobSkillMinSkillLevelObj.skillId) ?? null,
                    minSkillLevel: getSkillLevelByLevelId(jobSkillMinSkillLevelObj.minSkillLevelId),
                    isPrimaryJobSkill: true,
                    isInterested: false,
                };
            }
        ) ?? [];
    }, [allSkillLevels, workerInsightDetailsData]);

    const remainingWorkerSkills: SkillWithLevel[] = useMemo(() => {
        if (!workerInsightDetailsData?.workerSkillsByLevel) return [];

        const primarySkillIds = new Set(primaryJobSkills.map(skill => skill.skillId));

        return workerInsightDetailsData.workerSkillsByLevel
            .filter(({ skillId }) => !primarySkillIds.has(skillId))
            .map(({ skillName, skillId, level }) => ({
                skillName: skillName?.trim() ?? "-",
                skillId,
                level,
                minSkillLevel: null,
                isPrimaryJobSkill: false,
                isInterested: false,
            }));
    }, [workerInsightDetailsData, primaryJobSkills]);

    const interestedWorkerSkills: SkillWithLevel[] = useMemo(() => {
        return workerInsightDetailsData?.interestedSkills?.map(
            (interestedSkillObj: { skillName?: string; skillId: number }) => {
                return {
                    skillName: interestedSkillObj.skillName?.trim() ?? "-",
                    skillId: interestedSkillObj.skillId,
                    level: null,
                    minSkillLevel: null,
                    isPrimaryJobSkill: false,
                    isInterested: true,
                };
            }
        ) ?? [];
    }, [workerInsightDetailsData]);

    const allSkills = useMemo(() => {
        const combinedSkills = [...primaryJobSkills, ...remainingWorkerSkills, ...interestedWorkerSkills];

        if (!searchText?.length) return combinedSkills;

        const searchTextLowerCase = searchText.toLowerCase().replace(/\s/g, "");

        return combinedSkills.filter((skill) =>
            skill.skillName?.toLowerCase().replace(/\s/g, "").includes(searchTextLowerCase)
        );
    }, [primaryJobSkills, remainingWorkerSkills, interestedWorkerSkills, searchText]);

    return [
        {
            searchText,
            expanded,
            fetchWorkerInsightReportStatus,
            allSkills
        },
        {
            setSearchText,
            setExpanded
        }
    ]
}

export default useInsightWorkerDetailsByGapController;