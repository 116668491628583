import { Box, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

import LoadingButton from "../../../../../design/wilya-loading-button";
import useGatAdminConfigData from "../../../controllers/use-gat-admin-config-data";

interface ActionButtonProps {
	oldData: any;
	newData: any;
	resetChanges: () => void;
	handleSave: () => void;
}
const ActionButton = ({
	oldData,
	newData,
	resetChanges,
	handleSave,
}: ActionButtonProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const { getLocationSettingsStatus } = useGatAdminConfigData();

	const handleSettingsSave = () => {
		setIsLoading(true);
		handleSave();
	};

	useEffect(() => {
		if (getLocationSettingsStatus === "fulfilled") {
			setIsLoading(false);
		}
	}, [getLocationSettingsStatus]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "flex-end",
				paddingTop: "16px",
				paddingBottom: "12px",
			}}
		>
			<Button
				variant='outlined'
				disabled={
					JSON.stringify(oldData) === JSON.stringify(newData) ||
					getLocationSettingsStatus === "pending" ||
					isLoading
				}
				onClick={() => {
					resetChanges();
				}}
			>
				Discard all new changes
			</Button>
			<LoadingButton
				variant='contained'
				disabled={
					JSON.stringify(oldData) === JSON.stringify(newData) ||
					getLocationSettingsStatus === "pending" ||
					isLoading
				}
				sx={{ marginLeft: "16px" }}
				loading={isLoading}
				onClick={handleSettingsSave}
				loadingIndicator={
					<CircularProgress size={16} sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
				}
			>
				Save
			</LoadingButton>
		</Box>
	);
};

export default ActionButton;
