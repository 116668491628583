// TODO - Type 'any' needs to be fixed.
/* eslint-disable no-mixed-spaces-and-tabs */

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
	Alert,
	AlertTitle,
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormLabel,
	InputAdornment,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Typography,
} from "@mui/material";
import { TextField as MUITextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro-6";
import { AdapterDayjs } from "@mui/x-date-pickers-pro-6/AdapterDayjs";
import format from "date-fns/format";
import dayjs from "dayjs";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { useConfirm } from "material-ui-confirm";
import React, {
	forwardRef,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { useDispatch } from "react-redux";

import { colors } from "../../../../../../export/config";
import {
	closeAddWorkerDialog,
	setIsEmployeeIdUnique,
	// eslint-disable-next-line sort-imports
	ShiftPatternDialog,
} from "../../../../../../export/workers";
import { getLocationShiftPatterns } from "../../../../../../export/workers";
import { PhoneTextField } from "../../../../../../export/workers";
import JobColor from "../../../../../design/ui/job-color";
import { deCamelCase } from "../../../../../utils/helpers";
import Highlight from "../../../../Highlight";
import useShiftPatternMenupropsController from "../../../controllers/use-shift-pattern-menuprops-controller";
import useWorkersData from "../../../controllers/use-workers-data";

interface Supervisor {
	id: string;
	firstName: string;
	lastName: string;
	isActive: boolean;
	roles: {
		name: string;
	}[];
}

interface WorkerSubType {
	id: number;
	name: string;
	description?: string;
	isActive?: boolean;
}

const useStyles = makeStyles(() => ({
	formRoot: {
		width: "100%",
	},
	formControlRoot: {
		width: "100%",
		backgroundColor: colors.light,
	},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

interface WorkerDetailFormProps {
	values: any;
	setFieldValue: (field: string, value: any) => void;
	isActive: boolean;
	handleNext: () => void;
	isValid: boolean;
	existingWorkers: any[];
	workerTypes: any[];
	allLocations: any[];
	userLocations: any[];
	getWorkerType: (id: number) => any;
	checkWorkerIdAlreadyExists: (value: any) => void;
	isSubmitting: boolean;
	dirty: boolean;
	isEditing: boolean;
	setActiveStep: (step: number) => void;
	errors: any;
	allJobRoles: any[];
}

const EMPLOYEE_ID_AVAILABILITY_CHECK_BUTTON_TEXT = "Check";
const WORKER_ID_AVAILABLE = "Worker ID is available";

const WorkerDetailForm = forwardRef<HTMLDivElement, WorkerDetailFormProps>(
	function WorkerDetailForm(props, ref) {
		const {
			values,
			setFieldValue,
			isActive,
			handleNext,
			isValid,
			existingWorkers,
			workerTypes,
			allLocations,
			userLocations,
			getWorkerType,
			checkWorkerIdAlreadyExists,
			isSubmitting,
			dirty,
			isEditing,
			setActiveStep,
			errors,
			allJobRoles,
		} = props;
		const classes = useStyles();
		const dispatch = useDispatch();

		const { myElementRef, position } = useShiftPatternMenupropsController();

		const {
			locationShiftPatterns,
			getLocationShiftPatternStatus,
			allSupervisors,
			createNewWorkerStatus,
			createNewWorkerErrorMessage,
			editWorkerStatus,
			editWorkerErrorMessage,
			isEmployeeIdUniqueStatus,
			isEmployeeIdUniqueData,
			workerSubTypes,
			isWorkerSubTypeEnabled,
		} = useWorkersData();

		const [lastSelectedLocationId, setLastSelectedLocationId] = useState(
			values.locationId ?? ""
		);

		const [selectedShiftPattern, setSelectedShiftPattern] = useState<any>({});
		const [isShiftPatternOpen, setIsShiftPatternOpen] = useState(false);

		// WorkerId helper text
		const getWorkerIdHelperText = () => {
			try {
				const workerId = values.companyEmployeeId;

				const isWorkerIdChecked = isEmployeeIdUniqueData?.checked;
				const isWorkerIdUnique = isEmployeeIdUniqueData?.isUnique;
				const isWorkerIdSame =
					isEmployeeIdUniqueData?.originalEmployeeId !== workerId;
				const doesWorkerIdMatch =
					isEmployeeIdUniqueData?.employeeId === workerId;
				const isWorkerIdProvided = workerId.length > 0;

				let helperText = "";

				if (isWorkerIdSame) {
					if (
						isWorkerIdProvided &&
						doesWorkerIdMatch &&
						!isWorkerIdUnique &&
						isWorkerIdChecked
					) {
						helperText = "Worker ID already exists";
					} else if (!isWorkerIdChecked) {
						helperText = "";
					} else {
						helperText = WORKER_ID_AVAILABLE;
					}
				}

				return helperText;
			} catch (e) {
				return "";
			}
		};

		// WorkerId end adornment
		const getWorkerIdEndAdornment = () => {
			try {
				const isPending = isEmployeeIdUniqueStatus === "pending";
				const isWorkerIdEmpty = values.companyEmployeeId === "";
				const isWorkerIdChecked = isEmployeeIdUniqueData.checked;
				const showCheckIcon =
					!isPending &&
					!getWorkerIdError() &&
					!isWorkerIdEmpty &&
					isWorkerIdChecked &&
					isEmployeeIdUniqueData.originalEmployeeId !==
						values.companyEmployeeId;
				const showButton =
					!isPending &&
					!getWorkerIdError() &&
					!isWorkerIdEmpty &&
					!isWorkerIdChecked;

				if (showButton) {
					checkWorkerIdAlreadyExists(values.companyEmployeeId);
				}

				return (
					<InputAdornment position='end'>
						{isPending ? (
							<CircularProgress size={20} />
						) : showCheckIcon ? (
							<CheckCircleIcon sx={{ color: "#2E7D32" }} />
						) : null}
					</InputAdornment>
				);
			} catch (e) {
				return <></>;
			}
		};
		// WorkerId error
		const getWorkerIdError = () => {
			try {
				const errorInWorkerId = errors.companyEmployeeId !== undefined;
				const workerId = values.companyEmployeeId;
				const isWorkerIdChecked = isEmployeeIdUniqueData?.checked;
				const isWorkerIdUnique = isEmployeeIdUniqueData?.isUnique;
				const doesWorkerIdMatch =
					isEmployeeIdUniqueData?.employeeId === workerId;
				const isWorkerIdProvided = workerId.length > 0;
				const isWorkerIdDuplicate =
					isWorkerIdProvided &&
					doesWorkerIdMatch &&
					!isWorkerIdUnique &&
					isWorkerIdChecked;

				return errorInWorkerId || isWorkerIdDuplicate;
			} catch (e) {
				return false;
			}
		};

		const openShiftPattern = () => {
			setIsShiftPatternOpen(true);
		};

		const closeShiftPattern = () => {
			setIsShiftPatternOpen(false);
		};

		const companySupervisors = allSupervisors.filter(
			(obj: Supervisor) =>
				!obj.roles.some(
					(role: { name: string }) => role.name === "ReadOnly" && obj.isActive
				)
		);

		const locationWorkcenters = allLocations.find(
			(location) => location.id === values.locationId
		)?.workCenters;

		useEffect(() => {
			if (values.shiftPatternId && locationShiftPatterns.length > 0) {
				const pattern = locationShiftPatterns.find(
					(pattern: { id: any }) => pattern.id === values.shiftPatternId
				);
				setSelectedShiftPattern(pattern);
			}
		}, [values.shiftPatternId, locationShiftPatterns]);

		const confirm = useConfirm();

		const isUserLocation = useCallback(
			(id) => {
				let returnValue = false;
				if (userLocations) {
					if (userLocations.length > 0) {
						userLocations.forEach((location) => {
							if (Object.prototype.hasOwnProperty.call(location, "id")) {
								if (parseInt(location.id) === parseInt(id)) {
									returnValue = true;
								}
							}
						});
					}
				}
				return returnValue;
			},
			[userLocations]
		);

		const checkPhoneAlreadyExists = (value: string) => {
			try {
				const foundWorker = existingWorkers.filter(
					(worker) => worker.phoneNumber === value
				);
				return foundWorker.length > 0;
			} catch (e) {
				return false;
			}
		};

		const getSelectedLocation = useCallback(
			(forId = "") => {
				let returnValue = null;
				const id = forId !== "" ? forId : values.locationId;
				if (id && allLocations && allLocations.length > 0) {
					allLocations.forEach((location) => {
						if (parseInt(id) === parseInt(location.id)) {
							returnValue = location;
						}
					});
				}
				return returnValue;
			},
			[allLocations, values.locationId]
		);

		const getSelectedWorkcenter = useCallback(
			(forId = "") => {
				let returnValue = null;
				const id = forId !== "" ? forId : values.workCenterId;
				if (id && locationWorkcenters && locationWorkcenters.length > 0) {
					locationWorkcenters.forEach((wc: { id: string }) => {
						if (parseInt(id) === parseInt(wc.id)) {
							returnValue = wc;
						}
					});
				}
				return returnValue;
			},
			[locationWorkcenters, values.workCenterId]
		);

		const getSelectedSupervisor = useCallback(
			(forId = "") => {
				let returnValue = null;
				const id = forId !== "" ? forId : values.managerUserId;
				if (id && companySupervisors && companySupervisors.length > 0) {
					companySupervisors.forEach((supervisor: Supervisor) => {
						if (parseInt(id) === parseInt(supervisor.id)) {
							returnValue = supervisor;
						}
					});
				}
				return returnValue;
			},
			[companySupervisors, values.managerUserId]
		);

		const getJobsByWorkCenter = useCallback(
			(workCenterId) => {
				const returnValue: any = [];
				if (workCenterId && allJobRoles && allJobRoles.length > 0) {
					allJobRoles.forEach((job: any) => {
						const workCenterIds = job.workCenterIds.split(",");
						if (workCenterIds.includes(workCenterId.toString())) {
							returnValue.push(job);
						}
					});
				}
				return returnValue;
			},
			[allJobRoles]
		);
		// console.log("getJobsByWorkCenter",getJobsByWorkCenter(values?.workCenterId));

		const getSelectedJobRole = useCallback(() => {
			let returnValue = null;
			const id = values.primaryJobId;
			if (id && allJobRoles && allJobRoles.length > 0) {
				allJobRoles.forEach((job: any) => {
					if (parseInt(id) === parseInt(job.id)) {
						returnValue = job;
					}
				});
			}
			return returnValue;
		}, [allJobRoles, values.primaryJobId]);

		useEffect(() => {
			if (values.locationId && parseInt(values.workerTypeId) === 1) {
				const locationId = values.locationId;
				dispatch(getLocationShiftPatterns({ locationId }));
			}
		}, [values.locationId, values.workerTypeId, dispatch]);

		const combineWorkerTypes = (workerTypes: any[], workerSubTypes: any[]) => {
			return workerTypes.map((type) => ({
				...type,
				subtypes: workerSubTypes
					.filter((subtype) => subtype.workerTypeId === type.id)
					.map((subtype) => ({
						id: subtype.id,
						name: subtype.name,
						description: subtype.description,
						isActive: subtype.isActive,
					})),
			}));
		};
		const combinedWorkerTypes = combineWorkerTypes(workerTypes, workerSubTypes);

		return (
			<Box
				sx={{
					borderRadius: "10px",
					border: isActive ? "2px solid #2F4D8B" : "3px solid #f4f4f4",
					pointerEvents: isActive ? "auto" : "none",
				}}
				ref={ref}
			>
				<Box p={2}>
					<Typography fontWeight='bold'>Worker details*</Typography>
					<Typography variant='caption'>
						Add in workers personal information.
					</Typography>
					<Box
						display='flex'
						justifyContent='flex-start'
						flexWrap='wrap'
						mt={2}
						sx={{
							minHeight: "80px",
						}}
					>
						<Box mr={3} width={300}>
							<FormControl variant='filled' className={classes.formControlRoot}>
								<Field
									component={TextField}
									name='firstName'
									id='firstName'
									type='text'
									label='First Name*'
									variant='filled'
									InputLabelProps={{
										shrink: true,
									}}
									margin='dense'
								/>
							</FormControl>
						</Box>
						<Box width={300}>
							<FormControl variant='filled' className={classes.formControlRoot}>
								<Field
									component={TextField}
									name='lastName'
									id='lastName'
									type='text'
									label='Last Name*'
									variant='filled'
									InputLabelProps={{
										shrink: true,
									}}
									margin='dense'
								/>
							</FormControl>
						</Box>
					</Box>
					<Box
						display='flex'
						justifyContent='flex-start'
						flexWrap='wrap'
						mt={2}
						sx={{
							minHeight: "80px",
						}}
					>
						<Box mr={3} width={240}>
							<FormControl>
								<PhoneTextField
									values={values}
									setFieldValue={setFieldValue}
									checkPhoneAlreadyExists={checkPhoneAlreadyExists}
								/>
							</FormControl>
						</Box>
						<Box ml={7.5} mt={1} mr={3} width={300}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									value={values.joiningDate ? dayjs(values.joiningDate) : null}
									onChange={(newValue: any) => {
										try {
											if (newValue) {
												setFieldValue(
													"joiningDate",
													format(new Date(newValue.$d), "yyyy-MM-dd")
												);
											}
										} catch (e) {
											// Do nothing
										}
									}}
									name='joiningDate'
									slotProps={{
										//   field: { clearable: true },
										textField: {
											placeholder: "Select Date",
											variant: "filled",
											helperText: "Most recent joining date in the company",
										},
									}}
									format={"MM/DD/YYYY"}
									sx={{ width: "100%" }}
									label='Joining Date*'
								/>
							</LocalizationProvider>
						</Box>
					</Box>
					<Box
						display='flex'
						justifyContent='flex-start'
						flexWrap='wrap'
						mt={2}
						sx={{
							minHeight: "80px",
						}}
					>
						{allLocations && allLocations.length > 0 && (
							<Box mr={3} width={300}>
								<Autocomplete
									disableClearable
									renderInput={(params) => (
										<MUITextField
											{...params}
											variant='filled'
											label='Location*'
											InputLabelProps={{
												shrink: true,
											}}
										/>
									)}
									value={getSelectedLocation()}
									options={allLocations}
									getOptionLabel={(option) => option.name}
									onChange={(_event, value) => {
										if (
											!isUserLocation(value.id) &&
											parseInt(lastSelectedLocationId) !== parseInt(value.id)
										) {
											if (parseInt(value.id) !== parseInt(values.id)) {
												const location = getSelectedLocation(
													lastSelectedLocationId
												) ?? { name: "" };
												confirm({
													title: "Are you sure you want to proceed?",
													description: `You are changing Location for ${values.firstName} ${values.lastName} to ${value.name}, which is different from your Location - ${location.name}.`,
												})
													.then(() => {
														setFieldValue("locationId", value.id);
														setFieldValue("shiftPatternId", 0);
														if (value.id !== values.locationId) {
															const newWorkCenters =
																allLocations.find(
																	(location) => location.id === value.id
																)?.workCenters ?? [];
															if (newWorkCenters.length > 0) {
																console.log("newWorkCenter", newWorkCenters);
																setFieldValue(
																	"workCenterId",
																	newWorkCenters[0].id
																);
															} else {
																setFieldValue("workCenterId", undefined);
															}
														}
														setSelectedShiftPattern({});
													})
													.catch(() => {
														/* ... */
													});
											}
										} else {
											setLastSelectedLocationId(value.id);
											setFieldValue("locationId", value.id);
											setFieldValue("shiftPatternId", 0);
											if (value.id !== values.locationId) {
												const newWorkCenters =
													allLocations.find(
														(location) => location.id === value.id
													)?.workCenters ?? [];
												if (newWorkCenters.length > 0) {
													setFieldValue("workCenterId", newWorkCenters[0].id);
												} else {
													setFieldValue("workCenterId", undefined);
												}
											}
											setSelectedShiftPattern({});
											setFieldValue("primaryJobId", 0);
										}
									}}
									renderOption={(props, option, { inputValue }) => (
										<li {...props} key={option.id}>
											<Box
												sx={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													justifyContent: "flex-start",
													minHeight: "32px",
												}}
											>
												<Typography
													sx={{
														fontFamily: "Roboto",
														fontSize: "14px !important",
														color: "rgba(0,0,0,0.87) !important",
														lineHeight: "20px !important",
														fontWeight: 400,
													}}
												>
													<Highlight
														value={option.name}
														searchText={inputValue}
													/>
												</Typography>
											</Box>
										</li>
									)}
								/>
							</Box>
						)}
						{locationWorkcenters && (
							<Box mr={3} width={300}>
								<Autocomplete
									disableClearable
									renderInput={(params) => (
										<MUITextField
											{...params}
											variant='filled'
											label='Workcenter'
											InputLabelProps={{
												shrink: true,
											}}
										/>
									)}
									value={getSelectedWorkcenter() ?? undefined}
									options={locationWorkcenters}
									getOptionLabel={(
										option:
											| {
													id: string | number;
													name: string;
											  }
											| undefined
									) => {
										if (option) {
											return option.name;
										} else {
											return "";
										}
									}}
									onChange={(_event, value) => {
										if (value) {
											setFieldValue("workCenterId", value.id);
											setFieldValue("primaryJobId", 0);
										}
									}}
									renderOption={(props, option, { inputValue }) => {
										if (
											option &&
											Object.prototype.hasOwnProperty.call(option, "id")
										) {
											return (
												<li {...props} key={option.id}>
													<Box
														sx={{
															display: "flex",
															flexDirection: "row",
															alignItems: "center",
															justifyContent: "flex-start",
															minHeight: "32px",
														}}
													>
														<Typography
															sx={{
																fontFamily: "Roboto",
																fontSize: "14px !important",
																color: "rgba(0,0,0,0.87) !important",
																lineHeight: "20px !important",
																fontWeight: 400,
															}}
														>
															<Highlight
																value={option.name ?? ""}
																searchText={inputValue}
															/>
														</Typography>
													</Box>
												</li>
											);
										} else {
											return <></>;
										}
									}}
								/>
							</Box>
						)}
					</Box>
					<Box
						display='flex'
						justifyContent='flex-start'
						flexWrap='wrap'
						mt={2}
						sx={{
							minHeight: "80px",
						}}
					>
						<Box mr={3} width={300}>
							<FormControl variant='filled' className={classes.formControlRoot}>
								{!isEditing ? (
									<Field
										variant='filled'
										component={TextField}
										name='companyEmployeeId'
										id='companyEmployeeId'
										label='Worker ID*'
										sx={{
											minHeight: "80px",
										}}
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{
											maxLength: 20,
											onKeyPress: (event: {
												key: string;
												preventDefault: () => void;
											}) => {
												if (event.key === " ") {
													event.preventDefault();
												}
											},
										}}
									/>
								) : (
									<Field
										variant='filled'
										component={TextField}
										name='companyEmployeeId'
										id='companyEmployeeId'
										label='Worker ID*'
										sx={{
											minHeight: "80px",
										}}
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{
											maxLength: 20,
											onKeyPress: (event: {
												key: string;
												preventDefault: () => void;
											}) => {
												if (event.key === " ") {
													event.preventDefault();
												}
											},
										}}
										onChange={(e: any) => {
											if (
												isEmployeeIdUniqueData.originalEmployeeId !==
												e.target.value
											) {
												dispatch(
													setIsEmployeeIdUnique({
														originalEmployeeId:
															isEmployeeIdUniqueData.originalEmployeeId,
														employeeId: e.target.value,
														isUnique: false,
														checked: false,
													})
												);
											} else {
												dispatch(
													setIsEmployeeIdUnique({
														originalEmployeeId:
															isEmployeeIdUniqueData.originalEmployeeId,
														employeeId: e.target.value,
														isUnique: true,
														checked: true,
													})
												);
											}
											setFieldValue("companyEmployeeId", e.target.value);
										}}
										InputProps={{
											endAdornment: getWorkerIdEndAdornment(),
										}}
										error={
											getWorkerIdError() ||
											!(
												getWorkerIdHelperText() === WORKER_ID_AVAILABLE ||
												getWorkerIdHelperText() === ""
											)
										}
										helperText={getWorkerIdHelperText()}
									/>
								)}
							</FormControl>
						</Box>
					</Box>
					<Box
						display='flex'
						justifyContent='flex-start'
						flexWrap='wrap'
						mt={2}
						sx={{
							minHeight: "80px",
						}}
					>
						<Box mr={3} width={300}>
							<FormControl variant='filled' className={classes.formControlRoot}>
								<Field
									variant='filled'
									component={TextField}
									name='jobTitle'
									id='jobTitle'
									label='Job Title'
									InputLabelProps={{
										shrink: true,
									}}
									inputProps={{
										maxLength: 100,
									}}
								/>
							</FormControl>
						</Box>
						{allJobRoles && (
							<Box mr={3} width={300}>
								<Autocomplete
									renderInput={(params) => (
										<MUITextField
											{...params}
											variant='filled'
											label='Primary Job'
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												...params.InputProps,
												startAdornment: values.primaryJobId !== 0 &&
													values.primaryJobHexColor && (
														<JobColor
															color={`#${values.primaryJobHexColor ?? ""}`}
														/>
													),
											}}
										/>
									)}
									value={getSelectedJobRole()}
									options={
										getJobsByWorkCenter(values?.workCenterId) ?? undefined
									}
									getOptionLabel={(
										option: any
										// | {
										//     id: string | number;
										//     name: string;
										//     hexColor: string;
										//   }
										// | undefined
									) => {
										if (option) {
											return option.name;
										} else {
											return "";
										}
									}}
									onChange={(_event, value) => {
										if (value) {
											setFieldValue("primaryJobId", value.id);
											setFieldValue("primaryJobHexColor", value.hexColor);
										} else {
											setFieldValue("primaryJobId", 0);
											setFieldValue("primaryJobHexColor", null);
										}
									}}
									renderOption={(props, option, { inputValue }) => {
										if (
											option &&
											Object.prototype.hasOwnProperty.call(option, "id")
										) {
											return (
												<li {...props} key={option.id}>
													<Box
														sx={{
															display: "flex",
															flexDirection: "row",
															alignItems: "center",
															justifyContent: "flex-start",
															minHeight: "32px",
															gap: 1,
														}}
													>
														<JobColor color={`#${option.hexColor}`} />
														<Typography
															sx={{
																fontFamily: "Roboto",
																fontSize: "14px !important",
																color: "rgba(0,0,0,0.87) !important",
																lineHeight: "20px !important",
																fontWeight: 400,
															}}
														>
															<Highlight
																value={option.name ?? ""}
																searchText={inputValue}
															/>
														</Typography>
													</Box>
												</li>
											);
										} else {
											return <></>;
										}
									}}
								/>
							</Box>
						)}
					</Box>
					<Box
						display='flex'
						justifyContent='flex-start'
						flexWrap='wrap'
						mt={2}
						sx={{
							minHeight: "80px",
						}}
					>
						{workerTypes && (
							<Box minWidth={300} mr={3}>
								<FormControl
									variant='filled'
									className={classes.formControlRoot}
								>
									<Field
										component={TextField}
										select
										name='type'
										label='Worker Type*'
										value={values.workerTypeId || ""}
										onChange={(e: { target: { value: string } }) => {
											setFieldValue("workerTypeId", e.target.value);
											setFieldValue(
												"workerTypeName",
												getWorkerType(parseInt(e.target.value)).name
											);
											setFieldValue(
												"workerTypeText",
												getWorkerType(parseInt(e.target.value)).text
											);
											// Reset shift pattern if worker type is not Full time
											if (parseInt(e.target.value) !== 1) {
												setFieldValue("shiftPatternId", 0);
											}
										}}
										variant='filled'
									>
										{workerTypes.map((type) => (
											<MenuItem key={`worker-type-${type.id}`} value={type.id}>
												{type.text ?? deCamelCase(type.name)}
											</MenuItem>
										))}
									</Field>
								</FormControl>
							</Box>
						)}
						{companySupervisors && (
							<Box mr={3} width={300}>
								<Autocomplete
									disableClearable
									renderInput={(params) => (
										<MUITextField
											{...params}
											variant='filled'
											label='Reporting Manager*'
											InputLabelProps={{
												shrink: true,
											}}
										/>
									)}
									value={getSelectedSupervisor() ?? undefined}
									options={companySupervisors}
									getOptionLabel={(option: Supervisor | undefined) => {
										if (option) {
											const firstName = option.firstName ?? "";
											const lastName = option.lastName ?? "";
											return firstName + " " + lastName;
										} else {
											return "";
										}
									}}
									onChange={(_event, value: Supervisor | undefined) => {
										if (value) {
											setFieldValue("managerUserId", value.id);
										}
									}}
									renderOption={(
										props,
										option: Supervisor | undefined,
										{ inputValue }
									) => {
										if (option) {
											const id = option.id ?? "worker-id";
											return (
												<li {...props} key={id}>
													<Box
														sx={{
															display: "flex",
															flexDirection: "row",
															alignItems: "center",
															justifyContent: "flex-start",
															minHeight: "32px",
														}}
													>
														<Typography
															sx={{
																fontFamily: "Roboto",
																fontSize: "14px !important",
																color: "rgba(0,0,0,0.87) !important",
																lineHeight: "20px !important",
																fontWeight: 400,
															}}
														>
															<Highlight
																value={option.firstName + " " + option.lastName}
																searchText={inputValue}
															/>
														</Typography>
													</Box>
												</li>
											);
										} else {
											return <></>;
										}
									}}
								/>
							</Box>
						)}
					</Box>
					<Box
						display='flex'
						justifyContent='flex-start'
						flexWrap='wrap'
						mt={2}
						sx={{
							minHeight: "80px",
						}}
					>
						{getLocationShiftPatternStatus === "pending" &&
						parseInt(values.workerTypeId) === 1 ? (
							<Box width={300} mt={2} ml={3} alignItems='center'>
								<CircularProgress size={20} />
							</Box>
						) : parseInt(values.workerTypeId) === 1 &&
						  getLocationShiftPatternStatus === "fulfilled" ? (
							<Box display='flex' justifyContent='flex-start' flexWrap='wrap'>
								<Box width={300}>
									<FormControl
										variant='filled'
										className={classes.formControlRoot}
										ref={myElementRef}
									>
										<Field
											component={TextField}
											type='text'
											select
											variant='filled'
											name='shiftPatternId'
											id='shiftPatternId'
											label='Shift Pattern'
											value={
												values.shiftPatternId === 0 ? "" : values.shiftPatternId
											}
											onChange={(e: { target: { value: any } }) => {
												setFieldValue("shiftPatternId", e.target.value);

												const locationShiftPattern =
													locationShiftPatterns?.find(
														(lsp: { id: any }) => lsp.id === e.target.value
													);
												if (locationShiftPattern)
													setSelectedShiftPattern(locationShiftPattern);
												else setSelectedShiftPattern({});
											}}
											helperText=' '
											fullWidth
											SelectProps={{
												MenuProps: {
													//disableScrollLock: true,
													PaperProps: {
														style: {
															maxHeight: ITEM_HEIGHT * 5 - ITEM_PADDING_TOP,
															width: 250,
														},
													},
													anchorOrigin: {
														vertical:
															position && position.y > 400 ? "top" : "bottom",
														horizontal: "left",
													},
													transformOrigin: {
														vertical:
															position && position.y > 400 ? "bottom" : "top",
														horizontal: "left",
													},
													getContentAnchorEl: null,
												},
											}}
										>
											{getLocationShiftPatternStatus === "pending" ? (
												<MenuItem disabled>
													<CircularProgress size={20} />
												</MenuItem>
											) : locationShiftPatterns.length > 0 ? (
												locationShiftPatterns.map(
													(lsp: {
														id: string | undefined;
														name:
															| boolean
															| React.ReactChild
															| React.ReactFragment
															| React.ReactPortal
															| null
															| undefined;
													}) => (
														<MenuItem value={lsp.id} key={lsp.id} id={lsp.id}>
															{lsp.name}
														</MenuItem>
													)
												)
											) : (
												<MenuItem disabled>
													<Typography color='black'>
														No shift patterns found
													</Typography>
												</MenuItem>
											)}
										</Field>
									</FormControl>
								</Box>
								<Box
									width={300}
									ml={3}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-start",
										paddingBottom: "17px",
									}}
								>
									<Button
										disabled={
											values?.shiftPatternId === 0 ||
											typeof selectedShiftPattern !== "object" ||
											(typeof selectedShiftPattern === "object" &&
												Object.keys(selectedShiftPattern).length === 0)
										}
										onClick={openShiftPattern}
									>
										View Shift Pattern Details
									</Button>
								</Box>
							</Box>
						) : null}

						{isWorkerSubTypeEnabled && parseInt(values.workerTypeId) === 3 && (
							<Box width={300} alignItems='center'>
								<Autocomplete<WorkerSubType, false>
									options={
										combinedWorkerTypes.find(
											(type: { id: number }) => type.id === 3
										)?.subtypes || []
									}
									getOptionLabel={(option: WorkerSubType) => option.name}
									value={
										combinedWorkerTypes
											.find((type: { id: number }) => type.id === 3)
											?.subtypes?.find(
												(subtype: { id: number }) =>
													subtype.id === values.workerSubTypeId
											) || null
									}
									onChange={(_, newValue) => {
										setFieldValue("workerSubTypeId", newValue?.id || "");
									}}
									renderInput={(params) => (
										<MUITextField
											{...params}
											variant='filled'
											label='Worker Subtype*'
											InputLabelProps={{
												shrink: true,
											}}
										/>
									)}
								/>
							</Box>
						)}
					</Box>
					{isActive ? (
						<Box
							display='flex'
							alignItems='center'
							justifyContent='space-between'
						>
							<Box m={0}>
								{(createNewWorkerStatus === "error" ||
									editWorkerStatus === "error") && (
									<Alert severity='error'>
										<AlertTitle>Error</AlertTitle>
										<strong>
											{createNewWorkerErrorMessage || editWorkerErrorMessage}
										</strong>
									</Alert>
								)}
							</Box>
							<Box display='flex' alignItems='center' justifyContent='flex-end'>
								<Box m={1}>
									<Button
										onClick={() => {
											isEditing
												? setActiveStep(0)
												: dispatch(closeAddWorkerDialog());
										}}
									>
										Cancel
									</Button>
								</Box>
								<Box m={1}>
									{/* <Button
									variant='contained'
									onClick={handleNext}
									disabled={
										!isValid ||
										!values.firstName ||
										!values.lastName ||
										!values.joiningDate ||
										!values.managerUserId ||
										checkWorkerIdAlreadyExists(values.companyEmployeeId)
									}
								>
									Next
								</Button> */}

									<Button
										style={{ textTransform: "none", fontWeight: "bold" }}
										variant='contained'
										color='primary'
										type='submit'
										startIcon={
											isSubmitting ? <CircularProgress size={20} /> : undefined
										}
										disabled={
											isEmployeeIdUniqueStatus === "pending" ||
											!isValid ||
											!dirty ||
											isSubmitting ||
											!values.joiningDate ||
											!values.managerUserId ||
											(isEditing &&
												((isEmployeeIdUniqueData?.employeeId ===
													values.companyEmployeeId &&
													isEmployeeIdUniqueData?.isUnique === false &&
													isEmployeeIdUniqueData?.checked === true) ||
													isEmployeeIdUniqueData?.checked === false)) ||
											(isWorkerSubTypeEnabled &&
												Number(values.workerTypeId) === 3 &&
												!values.workerSubTypeId)
										}
										//onClick={() => dispatch(resetWorkerDetails())}
									>
										{isSubmitting
											? isEditing
												? "Saving"
												: "Creating Worker"
											: isEditing
											? "Save"
											: "Create Worker"}
									</Button>
								</Box>
							</Box>
						</Box>
					) : null}
				</Box>
				<ShiftPatternDialog
					open={isShiftPatternOpen}
					handleClose={closeShiftPattern}
					shiftPattern={selectedShiftPattern}
					locationId={values.locationId}
				/>
			</Box>
		);
	}
);

export default WorkerDetailForm;
