import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import * as React from "react";

import CustomThemeProvider from "../custom-theme-provider";
import TabPanel from "./ui/tab-panel";
import { tabPanelMapper } from "./utils";

type Props = {
	tabPanelList: Array<React.ReactNode>;
	tabPanelLabel: Array<string>;
	onClickHandlers: { [key: string]: () => void };
};

const GigAndTakeTabs = ({
	tabPanelList,
	tabPanelLabel,
	onClickHandlers,
}: Props) => {
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<CustomThemeProvider>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label='Tabs'
						textColor='primary'
						indicatorColor='primary'
					>
						{tabPanelLabel.map((label, index) =>
							tabPanelMapper({ label, index, onClickHandlers })
						)}
					</Tabs>
				</CustomThemeProvider>
			</Box>
			{tabPanelList.map((tabPanel, index) => {
				return (
					<TabPanel value={value} index={index} key={index}>
						{tabPanel}
					</TabPanel>
				);
			})}
		</Box>
	);
};

export default GigAndTakeTabs;
