import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import { Box } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import React from "react";

import { deCamelCase } from "../../../../../../utils/helpers";
import CrossSkillFilterCheckbox from "../cross-skill-filter-checkbox";

interface WorkerSubTypeFilterProps {
	workerType: any;
	workerSubTypes: any;
	isWorkerTypeChecked: any;
	isWorkerSubTypeChecked: any;
	applyFilter: any;
	filterUpdateCount: any;
}

const WorkerSubTypeFilter: React.FC<WorkerSubTypeFilterProps> = ({
	workerType,
	workerSubTypes,
	isWorkerTypeChecked,
	isWorkerSubTypeChecked,
	applyFilter,
	filterUpdateCount,
}) => {
	const types = workerSubTypes.filter(
		(subType: any) => subType.workerTypeId === workerType.id
	);

	return (
		<Box>
			<FormControlLabel
				key={`worker-type-${workerType.id}`}
				control={
					<CrossSkillFilterCheckbox
						checked={isWorkerTypeChecked(workerType.id)}
						onChange={(e) => {
							if (e.target.checked) {
								applyFilter(
									"workerType",
									true,
									workerType.id,
									"",
									workerType.text
								);
							} else {
								applyFilter("workerType", false, workerType.id);
							}
						}}
					/>
				}
				label={
					<Box display='flex' alignItems='center'>
						{isWorkerTypeChecked(workerType.id) ? (
							<ArrowDropDown />
						) : (
							<ArrowRight />
						)}
						<span style={{ fontSize: "14px" }}>
							{workerType.text ?? deCamelCase(workerType.name)}
						</span>
					</Box>
				}
			/>
			{isWorkerTypeChecked(workerType.id) && (
				<Box
					sx={{
						marginLeft: "36px",
						display: "flex",
						flexDirection: "column",
						//gap: "12px",
					}}
				>
					{types.map((type: any) => (
						<FormControlLabel
							key={`worker-subtype-${type.id}`}
							control={
								<CrossSkillFilterCheckbox
									checked={isWorkerSubTypeChecked(type.id)}
									onChange={(e) => {
										if (e.target.checked) {
											applyFilter(
												"workerSubType",
												true,
												type.id,
												"",
												type.text
											);
										} else {
											applyFilter("workerSubType", false, type.id);
										}
									}}
								/>
							}
							label={
								<Box display='flex' alignItems='center'>
									<span style={{ fontSize: "14px", marginLeft: "10px" }}>
										{type.name ?? deCamelCase(type.name)}
									</span>
								</Box>
							}
						/>
					))}
				</Box>
			)}
		</Box>
	);
};

export default WorkerSubTypeFilter;
