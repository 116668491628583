import { useCallback, useEffect, useRef, useState } from "react";

const jobsFilterObject = {
	type: "job",
	jobs: [],
};

const locationsFilterObject = {
	type: "location",
	locations: [],
};

const skillsFilterObject = {
	type: "skill",
	skills: [],
};

const workerTypeFilterObject = {
	type: "workerType",
	workerTypes: [],
};

const workerSubTypeFilterObject = {
	type: "workerSubType",
	workerSubTypes: [],
};

const skillPriorityFilterObject = {
	type: "skillPriority",
	skillPrioritys: [],
};

const departmentFilterObject = {
	type: "department",
	departments: [],
};

const managerFilterObject = {
	type: "manager",
	managers: [],
};

const workCenterFilterObject = {
	type: "workCenter",
	workCenters: [],
};

const shiftPatternFilterObject = {
	type: "shiftPattern",
	shiftPatterns: [],
};

const useCrossSkillFilterController = ({
	filters,
	changeFilters,
	allJobRoles,
	allSkills,
	allWorkCenters, // work center supervisor is associated to
	allShiftPatterns,
	allLocations,
	allManagers,
	workerTypes,
	skillPriorities,
	skillMatrixAppliedFilters,
	skillMatrixFiltersWorkerSubTypes,
}) => {
	const [filteredJobs, setFilteredJobs] = useState([]);
	const [filteredLocations, setFilteredLocations] = useState([]);
	const [filteredSkills, setFilteredSkills] = useState([]);
	const [filteredManagers, setFilteredManagers] = useState([]);
	const [filteredWorkcenters, setFilteredWorkcenters] = useState([]);
	const [filteredShiftPatterns, setFilteredShiftPatterns] = useState([]);
	const [allWorkerTypes, setAllWorkerTypes] = useState([]);
	const [allWorkerSubTypes, setAllWorkerSubTypes] = useState([]);
	const [allSkillPriorities, setAllSkillPriorities] = useState([]);
	const [searchedJob, setSearchedJob] = useState("");
	const [searchedWorkcenter, setSearchedWorkcenter] = useState("");
	const [searchedWorkcenters, setSearchedWorkcenters] = useState([]);
	const [searchedLocation, setSearchedLocation] = useState("");
	const [searchedLocations, setSearchedLocations] = useState([]);
	const [searchedJobs, setSearchedJobs] = useState([]);
	const [searchedSkill, setSearchedSkill] = useState("");
	const [searchedSkills, setSearchedSkills] = useState([]);
	const [searchedDepartment, setSearchedDepartment] = useState("");
	const [searchedManager, setSearchedManager] = useState("");
	const [searchedShiftPattern, setSearchedShiftPattern] = useState("");
	const [searchedShiftPatterns, setSearchedShiftPatterns] = useState([]);
	const [expandedSkills, setExpandedSkills] = useState([]);
	const [workcenterExpanded, setWorkcenterExpanded] = useState(false);
	const [jobsExpanded, setJobsExpanded] = useState(true);
	const [locationsExpanded, setLocationsExpanded] = useState(false);
	const [skillsExpanded, setSkillsExpanded] = useState(false);
	const [departmentExpanded, setDepartmentExpanded] = useState(false);
	const [managerExpanded, setManagerExpanded] = useState(false);
	const [workerTypeExpanded, setWorkerTypeExpanded] = useState(false);
	const [workerSubTypeExpanded, setWorkerSubTypeExpanded] = useState(false);
	const [skillPriorityExpanded, setSkillPriorityExpanded] = useState(false);
	const [shiftPatternExpanded, setShiftPatternExpanded] = useState(false);
	const [filterUpdateCount, setFilterUpdateCount] = useState(0);

	// Multi filter states only for workcenter, job and skills
	const [reducedAllSkills, setReducedAllSkills] = useState([]);
	const [reducedAllJobs, setReducedAllJobs] = useState([]);
	const [reducedAllWorkCenters, setReducedAllWorkCenters] = useState([]);
	const [totalJobsFilters, setTotalJobsFilters] = useState(0);
	const [totalSkillsFilters, setTotalSkillsFilters] = useState(0);

	const skillListRef = useRef();
	const [rowHeightsForSkill, setRowHeightsForSkill] = useState({});

	const setRowHeightForSkill = (index, skillId, size) => {
		const newRowHeightsForSkill = { ...rowHeightsForSkill };
		newRowHeightsForSkill[skillId] = size;
		setRowHeightsForSkill(newRowHeightsForSkill);
		skillListRef.current.resetAfterIndex(index);
	};

	const getSkillItemSize = useCallback(
		(index, id) => {
			if (index === 0 || index === searchedSkills.length - 1) {
				return rowHeightsForSkill[id] > 42 ? rowHeightsForSkill[id] + 12 : 42; // Height for the first and last rows
			}
			return rowHeightsForSkill[id] || 30;
		},
		[rowHeightsForSkill, searchedSkills]
	);

	const getJobItemSize = useCallback(
		(index) => {
			if (index === 0 || index === searchedJobs.length - 1) {
				return 42; // Height for the first and last rows
			}
			return 30;
		},
		[searchedJobs]
	);

	const getLocationItemSize = useCallback(
		(index) => {
			if (index === 0 || index === searchedLocations.length - 1) {
				return 42; // Height for the first and last rows
			}
			return 30;
		},
		[searchedLocations]
	);

	const getWorkcenterItemSize = useCallback(
		(index) => {
			if (index === 0 || index === searchedWorkcenters.length - 1) {
				return 42;
			}
			return 30;
		},
		[searchedWorkcenters]
	);

	const getShiftPatternItemSize = useCallback(
		(index) => {
			if (index === 0 || index === searchedShiftPatterns.length - 1) {
				return 42;
			}
			return 30;
		},
		[searchedShiftPatterns]
	);
	// Pre-select work center of supervisor
	useEffect(() => {
		try {
			let locationObject = {
				...locationsFilterObject,
			};
			const locationFilter = skillMatrixAppliedFilters.locations
				? skillMatrixAppliedFilters.locations.split(",")
				: [];
			if (
				locationFilter.length !== 0 &&
				locationFilter.length !== allLocations.length
			) {
				locationObject.locations = locationFilter.reduce(
					(locations, locationId) => {
						const location = allLocations.find(
							(location) => location.id === parseInt(locationId)
						);
						if (location) {
							locations.push({
								id: location.id,
								name: location.name,
								timeAdded: new Date().getTime(),
							});
						}
						return locations;
					},
					[]
				);
			}

			let workCenterObject = {
				...workCenterFilterObject,
			};
			const workCenterFilter = skillMatrixAppliedFilters.workcenters
				? skillMatrixAppliedFilters.workcenters.split(",")
				: [];
			if (
				workCenterFilter.length !== 0 &&
				workCenterFilter.length !== allWorkCenters.length
			) {
				workCenterObject.workCenters = workCenterFilter.reduce((wcs, wcId) => {
					const wc = allWorkCenters.find((wc) => wc.id === parseInt(wcId));
					if (wc) {
						wcs.push({
							id: wc.id,
							name: wc.name,
							timeAdded: new Date().getTime() + 1,
						});
					}
					return wcs;
				}, []);
			}

			let shiftPatternObject = {
				...shiftPatternFilterObject,
			};
			const shiftPatternFilter = skillMatrixAppliedFilters.shiftPatterns
				? skillMatrixAppliedFilters.shiftPatterns.split(",")
				: [];
			if (
				shiftPatternFilter.length !== 0 &&
				shiftPatternFilter.length !== allShiftPatterns.length
			) {
				shiftPatternObject.workCenters = shiftPatternFilter.reduce(
					(sps, spId) => {
						const sp = allShiftPatterns.find((sp) => sp.id === parseInt(spId));
						if (sp) {
							sps.push({
								id: sp.id,
								name: sp.name,
								timeAdded: new Date().getTime() + 2,
							});
						}
						return sps;
					},
					[]
				);
			}

			let managerObject = {
				...managerFilterObject,
			};
			managerObject.managers = skillMatrixAppliedFilters.managers
				? skillMatrixAppliedFilters.managers
						.split(",")
						.reduce((managers, managerId) => {
							const manager = allManagers.find(
								(manager) => manager.id === parseInt(managerId)
							);
							if (manager) {
								managers.push({
									id: manager.id,
									name: manager.name,
									timeAdded: new Date().getTime() + 3,
								});
							}
							return managers;
						}, [])
				: [];
			changeFilters([locationObject, workCenterObject, managerObject]);
		} catch (e) {
			console.log("Error applying filter", e);
			// Do nothing
		}
	}, [
		changeFilters,
		skillMatrixAppliedFilters,
		allLocations,
		allWorkCenters,
		allManagers,
		allShiftPatterns,
	]);

	useEffect(() => {
		if (allSkills) {
			setReducedAllSkills(allSkills);
		}
	}, [allSkills]);

	useEffect(() => {
		if (allWorkCenters) {
			setReducedAllWorkCenters(allWorkCenters);
		}
	}, [allWorkCenters]);

	useEffect(() => {
		if (Object.values(allJobRoles)) {
			let workCentersInFilters = [];
			let skillsInFilters = [];
			let jobsInFilters = [];
			filters.forEach((filter) => {
				if (filter.type === "workCenter") {
					workCentersInFilters = filter.workCenters;
				}
				if (filter.type === "skill") {
					skillsInFilters = filter.skills;
				}
				if (filter.type === "job") {
					jobsInFilters = filter.jobs;
				}
			});

			// Check if job filter is applied before skill
			if (jobsInFilters.length > 0 && skillsInFilters.length > 0) {
				const earliestTimeJobAdded = jobsInFilters.reduce(
					(earliestTime, job) =>
						job.timeAdded < earliestTime ? job.timeAdded : earliestTime,
					new Date().getTime()
				);
				const earliestTimeSkillAdded = skillsInFilters.reduce(
					(earliestTime, skill) =>
						skill.timeAdded < earliestTime ? skill.timeAdded : earliestTime,
					new Date().getTime()
				);
				if (earliestTimeJobAdded < earliestTimeSkillAdded) {
					skillsInFilters = [];
				}
			}
			if (workCentersInFilters.length === 0) {
				const allJobs = Object.values(allJobRoles);
				if (skillsInFilters.length === 0) {
					setReducedAllJobs(allJobs);
				}
			} else if (workCentersInFilters) {
				// Filter based on work center
				const allJobs = Object.values(allJobRoles)?.filter((job) => {
					const wcIds = job.workCenterIdList
						? job.workCenterIdList.split(",")
						: [];
					const result = workCentersInFilters.filter((element) =>
						wcIds.includes(element.id.toString())
					);
					return result.length > 0;
				});
				// Filter based on skills
				if (skillsInFilters.length > 0) {
					let newReducedAllJobs = [];
					allJobs.forEach((job) => {
						skillsInFilters.forEach(({ id }) => {
							if (
								job.skills.findIndex(
									(s) => parseInt(s.skillId) === parseInt(id)
								) > -1
							) {
								newReducedAllJobs.push(job);
							}
						});
					});
					// Remove duplicate jobs if present in multiple skills
					newReducedAllJobs = newReducedAllJobs.filter(
						(v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
					);
					setReducedAllJobs(newReducedAllJobs);
				} else {
					// If no skill filter found
					setReducedAllJobs(allJobs);
				}
			}
		}
	}, [allJobRoles, filters]);

	useEffect(() => {
		if (workerTypes) {
			setAllWorkerTypes(workerTypes);
		}
	}, [workerTypes]);

	useEffect(() => {
		if (skillMatrixFiltersWorkerSubTypes) {
			setAllWorkerSubTypes(skillMatrixFiltersWorkerSubTypes);
		}
	}, [skillMatrixFiltersWorkerSubTypes]);

	useEffect(() => {
		if (skillPriorities) {
			setAllSkillPriorities(skillPriorities);
		}
	}, [skillPriorities]);

	useEffect(() => {
		if (allWorkCenters && filters.length !== 0) {
			const locationObject =
				filters.find((filter) => filter.type === "location") ?? {};
			const workCenterObject =
				filters.find((filter) => filter.type === "workCenter") ?? {};
			const locations = locationObject.locations ?? [];
			// To avoid infinite loop and apply only if work center filter is not applied
			if (locations.length > 0) {
				// Reduce work centers
				let newReducedAllWorkCenters = [];
				allWorkCenters.forEach((wc) => {
					locations.forEach((location) => {
						if (location.id === wc.locationId) {
							newReducedAllWorkCenters.push(wc);
						}
					});
				});
				// Set reduced work centers
				setReducedAllWorkCenters(newReducedAllWorkCenters);
			} else if (locations.length === 0) {
				// Show all work centers if no location filter is applied
				setReducedAllWorkCenters(allWorkCenters);
			}
		}
	}, [allWorkCenters, filters]);

	useEffect(() => {
		if (filteredJobs && filters.length !== 0) {
			const jobObject = filters.find((filter) => filter.type === "job") ?? {};
			const jobs = jobObject.jobs ?? [];
			const skillObject =
				filters.find((filter) => filter.type === "skill") ?? {};
			const skills = skillObject.skills ?? [];
			// To avoid infinite loop and only reduce when skill filer is not applied
			if (
				totalJobsFilters !== jobs.length &&
				jobs.length > 0 &&
				skills.length === 0
			) {
				// Reduce skills
				let newReducedAllSkills = [];
				const allJobsArray = Object.values(filteredJobs);
				allJobsArray.forEach((job) => {
					jobs.forEach(({ id }) => {
						if (parseInt(id) === parseInt(job.id)) {
							job.skills.forEach((skill) => {
								const skillObject = {
									id: skill.skillId,
									name: skill.name,
								};
								newReducedAllSkills.push(skillObject);
							});
						}
					});
				});
				// Remove duplicate skills if present in multiple jobs
				newReducedAllSkills = newReducedAllSkills.filter(
					(v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
				);
				// Set reduced skills
				setReducedAllSkills(newReducedAllSkills);
			} else if (jobs.length === 0) {
				// Show all skills if no job filter is applied
				setReducedAllSkills(allSkills);
			}
			setTotalJobsFilters(jobs.length);
		}
	}, [allSkills, filteredJobs, filters, totalJobsFilters]);

	useEffect(() => {
		if (filteredSkills && filters.length !== 0) {
			const skillObject =
				filters.find((filter) => filter.type === "skill") ?? {};
			const skills = skillObject.skills ?? [];
			const jobObject = filters.find((filter) => filter.type === "job") ?? {};
			const jobs = jobObject.jobs ?? [];
			let workCentersInFilters = [];
			filters.forEach((filter) => {
				if (filter.type === "workCenter") {
					workCentersInFilters = filter.workCenters;
				}
			});
			// To avoid infinite loop and apply only if job filter is not applied
			if (
				totalSkillsFilters !== skills.length &&
				skills.length > 0 &&
				jobs.length === 0
			) {
				// Reduce skills
				let newReducedAllJobs = [];
				filteredSkills.forEach((skill) => {
					skills.forEach(({ id }) => {
						if (skill.id === id) {
							let jobs = Object.values(allJobRoles);
							let result = [
								...jobs.filter(
									(x) =>
										x.skills.findIndex(
											(s) => parseInt(s.skillId) === parseInt(id)
										) > -1
								),
							];
							if (result) {
								newReducedAllJobs = newReducedAllJobs.concat(result);
							}
						}
					});
				});
				// Remove duplicate skills if present in multiple jobs
				newReducedAllJobs = newReducedAllJobs.filter(
					(v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
				);
				const allJobs = Object.values(newReducedAllJobs)?.filter((job) => {
					const wcIds = job.workCenterIdList
						? job.workCenterIdList.split(",")
						: [];
					if (workCentersInFilters.length > 0) {
						const result = workCentersInFilters.filter((element) =>
							wcIds.includes(element.id.toString())
						);
						return result.length > 0;
					} else {
						return true;
					}
				});
				// Set reduced skills
				setReducedAllJobs(allJobs);
			} else if (skills.length === 0) {
				const allJobs = Object.values(allJobRoles)?.filter((job) => {
					const wcIds = job.workCenterIdList
						? job.workCenterIdList.split(",")
						: [];
					if (workCentersInFilters.length > 0) {
						const result = workCentersInFilters.filter((element) =>
							wcIds.includes(element.id.toString())
						);
						return result.length > 0;
					} else {
						return true;
					}
				});
				// Show all skills if no job filter is applied
				setReducedAllJobs(allJobs);
			}
			setTotalSkillsFilters(skills.length);
		}
	}, [filteredSkills, filters, allJobRoles, totalSkillsFilters]);

	useEffect(() => {
		const searchJobs = (text) => {
			let jobs = Object.values(reducedAllJobs);
			let result = [
				...jobs.filter((x) =>
					x.name.toLowerCase().includes(text?.trimStart("").toLowerCase())
				),
			];
			if (result.length > 0) {
				setSearchedJobs(result);
			} else {
				setSearchedJobs(jobs);
			}
		};

		if (searchedJob !== "") {
			searchJobs(searchedJob);
		} else {
			setSearchedJobs(Object.values(reducedAllJobs));
			setFilteredJobs(Object.values(reducedAllJobs));
		}
	}, [searchedJob, reducedAllJobs]);

	useEffect(() => {
		const searchManager = (text) => {
			let result = [
				...allManagers.filter((x) =>
					x.name.toLowerCase().includes(text?.trimStart("").toLowerCase())
				),
			];
			if (result.length > 0) {
				setFilteredManagers(result);
			} else {
				setFilteredManagers(allManagers);
			}
		};
		if (searchedManager !== "") {
			searchManager(searchedManager);
		} else {
			setFilteredManagers(allManagers);
		}
	}, [searchedManager, allManagers]);

	useEffect(() => {
		const searchLocation = (text) => {
			let result = [
				...allLocations.filter((x) =>
					x.name.toLowerCase().includes(text?.trimStart("").toLowerCase())
				),
			];
			if (result.length > 0) {
				setSearchedLocations(result);
			} else {
				setSearchedLocations(allLocations);
			}
		};
		if (searchedLocation !== "") {
			searchLocation(searchedLocation);
		} else {
			setSearchedLocations(allLocations);
			setFilteredLocations(allLocations);
		}
	}, [searchedLocation, allLocations]);

	useEffect(() => {
		const searchShiftPattern = (text) => {
			let result = [
				...allShiftPatterns.filter((x) =>
					x.name.toLowerCase().includes(text?.trimStart("").toLowerCase())
				),
			];
			if (result.length > 0) {
				setSearchedShiftPatterns(result);
			} else {
				setSearchedShiftPatterns(allShiftPatterns);
			}
		};
		if (searchedShiftPattern !== "") {
			searchShiftPattern(searchedShiftPattern);
		} else {
			setSearchedShiftPatterns(allShiftPatterns);
			setFilteredShiftPatterns(allShiftPatterns);
		}
	}, [searchedShiftPattern, allShiftPatterns]);

	useEffect(() => {
		const searchWorkcenter = (text) => {
			let result = [
				...reducedAllWorkCenters.filter((x) =>
					x.name.toLowerCase().includes(text?.trimStart("").toLowerCase())
				),
			];
			if (result.length > 0) {
				setSearchedWorkcenters(result);
			} else {
				setSearchedWorkcenters(reducedAllWorkCenters);
			}
		};
		if (searchedWorkcenter !== "") {
			searchWorkcenter(searchedWorkcenter);
		} else {
			setSearchedWorkcenters(reducedAllWorkCenters);
			setFilteredWorkcenters(reducedAllWorkCenters);
		}
	}, [searchedWorkcenter, reducedAllWorkCenters]);

	useEffect(() => {
		const searchSkills = (text) => {
			let result = [
				...reducedAllSkills.filter((x) =>
					x.name.toLowerCase().includes(text?.trimStart("").toLowerCase())
				),
			];
			if (result.length > 0) {
				setSearchedSkills(result);
			} else {
				setSearchedSkills(reducedAllSkills);
			}
		};
		if (searchedSkill !== "") {
			searchSkills(searchedSkill);
		} else {
			setSearchedSkills(reducedAllSkills);
			setFilteredSkills(reducedAllSkills);
		}
		if (skillListRef.current) {
			// Re-render after search
			skillListRef.current.resetAfterIndex(0);
		}
	}, [searchedSkill, reducedAllSkills]);

	const resetFilters = (filtersToReset = []) => {
		try {
			if (filtersToReset.length > 0) {
				let newFilters = [...filters];
				filtersToReset.forEach((type) => {
					const index = newFilters.findIndex((obj) => obj.type === type);
					newFilters[index][`${type}s`] = [];
					if (type === "workerType") {
						const workerSubTypeIndex = newFilters.findIndex(
							(obj) => obj.type === "workerSubType"
						);
						newFilters[workerSubTypeIndex].workerSubTypes = [];
					}
				});
				changeFilters(newFilters);
			} else {
				changeFilters([
					{
						type: "location",
						locations: [],
					},
					{
						type: "job",
						jobs: [],
					},
					{
						type: "skill",
						skills: [],
					},
					{
						type: "workerType",
						workerTypes: [],
						workerSubTypes: [],
					},
					{
						type: "workerSubType",
						workerSubTypes: [],
					},
					{
						type: "skillPriority",
						skillPrioritys: [],
					},

					{
						type: "department",
						departments: [],
					},
					{
						type: "manager",
						managers: [],
					},
					{
						type: "workCenter",
						workCenters: [],
					},
					{
						type: "shiftPattern",
						shiftPatterns: [],
					},
				]);
			}

			setExpandedSkills([]);
			setSearchedSkill("");
			setSearchedJob("");
			setSearchedManager("");
			setSearchedDepartment("");
			setSearchedWorkcenter("");
			setSearchedShiftPattern("");
			setSearchedLocation("");
			setRowHeightsForSkill({});
			skillListRef.current.resetAfterIndex(0);
		} catch (e) {
			// Do nothing
		}
	};

	const applyFilter = (type, isAdding, id, levelId = "", name = "") => {
		// Default values
		let jobObject = { ...jobsFilterObject };
		let locationObject = { ...locationsFilterObject };
		let skillObject = { ...skillsFilterObject };
		let workerTypeObject = { ...workerTypeFilterObject };
		let workerSubTypeObject = { ...workerSubTypeFilterObject };
		let skillPriorityObject = { ...skillPriorityFilterObject };
		let departmentObject = { ...departmentFilterObject };
		let managerObject = { ...managerFilterObject };

		let workCenterObject = {
			...workCenterFilterObject,
		};
		let shiftPatternObject = { ...shiftPatternFilterObject };
		// Existing values
		filters.forEach((filter) => {
			if (filter.type === "location") {
				locationObject = { ...filter };
			}
			if (filter.type === "job") {
				jobObject = { ...filter };
			}
			if (filter.type === "skill") {
				skillObject = { ...filter };
			}
			if (filter.type === "workerType") {
				workerTypeObject = { ...filter };
			}
			if (filter.type === "workerSubType") {
				workerSubTypeObject = { ...filter };
			}
			if (filter.type === "skillPriority") {
				skillPriorityObject = { ...filter };
			}

			if (filter.type === "department") {
				departmentObject = { ...filter };
			}
			if (filter.type === "manager") {
				managerObject = { ...filter };
			}
			if (filter.type === "workCenter") {
				workCenterObject = { ...filter };
			}
			if (filter.type === "shiftPattern") {
				shiftPatternObject = { ...filter };
			}
		});
		if (isAdding) {
			// For org filter
			let idsToAdd = [id];

			// Adding filter
			switch (type) {
				case "location":
					// Remove if location exists
					locationObject = {
						...locationObject,
						locations: locationObject.locations.filter((x) => x.id !== id),
					};
					// Add location
					locationObject.locations.push({
						id,
						name,
						timeAdded: new Date().getTime(),
					});
					break;
				case "job":
					// Remove if job exists
					jobObject = {
						...jobObject,
						jobs: jobObject.jobs.filter((x) => x.id !== id),
					};
					// Add job
					jobObject.jobs.push({ id, name, timeAdded: new Date().getTime() });
					break;
				case "skill":
					// Add skill
					if (levelId !== "") {
						let skillByIdArray = skillObject.skills.filter((x) => x.id === id);
						let skillObjectById = { id: id, levelIds: [] };
						if (skillByIdArray.length > 0) {
							skillObjectById = skillByIdArray[0];
							// Remove existing same levelId if any
							skillObjectById.levelIds = skillObjectById.levelIds.filter(
								(x) => x !== levelId
							);
						}
						// Add new levelId
						skillObjectById.levelIds.push(levelId);
						// Find index of skill object to update
						const skillIndex = skillObject.skills.findIndex(
							(el) => parseInt(el.id) === parseInt(id)
						);
						// Update levelIds
						skillObject.skills[skillIndex].levelIds = skillObjectById.levelIds;
					} else {
						skillObject.skills.push({
							id: id,
							name: name,
							levelIds: [],
							timeAdded: new Date().getTime(),
						});
					}
					break;
				case "workerType":
					// Remove if worker type exists
					workerTypeObject = {
						...workerTypeObject,
						workerTypes: workerTypeObject.workerTypes.filter(
							(x) => x.id !== id
						),
					};
					// Add worker type
					workerTypeObject.workerTypes.push({
						id: id,
						text: name,
						timeAdded: new Date().getTime(),
					});
					break;
				case "workerSubType":
					// Remove if worker sub type exists
					workerSubTypeObject = {
						...workerSubTypeObject,
						workerSubTypes: workerSubTypeObject.workerSubTypes.filter(
							(x) => x.id !== id
						),
					};
					// Add worker sub type
					workerSubTypeObject.workerSubTypes.push({
						id: id,
						text: name,
						timeAdded: new Date().getTime(),
					});
					break;
				case "skillPriority":
					skillPriorityObject = {
						...skillPriorityObject,
						skillPrioritys: skillPriorityObject.skillPrioritys.filter(
							(x) => x.id !== id
						),
					};
					skillPriorityObject.skillPrioritys.push({
						id: id,
						text: name,
						timeAdded: new Date().getTime(),
					});
					break;
				case "department":
					// Remove department if exists
					departmentObject = {
						...departmentObject,
						departments: departmentObject.departments.filter(
							(x) => x.id !== id
						),
					};
					// Add department id
					departmentObject.departments.push({
						id,
						timeAdded: new Date().getTime(),
					});
					break;
				case "manager":
					// Remove manager if exists
					managerObject = {
						...managerObject,
						managers: managerObject.managers.filter((x) => x.id !== id),
					};
					// Add manager id
					managerObject.managers.push({
						id,
						name,
						timeAdded: new Date().getTime(),
					});
					break;

				case "workCenter":
					// Remove work center if exists
					workCenterObject = {
						...workCenterObject,
						workCenters: workCenterObject.workCenters.filter(
							(x) => x.id !== id
						),
					};
					// Add work center id
					workCenterObject.workCenters.push({
						id,
						name,
						timeAdded: new Date().getTime(),
					});
					break;

				case "shiftPattern":
					// Remove shift pattern if exists
					shiftPatternObject = {
						...shiftPatternObject,
						shiftPatterns: shiftPatternObject.shiftPatterns.filter(
							(x) => x.id !== id
						),
					};
					// Add work center id
					shiftPatternObject.shiftPatterns.push({
						id,
						name,
						timeAdded: new Date().getTime(),
					});
					break;
				default:
					break;
			}
		} else {
			// Removing filter
			switch (type) {
				case "location":
					// Remove location
					locationObject = {
						...locationObject,
						locations: locationObject.locations.filter((x) => x.id !== id),
					};
					break;
				case "job":
					// Remove job
					jobObject = {
						...jobObject,
						jobs: jobObject.jobs.filter((x) => x.id !== id),
					};
					break;
				case "skill":
					if (levelId !== "") {
						// If level removed
						let skillByIdArray = skillObject.skills.filter((x) => x.id === id);
						let skillObjectById = { id: id, levelIds: [] };
						if (skillByIdArray.length > 0) {
							skillObjectById = skillByIdArray[0];
							skillObjectById.levelIds = skillObjectById.levelIds.filter(
								(x) => x !== levelId
							);
						}
						// Remove existing skill
						skillObject = {
							...skillObject,
							skills: skillObject.skills.filter((x) => x.id !== id),
						};
						// Push skill with updated levelIds
						skillObject.skills.push({
							id: id,
							name: name,
							levelIds: skillObjectById.levelIds,
							timeAdded: new Date().getTime(),
						});
					} else {
						skillObject = {
							...skillObject,
							skills: skillObject.skills.filter((x) => x.id !== id),
						};
					}
					break;
				case "workerType":
					// Remove worker type
					workerTypeObject = {
						...workerTypeObject,
						workerTypes: workerTypeObject.workerTypes.filter(
							(x) => x.id !== id
						),
					};
					if (workerSubTypeObject.workerSubTypes.length > 0) {
						workerSubTypeObject = {
							...workerSubTypeObject,
							workerSubTypes: [],
						};
					}
					break;
				case "workerSubType":
					// Remove worker sub type
					workerSubTypeObject = {
						...workerSubTypeObject,
						workerSubTypes: workerSubTypeObject.workerSubTypes.filter(
							(x) => x.id !== id
						),
					};
					break;
				case "skillPriority":
					// Remove skill priority
					skillPriorityObject = {
						...skillPriorityObject,
						skillPrioritys: skillPriorityObject.skillPrioritys.filter(
							(x) => x.id !== id
						),
					};
					break;
				case "department":
					// Remove department
					departmentObject = {
						...departmentObject,
						departments: departmentObject.departments.filter(
							(x) => x.id !== id
						),
					};
					break;
				case "manager":
					// Remove manager
					managerObject = {
						...managerObject,
						managers: managerObject.managers.filter((x) => x.id !== id),
					};
					break;

				case "workCenter":
					// Remove work center
					workCenterObject = {
						...workCenterObject,
						workCenters: workCenterObject.workCenters.filter(
							(x) => x.id !== id
						),
					};
					break;

				case "shiftPattern":
					// Remove work center
					shiftPatternObject = {
						...shiftPatternObject,
						shiftPatterns: shiftPatternObject.shiftPatterns.filter(
							(x) => x.id !== id
						),
					};
					break;
				default:
					break;
			}
		}
		// Set filters to parent component
		changeFilters([
			locationObject,
			jobObject,
			skillObject,
			workerTypeObject,
			workerSubTypeObject,
			skillPriorityObject,
			departmentObject,
			managerObject,
			workCenterObject,
			shiftPatternObject,
		]);
	};

	const isSkillLevelChecked = (skillId, levelId) => {
		let returnValue = false;
		filters.forEach((filter) => {
			if (filter.type === "skill") {
				filter.skills.forEach((fSkill) => {
					if (fSkill.id === skillId && fSkill.levelIds.includes(levelId)) {
						returnValue = true;
					}
				});
			}
		});
		return returnValue;
	};

	const isSkillChecked = (skillId) => {
		let returnValue = false;
		filters.forEach((filter) => {
			if (filter.type === "skill") {
				filter.skills.forEach((fSkill) => {
					if (fSkill.id === skillId) {
						returnValue = true;
					}
				});
			}
		});
		return returnValue;
	};

	const isJobChecked = (jobId) => {
		let returnValue = false;
		filters.forEach((filter) => {
			if (filter.type === "job") {
				filter.jobs.forEach((fJob) => {
					if (fJob.id === jobId) {
						returnValue = true;
					}
				});
			}
		});
		return returnValue;
	};

	const isLocationChecked = (locationId) => {
		let returnValue = false;
		filters.forEach((filter) => {
			if (filter.type === "location") {
				filter.locations.forEach((fLocation) => {
					if (fLocation.id === locationId) {
						returnValue = true;
					}
				});
			}
		});
		return returnValue;
	};

	const isDepartmentChecked = (departmentId) => {
		let returnValue = false;
		filters.forEach((filter) => {
			if (filter.type === "department") {
				filter.departments.forEach((fDepartment) => {
					if (fDepartment.id === departmentId) {
						returnValue = true;
					}
				});
			}
		});
		return returnValue;
	};

	const isManagerChecked = (managerId) => {
		let returnValue = false;
		filters.forEach((filter) => {
			if (filter.type === "manager") {
				filter.managers.forEach((fManager) => {
					if (fManager.id === managerId) {
						returnValue = true;
					}
				});
			}
		});
		return returnValue;
	};

	const isWorkCenterChecked = (workCenterId) => {
		let returnValue = false;
		filters.forEach((filter) => {
			if (filter.type === "workCenter") {
				filter.workCenters.forEach((fWorkCenter) => {
					if (fWorkCenter.id === workCenterId) {
						returnValue = true;
					}
				});
			}
		});
		return returnValue;
	};

	const isShiftPatternChecked = (shiftPatternId) => {
		let returnValue = false;
		filters.forEach((filter) => {
			if (filter.type === "shiftPattern") {
				filter.shiftPatterns.forEach((fShiftPattern) => {
					if (fShiftPattern.id === shiftPatternId) {
						returnValue = true;
					}
				});
			}
		});
		return returnValue;
	};

	const isWorkerTypeChecked = (workerTypeId) => {
		let returnValue = false;
		filters.forEach((filter) => {
			if (filter.type === "workerType") {
				filter.workerTypes.forEach((fWorkerType) => {
					if (fWorkerType.id === workerTypeId) {
						returnValue = true;
					}
				});
			}
		});
		return returnValue;
	};

	const isWorkerSubTypeChecked = (workerSubTypeId) => {
		let returnValue = false;
		filters.forEach((filter) => {
			if (filter.type === "workerSubType") {
				filter.workerSubTypes.forEach((fWorkerSubType) => {
					if (fWorkerSubType.id === workerSubTypeId) {
						returnValue = true;
					}
				});
			}
		});
		return returnValue;
	};
	const isSkillPriorityChecked = (skillPriorityId) => {
		let returnValue = false;
		filters.forEach((filter) => {
			if (filter.type === "skillPriority") {
				filter.skillPrioritys.forEach((fSkillPriority) => {
					if (fSkillPriority.id === skillPriorityId) {
						returnValue = true;
					}
				});
			}
		});
		return returnValue;
	};

	const expandSkill = (skillId) => {
		let tempExpandedSkills = [...expandedSkills];
		let searchedSkill = tempExpandedSkills.filter((x) => x === skillId);
		if (searchedSkill.length > 0) {
			tempExpandedSkills = tempExpandedSkills.filter((x) => x !== skillId);
		} else {
			tempExpandedSkills.push(skillId);
		}
		setExpandedSkills(tempExpandedSkills);
	};

	const isSkillExpanded = (skillId) => {
		let returnValue = false;
		expandedSkills.forEach((eSkillId) => {
			if (eSkillId === skillId) {
				returnValue = true;
			}
		});
		return returnValue;
	};

	return [
		{
			isSkillLevelChecked,
			isSkillChecked,
			isJobChecked,
			isLocationChecked,
			isWorkerTypeChecked,
			isWorkerSubTypeChecked,
			isSkillPriorityChecked,
			isDepartmentChecked,
			isManagerChecked,
			isWorkCenterChecked,
			isShiftPatternChecked,
			isSkillExpanded,
			skillListRef,
			filterUpdateCount,
			filteredJobs,
			jobsExpanded,
			workcenterExpanded,
			filteredSkills,
			filteredLocations,
			filteredManagers,
			allWorkerTypes,
			allWorkerSubTypes,
			allSkillPriorities,
			allShiftPatterns,
			skillsExpanded,
			departmentExpanded,
			locationsExpanded,
			managerExpanded,
			workerTypeExpanded,
			workerSubTypeExpanded,
			skillPriorityExpanded,
			shiftPatternExpanded,
			searchedJob,
			searchedLocation,
			searchedJobs,
			searchedSkill,
			searchedSkills,
			setSearchedWorkcenter,
			setSearchedLocations,
			searchedDepartment,
			searchedManager,
			searchedLocations,
			filteredWorkcenters,
			filteredShiftPatterns,
			searchedWorkcenter,
			searchedShiftPattern,
			searchedWorkcenters,
			searchedShiftPatterns,
			getSkillItemSize,
			getJobItemSize,
			getLocationItemSize,
			getWorkcenterItemSize,
			getShiftPatternItemSize,
			rowHeightsForSkill,
		},
		{
			resetFilters,
			applyFilter,
			expandSkill,
			setJobsExpanded,
			setWorkcenterExpanded,
			setShiftPatternExpanded,
			setSkillsExpanded,
			setDepartmentExpanded,
			setManagerExpanded,
			setWorkerTypeExpanded,
			setWorkerSubTypeExpanded,
			setSkillPriorityExpanded,
			setLocationsExpanded,
			setSearchedJob,
			setSearchedDepartment,
			setSearchedManager,
			setSearchedLocations,
			setSearchedSkill,
			setSearchedWorkcenter,
			setSearchedShiftPattern,
			setSearchedLocation,
			setFilterUpdateCount,
			setRowHeightForSkill,
		},
	];
};

export default useCrossSkillFilterController;
